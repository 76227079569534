@charset "UTF-8";
/*!
 * 
 * antd v4.6.3
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

[class^=ant-]::-ms-clear,
[class*=ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*=ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*=ant-] input::-ms-reveal {
  display: none; }

[class^=ant-],
[class*=ant-],
[class^=ant-] *,
[class*=ant-] *,
[class^=ant-] *::before,
[class*=ant-] *::before,
[class^=ant-] *::after,
[class*=ant-] *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%; }

input::-ms-clear,
input::-ms-reveal {
  display: none; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum"; }

[tabindex='-1']:focus {
  outline: none !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500; }

p {
  margin-top: 0;
  margin-bottom: 1em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help; }

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit; }

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: 0.5em;
  margin-left: 0; }

blockquote {
  margin: 0 0 1em; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #40a9ff; }

a:active {
  color: #096dd9; }

a:active,
a:hover {
  text-decoration: none;
  outline: 0; }

a:focus {
  text-decoration: none;
  outline: 0; }

a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none; }

pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace; }

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto; }

figure {
  margin: 0 0 1em; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

mark {
  padding: 0.2em;
  background-color: #feffe6; }

::-moz-selection {
  color: #fff;
  background: #1890ff; }

::selection {
  color: #fff;
  background: #1890ff; }

.clearfix::before {
  display: table;
  content: ''; }

.clearfix::after {
  display: table;
  clear: both;
  content: ''; }

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.anticon > * {
  line-height: 1; }

.anticon svg {
  display: inline-block; }

.anticon::before {
  display: none; }

.anticon .anticon-icon {
  display: block; }

.anticon[tabindex] {
  cursor: pointer; }

.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear; }

.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear; }

.fade-enter,
.fade-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
  animation-name: antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
  animation-name: antFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes antFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes antFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.move-up-enter,
.move-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.move-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
  animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
  animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.move-down-enter,
.move-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.move-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
  animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
  animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.move-left-enter,
.move-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.move-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
  animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
  animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.move-right-enter,
.move-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.move-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
  animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
  animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; } }

@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; } }

@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; } }

@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; } }

@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; } }

@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; } }

@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; } }

@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; } }

@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; } }

@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; } }

@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; } }

@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; } }

@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; } }

@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; } }

@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; } }

@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0; } }

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative; }

html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0; }

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 #1890ff;
  -webkit-box-shadow: 0 0 0 0 #1890ff;
  -webkit-box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none; }

@-webkit-keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    box-shadow: 0 0 0 6px #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color); } }

@keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    box-shadow: 0 0 0 6px #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color); } }

@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0; } }

@keyframes fadeEffect {
  100% {
    opacity: 0; } }

.slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.slide-left-enter,
.slide-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
  animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
  animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.slide-right-enter,
.slide-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
  animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
  animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; } }

@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; } }

@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; } }

@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; } }

@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1; } }

@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1; } }

@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0; } }

@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0; } }

@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; } }

@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; } }

@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; } }

@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; } }

@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1; } }

@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1; } }

@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0; } }

@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0; } }

.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.zoom-big-enter,
.zoom-big-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.zoom-big-enter,
.zoom-big-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.zoom-up-enter,
.zoom-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
  animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
  animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.zoom-up-enter,
.zoom-up-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.zoom-down-enter,
.zoom-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
  animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
  animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.zoom-down-enter,
.zoom-down-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.zoom-left-enter,
.zoom-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
  animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
  animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.zoom-left-enter,
.zoom-left-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.zoom-right-enter,
.zoom-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
  animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
  animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.zoom-right-enter,
.zoom-right-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0; } }

@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0; } }

@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; } }

@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; } }

@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%; } }

@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%; } }

@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%; }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0; } }

@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%; }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0; } }

@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%; } }

@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%; } }

@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%; }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0; } }

@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%; }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0; } }

@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%; } }

@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%; } }

@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%; }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0; } }

@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%; }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0; } }

@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%; } }

@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%; } }

@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%; }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0; } }

@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%; }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0; } }

.ant-motion-collapse-legacy {
  overflow: hidden; }

.ant-motion-collapse-legacy-active {
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important; }

.ant-motion-collapse {
  overflow: hidden;
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
  position: fixed;
  z-index: 10; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-alert {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 2px; }

.ant-alert.ant-alert-no-icon {
  padding: 8px 15px; }

.ant-alert.ant-alert-no-icon .ant-alert-close-icon {
  top: 12.0005px; }

.ant-alert.ant-alert-closable {
  padding-right: 30px; }

.ant-alert-icon {
  position: absolute;
  top: 12.0005px;
  left: 16px; }

.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px; }

.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f; }

.ant-alert-success .ant-alert-icon {
  color: #52c41a; }

.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff; }

.ant-alert-info .ant-alert-icon {
  color: #1890ff; }

.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f; }

.ant-alert-warning .ant-alert-icon {
  color: #faad14; }

.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7; }

.ant-alert-error .ant-alert-icon {
  color: #ff4d4f; }

.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0; }

.ant-alert-close-icon {
  position: absolute;
  top: 12.0005px;
  right: 16px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer; }

.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75); }

.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75); }

.ant-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 63px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  border-radius: 2px; }

.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px; }

.ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 15px;
  left: 24px;
  font-size: 24px; }

.ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer; }

.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px; }

.ant-alert-message {
  color: rgba(0, 0, 0, 0.85); }

.ant-alert-with-description .ant-alert-description {
  display: block; }

.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  -webkit-transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0; }

.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0; }

.ant-alert.ant-alert-rtl {
  padding: 8px 37px 8px 15px;
  direction: rtl; }

.ant-alert-rtl.ant-alert.ant-alert-no-icon {
  padding: 8px 15px; }

.ant-alert.ant-alert-rtl.ant-alert.ant-alert-closable {
  padding-right: 37px;
  padding-left: 30px; }

.ant-alert.ant-alert-rtl.ant-alert.ant-alert-no-icon.ant-alert-closable {
  padding-right: 15px;
  padding-left: 30px; }

.ant-alert-rtl .ant-alert-icon {
  right: 16px;
  left: auto; }

.ant-alert-rtl .ant-alert-close-icon {
  right: auto;
  left: 16px; }

.ant-alert.ant-alert-rtl.ant-alert-with-description,
.ant-alert.ant-alert-rtl.ant-alert-with-description.ant-alert-closable {
  padding: 15px 63px 15px 15px; }

.ant-alert.ant-alert-rtl.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px; }

.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  right: 24px;
  left: auto; }

.ant-alert-rtl.ant-alert-with-description .ant-alert-close-icon {
  right: auto;
  left: 16px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-anchor {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px; }

.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff; }

.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; }

.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' '; }

.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #1890ff;
  border-radius: 8px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: top 0.3s ease-in-out;
  transition: top 0.3s ease-in-out; }

.ant-anchor-ink-ball.visible {
  display: inline-block; }

.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none; }

.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143; }

.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-anchor-link-title:only-child {
  margin-bottom: 0; }

.ant-anchor-link-active > .ant-anchor-link-title {
  color: #1890ff; }

.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px; }

.ant-anchor-rtl {
  direction: rtl; }

.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0; }

.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto; }

.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  -webkit-transform: translateX(50%);
  transform: translateX(50%); }

.ant-anchor-rtl .ant-anchor-link {
  padding: 7px 16px 7px 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-select-auto-complete {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum"; }

.ant-select-auto-complete .ant-select-clear {
  right: 13px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-select-single .ant-select-selector {
  display: -ms-flexbox;
  display: flex; }

.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px; }

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%; }

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px; } }

.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none; }

.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\A0'; }

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px; }

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px; }

.ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: 0.4; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 32px;
  padding: 0 11px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer; }

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text; }

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto; }

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed; }

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px; }

.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none; }

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%; }

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px; }

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none; }

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px; }

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px; }

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px; }

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px; }

/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-multiple .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px 4px; }

.ant-select-multiple .ant-select-selector input {
  cursor: pointer; }

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text; }

.ant-select-show-search.ant-select-multiple .ant-select-selector input {
  cursor: auto; }

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-select-disabled.ant-select-multiple .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed; }

.ant-select-disabled.ant-select-multiple .ant-select-selector input {
  cursor: not-allowed; }

.ant-select-multiple .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.ant-select-multiple .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none; }

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text; }

.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed; }

.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\A0'; }

.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px; }

.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
  flex: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-right: 4px;
  margin-bottom: 2px;
  padding: 0 4px 0 8px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  -webkit-transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 12px;
  line-height: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 10px; }

.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1; }

.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block; }

.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none; }

.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block; }

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.2em; }

.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75); }

.ant-select-multiple .ant-select-selection-search {
  position: relative;
  margin-left: 0.5px; }

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5715;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px; }

.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: nowrap;
  visibility: hidden; }

.ant-select-multiple .ant-select-selection-search:first-child .ant-select-selection-search-input {
  margin-left: 6.5px; }

.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px; }

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px; }

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 33px;
  line-height: 33px; }

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px; }

.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 17px;
  line-height: 17px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-search:first-child .ant-select-selection-search-input {
  margin-left: 3px; }

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px; }

.ant-select-disabled .ant-select-selection-item-remove {
  display: none; }

/* Reset search input style */
.ant-select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer; }

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-select-selection-item {
  -ms-flex: 1;
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    -ms-flex: auto;
    flex: auto; } }

.ant-select-selection-placeholder {
  -ms-flex: 1;
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.4; }

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    -ms-flex: auto;
    flex: auto; } }

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none; }

.ant-select-arrow > * {
  line-height: 1; }

.ant-select-arrow svg {
  display: inline-block; }

.ant-select-arrow::before {
  display: none; }

.ant-select-arrow .ant-select-arrow-icon {
  display: block; }

.ant-select-arrow .anticon {
  vertical-align: top;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.ant-select-arrow .anticon > svg {
  vertical-align: top; }

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto; }

.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed; }

.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto; }

.ant-select-clear::before {
  display: block; }

.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-select:hover .ant-select-clear {
  opacity: 1; }

.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn; }

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn; }

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut; }

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut; }

.ant-select-dropdown-hidden {
  display: none; }

.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25); }

.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25); }

.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease; }

.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default; }

.ant-select-item-option {
  display: -ms-flexbox;
  display: flex; }

.ant-select-item-option-content {
  -ms-flex: auto;
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-select-item-option-state {
  -ms-flex: none;
  flex: none; }

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e6f7ff; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #1890ff; }

.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-select-item-option-grouped {
  padding-left: 24px; }

.ant-select-lg {
  font-size: 16px; }

.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.ant-select-rtl {
  direction: rtl; }

.ant-select-rtl .ant-select-arrow {
  right: auto;
  right: initial;
  left: 11px; }

.ant-select-rtl .ant-select-clear {
  right: auto;
  right: initial;
  left: 11px; }

.ant-select-dropdown-rtl {
  direction: rtl; }

.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px; }

.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  margin-right: 0;
  margin-left: 4px;
  padding: 0 8px 0 4px;
  text-align: right; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-search {
  margin-right: 0.5px;
  margin-left: 4px; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto; }

.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px; }

.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right; }

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px; }

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px; }

.ant-select-rtl.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px; }

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 0; }

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center; }

.ant-empty-image {
  height: 100px;
  margin-bottom: 8px; }

.ant-empty-image img {
  height: 100%; }

.ant-empty-image svg {
  height: 100%;
  margin: auto; }

.ant-empty-description {
  margin: 0; }

.ant-empty-footer {
  margin-top: 16px; }

.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25); }

.ant-empty-normal .ant-empty-image {
  height: 40px; }

.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25); }

.ant-empty-small .ant-empty-image {
  height: 35px; }

.ant-empty-img-default-ellipse {
  fill-opacity: 0.8;
  fill: #f5f5f5; }

.ant-empty-img-default-path-1 {
  fill: #aeb8c2; }

.ant-empty-img-default-path-2 {
  fill: url(#linearGradient-1); }

.ant-empty-img-default-path-3 {
  fill: #f5f5f7; }

.ant-empty-img-default-path-4 {
  fill: #dce0e6; }

.ant-empty-img-default-path-5 {
  fill: #dce0e6; }

.ant-empty-img-default-g {
  fill: #fff; }

.ant-empty-img-simple-ellipse {
  fill: #f5f5f5; }

.ant-empty-img-simple-g {
  stroke: #d9d9d9; }

.ant-empty-img-simple-path {
  fill: #fafafa; }

.ant-empty-rtl {
  direction: rtl; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-avatar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%; }

.ant-avatar-image {
  background: transparent; }

.ant-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  transform-origin: 0 center; }

.ant-avatar.ant-avatar-icon {
  font-size: 18px; }

.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0; }

.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%; }

.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  transform-origin: 0 center; }

.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px; }

.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0; }

.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%; }

.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  transform-origin: 0 center; }

.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px; }

.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0; }

.ant-avatar-square {
  border-radius: 2px; }

.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; }

.ant-avatar-group {
  display: -ms-inline-flexbox;
  display: inline-flex; }

.ant-avatar-group .ant-avatar {
  border: 1px solid #fff; }

.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px; }

.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px; }

.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0; }

.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-popover {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: ''; }

.ant-popover-hidden {
  display: none; }

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px; }

.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px; }

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px; }

.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px; }

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */ }
  .ant-popover-inner {
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); } }

.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0; }

.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85); }

.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px; }

.ant-popover-message > .anticon {
  position: absolute;
  top: 8.0005px;
  color: #faad14;
  font-size: 14px; }

.ant-popover-message-title {
  padding-left: 22px; }

.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right; }

.ant-popover-buttons button {
  margin-left: 8px; }

.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07); }

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg); }

.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px; }

.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px; }

.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  -webkit-box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07); }

.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg); }

.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px; }

.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px; }

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06); }

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg); }

.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px; }

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px; }

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07); }

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg); }

.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px; }

.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px; }

.ant-popover-rtl {
  direction: rtl;
  text-align: right; }

.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px; }

.ant-popover-rtl .ant-popover-buttons {
  text-align: left; }

.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-back-top {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer; }

.ant-back-top:empty {
  display: none; }

.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl; }

.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px; }

@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px; } }

@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-badge {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1; }

.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff; }

.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff; }

.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px; }

.ant-badge-multiple-words {
  padding: 0 8px; }

.ant-badge-dot {
  z-index: auto;
  width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff; }

.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%; }

.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline; }

.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%; }

.ant-badge-status-success {
  background-color: #52c41a; }

.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff; }

.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: ''; }

.ant-badge-status-default {
  background-color: #d9d9d9; }

.ant-badge-status-error {
  background-color: #ff4d4f; }

.ant-badge-status-warning {
  background-color: #faad14; }

.ant-badge-status-pink {
  background: #eb2f96; }

.ant-badge-status-magenta {
  background: #eb2f96; }

.ant-badge-status-red {
  background: #f5222d; }

.ant-badge-status-volcano {
  background: #fa541c; }

.ant-badge-status-orange {
  background: #fa8c16; }

.ant-badge-status-yellow {
  background: #fadb14; }

.ant-badge-status-gold {
  background: #faad14; }

.ant-badge-status-cyan {
  background: #13c2c2; }

.ant-badge-status-lime {
  background: #a0d911; }

.ant-badge-status-green {
  background: #52c41a; }

.ant-badge-status-blue {
  background: #1890ff; }

.ant-badge-status-geekblue {
  background: #2f54eb; }

.ant-badge-status-purple {
  background: #722ed1; }

.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px; }

.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle; }

.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block; }

.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
  transform: none; }

@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0; } }

@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0; } }

.ant-scroll-number {
  overflow: hidden; }

.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0; }

.ant-scroll-number-symbol {
  vertical-align: top; }

@-webkit-keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%); } }

@keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%); } }

@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%); }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0; } }

@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%); }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0; } }

.ant-ribbon-wrapper {
  position: relative; }

.ant-ribbon {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #1890ff;
  border-radius: 2px; }

.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: #1890ff;
  border: 4px solid;
  -webkit-transform: scaleY(0.75);
  transform: scaleY(0.75);
  -webkit-transform-origin: top;
  transform-origin: top; }

.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: ''; }

.ant-ribbon-color-pink {
  background-color: #eb2f96; }

.ant-ribbon-color-pink .ant-ribbon-corner {
  color: #eb2f96; }

.ant-ribbon-color-magenta {
  background-color: #eb2f96; }

.ant-ribbon-color-magenta .ant-ribbon-corner {
  color: #eb2f96; }

.ant-ribbon-color-red {
  background-color: #f5222d; }

.ant-ribbon-color-red .ant-ribbon-corner {
  color: #f5222d; }

.ant-ribbon-color-volcano {
  background-color: #fa541c; }

.ant-ribbon-color-volcano .ant-ribbon-corner {
  color: #fa541c; }

.ant-ribbon-color-orange {
  background-color: #fa8c16; }

.ant-ribbon-color-orange .ant-ribbon-corner {
  color: #fa8c16; }

.ant-ribbon-color-yellow {
  background-color: #fadb14; }

.ant-ribbon-color-yellow .ant-ribbon-corner {
  color: #fadb14; }

.ant-ribbon-color-gold {
  background-color: #faad14; }

.ant-ribbon-color-gold .ant-ribbon-corner {
  color: #faad14; }

.ant-ribbon-color-cyan {
  background-color: #13c2c2; }

.ant-ribbon-color-cyan .ant-ribbon-corner {
  color: #13c2c2; }

.ant-ribbon-color-lime {
  background-color: #a0d911; }

.ant-ribbon-color-lime .ant-ribbon-corner {
  color: #a0d911; }

.ant-ribbon-color-green {
  background-color: #52c41a; }

.ant-ribbon-color-green .ant-ribbon-corner {
  color: #52c41a; }

.ant-ribbon-color-blue {
  background-color: #1890ff; }

.ant-ribbon-color-blue .ant-ribbon-corner {
  color: #1890ff; }

.ant-ribbon-color-geekblue {
  background-color: #2f54eb; }

.ant-ribbon-color-geekblue .ant-ribbon-corner {
  color: #2f54eb; }

.ant-ribbon-color-purple {
  background-color: #722ed1; }

.ant-ribbon-color-purple .ant-ribbon-corner {
  color: #722ed1; }

.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0; }

.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor; }

.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor; }

.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0; }

.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent; }

.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent; }

.ant-badge-rtl {
  direction: rtl; }

.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot,
.ant-badge-rtl .ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%; }

.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%; }

.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0; }

.ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge-rtl .ant-badge-zoom-enter {
  -webkit-animation-name: antZoomBadgeInRtl;
  animation-name: antZoomBadgeInRtl; }

.ant-badge-rtl .ant-badge-zoom-leave {
  -webkit-animation-name: antZoomBadgeOutRtl;
  animation-name: antZoomBadgeOutRtl; }

.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
  transform: none; }

.ant-ribbon-rtl {
  direction: rtl; }

.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0; }

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentColor currentColor transparent transparent; }

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent; }

.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px; }

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor transparent transparent currentColor; }

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor; }

@-webkit-keyframes antZoomBadgeInRtl {
  0% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%); } }

@keyframes antZoomBadgeInRtl {
  0% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%); } }

@-webkit-keyframes antZoomBadgeOutRtl {
  0% {
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%); }
  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0; } }

@keyframes antZoomBadgeOutRtl {
  0% {
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%); }
  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-breadcrumb {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px; }

.ant-breadcrumb .anticon {
  font-size: 14px; }

.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-breadcrumb a:hover {
  color: #40a9ff; }

.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.85); }

.ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.85); }

.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none; }

.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45); }

.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px; }

.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px; }

.ant-breadcrumb-rtl {
  direction: rtl; }

.ant-breadcrumb-rtl::before {
  display: table;
  content: ''; }

.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: ''; }

.ant-breadcrumb-rtl > span {
  float: right; }

.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0; }

.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f; }

.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f; }

.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0; }

.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f; }

.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0; }

.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f; }

.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f; }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f; }

.ant-menu {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  -webkit-transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s; }

.ant-menu::before {
  display: table;
  content: ''; }

.ant-menu::after {
  display: table;
  clear: both;
  content: ''; }

.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.ant-menu-hidden {
  display: none; }

.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-menu-horizontal .ant-menu-submenu {
  -webkit-transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-submenu,
.ant-menu-submenu-inline {
  -webkit-transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-submenu-selected {
  color: #1890ff; }

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6f7ff; }

.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85); }

.ant-menu-item a:hover {
  color: #1890ff; }

.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: ''; }

.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85); }

.ant-menu-item > .ant-badge a:hover {
  color: #1890ff; }

.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0; }

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #1890ff; }

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px; }

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent; }

.ant-menu-item-selected {
  color: #1890ff; }

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #1890ff; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff; }

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0; }

.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0; }

.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

.ant-menu-vertical.ant-menu-sub:not(.zoom-big-enter-active):not(.zoom-big-leave-active),
.ant-menu-vertical-left.ant-menu-sub:not(.zoom-big-enter-active):not(.zoom-big-leave-active),
.ant-menu-vertical-right.ant-menu-sub:not(.zoom-big-enter-active):not(.zoom-big-leave-active) {
  overflow-x: hidden;
  overflow-y: auto; }

.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0; }

.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0; }

.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px; }

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-right: 0; }

.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0; }

.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' '; }

.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px; }

.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px; }

.ant-menu-submenu > .ant-menu-submenu-title::after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff; }

.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.85)), to(rgba(0, 0, 0, 0.85)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));
  border-radius: 2px;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: ''; }

.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateY(-2px);
  transform: rotate(45deg) translateY(-2px); }

.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateY(2px);
  transform: rotate(-45deg) translateY(2px); }

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from(#1890ff), to(#1890ff));
  background: linear-gradient(to right, #1890ff, #1890ff); }

.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateY(-2px);
  transform: rotate(45deg) translateY(-2px); }

.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateY(2px);
  transform: rotate(-45deg) translateY(2px); }

.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateX(2px);
  transform: rotate(-45deg) translateX(2px); }

.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateX(-2px);
  transform: rotate(45deg) translateX(-2px); }

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px); }

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateX(-2px);
  transform: rotate(-45deg) translateX(-2px); }

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateX(2px);
  transform: rotate(45deg) translateX(2px); }

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #1890ff; }

.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 20px;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
  padding-right: 0;
  padding-left: 0; }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #1890ff;
  border-bottom: 2px solid #1890ff; }

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent; }

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0; }

.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85); }

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #1890ff; }

.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px; }

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #1890ff; }

.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: ' '; }

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative; }

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  -webkit-transform: scaleY(0.0001);
  transform: scaleY(0.0001);
  opacity: 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: ''; }

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis; }

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px; }

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px; }

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px; }

.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px; }

.ant-menu-inline {
  width: 100%; }

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px); }

.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px; }

.ant-menu-inline-collapsed {
  width: 80px; }

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px;
  text-overflow: clip; }

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none; }

.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px; }

.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0; }

.ant-menu-inline-collapsed .anticon {
  display: inline-block; }

.ant-menu-inline-collapsed-tooltip {
  pointer-events: none; }

.ant-menu-inline-collapsed-tooltip .anticon {
  display: none; }

.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85); }

.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-menu-item-group-list {
  margin: 0;
  padding: 0; }

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px; }

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center; }

.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc; }

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px; }

.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed; }

.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none; }

.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed; }

.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important; }

.ant-layout-header .ant-menu {
  line-height: inherit; }

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529; }

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff; }

.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent; }

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17; }

.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0; }

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0; }

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #1890ff; }

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0; }

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65); }

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0; }

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0; }

.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0; }

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%; }

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent; }

.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff; }

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1; }

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff; }

.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent; }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff; }

.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0; }

.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0; }

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff; }

.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff; }

.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff; }

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff; }

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8; }

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important; }

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important; }

.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right; }

.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right; }

.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0; }

.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none; }

.ant-menu-rtl.ant-menu-vertical.ant-menu-sub,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub {
  -webkit-transform-origin: top right;
  transform-origin: top right; }

.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: top right;
  transform-origin: top right; }

.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px; }

.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0; }

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px; }

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateY(-2px);
  transform: rotate(-45deg) translateY(-2px); }

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateY(2px);
  transform: rotate(45deg) translateY(2px); }

.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0; }

.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right; }

.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px; }

.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px; }

.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 32px; }

.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px; }

.ant-menu-sub.ant-menu-inline {
  border: 0; }

.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-tooltip {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  max-width: 250px;
  visibility: visible; }

.ant-tooltip-hidden {
  display: none; }

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px; }

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px; }

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px; }

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px; }

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none; }

.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto; }

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px; }

.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(-6.53553px) rotate(45deg);
  transform: translateY(-6.53553px) rotate(45deg); }

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px; }

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px; }

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px; }

.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  -webkit-box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(6.53553px) rotate(45deg);
  transform: translateX(6.53553px) rotate(45deg); }

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px; }

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px; }

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px; }

.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(-6.53553px) rotate(45deg);
  transform: translateX(-6.53553px) rotate(45deg); }

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px; }

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px; }

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px; }

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  -webkit-box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(6.53553px) rotate(45deg);
  transform: translateY(6.53553px) rotate(45deg); }

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px; }

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px; }

.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96; }

.ant-tooltip-pink .ant-tooltip-arrow-content {
  background-color: #eb2f96; }

.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96; }

.ant-tooltip-magenta .ant-tooltip-arrow-content {
  background-color: #eb2f96; }

.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d; }

.ant-tooltip-red .ant-tooltip-arrow-content {
  background-color: #f5222d; }

.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c; }

.ant-tooltip-volcano .ant-tooltip-arrow-content {
  background-color: #fa541c; }

.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16; }

.ant-tooltip-orange .ant-tooltip-arrow-content {
  background-color: #fa8c16; }

.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14; }

.ant-tooltip-yellow .ant-tooltip-arrow-content {
  background-color: #fadb14; }

.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14; }

.ant-tooltip-gold .ant-tooltip-arrow-content {
  background-color: #faad14; }

.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2; }

.ant-tooltip-cyan .ant-tooltip-arrow-content {
  background-color: #13c2c2; }

.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911; }

.ant-tooltip-lime .ant-tooltip-arrow-content {
  background-color: #a0d911; }

.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a; }

.ant-tooltip-green .ant-tooltip-arrow-content {
  background-color: #52c41a; }

.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff; }

.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: #1890ff; }

.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb; }

.ant-tooltip-geekblue .ant-tooltip-arrow-content {
  background-color: #2f54eb; }

.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1; }

.ant-tooltip-purple .ant-tooltip-arrow-content {
  background-color: #722ed1; }

.ant-tooltip-rtl {
  direction: rtl; }

.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f; }

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f; }

.ant-dropdown {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block; }

.ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' '; }

.ant-dropdown-wrap {
  position: relative; }

.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 10px; }

.ant-dropdown-wrap .anticon-down::before {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s; }

.ant-dropdown-wrap-open .anticon-down::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none; }

.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px; }

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px; }

.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07); }

.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg); }

.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px; }

.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px; }

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06); }

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg); }

.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px; }

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px; }

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none; }

.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em; }

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px; }

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: rgba(0, 0, 0, 0.85);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #1890ff;
  background-color: #e6f7ff; }

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5; }

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed; }

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0; }

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px; }

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 10px; }

.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none; }

.ant-dropdown-menu-submenu-title {
  padding-right: 24px; }

.ant-dropdown-menu-submenu-vertical {
  position: relative; }

.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed; }

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff; }

.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn; }

.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn; }

.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut; }

.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut; }

.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  vertical-align: baseline;
  display: inline-block;
  font-size: 10px; }

.ant-dropdown-button {
  white-space: nowrap; }

.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px; }

.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529; }

.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65); }

.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65); }

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent; }

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff; }

.ant-dropdown-rtl {
  direction: rtl; }

.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0; }

.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right; }

.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right; }

.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right; }

.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right; }

.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px; }

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  right: auto;
  left: 8px; }

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1); }

.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px; }

.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9; }

.ant-btn > .anticon {
  line-height: 1; }

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0; }

.ant-btn:not([disabled]):hover {
  text-decoration: none; }

.ant-btn:not([disabled]):active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn[disabled] {
  cursor: not-allowed; }

.ant-btn[disabled] > * {
  pointer-events: none; }

.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px; }

.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px; }

.ant-btn > a:only-child {
  color: currentColor; }

.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn:hover,
.ant-btn:focus {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff; }

.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor; }

.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9; }

.ant-btn:active > a:only-child {
  color: currentColor; }

.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff; }

.ant-btn > span {
  display: inline-block; }

.ant-btn-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.ant-btn-primary > a:only-child {
  color: currentColor; }

.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff; }

.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor; }

.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-primary:active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9; }

.ant-btn-primary:active > a:only-child {
  color: currentColor; }

.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff; }

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9; }

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff; }

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9; }

.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff; }

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9; }

.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: #d9d9d9; }

.ant-btn-ghost > a:only-child {
  color: currentColor; }

.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #40a9ff;
  background: transparent;
  border-color: #40a9ff; }

.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor; }

.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-ghost:active {
  color: #096dd9;
  background: transparent;
  border-color: #096dd9; }

.ant-btn-ghost:active > a:only-child {
  color: currentColor; }

.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  border-style: dashed; }

.ant-btn-dashed > a:only-child {
  color: currentColor; }

.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff; }

.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor; }

.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dashed:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9; }

.ant-btn-dashed:active > a:only-child {
  color: currentColor; }

.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-danger {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.ant-btn-danger > a:only-child {
  color: currentColor; }

.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875; }

.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor; }

.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-danger:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e; }

.ant-btn-danger:active > a:only-child {
  color: currentColor; }

.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-link {
  color: #1890ff;
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-link > a:only-child {
  color: currentColor; }

.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #40a9ff;
  background: transparent;
  border-color: #40a9ff; }

.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor; }

.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-link:active {
  color: #096dd9;
  background: transparent;
  border-color: #096dd9; }

.ant-btn-link:active > a:only-child {
  color: currentColor; }

.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-link:hover {
  background: transparent; }

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent; }

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-text > a:only-child {
  color: currentColor; }

.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #40a9ff;
  background: transparent;
  border-color: #40a9ff; }

.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentColor; }

.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-text:active {
  color: #096dd9;
  background: transparent;
  border-color: #096dd9; }

.ant-btn-text:active > a:only-child {
  color: currentColor; }

.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent; }

.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent; }

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f; }

.ant-btn-dangerous > a:only-child {
  color: currentColor; }

.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  background: #fff;
  border-color: #ff7875; }

.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentColor; }

.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous:active {
  color: #d9363e;
  background: #fff;
  border-color: #d9363e; }

.ant-btn-dangerous:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875; }

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e; }

.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #40a9ff;
  background: transparent;
  border-color: #40a9ff; }

.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-link:active {
  color: #096dd9;
  background: transparent;
  border-color: #096dd9; }

.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: transparent;
  border-color: transparent; }

.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: transparent;
  border-color: transparent; }

.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #40a9ff;
  background: transparent;
  border-color: #40a9ff; }

.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-text:active {
  color: #096dd9;
  background: transparent;
  border-color: #096dd9; }

.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent; }

.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent; }

.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -0.5px; }

.ant-btn-icon-only > * {
  font-size: 16px; }

.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px; }

.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px; }

.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px; }

.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px; }

.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px; }

.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px; }

.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px; }

.ant-btn-round.ant-btn-icon-only {
  width: auto; }

.ant-btn-circle,
.ant-btn-circle-outline {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%; }

.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%; }

.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%; }

.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none; }

.ant-btn .anticon {
  -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed; }

.ant-btn.ant-btn-loading {
  position: relative; }

.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none; }

.ant-btn.ant-btn-loading::before {
  display: block; }

.ant-btn > .ant-btn-loading-icon {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px; }

.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0; }

.ant-btn-group {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative; }

.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2; }

.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0; }

.ant-btn-group .ant-btn-icon-only {
  font-size: 14px; }

.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 0; }

.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0; }

.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 0; }

.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px; }

.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0; }

.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px; }

.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent; }

.ant-btn-group .ant-btn {
  border-radius: 0; }

.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0; }

.ant-btn-group > .ant-btn:only-child {
  border-radius: 2px; }

.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px; }

.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 2px; }

.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px; }

.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.ant-btn-group > .ant-btn-group {
  float: left; }

.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0; }

.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto; }

.ant-btn-group.ant-btn-group-rtl {
  direction: rtl; }

.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0; }

.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px; }

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0; }

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px; }

.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative; }

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px; }

.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff; }

.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  background: transparent;
  border-color: #1890ff;
  text-shadow: none; }

.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  background: transparent;
  border-color: #40a9ff; }

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-primary:active {
  color: #096dd9;
  background: transparent;
  border-color: #096dd9; }

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  text-shadow: none; }

.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  background: transparent;
  border-color: #ff7875; }

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  background: transparent;
  border-color: #d9363e; }

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  text-shadow: none; }

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  background: transparent;
  border-color: #ff7875; }

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  background: transparent;
  border-color: #d9363e; }

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  text-shadow: none; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: transparent;
  border-color: transparent; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: transparent;
  border-color: transparent; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: ''; }

.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em; }

.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em; }

.ant-btn-block {
  width: 100%; }

.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\A0'; }

a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px; }

a.ant-btn-lg {
  line-height: 38px; }

a.ant-btn-sm {
  line-height: 22px; }

.ant-btn-rtl {
  direction: rtl; }

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #40a9ff;
  border-left-color: #d9d9d9; }

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #40a9ff; }

.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px; }

.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0; }

.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-picker-calendar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  background: #fff; }

.ant-picker-calendar-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 12px 0; }

.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px; }

.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px; }

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px; }

.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0; }

.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto; }

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0; }

.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%; }

.ant-picker-calendar-mini {
  border-radius: 2px; }

.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px; }

.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px; }

.ant-picker-calendar-mini .ant-picker-content {
  height: 256px; }

.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px; }

.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #e6f7ff; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #1890ff; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  -webkit-transition: background 0.3s;
  transition: background 0.3s; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #1890ff; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85); }

@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block; }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%; }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px); }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0; }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center; } }

.ant-picker-calendar-rtl {
  direction: rtl; }

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0; }

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0; }

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left; }

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px; }

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-radio-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  font-size: 0;
  line-height: unset; }

.ant-radio-group .ant-badge-count {
  z-index: 1; }

.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none; }

.ant-radio-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer; }

.ant-radio {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer; }

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff; }

.ant-radio-input:focus + .ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }

.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: ''; }

.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible; }

.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' '; }

.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0; }

.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff; }

.ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed; }

.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2); }

.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed; }

.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px; }

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s; }

.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85); }

.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px; }

.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px; }

.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  content: ''; }

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px; }

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0; }

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px; }

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #1890ff; }

.ant-radio-button-wrapper:focus-within {
  -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }

.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none; }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff; }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #1890ff; }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1890ff; }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #40a9ff;
  border-color: #40a9ff; }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #40a9ff; }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #096dd9;
  border-color: #096dd9; }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #096dd9; }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff; }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff; }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9; }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }

.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9; }

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  -webkit-box-shadow: none;
  box-shadow: none; }

@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom; } }

.ant-radio-group.ant-radio-group-rtl {
  direction: rtl; }

.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl; }

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px; }

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0; }

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0; }

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #40a9ff; }

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px; }

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-picker-panel {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none; }

.ant-picker-panel-focused {
  border-color: #1890ff; }

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 280px; }

.ant-picker-header {
  display: -ms-flexbox;
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0; }

.ant-picker-header > * {
  -ms-flex: none;
  flex: none; }

.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px; }

.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-picker-header-view {
  -ms-flex: auto;
  flex: auto;
  font-weight: 500;
  line-height: 40px; }

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit; }

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px; }

.ant-picker-header-view button:hover {
  color: #1890ff; }

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px; }

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: ''; }

.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: ''; }

.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.ant-picker-next-icon,
.ant-picker-super-next-icon {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; }

.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400; }

.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px; }

.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer; }

.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85); }

.ant-picker-cell-disabled {
  cursor: not-allowed; }

.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: ''; }

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  -webkit-transition: background 0.3s, border 0.3s;
  transition: background 0.3s, border 0.3s; }

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5; }

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #1890ff;
  border-radius: 2px;
  content: ''; }

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative; }

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6f7ff; }

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1890ff; }

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #e6f7ff; }

.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%; }

.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%; }

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #7ec1ff;
  border-bottom: 1px dashed #7ec1ff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: ''; }

.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px; }

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #cbe6ff; }

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px; }

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0; }

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #cbe6ff;
  content: ''; }

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -7px;
  left: 0; }

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -7px; }

.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%; }

.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%; }

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.ant-picker-cell-disabled {
  pointer-events: none; }

.ant-picker-cell-disabled .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent; }

.ant-picker-cell-disabled::before {
  background: #f5f5f5; }

.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25); }

.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px; }

.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px; }

.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
  background: #f5f5f5; }

.ant-picker-quarter-panel .ant-picker-content {
  height: 56px; }

.ant-picker-footer {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent; }

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0; }

.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left; }

.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0; }

.ant-picker-now {
  text-align: left; }

.ant-picker-today-btn {
  color: #1890ff; }

.ant-picker-today-btn:hover {
  color: #40a9ff; }

.ant-picker-today-btn:active {
  color: #096dd9; }

.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px; }

.ant-picker-decade-panel .ant-picker-cell::before {
  display: none; }

.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px; }

.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px; }

.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px; }

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0; }

.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0; }

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px; }

.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px; }

.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important; }

.ant-picker-week-panel-row td {
  -webkit-transition: background 0.3s;
  transition: background 0.3s; }

.ant-picker-week-panel-row:hover td {
  background: #f5f5f5; }

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #1890ff; }

.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5); }

.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff; }

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff; }

.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px; }

.ant-picker-date-panel .ant-picker-content {
  width: 252px; }

.ant-picker-date-panel .ant-picker-content th {
  width: 36px; }

.ant-picker-datetime-panel {
  display: -ms-flexbox;
  display: flex; }

.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0; }

.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s; }

.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3; }

.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1; }

.ant-picker-time-panel {
  width: auto;
  min-width: auto; }

.ant-picker-time-panel .ant-picker-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  height: 224px; }

.ant-picker-time-panel-column {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  -webkit-transition: background 0.3s;
  transition: background 0.3s; }

.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: ''; }

.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px; }

.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0; }

.ant-picker-time-panel-column-active {
  background: rgba(230, 247, 255, 0.2); }

.ant-picker-time-panel-column:hover {
  overflow-y: auto; }

.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0; }

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s; }

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5; }

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6f7ff; }

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed; }

/* stylelint-disable-next-line */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0; }

.ant-picker {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, box-shadow 0.3s;
  transition: border 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s; }

.ant-picker:hover,
.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25); }

.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.ant-picker-input {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%; }

.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -ms-flex: auto;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0; }

.ant-picker-input > input::-moz-placeholder {
  opacity: 1; }

.ant-picker-input > input::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-picker-input > input:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-picker-input > input::-ms-input-placeholder {
  color: #bfbfbf; }

.ant-picker-input > input::placeholder {
  color: #bfbfbf; }

.ant-picker-input > input:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-picker-input > input:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis; }

.ant-picker-input > input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s; }

.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px; }

.ant-picker-input > input-sm {
  padding: 0px 7px; }

.ant-picker-input > input:focus {
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-picker-input > input[disabled] {
  background: transparent; }

.ant-picker-input:hover .ant-picker-clear {
  opacity: 1; }

.ant-picker-input-placeholder > input {
  color: #bfbfbf; }

.ant-picker-large {
  padding: 6.5px 11px 6.5px; }

.ant-picker-large .ant-picker-input > input {
  font-size: 16px; }

.ant-picker-small {
  padding: 0px 7px 0px; }

.ant-picker-suffix {
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none; }

.ant-picker-suffix > * {
  vertical-align: top; }

.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  -webkit-transition: opacity 0.3s, color 0.3s;
  transition: opacity 0.3s, color 0.3s; }

.ant-picker-clear > * {
  vertical-align: top; }

.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default; }

.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45); }

.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed; }

.ant-picker-range {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.ant-picker-range .ant-picker-clear {
  right: 11px; }

.ant-picker-range:hover .ant-picker-clear {
  opacity: 1; }

.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #1890ff;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  pointer-events: none; }

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1; }

.ant-picker-range-separator {
  -ms-flex-align: center;
  align-items: center;
  padding: 0 8px;
  line-height: 1; }

.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px; }

.ant-picker-dropdown {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050; }

.ant-picker-dropdown-hidden {
  display: none; }

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 1.66666667px;
  display: block;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 1.66666667px;
  display: block;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

.ant-picker-dropdown-range {
  padding: 6.66666667px 0; }

.ant-picker-dropdown-range-hidden {
  display: none; }

.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px; }

.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none; }

.ant-picker-ranges > li {
  display: inline-block; }

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  cursor: pointer; }

.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px; }

.ant-picker-range-wrapper {
  display: -ms-flexbox;
  display: flex; }

.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  -webkit-box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out; }

.ant-picker-range-arrow::after {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  border: 5px solid #f0f0f0;
  border-color: #fff #fff transparent transparent;
  content: ''; }

.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  -webkit-transition: margin 0.3s;
  transition: margin 0.3s; }

.ant-picker-panel-container .ant-picker-panels {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  direction: ltr; }

.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0; }

.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0; }

.ant-picker-rtl {
  direction: rtl; }

.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0; }

.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0; }

.ant-picker-rtl .ant-picker-separator {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0; }

.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px; }

.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0; }

.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right; }

.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0; }

.ant-picker-panel-rtl {
  direction: rtl; }

.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  -webkit-transition: background 0.3s, border 0.3s;
  transition: background 0.3s, border 0.3s; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%; }

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -7px; }

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -7px;
  left: 0; }

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%; }

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px; }

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #7ec1ff;
  border-left: none;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0; }

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px; }

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after {
  left: 6px;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right; }

.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-tag {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ant-tag:hover {
  opacity: 0.85; }

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px; }

.ant-tag-close-icon {
  display: inline-block;
  font-size: 10px;
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-tag-has-color {
  border-color: transparent; }

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff; }

.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer; }

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1890ff; }

.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff; }

.ant-tag-checkable-checked {
  background-color: #1890ff; }

.ant-tag-checkable:active {
  background-color: #096dd9; }

.ant-tag-hidden {
  display: none; }

.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2; }

.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96; }

.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2; }

.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96; }

.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e; }

.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d; }

.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96; }

.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c; }

.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591; }

.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16; }

.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f; }

.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14; }

.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f; }

.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14; }

.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de; }

.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2; }

.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f; }

.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911; }

.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f; }

.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a; }

.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff; }

.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff; }

.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff; }

.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb; }

.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7; }

.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1; }

.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f; }

.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff; }

.ant-tag-error {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e; }

.ant-tag-warning {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591; }

.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px; }

.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right; }

.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0; }

.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-card {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px; }

.ant-card-rtl {
  direction: rtl; }

.ant-card-hoverable {
  cursor: pointer;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s; }

.ant-card-hoverable:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09); }

.ant-card-bordered {
  border: 1px solid #f0f0f0; }

.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0; }

.ant-card-head::before {
  display: table;
  content: ''; }

.ant-card-head::after {
  display: table;
  clear: both;
  content: ''; }

.ant-card-head-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.ant-card-head-title {
  display: inline-block;
  -ms-flex: 1;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px; }

.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #f0f0f0; }

.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px; }

.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0; }

.ant-card-body {
  padding: 24px; }

.ant-card-body::before {
  display: table;
  content: ''; }

.ant-card-body::after {
  display: table;
  clear: both;
  content: ''; }

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0; }

.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-card-rtl .ant-card-grid {
  float: right; }

.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09); }

.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0; }

.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0; }

.ant-card-bordered .ant-card-cover {
  margin-right: -1px;
  margin-left: -1px; }

.ant-card-cover > * {
  display: block;
  width: 100%; }

.ant-card-cover img {
  border-radius: 2px 2px 0 0; }

.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0; }

.ant-card-actions::before {
  display: table;
  content: ''; }

.ant-card-actions::after {
  display: table;
  clear: both;
  content: ''; }

.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center; }

.ant-card-rtl .ant-card-actions > li {
  float: right; }

.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer; }

.ant-card-actions > li > span:hover {
  color: #1890ff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #1890ff; }

.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px; }

.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0; }

.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa; }

.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px; }

.ant-card-type-inner .ant-card-body {
  padding: 16px 24px; }

.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0; }

.ant-card-meta {
  margin: -4px 0; }

.ant-card-meta::before {
  display: table;
  content: ''; }

.ant-card-meta::after {
  display: table;
  clear: both;
  content: ''; }

.ant-card-meta-avatar {
  float: left;
  padding-right: 16px; }

.ant-card-rtl .ant-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px; }

.ant-card-meta-detail {
  overflow: hidden; }

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px; }

.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45); }

.ant-card-loading {
  overflow: hidden; }

.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-card-loading-content p {
  margin: 0; }

.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  -webkit-animation: card-loading 1.4s ease infinite;
  animation: card-loading 1.4s ease infinite; }

@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; } }

@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; } }

.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px; }

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0; }

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px; }

.ant-card-small > .ant-card-body {
  padding: 12px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px; }

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px; }

.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px; }

.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px; }

.ant-tabs-rtl {
  direction: rtl; }

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px; }

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0; }

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px; }

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px; }

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0; }

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  -ms-flex-order: 1;
  order: 1; }

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  -ms-flex-order: 0;
  order: 0; }

.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  -ms-flex-order: 0;
  order: 0; }

.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  -ms-flex-order: 1;
  order: 1; }

.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 0;
  margin-left: 2px; }

.ant-tabs-dropdown-rtl {
  direction: rtl; }

.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right; }

.ant-tabs-top,
.ant-tabs-bottom {
  -ms-flex-direction: column;
  flex-direction: column; }

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0; }

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: ''; }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px; }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  -webkit-transition: width 0.3s, left 0.3s, right 0.3s;
  transition: width 0.3s, left 0.3s, right 0.3s; }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px; }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  -webkit-box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08); }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  -webkit-box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08); }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1; }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1; }

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0; }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0; }

.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  -ms-flex-order: 1;
  order: 1;
  margin-top: 16px;
  margin-bottom: 0; }

.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0; }

.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0; }

.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  -ms-flex-order: 0;
  order: 0; }

.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 50px; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 16px 0;
  padding: 8px 24px;
  text-align: center; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-bottom: 0; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  -ms-flex-direction: column;
  flex-direction: column; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  -webkit-box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08); }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  -webkit-box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08); }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  -webkit-transition: height 0.3s, top 0.3s;
  transition: height 0.3s, top 0.3s; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  -ms-flex-direction: column;
  flex-direction: column; }

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0; }

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0; }

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px; }

.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  -ms-flex-order: 1;
  order: 1; }

.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0; }

.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  -ms-flex-order: 0;
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0; }

.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px; }

.ant-tabs-dropdown {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block; }

.ant-tabs-dropdown-hidden {
  display: none; }

.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-tabs-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5; }

.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed; }

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  background: #fff; }

.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden; }

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 2px; }

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0; }

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff; }

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px; }

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff; }

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-bottom: 2px; }

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px; }

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff; }

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0; }

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff; }

.ant-tabs {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: -ms-flexbox;
  display: flex;
  overflow: hidden; }

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
  flex: none;
  -ms-flex-align: center;
  align-items: center; }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transform: translate(0);
  transform: translate(0); }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none; }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  align-self: stretch; }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none; }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0; }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  content: ''; }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #40a9ff; }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #096dd9; }

.ant-tabs-extra-content {
  -ms-flex: none;
  flex: none; }

.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  -ms-flex-pack: center;
  justify-content: center; }

.ant-tabs-ink-bar {
  position: absolute;
  background: #1890ff;
  pointer-events: none; }

.ant-tabs-tab {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 32px 0 0;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer; }

.ant-tabs-tab:last-of-type {
  margin-right: 0;
  margin-left: 0; }

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #096dd9; }

.ant-tabs-tab-btn {
  outline: none; }

.ant-tabs-tab-remove {
  -ms-flex: none;
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-tabs-tab:hover {
  color: #40a9ff; }

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
  font-weight: 500; }

.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25); }

.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0; }

.ant-tabs-tab .anticon {
  margin-right: 12px; }

.ant-tabs-content {
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.ant-tabs-content-holder {
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
  min-height: 0; }

.ant-tabs-content-animated {
  -webkit-transition: margin 0.3s;
  transition: margin 0.3s; }

.ant-tabs-tabpane {
  -ms-flex: none;
  flex: none;
  width: 100%;
  outline: none; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.ant-row::before,
.ant-row::after {
  display: -ms-flexbox;
  display: flex; }

.ant-row-start {
  -ms-flex-pack: start;
  justify-content: flex-start; }

.ant-row-center {
  -ms-flex-pack: center;
  justify-content: center; }

.ant-row-end {
  -ms-flex-pack: end;
  justify-content: flex-end; }

.ant-row-space-between {
  -ms-flex-pack: justify;
  justify-content: space-between; }

.ant-row-space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.ant-row-top {
  -ms-flex-align: start;
  align-items: flex-start; }

.ant-row-middle {
  -ms-flex-align: center;
  align-items: center; }

.ant-row-bottom {
  -ms-flex-align: end;
  align-items: flex-end; }

.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px; }

.ant-col-24 {
  display: block;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.ant-col-push-24 {
  left: 100%; }

.ant-col-pull-24 {
  right: 100%; }

.ant-col-offset-24 {
  margin-left: 100%; }

.ant-col-order-24 {
  -ms-flex-order: 24;
  order: 24; }

.ant-col-23 {
  display: block;
  -ms-flex: 0 0 95.83333333%;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%; }

.ant-col-push-23 {
  left: 95.83333333%; }

.ant-col-pull-23 {
  right: 95.83333333%; }

.ant-col-offset-23 {
  margin-left: 95.83333333%; }

.ant-col-order-23 {
  -ms-flex-order: 23;
  order: 23; }

.ant-col-22 {
  display: block;
  -ms-flex: 0 0 91.66666667%;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%; }

.ant-col-push-22 {
  left: 91.66666667%; }

.ant-col-pull-22 {
  right: 91.66666667%; }

.ant-col-offset-22 {
  margin-left: 91.66666667%; }

.ant-col-order-22 {
  -ms-flex-order: 22;
  order: 22; }

.ant-col-21 {
  display: block;
  -ms-flex: 0 0 87.5%;
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.ant-col-push-21 {
  left: 87.5%; }

.ant-col-pull-21 {
  right: 87.5%; }

.ant-col-offset-21 {
  margin-left: 87.5%; }

.ant-col-order-21 {
  -ms-flex-order: 21;
  order: 21; }

.ant-col-20 {
  display: block;
  -ms-flex: 0 0 83.33333333%;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%; }

.ant-col-push-20 {
  left: 83.33333333%; }

.ant-col-pull-20 {
  right: 83.33333333%; }

.ant-col-offset-20 {
  margin-left: 83.33333333%; }

.ant-col-order-20 {
  -ms-flex-order: 20;
  order: 20; }

.ant-col-19 {
  display: block;
  -ms-flex: 0 0 79.16666667%;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%; }

.ant-col-push-19 {
  left: 79.16666667%; }

.ant-col-pull-19 {
  right: 79.16666667%; }

.ant-col-offset-19 {
  margin-left: 79.16666667%; }

.ant-col-order-19 {
  -ms-flex-order: 19;
  order: 19; }

.ant-col-18 {
  display: block;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.ant-col-push-18 {
  left: 75%; }

.ant-col-pull-18 {
  right: 75%; }

.ant-col-offset-18 {
  margin-left: 75%; }

.ant-col-order-18 {
  -ms-flex-order: 18;
  order: 18; }

.ant-col-17 {
  display: block;
  -ms-flex: 0 0 70.83333333%;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%; }

.ant-col-push-17 {
  left: 70.83333333%; }

.ant-col-pull-17 {
  right: 70.83333333%; }

.ant-col-offset-17 {
  margin-left: 70.83333333%; }

.ant-col-order-17 {
  -ms-flex-order: 17;
  order: 17; }

.ant-col-16 {
  display: block;
  -ms-flex: 0 0 66.66666667%;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%; }

.ant-col-push-16 {
  left: 66.66666667%; }

.ant-col-pull-16 {
  right: 66.66666667%; }

.ant-col-offset-16 {
  margin-left: 66.66666667%; }

.ant-col-order-16 {
  -ms-flex-order: 16;
  order: 16; }

.ant-col-15 {
  display: block;
  -ms-flex: 0 0 62.5%;
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.ant-col-push-15 {
  left: 62.5%; }

.ant-col-pull-15 {
  right: 62.5%; }

.ant-col-offset-15 {
  margin-left: 62.5%; }

.ant-col-order-15 {
  -ms-flex-order: 15;
  order: 15; }

.ant-col-14 {
  display: block;
  -ms-flex: 0 0 58.33333333%;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%; }

.ant-col-push-14 {
  left: 58.33333333%; }

.ant-col-pull-14 {
  right: 58.33333333%; }

.ant-col-offset-14 {
  margin-left: 58.33333333%; }

.ant-col-order-14 {
  -ms-flex-order: 14;
  order: 14; }

.ant-col-13 {
  display: block;
  -ms-flex: 0 0 54.16666667%;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%; }

.ant-col-push-13 {
  left: 54.16666667%; }

.ant-col-pull-13 {
  right: 54.16666667%; }

.ant-col-offset-13 {
  margin-left: 54.16666667%; }

.ant-col-order-13 {
  -ms-flex-order: 13;
  order: 13; }

.ant-col-12 {
  display: block;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.ant-col-push-12 {
  left: 50%; }

.ant-col-pull-12 {
  right: 50%; }

.ant-col-offset-12 {
  margin-left: 50%; }

.ant-col-order-12 {
  -ms-flex-order: 12;
  order: 12; }

.ant-col-11 {
  display: block;
  -ms-flex: 0 0 45.83333333%;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%; }

.ant-col-push-11 {
  left: 45.83333333%; }

.ant-col-pull-11 {
  right: 45.83333333%; }

.ant-col-offset-11 {
  margin-left: 45.83333333%; }

.ant-col-order-11 {
  -ms-flex-order: 11;
  order: 11; }

.ant-col-10 {
  display: block;
  -ms-flex: 0 0 41.66666667%;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%; }

.ant-col-push-10 {
  left: 41.66666667%; }

.ant-col-pull-10 {
  right: 41.66666667%; }

.ant-col-offset-10 {
  margin-left: 41.66666667%; }

.ant-col-order-10 {
  -ms-flex-order: 10;
  order: 10; }

.ant-col-9 {
  display: block;
  -ms-flex: 0 0 37.5%;
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.ant-col-push-9 {
  left: 37.5%; }

.ant-col-pull-9 {
  right: 37.5%; }

.ant-col-offset-9 {
  margin-left: 37.5%; }

.ant-col-order-9 {
  -ms-flex-order: 9;
  order: 9; }

.ant-col-8 {
  display: block;
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%; }

.ant-col-push-8 {
  left: 33.33333333%; }

.ant-col-pull-8 {
  right: 33.33333333%; }

.ant-col-offset-8 {
  margin-left: 33.33333333%; }

.ant-col-order-8 {
  -ms-flex-order: 8;
  order: 8; }

.ant-col-7 {
  display: block;
  -ms-flex: 0 0 29.16666667%;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%; }

.ant-col-push-7 {
  left: 29.16666667%; }

.ant-col-pull-7 {
  right: 29.16666667%; }

.ant-col-offset-7 {
  margin-left: 29.16666667%; }

.ant-col-order-7 {
  -ms-flex-order: 7;
  order: 7; }

.ant-col-6 {
  display: block;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.ant-col-push-6 {
  left: 25%; }

.ant-col-pull-6 {
  right: 25%; }

.ant-col-offset-6 {
  margin-left: 25%; }

.ant-col-order-6 {
  -ms-flex-order: 6;
  order: 6; }

.ant-col-5 {
  display: block;
  -ms-flex: 0 0 20.83333333%;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%; }

.ant-col-push-5 {
  left: 20.83333333%; }

.ant-col-pull-5 {
  right: 20.83333333%; }

.ant-col-offset-5 {
  margin-left: 20.83333333%; }

.ant-col-order-5 {
  -ms-flex-order: 5;
  order: 5; }

.ant-col-4 {
  display: block;
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%; }

.ant-col-push-4 {
  left: 16.66666667%; }

.ant-col-pull-4 {
  right: 16.66666667%; }

.ant-col-offset-4 {
  margin-left: 16.66666667%; }

.ant-col-order-4 {
  -ms-flex-order: 4;
  order: 4; }

.ant-col-3 {
  display: block;
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.ant-col-push-3 {
  left: 12.5%; }

.ant-col-pull-3 {
  right: 12.5%; }

.ant-col-offset-3 {
  margin-left: 12.5%; }

.ant-col-order-3 {
  -ms-flex-order: 3;
  order: 3; }

.ant-col-2 {
  display: block;
  -ms-flex: 0 0 8.33333333%;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%; }

.ant-col-push-2 {
  left: 8.33333333%; }

.ant-col-pull-2 {
  right: 8.33333333%; }

.ant-col-offset-2 {
  margin-left: 8.33333333%; }

.ant-col-order-2 {
  -ms-flex-order: 2;
  order: 2; }

.ant-col-1 {
  display: block;
  -ms-flex: 0 0 4.16666667%;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%; }

.ant-col-push-1 {
  left: 4.16666667%; }

.ant-col-pull-1 {
  right: 4.16666667%; }

.ant-col-offset-1 {
  margin-left: 4.16666667%; }

.ant-col-order-1 {
  -ms-flex-order: 1;
  order: 1; }

.ant-col-0 {
  display: none; }

.ant-col-push-0 {
  left: auto; }

.ant-col-pull-0 {
  right: auto; }

.ant-col-push-0 {
  left: auto; }

.ant-col-pull-0 {
  right: auto; }

.ant-col-offset-0 {
  margin-left: 0; }

.ant-col-order-0 {
  -ms-flex-order: 0;
  order: 0; }

.ant-col-push-0.ant-col-rtl {
  right: auto; }

.ant-col-pull-0.ant-col-rtl {
  left: auto; }

.ant-col-push-0.ant-col-rtl {
  right: auto; }

.ant-col-pull-0.ant-col-rtl {
  left: auto; }

.ant-col-offset-0.ant-col-rtl {
  margin-right: 0; }

.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto; }

.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%; }

.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0; }

.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto; }

.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%; }

.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0; }

.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto; }

.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%; }

.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0; }

.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto; }

.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%; }

.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0; }

.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto; }

.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%; }

.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0; }

.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto; }

.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%; }

.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0; }

.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto; }

.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%; }

.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0; }

.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto; }

.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%; }

.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0; }

.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto; }

.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%; }

.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0; }

.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto; }

.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%; }

.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0; }

.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto; }

.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%; }

.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0; }

.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto; }

.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%; }

.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0; }

.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto; }

.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%; }

.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0; }

.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto; }

.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%; }

.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0; }

.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto; }

.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%; }

.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0; }

.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto; }

.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%; }

.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0; }

.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto; }

.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%; }

.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0; }

.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto; }

.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%; }

.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0; }

.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto; }

.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%; }

.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0; }

.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto; }

.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%; }

.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0; }

.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto; }

.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%; }

.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0; }

.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto; }

.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%; }

.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0; }

.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto; }

.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%; }

.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0; }

.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto; }

.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%; }

.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0; }

.ant-col-xs-24 {
  display: block;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.ant-col-xs-push-24 {
  left: 100%; }

.ant-col-xs-pull-24 {
  right: 100%; }

.ant-col-xs-offset-24 {
  margin-left: 100%; }

.ant-col-xs-order-24 {
  -ms-flex-order: 24;
  order: 24; }

.ant-col-xs-23 {
  display: block;
  -ms-flex: 0 0 95.83333333%;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%; }

.ant-col-xs-push-23 {
  left: 95.83333333%; }

.ant-col-xs-pull-23 {
  right: 95.83333333%; }

.ant-col-xs-offset-23 {
  margin-left: 95.83333333%; }

.ant-col-xs-order-23 {
  -ms-flex-order: 23;
  order: 23; }

.ant-col-xs-22 {
  display: block;
  -ms-flex: 0 0 91.66666667%;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%; }

.ant-col-xs-push-22 {
  left: 91.66666667%; }

.ant-col-xs-pull-22 {
  right: 91.66666667%; }

.ant-col-xs-offset-22 {
  margin-left: 91.66666667%; }

.ant-col-xs-order-22 {
  -ms-flex-order: 22;
  order: 22; }

.ant-col-xs-21 {
  display: block;
  -ms-flex: 0 0 87.5%;
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.ant-col-xs-push-21 {
  left: 87.5%; }

.ant-col-xs-pull-21 {
  right: 87.5%; }

.ant-col-xs-offset-21 {
  margin-left: 87.5%; }

.ant-col-xs-order-21 {
  -ms-flex-order: 21;
  order: 21; }

.ant-col-xs-20 {
  display: block;
  -ms-flex: 0 0 83.33333333%;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%; }

.ant-col-xs-push-20 {
  left: 83.33333333%; }

.ant-col-xs-pull-20 {
  right: 83.33333333%; }

.ant-col-xs-offset-20 {
  margin-left: 83.33333333%; }

.ant-col-xs-order-20 {
  -ms-flex-order: 20;
  order: 20; }

.ant-col-xs-19 {
  display: block;
  -ms-flex: 0 0 79.16666667%;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%; }

.ant-col-xs-push-19 {
  left: 79.16666667%; }

.ant-col-xs-pull-19 {
  right: 79.16666667%; }

.ant-col-xs-offset-19 {
  margin-left: 79.16666667%; }

.ant-col-xs-order-19 {
  -ms-flex-order: 19;
  order: 19; }

.ant-col-xs-18 {
  display: block;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.ant-col-xs-push-18 {
  left: 75%; }

.ant-col-xs-pull-18 {
  right: 75%; }

.ant-col-xs-offset-18 {
  margin-left: 75%; }

.ant-col-xs-order-18 {
  -ms-flex-order: 18;
  order: 18; }

.ant-col-xs-17 {
  display: block;
  -ms-flex: 0 0 70.83333333%;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%; }

.ant-col-xs-push-17 {
  left: 70.83333333%; }

.ant-col-xs-pull-17 {
  right: 70.83333333%; }

.ant-col-xs-offset-17 {
  margin-left: 70.83333333%; }

.ant-col-xs-order-17 {
  -ms-flex-order: 17;
  order: 17; }

.ant-col-xs-16 {
  display: block;
  -ms-flex: 0 0 66.66666667%;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%; }

.ant-col-xs-push-16 {
  left: 66.66666667%; }

.ant-col-xs-pull-16 {
  right: 66.66666667%; }

.ant-col-xs-offset-16 {
  margin-left: 66.66666667%; }

.ant-col-xs-order-16 {
  -ms-flex-order: 16;
  order: 16; }

.ant-col-xs-15 {
  display: block;
  -ms-flex: 0 0 62.5%;
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.ant-col-xs-push-15 {
  left: 62.5%; }

.ant-col-xs-pull-15 {
  right: 62.5%; }

.ant-col-xs-offset-15 {
  margin-left: 62.5%; }

.ant-col-xs-order-15 {
  -ms-flex-order: 15;
  order: 15; }

.ant-col-xs-14 {
  display: block;
  -ms-flex: 0 0 58.33333333%;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%; }

.ant-col-xs-push-14 {
  left: 58.33333333%; }

.ant-col-xs-pull-14 {
  right: 58.33333333%; }

.ant-col-xs-offset-14 {
  margin-left: 58.33333333%; }

.ant-col-xs-order-14 {
  -ms-flex-order: 14;
  order: 14; }

.ant-col-xs-13 {
  display: block;
  -ms-flex: 0 0 54.16666667%;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%; }

.ant-col-xs-push-13 {
  left: 54.16666667%; }

.ant-col-xs-pull-13 {
  right: 54.16666667%; }

.ant-col-xs-offset-13 {
  margin-left: 54.16666667%; }

.ant-col-xs-order-13 {
  -ms-flex-order: 13;
  order: 13; }

.ant-col-xs-12 {
  display: block;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.ant-col-xs-push-12 {
  left: 50%; }

.ant-col-xs-pull-12 {
  right: 50%; }

.ant-col-xs-offset-12 {
  margin-left: 50%; }

.ant-col-xs-order-12 {
  -ms-flex-order: 12;
  order: 12; }

.ant-col-xs-11 {
  display: block;
  -ms-flex: 0 0 45.83333333%;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%; }

.ant-col-xs-push-11 {
  left: 45.83333333%; }

.ant-col-xs-pull-11 {
  right: 45.83333333%; }

.ant-col-xs-offset-11 {
  margin-left: 45.83333333%; }

.ant-col-xs-order-11 {
  -ms-flex-order: 11;
  order: 11; }

.ant-col-xs-10 {
  display: block;
  -ms-flex: 0 0 41.66666667%;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%; }

.ant-col-xs-push-10 {
  left: 41.66666667%; }

.ant-col-xs-pull-10 {
  right: 41.66666667%; }

.ant-col-xs-offset-10 {
  margin-left: 41.66666667%; }

.ant-col-xs-order-10 {
  -ms-flex-order: 10;
  order: 10; }

.ant-col-xs-9 {
  display: block;
  -ms-flex: 0 0 37.5%;
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.ant-col-xs-push-9 {
  left: 37.5%; }

.ant-col-xs-pull-9 {
  right: 37.5%; }

.ant-col-xs-offset-9 {
  margin-left: 37.5%; }

.ant-col-xs-order-9 {
  -ms-flex-order: 9;
  order: 9; }

.ant-col-xs-8 {
  display: block;
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%; }

.ant-col-xs-push-8 {
  left: 33.33333333%; }

.ant-col-xs-pull-8 {
  right: 33.33333333%; }

.ant-col-xs-offset-8 {
  margin-left: 33.33333333%; }

.ant-col-xs-order-8 {
  -ms-flex-order: 8;
  order: 8; }

.ant-col-xs-7 {
  display: block;
  -ms-flex: 0 0 29.16666667%;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%; }

.ant-col-xs-push-7 {
  left: 29.16666667%; }

.ant-col-xs-pull-7 {
  right: 29.16666667%; }

.ant-col-xs-offset-7 {
  margin-left: 29.16666667%; }

.ant-col-xs-order-7 {
  -ms-flex-order: 7;
  order: 7; }

.ant-col-xs-6 {
  display: block;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.ant-col-xs-push-6 {
  left: 25%; }

.ant-col-xs-pull-6 {
  right: 25%; }

.ant-col-xs-offset-6 {
  margin-left: 25%; }

.ant-col-xs-order-6 {
  -ms-flex-order: 6;
  order: 6; }

.ant-col-xs-5 {
  display: block;
  -ms-flex: 0 0 20.83333333%;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%; }

.ant-col-xs-push-5 {
  left: 20.83333333%; }

.ant-col-xs-pull-5 {
  right: 20.83333333%; }

.ant-col-xs-offset-5 {
  margin-left: 20.83333333%; }

.ant-col-xs-order-5 {
  -ms-flex-order: 5;
  order: 5; }

.ant-col-xs-4 {
  display: block;
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%; }

.ant-col-xs-push-4 {
  left: 16.66666667%; }

.ant-col-xs-pull-4 {
  right: 16.66666667%; }

.ant-col-xs-offset-4 {
  margin-left: 16.66666667%; }

.ant-col-xs-order-4 {
  -ms-flex-order: 4;
  order: 4; }

.ant-col-xs-3 {
  display: block;
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.ant-col-xs-push-3 {
  left: 12.5%; }

.ant-col-xs-pull-3 {
  right: 12.5%; }

.ant-col-xs-offset-3 {
  margin-left: 12.5%; }

.ant-col-xs-order-3 {
  -ms-flex-order: 3;
  order: 3; }

.ant-col-xs-2 {
  display: block;
  -ms-flex: 0 0 8.33333333%;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%; }

.ant-col-xs-push-2 {
  left: 8.33333333%; }

.ant-col-xs-pull-2 {
  right: 8.33333333%; }

.ant-col-xs-offset-2 {
  margin-left: 8.33333333%; }

.ant-col-xs-order-2 {
  -ms-flex-order: 2;
  order: 2; }

.ant-col-xs-1 {
  display: block;
  -ms-flex: 0 0 4.16666667%;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%; }

.ant-col-xs-push-1 {
  left: 4.16666667%; }

.ant-col-xs-pull-1 {
  right: 4.16666667%; }

.ant-col-xs-offset-1 {
  margin-left: 4.16666667%; }

.ant-col-xs-order-1 {
  -ms-flex-order: 1;
  order: 1; }

.ant-col-xs-0 {
  display: none; }

.ant-col-push-0 {
  left: auto; }

.ant-col-pull-0 {
  right: auto; }

.ant-col-xs-push-0 {
  left: auto; }

.ant-col-xs-pull-0 {
  right: auto; }

.ant-col-xs-offset-0 {
  margin-left: 0; }

.ant-col-xs-order-0 {
  -ms-flex-order: 0;
  order: 0; }

.ant-col-push-0.ant-col-rtl {
  right: auto; }

.ant-col-pull-0.ant-col-rtl {
  left: auto; }

.ant-col-xs-push-0.ant-col-rtl {
  right: auto; }

.ant-col-xs-pull-0.ant-col-rtl {
  left: auto; }

.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0; }

.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto; }

.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%; }

.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0; }

.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto; }

.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%; }

.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0; }

.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto; }

.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%; }

.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0; }

.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto; }

.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%; }

.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0; }

.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto; }

.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%; }

.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0; }

.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto; }

.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%; }

.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0; }

.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto; }

.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%; }

.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0; }

.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto; }

.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%; }

.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0; }

.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto; }

.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%; }

.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0; }

.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto; }

.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%; }

.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0; }

.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto; }

.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%; }

.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0; }

.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto; }

.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%; }

.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0; }

.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto; }

.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%; }

.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0; }

.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto; }

.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%; }

.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0; }

.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto; }

.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%; }

.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0; }

.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto; }

.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%; }

.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0; }

.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto; }

.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%; }

.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0; }

.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto; }

.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%; }

.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0; }

.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto; }

.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%; }

.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0; }

.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto; }

.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%; }

.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0; }

.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto; }

.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%; }

.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0; }

.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto; }

.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%; }

.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0; }

.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto; }

.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%; }

.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0; }

.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto; }

.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%; }

.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0; }

@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .ant-col-sm-push-24 {
    left: 100%; }
  .ant-col-sm-pull-24 {
    right: 100%; }
  .ant-col-sm-offset-24 {
    margin-left: 100%; }
  .ant-col-sm-order-24 {
    -ms-flex-order: 24;
    order: 24; }
  .ant-col-sm-23 {
    display: block;
    -ms-flex: 0 0 95.83333333%;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%; }
  .ant-col-sm-push-23 {
    left: 95.83333333%; }
  .ant-col-sm-pull-23 {
    right: 95.83333333%; }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%; }
  .ant-col-sm-order-23 {
    -ms-flex-order: 23;
    order: 23; }
  .ant-col-sm-22 {
    display: block;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .ant-col-sm-push-22 {
    left: 91.66666667%; }
  .ant-col-sm-pull-22 {
    right: 91.66666667%; }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%; }
  .ant-col-sm-order-22 {
    -ms-flex-order: 22;
    order: 22; }
  .ant-col-sm-21 {
    display: block;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .ant-col-sm-push-21 {
    left: 87.5%; }
  .ant-col-sm-pull-21 {
    right: 87.5%; }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%; }
  .ant-col-sm-order-21 {
    -ms-flex-order: 21;
    order: 21; }
  .ant-col-sm-20 {
    display: block;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .ant-col-sm-push-20 {
    left: 83.33333333%; }
  .ant-col-sm-pull-20 {
    right: 83.33333333%; }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%; }
  .ant-col-sm-order-20 {
    -ms-flex-order: 20;
    order: 20; }
  .ant-col-sm-19 {
    display: block;
    -ms-flex: 0 0 79.16666667%;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%; }
  .ant-col-sm-push-19 {
    left: 79.16666667%; }
  .ant-col-sm-pull-19 {
    right: 79.16666667%; }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%; }
  .ant-col-sm-order-19 {
    -ms-flex-order: 19;
    order: 19; }
  .ant-col-sm-18 {
    display: block;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .ant-col-sm-push-18 {
    left: 75%; }
  .ant-col-sm-pull-18 {
    right: 75%; }
  .ant-col-sm-offset-18 {
    margin-left: 75%; }
  .ant-col-sm-order-18 {
    -ms-flex-order: 18;
    order: 18; }
  .ant-col-sm-17 {
    display: block;
    -ms-flex: 0 0 70.83333333%;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%; }
  .ant-col-sm-push-17 {
    left: 70.83333333%; }
  .ant-col-sm-pull-17 {
    right: 70.83333333%; }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%; }
  .ant-col-sm-order-17 {
    -ms-flex-order: 17;
    order: 17; }
  .ant-col-sm-16 {
    display: block;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .ant-col-sm-push-16 {
    left: 66.66666667%; }
  .ant-col-sm-pull-16 {
    right: 66.66666667%; }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%; }
  .ant-col-sm-order-16 {
    -ms-flex-order: 16;
    order: 16; }
  .ant-col-sm-15 {
    display: block;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .ant-col-sm-push-15 {
    left: 62.5%; }
  .ant-col-sm-pull-15 {
    right: 62.5%; }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%; }
  .ant-col-sm-order-15 {
    -ms-flex-order: 15;
    order: 15; }
  .ant-col-sm-14 {
    display: block;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .ant-col-sm-push-14 {
    left: 58.33333333%; }
  .ant-col-sm-pull-14 {
    right: 58.33333333%; }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%; }
  .ant-col-sm-order-14 {
    -ms-flex-order: 14;
    order: 14; }
  .ant-col-sm-13 {
    display: block;
    -ms-flex: 0 0 54.16666667%;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%; }
  .ant-col-sm-push-13 {
    left: 54.16666667%; }
  .ant-col-sm-pull-13 {
    right: 54.16666667%; }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%; }
  .ant-col-sm-order-13 {
    -ms-flex-order: 13;
    order: 13; }
  .ant-col-sm-12 {
    display: block;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .ant-col-sm-push-12 {
    left: 50%; }
  .ant-col-sm-pull-12 {
    right: 50%; }
  .ant-col-sm-offset-12 {
    margin-left: 50%; }
  .ant-col-sm-order-12 {
    -ms-flex-order: 12;
    order: 12; }
  .ant-col-sm-11 {
    display: block;
    -ms-flex: 0 0 45.83333333%;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%; }
  .ant-col-sm-push-11 {
    left: 45.83333333%; }
  .ant-col-sm-pull-11 {
    right: 45.83333333%; }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%; }
  .ant-col-sm-order-11 {
    -ms-flex-order: 11;
    order: 11; }
  .ant-col-sm-10 {
    display: block;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .ant-col-sm-push-10 {
    left: 41.66666667%; }
  .ant-col-sm-pull-10 {
    right: 41.66666667%; }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%; }
  .ant-col-sm-order-10 {
    -ms-flex-order: 10;
    order: 10; }
  .ant-col-sm-9 {
    display: block;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .ant-col-sm-push-9 {
    left: 37.5%; }
  .ant-col-sm-pull-9 {
    right: 37.5%; }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%; }
  .ant-col-sm-order-9 {
    -ms-flex-order: 9;
    order: 9; }
  .ant-col-sm-8 {
    display: block;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .ant-col-sm-push-8 {
    left: 33.33333333%; }
  .ant-col-sm-pull-8 {
    right: 33.33333333%; }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%; }
  .ant-col-sm-order-8 {
    -ms-flex-order: 8;
    order: 8; }
  .ant-col-sm-7 {
    display: block;
    -ms-flex: 0 0 29.16666667%;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%; }
  .ant-col-sm-push-7 {
    left: 29.16666667%; }
  .ant-col-sm-pull-7 {
    right: 29.16666667%; }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%; }
  .ant-col-sm-order-7 {
    -ms-flex-order: 7;
    order: 7; }
  .ant-col-sm-6 {
    display: block;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .ant-col-sm-push-6 {
    left: 25%; }
  .ant-col-sm-pull-6 {
    right: 25%; }
  .ant-col-sm-offset-6 {
    margin-left: 25%; }
  .ant-col-sm-order-6 {
    -ms-flex-order: 6;
    order: 6; }
  .ant-col-sm-5 {
    display: block;
    -ms-flex: 0 0 20.83333333%;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%; }
  .ant-col-sm-push-5 {
    left: 20.83333333%; }
  .ant-col-sm-pull-5 {
    right: 20.83333333%; }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%; }
  .ant-col-sm-order-5 {
    -ms-flex-order: 5;
    order: 5; }
  .ant-col-sm-4 {
    display: block;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .ant-col-sm-push-4 {
    left: 16.66666667%; }
  .ant-col-sm-pull-4 {
    right: 16.66666667%; }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%; }
  .ant-col-sm-order-4 {
    -ms-flex-order: 4;
    order: 4; }
  .ant-col-sm-3 {
    display: block;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .ant-col-sm-push-3 {
    left: 12.5%; }
  .ant-col-sm-pull-3 {
    right: 12.5%; }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%; }
  .ant-col-sm-order-3 {
    -ms-flex-order: 3;
    order: 3; }
  .ant-col-sm-2 {
    display: block;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .ant-col-sm-push-2 {
    left: 8.33333333%; }
  .ant-col-sm-pull-2 {
    right: 8.33333333%; }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%; }
  .ant-col-sm-order-2 {
    -ms-flex-order: 2;
    order: 2; }
  .ant-col-sm-1 {
    display: block;
    -ms-flex: 0 0 4.16666667%;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%; }
  .ant-col-sm-push-1 {
    left: 4.16666667%; }
  .ant-col-sm-pull-1 {
    right: 4.16666667%; }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%; }
  .ant-col-sm-order-1 {
    -ms-flex-order: 1;
    order: 1; }
  .ant-col-sm-0 {
    display: none; }
  .ant-col-push-0 {
    left: auto; }
  .ant-col-pull-0 {
    right: auto; }
  .ant-col-sm-push-0 {
    left: auto; }
  .ant-col-sm-pull-0 {
    right: auto; }
  .ant-col-sm-offset-0 {
    margin-left: 0; }
  .ant-col-sm-order-0 {
    -ms-flex-order: 0;
    order: 0; }
  .ant-col-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0; }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto; }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%; }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0; }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto; }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%; }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0; }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto; }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%; }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0; }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto; }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%; }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0; }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto; }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%; }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0; }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto; }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%; }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0; }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto; }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%; }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0; }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto; }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%; }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0; }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto; }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%; }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0; }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto; }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%; }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0; }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto; }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%; }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0; }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto; }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%; }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0; }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto; }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%; }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0; }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto; }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%; }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0; }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto; }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%; }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0; }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto; }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%; }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0; }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto; }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%; }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0; }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto; }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%; }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0; }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto; }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%; }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0; }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto; }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%; }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0; }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto; }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%; }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0; }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto; }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%; }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0; }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto; }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%; }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0; }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto; }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%; }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0; } }

@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .ant-col-md-push-24 {
    left: 100%; }
  .ant-col-md-pull-24 {
    right: 100%; }
  .ant-col-md-offset-24 {
    margin-left: 100%; }
  .ant-col-md-order-24 {
    -ms-flex-order: 24;
    order: 24; }
  .ant-col-md-23 {
    display: block;
    -ms-flex: 0 0 95.83333333%;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%; }
  .ant-col-md-push-23 {
    left: 95.83333333%; }
  .ant-col-md-pull-23 {
    right: 95.83333333%; }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%; }
  .ant-col-md-order-23 {
    -ms-flex-order: 23;
    order: 23; }
  .ant-col-md-22 {
    display: block;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .ant-col-md-push-22 {
    left: 91.66666667%; }
  .ant-col-md-pull-22 {
    right: 91.66666667%; }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%; }
  .ant-col-md-order-22 {
    -ms-flex-order: 22;
    order: 22; }
  .ant-col-md-21 {
    display: block;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .ant-col-md-push-21 {
    left: 87.5%; }
  .ant-col-md-pull-21 {
    right: 87.5%; }
  .ant-col-md-offset-21 {
    margin-left: 87.5%; }
  .ant-col-md-order-21 {
    -ms-flex-order: 21;
    order: 21; }
  .ant-col-md-20 {
    display: block;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .ant-col-md-push-20 {
    left: 83.33333333%; }
  .ant-col-md-pull-20 {
    right: 83.33333333%; }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%; }
  .ant-col-md-order-20 {
    -ms-flex-order: 20;
    order: 20; }
  .ant-col-md-19 {
    display: block;
    -ms-flex: 0 0 79.16666667%;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%; }
  .ant-col-md-push-19 {
    left: 79.16666667%; }
  .ant-col-md-pull-19 {
    right: 79.16666667%; }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%; }
  .ant-col-md-order-19 {
    -ms-flex-order: 19;
    order: 19; }
  .ant-col-md-18 {
    display: block;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .ant-col-md-push-18 {
    left: 75%; }
  .ant-col-md-pull-18 {
    right: 75%; }
  .ant-col-md-offset-18 {
    margin-left: 75%; }
  .ant-col-md-order-18 {
    -ms-flex-order: 18;
    order: 18; }
  .ant-col-md-17 {
    display: block;
    -ms-flex: 0 0 70.83333333%;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%; }
  .ant-col-md-push-17 {
    left: 70.83333333%; }
  .ant-col-md-pull-17 {
    right: 70.83333333%; }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%; }
  .ant-col-md-order-17 {
    -ms-flex-order: 17;
    order: 17; }
  .ant-col-md-16 {
    display: block;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .ant-col-md-push-16 {
    left: 66.66666667%; }
  .ant-col-md-pull-16 {
    right: 66.66666667%; }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%; }
  .ant-col-md-order-16 {
    -ms-flex-order: 16;
    order: 16; }
  .ant-col-md-15 {
    display: block;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .ant-col-md-push-15 {
    left: 62.5%; }
  .ant-col-md-pull-15 {
    right: 62.5%; }
  .ant-col-md-offset-15 {
    margin-left: 62.5%; }
  .ant-col-md-order-15 {
    -ms-flex-order: 15;
    order: 15; }
  .ant-col-md-14 {
    display: block;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .ant-col-md-push-14 {
    left: 58.33333333%; }
  .ant-col-md-pull-14 {
    right: 58.33333333%; }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%; }
  .ant-col-md-order-14 {
    -ms-flex-order: 14;
    order: 14; }
  .ant-col-md-13 {
    display: block;
    -ms-flex: 0 0 54.16666667%;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%; }
  .ant-col-md-push-13 {
    left: 54.16666667%; }
  .ant-col-md-pull-13 {
    right: 54.16666667%; }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%; }
  .ant-col-md-order-13 {
    -ms-flex-order: 13;
    order: 13; }
  .ant-col-md-12 {
    display: block;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .ant-col-md-push-12 {
    left: 50%; }
  .ant-col-md-pull-12 {
    right: 50%; }
  .ant-col-md-offset-12 {
    margin-left: 50%; }
  .ant-col-md-order-12 {
    -ms-flex-order: 12;
    order: 12; }
  .ant-col-md-11 {
    display: block;
    -ms-flex: 0 0 45.83333333%;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%; }
  .ant-col-md-push-11 {
    left: 45.83333333%; }
  .ant-col-md-pull-11 {
    right: 45.83333333%; }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%; }
  .ant-col-md-order-11 {
    -ms-flex-order: 11;
    order: 11; }
  .ant-col-md-10 {
    display: block;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .ant-col-md-push-10 {
    left: 41.66666667%; }
  .ant-col-md-pull-10 {
    right: 41.66666667%; }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%; }
  .ant-col-md-order-10 {
    -ms-flex-order: 10;
    order: 10; }
  .ant-col-md-9 {
    display: block;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .ant-col-md-push-9 {
    left: 37.5%; }
  .ant-col-md-pull-9 {
    right: 37.5%; }
  .ant-col-md-offset-9 {
    margin-left: 37.5%; }
  .ant-col-md-order-9 {
    -ms-flex-order: 9;
    order: 9; }
  .ant-col-md-8 {
    display: block;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .ant-col-md-push-8 {
    left: 33.33333333%; }
  .ant-col-md-pull-8 {
    right: 33.33333333%; }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%; }
  .ant-col-md-order-8 {
    -ms-flex-order: 8;
    order: 8; }
  .ant-col-md-7 {
    display: block;
    -ms-flex: 0 0 29.16666667%;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%; }
  .ant-col-md-push-7 {
    left: 29.16666667%; }
  .ant-col-md-pull-7 {
    right: 29.16666667%; }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%; }
  .ant-col-md-order-7 {
    -ms-flex-order: 7;
    order: 7; }
  .ant-col-md-6 {
    display: block;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .ant-col-md-push-6 {
    left: 25%; }
  .ant-col-md-pull-6 {
    right: 25%; }
  .ant-col-md-offset-6 {
    margin-left: 25%; }
  .ant-col-md-order-6 {
    -ms-flex-order: 6;
    order: 6; }
  .ant-col-md-5 {
    display: block;
    -ms-flex: 0 0 20.83333333%;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%; }
  .ant-col-md-push-5 {
    left: 20.83333333%; }
  .ant-col-md-pull-5 {
    right: 20.83333333%; }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%; }
  .ant-col-md-order-5 {
    -ms-flex-order: 5;
    order: 5; }
  .ant-col-md-4 {
    display: block;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .ant-col-md-push-4 {
    left: 16.66666667%; }
  .ant-col-md-pull-4 {
    right: 16.66666667%; }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%; }
  .ant-col-md-order-4 {
    -ms-flex-order: 4;
    order: 4; }
  .ant-col-md-3 {
    display: block;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .ant-col-md-push-3 {
    left: 12.5%; }
  .ant-col-md-pull-3 {
    right: 12.5%; }
  .ant-col-md-offset-3 {
    margin-left: 12.5%; }
  .ant-col-md-order-3 {
    -ms-flex-order: 3;
    order: 3; }
  .ant-col-md-2 {
    display: block;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .ant-col-md-push-2 {
    left: 8.33333333%; }
  .ant-col-md-pull-2 {
    right: 8.33333333%; }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%; }
  .ant-col-md-order-2 {
    -ms-flex-order: 2;
    order: 2; }
  .ant-col-md-1 {
    display: block;
    -ms-flex: 0 0 4.16666667%;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%; }
  .ant-col-md-push-1 {
    left: 4.16666667%; }
  .ant-col-md-pull-1 {
    right: 4.16666667%; }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%; }
  .ant-col-md-order-1 {
    -ms-flex-order: 1;
    order: 1; }
  .ant-col-md-0 {
    display: none; }
  .ant-col-push-0 {
    left: auto; }
  .ant-col-pull-0 {
    right: auto; }
  .ant-col-md-push-0 {
    left: auto; }
  .ant-col-md-pull-0 {
    right: auto; }
  .ant-col-md-offset-0 {
    margin-left: 0; }
  .ant-col-md-order-0 {
    -ms-flex-order: 0;
    order: 0; }
  .ant-col-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0; }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto; }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%; }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0; }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto; }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%; }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0; }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto; }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%; }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0; }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto; }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%; }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0; }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto; }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%; }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0; }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto; }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%; }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0; }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto; }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%; }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0; }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto; }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%; }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0; }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto; }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%; }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0; }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto; }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%; }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0; }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto; }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%; }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0; }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto; }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%; }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0; }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto; }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%; }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0; }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto; }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%; }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0; }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto; }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%; }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0; }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto; }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%; }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0; }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto; }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%; }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0; }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto; }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%; }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0; }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto; }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%; }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0; }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto; }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%; }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0; }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto; }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%; }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0; }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto; }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%; }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0; }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto; }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%; }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0; }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto; }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%; }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0; } }

@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .ant-col-lg-push-24 {
    left: 100%; }
  .ant-col-lg-pull-24 {
    right: 100%; }
  .ant-col-lg-offset-24 {
    margin-left: 100%; }
  .ant-col-lg-order-24 {
    -ms-flex-order: 24;
    order: 24; }
  .ant-col-lg-23 {
    display: block;
    -ms-flex: 0 0 95.83333333%;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%; }
  .ant-col-lg-push-23 {
    left: 95.83333333%; }
  .ant-col-lg-pull-23 {
    right: 95.83333333%; }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%; }
  .ant-col-lg-order-23 {
    -ms-flex-order: 23;
    order: 23; }
  .ant-col-lg-22 {
    display: block;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .ant-col-lg-push-22 {
    left: 91.66666667%; }
  .ant-col-lg-pull-22 {
    right: 91.66666667%; }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%; }
  .ant-col-lg-order-22 {
    -ms-flex-order: 22;
    order: 22; }
  .ant-col-lg-21 {
    display: block;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .ant-col-lg-push-21 {
    left: 87.5%; }
  .ant-col-lg-pull-21 {
    right: 87.5%; }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%; }
  .ant-col-lg-order-21 {
    -ms-flex-order: 21;
    order: 21; }
  .ant-col-lg-20 {
    display: block;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .ant-col-lg-push-20 {
    left: 83.33333333%; }
  .ant-col-lg-pull-20 {
    right: 83.33333333%; }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%; }
  .ant-col-lg-order-20 {
    -ms-flex-order: 20;
    order: 20; }
  .ant-col-lg-19 {
    display: block;
    -ms-flex: 0 0 79.16666667%;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%; }
  .ant-col-lg-push-19 {
    left: 79.16666667%; }
  .ant-col-lg-pull-19 {
    right: 79.16666667%; }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%; }
  .ant-col-lg-order-19 {
    -ms-flex-order: 19;
    order: 19; }
  .ant-col-lg-18 {
    display: block;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .ant-col-lg-push-18 {
    left: 75%; }
  .ant-col-lg-pull-18 {
    right: 75%; }
  .ant-col-lg-offset-18 {
    margin-left: 75%; }
  .ant-col-lg-order-18 {
    -ms-flex-order: 18;
    order: 18; }
  .ant-col-lg-17 {
    display: block;
    -ms-flex: 0 0 70.83333333%;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%; }
  .ant-col-lg-push-17 {
    left: 70.83333333%; }
  .ant-col-lg-pull-17 {
    right: 70.83333333%; }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%; }
  .ant-col-lg-order-17 {
    -ms-flex-order: 17;
    order: 17; }
  .ant-col-lg-16 {
    display: block;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .ant-col-lg-push-16 {
    left: 66.66666667%; }
  .ant-col-lg-pull-16 {
    right: 66.66666667%; }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%; }
  .ant-col-lg-order-16 {
    -ms-flex-order: 16;
    order: 16; }
  .ant-col-lg-15 {
    display: block;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .ant-col-lg-push-15 {
    left: 62.5%; }
  .ant-col-lg-pull-15 {
    right: 62.5%; }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%; }
  .ant-col-lg-order-15 {
    -ms-flex-order: 15;
    order: 15; }
  .ant-col-lg-14 {
    display: block;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .ant-col-lg-push-14 {
    left: 58.33333333%; }
  .ant-col-lg-pull-14 {
    right: 58.33333333%; }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%; }
  .ant-col-lg-order-14 {
    -ms-flex-order: 14;
    order: 14; }
  .ant-col-lg-13 {
    display: block;
    -ms-flex: 0 0 54.16666667%;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%; }
  .ant-col-lg-push-13 {
    left: 54.16666667%; }
  .ant-col-lg-pull-13 {
    right: 54.16666667%; }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%; }
  .ant-col-lg-order-13 {
    -ms-flex-order: 13;
    order: 13; }
  .ant-col-lg-12 {
    display: block;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .ant-col-lg-push-12 {
    left: 50%; }
  .ant-col-lg-pull-12 {
    right: 50%; }
  .ant-col-lg-offset-12 {
    margin-left: 50%; }
  .ant-col-lg-order-12 {
    -ms-flex-order: 12;
    order: 12; }
  .ant-col-lg-11 {
    display: block;
    -ms-flex: 0 0 45.83333333%;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%; }
  .ant-col-lg-push-11 {
    left: 45.83333333%; }
  .ant-col-lg-pull-11 {
    right: 45.83333333%; }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%; }
  .ant-col-lg-order-11 {
    -ms-flex-order: 11;
    order: 11; }
  .ant-col-lg-10 {
    display: block;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .ant-col-lg-push-10 {
    left: 41.66666667%; }
  .ant-col-lg-pull-10 {
    right: 41.66666667%; }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%; }
  .ant-col-lg-order-10 {
    -ms-flex-order: 10;
    order: 10; }
  .ant-col-lg-9 {
    display: block;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .ant-col-lg-push-9 {
    left: 37.5%; }
  .ant-col-lg-pull-9 {
    right: 37.5%; }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%; }
  .ant-col-lg-order-9 {
    -ms-flex-order: 9;
    order: 9; }
  .ant-col-lg-8 {
    display: block;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .ant-col-lg-push-8 {
    left: 33.33333333%; }
  .ant-col-lg-pull-8 {
    right: 33.33333333%; }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%; }
  .ant-col-lg-order-8 {
    -ms-flex-order: 8;
    order: 8; }
  .ant-col-lg-7 {
    display: block;
    -ms-flex: 0 0 29.16666667%;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%; }
  .ant-col-lg-push-7 {
    left: 29.16666667%; }
  .ant-col-lg-pull-7 {
    right: 29.16666667%; }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%; }
  .ant-col-lg-order-7 {
    -ms-flex-order: 7;
    order: 7; }
  .ant-col-lg-6 {
    display: block;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .ant-col-lg-push-6 {
    left: 25%; }
  .ant-col-lg-pull-6 {
    right: 25%; }
  .ant-col-lg-offset-6 {
    margin-left: 25%; }
  .ant-col-lg-order-6 {
    -ms-flex-order: 6;
    order: 6; }
  .ant-col-lg-5 {
    display: block;
    -ms-flex: 0 0 20.83333333%;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%; }
  .ant-col-lg-push-5 {
    left: 20.83333333%; }
  .ant-col-lg-pull-5 {
    right: 20.83333333%; }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%; }
  .ant-col-lg-order-5 {
    -ms-flex-order: 5;
    order: 5; }
  .ant-col-lg-4 {
    display: block;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .ant-col-lg-push-4 {
    left: 16.66666667%; }
  .ant-col-lg-pull-4 {
    right: 16.66666667%; }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%; }
  .ant-col-lg-order-4 {
    -ms-flex-order: 4;
    order: 4; }
  .ant-col-lg-3 {
    display: block;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .ant-col-lg-push-3 {
    left: 12.5%; }
  .ant-col-lg-pull-3 {
    right: 12.5%; }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%; }
  .ant-col-lg-order-3 {
    -ms-flex-order: 3;
    order: 3; }
  .ant-col-lg-2 {
    display: block;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .ant-col-lg-push-2 {
    left: 8.33333333%; }
  .ant-col-lg-pull-2 {
    right: 8.33333333%; }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%; }
  .ant-col-lg-order-2 {
    -ms-flex-order: 2;
    order: 2; }
  .ant-col-lg-1 {
    display: block;
    -ms-flex: 0 0 4.16666667%;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%; }
  .ant-col-lg-push-1 {
    left: 4.16666667%; }
  .ant-col-lg-pull-1 {
    right: 4.16666667%; }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%; }
  .ant-col-lg-order-1 {
    -ms-flex-order: 1;
    order: 1; }
  .ant-col-lg-0 {
    display: none; }
  .ant-col-push-0 {
    left: auto; }
  .ant-col-pull-0 {
    right: auto; }
  .ant-col-lg-push-0 {
    left: auto; }
  .ant-col-lg-pull-0 {
    right: auto; }
  .ant-col-lg-offset-0 {
    margin-left: 0; }
  .ant-col-lg-order-0 {
    -ms-flex-order: 0;
    order: 0; }
  .ant-col-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0; }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto; }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%; }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0; }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto; }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%; }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0; }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto; }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%; }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0; }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto; }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%; }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0; }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto; }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%; }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0; }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto; }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%; }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0; }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto; }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%; }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0; }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto; }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%; }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0; }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto; }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%; }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0; }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto; }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%; }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0; }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto; }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%; }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0; }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto; }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%; }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0; }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto; }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%; }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0; }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto; }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%; }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0; }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto; }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%; }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0; }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto; }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%; }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0; }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto; }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%; }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0; }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto; }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%; }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0; }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto; }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%; }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0; }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto; }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%; }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0; }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto; }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%; }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0; }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto; }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%; }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0; }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto; }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%; }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0; }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto; }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%; }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0; } }

@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .ant-col-xl-push-24 {
    left: 100%; }
  .ant-col-xl-pull-24 {
    right: 100%; }
  .ant-col-xl-offset-24 {
    margin-left: 100%; }
  .ant-col-xl-order-24 {
    -ms-flex-order: 24;
    order: 24; }
  .ant-col-xl-23 {
    display: block;
    -ms-flex: 0 0 95.83333333%;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%; }
  .ant-col-xl-push-23 {
    left: 95.83333333%; }
  .ant-col-xl-pull-23 {
    right: 95.83333333%; }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%; }
  .ant-col-xl-order-23 {
    -ms-flex-order: 23;
    order: 23; }
  .ant-col-xl-22 {
    display: block;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .ant-col-xl-push-22 {
    left: 91.66666667%; }
  .ant-col-xl-pull-22 {
    right: 91.66666667%; }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%; }
  .ant-col-xl-order-22 {
    -ms-flex-order: 22;
    order: 22; }
  .ant-col-xl-21 {
    display: block;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .ant-col-xl-push-21 {
    left: 87.5%; }
  .ant-col-xl-pull-21 {
    right: 87.5%; }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%; }
  .ant-col-xl-order-21 {
    -ms-flex-order: 21;
    order: 21; }
  .ant-col-xl-20 {
    display: block;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .ant-col-xl-push-20 {
    left: 83.33333333%; }
  .ant-col-xl-pull-20 {
    right: 83.33333333%; }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%; }
  .ant-col-xl-order-20 {
    -ms-flex-order: 20;
    order: 20; }
  .ant-col-xl-19 {
    display: block;
    -ms-flex: 0 0 79.16666667%;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%; }
  .ant-col-xl-push-19 {
    left: 79.16666667%; }
  .ant-col-xl-pull-19 {
    right: 79.16666667%; }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%; }
  .ant-col-xl-order-19 {
    -ms-flex-order: 19;
    order: 19; }
  .ant-col-xl-18 {
    display: block;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .ant-col-xl-push-18 {
    left: 75%; }
  .ant-col-xl-pull-18 {
    right: 75%; }
  .ant-col-xl-offset-18 {
    margin-left: 75%; }
  .ant-col-xl-order-18 {
    -ms-flex-order: 18;
    order: 18; }
  .ant-col-xl-17 {
    display: block;
    -ms-flex: 0 0 70.83333333%;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%; }
  .ant-col-xl-push-17 {
    left: 70.83333333%; }
  .ant-col-xl-pull-17 {
    right: 70.83333333%; }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%; }
  .ant-col-xl-order-17 {
    -ms-flex-order: 17;
    order: 17; }
  .ant-col-xl-16 {
    display: block;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .ant-col-xl-push-16 {
    left: 66.66666667%; }
  .ant-col-xl-pull-16 {
    right: 66.66666667%; }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%; }
  .ant-col-xl-order-16 {
    -ms-flex-order: 16;
    order: 16; }
  .ant-col-xl-15 {
    display: block;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .ant-col-xl-push-15 {
    left: 62.5%; }
  .ant-col-xl-pull-15 {
    right: 62.5%; }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%; }
  .ant-col-xl-order-15 {
    -ms-flex-order: 15;
    order: 15; }
  .ant-col-xl-14 {
    display: block;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .ant-col-xl-push-14 {
    left: 58.33333333%; }
  .ant-col-xl-pull-14 {
    right: 58.33333333%; }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%; }
  .ant-col-xl-order-14 {
    -ms-flex-order: 14;
    order: 14; }
  .ant-col-xl-13 {
    display: block;
    -ms-flex: 0 0 54.16666667%;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%; }
  .ant-col-xl-push-13 {
    left: 54.16666667%; }
  .ant-col-xl-pull-13 {
    right: 54.16666667%; }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%; }
  .ant-col-xl-order-13 {
    -ms-flex-order: 13;
    order: 13; }
  .ant-col-xl-12 {
    display: block;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .ant-col-xl-push-12 {
    left: 50%; }
  .ant-col-xl-pull-12 {
    right: 50%; }
  .ant-col-xl-offset-12 {
    margin-left: 50%; }
  .ant-col-xl-order-12 {
    -ms-flex-order: 12;
    order: 12; }
  .ant-col-xl-11 {
    display: block;
    -ms-flex: 0 0 45.83333333%;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%; }
  .ant-col-xl-push-11 {
    left: 45.83333333%; }
  .ant-col-xl-pull-11 {
    right: 45.83333333%; }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%; }
  .ant-col-xl-order-11 {
    -ms-flex-order: 11;
    order: 11; }
  .ant-col-xl-10 {
    display: block;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .ant-col-xl-push-10 {
    left: 41.66666667%; }
  .ant-col-xl-pull-10 {
    right: 41.66666667%; }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%; }
  .ant-col-xl-order-10 {
    -ms-flex-order: 10;
    order: 10; }
  .ant-col-xl-9 {
    display: block;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .ant-col-xl-push-9 {
    left: 37.5%; }
  .ant-col-xl-pull-9 {
    right: 37.5%; }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%; }
  .ant-col-xl-order-9 {
    -ms-flex-order: 9;
    order: 9; }
  .ant-col-xl-8 {
    display: block;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .ant-col-xl-push-8 {
    left: 33.33333333%; }
  .ant-col-xl-pull-8 {
    right: 33.33333333%; }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%; }
  .ant-col-xl-order-8 {
    -ms-flex-order: 8;
    order: 8; }
  .ant-col-xl-7 {
    display: block;
    -ms-flex: 0 0 29.16666667%;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%; }
  .ant-col-xl-push-7 {
    left: 29.16666667%; }
  .ant-col-xl-pull-7 {
    right: 29.16666667%; }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%; }
  .ant-col-xl-order-7 {
    -ms-flex-order: 7;
    order: 7; }
  .ant-col-xl-6 {
    display: block;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .ant-col-xl-push-6 {
    left: 25%; }
  .ant-col-xl-pull-6 {
    right: 25%; }
  .ant-col-xl-offset-6 {
    margin-left: 25%; }
  .ant-col-xl-order-6 {
    -ms-flex-order: 6;
    order: 6; }
  .ant-col-xl-5 {
    display: block;
    -ms-flex: 0 0 20.83333333%;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%; }
  .ant-col-xl-push-5 {
    left: 20.83333333%; }
  .ant-col-xl-pull-5 {
    right: 20.83333333%; }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%; }
  .ant-col-xl-order-5 {
    -ms-flex-order: 5;
    order: 5; }
  .ant-col-xl-4 {
    display: block;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .ant-col-xl-push-4 {
    left: 16.66666667%; }
  .ant-col-xl-pull-4 {
    right: 16.66666667%; }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%; }
  .ant-col-xl-order-4 {
    -ms-flex-order: 4;
    order: 4; }
  .ant-col-xl-3 {
    display: block;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .ant-col-xl-push-3 {
    left: 12.5%; }
  .ant-col-xl-pull-3 {
    right: 12.5%; }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%; }
  .ant-col-xl-order-3 {
    -ms-flex-order: 3;
    order: 3; }
  .ant-col-xl-2 {
    display: block;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .ant-col-xl-push-2 {
    left: 8.33333333%; }
  .ant-col-xl-pull-2 {
    right: 8.33333333%; }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%; }
  .ant-col-xl-order-2 {
    -ms-flex-order: 2;
    order: 2; }
  .ant-col-xl-1 {
    display: block;
    -ms-flex: 0 0 4.16666667%;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%; }
  .ant-col-xl-push-1 {
    left: 4.16666667%; }
  .ant-col-xl-pull-1 {
    right: 4.16666667%; }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%; }
  .ant-col-xl-order-1 {
    -ms-flex-order: 1;
    order: 1; }
  .ant-col-xl-0 {
    display: none; }
  .ant-col-push-0 {
    left: auto; }
  .ant-col-pull-0 {
    right: auto; }
  .ant-col-xl-push-0 {
    left: auto; }
  .ant-col-xl-pull-0 {
    right: auto; }
  .ant-col-xl-offset-0 {
    margin-left: 0; }
  .ant-col-xl-order-0 {
    -ms-flex-order: 0;
    order: 0; }
  .ant-col-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0; }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto; }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%; }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0; }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto; }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%; }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0; }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto; }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%; }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0; }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto; }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%; }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0; }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto; }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%; }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0; }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto; }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%; }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0; }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto; }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%; }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0; }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto; }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%; }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0; }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto; }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%; }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0; }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto; }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%; }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0; }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto; }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%; }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0; }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto; }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%; }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0; }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto; }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%; }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0; }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto; }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%; }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0; }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto; }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%; }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0; }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto; }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%; }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0; }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto; }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%; }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0; }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto; }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%; }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0; }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto; }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%; }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0; }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto; }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%; }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0; }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto; }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%; }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0; }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto; }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%; }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0; }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto; }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%; }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0; }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto; }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%; }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0; } }

@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .ant-col-xxl-push-24 {
    left: 100%; }
  .ant-col-xxl-pull-24 {
    right: 100%; }
  .ant-col-xxl-offset-24 {
    margin-left: 100%; }
  .ant-col-xxl-order-24 {
    -ms-flex-order: 24;
    order: 24; }
  .ant-col-xxl-23 {
    display: block;
    -ms-flex: 0 0 95.83333333%;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%; }
  .ant-col-xxl-push-23 {
    left: 95.83333333%; }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%; }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%; }
  .ant-col-xxl-order-23 {
    -ms-flex-order: 23;
    order: 23; }
  .ant-col-xxl-22 {
    display: block;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .ant-col-xxl-push-22 {
    left: 91.66666667%; }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%; }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%; }
  .ant-col-xxl-order-22 {
    -ms-flex-order: 22;
    order: 22; }
  .ant-col-xxl-21 {
    display: block;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .ant-col-xxl-push-21 {
    left: 87.5%; }
  .ant-col-xxl-pull-21 {
    right: 87.5%; }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%; }
  .ant-col-xxl-order-21 {
    -ms-flex-order: 21;
    order: 21; }
  .ant-col-xxl-20 {
    display: block;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .ant-col-xxl-push-20 {
    left: 83.33333333%; }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%; }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%; }
  .ant-col-xxl-order-20 {
    -ms-flex-order: 20;
    order: 20; }
  .ant-col-xxl-19 {
    display: block;
    -ms-flex: 0 0 79.16666667%;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%; }
  .ant-col-xxl-push-19 {
    left: 79.16666667%; }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%; }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%; }
  .ant-col-xxl-order-19 {
    -ms-flex-order: 19;
    order: 19; }
  .ant-col-xxl-18 {
    display: block;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .ant-col-xxl-push-18 {
    left: 75%; }
  .ant-col-xxl-pull-18 {
    right: 75%; }
  .ant-col-xxl-offset-18 {
    margin-left: 75%; }
  .ant-col-xxl-order-18 {
    -ms-flex-order: 18;
    order: 18; }
  .ant-col-xxl-17 {
    display: block;
    -ms-flex: 0 0 70.83333333%;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%; }
  .ant-col-xxl-push-17 {
    left: 70.83333333%; }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%; }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%; }
  .ant-col-xxl-order-17 {
    -ms-flex-order: 17;
    order: 17; }
  .ant-col-xxl-16 {
    display: block;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .ant-col-xxl-push-16 {
    left: 66.66666667%; }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%; }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%; }
  .ant-col-xxl-order-16 {
    -ms-flex-order: 16;
    order: 16; }
  .ant-col-xxl-15 {
    display: block;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .ant-col-xxl-push-15 {
    left: 62.5%; }
  .ant-col-xxl-pull-15 {
    right: 62.5%; }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%; }
  .ant-col-xxl-order-15 {
    -ms-flex-order: 15;
    order: 15; }
  .ant-col-xxl-14 {
    display: block;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .ant-col-xxl-push-14 {
    left: 58.33333333%; }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%; }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%; }
  .ant-col-xxl-order-14 {
    -ms-flex-order: 14;
    order: 14; }
  .ant-col-xxl-13 {
    display: block;
    -ms-flex: 0 0 54.16666667%;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%; }
  .ant-col-xxl-push-13 {
    left: 54.16666667%; }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%; }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%; }
  .ant-col-xxl-order-13 {
    -ms-flex-order: 13;
    order: 13; }
  .ant-col-xxl-12 {
    display: block;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .ant-col-xxl-push-12 {
    left: 50%; }
  .ant-col-xxl-pull-12 {
    right: 50%; }
  .ant-col-xxl-offset-12 {
    margin-left: 50%; }
  .ant-col-xxl-order-12 {
    -ms-flex-order: 12;
    order: 12; }
  .ant-col-xxl-11 {
    display: block;
    -ms-flex: 0 0 45.83333333%;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%; }
  .ant-col-xxl-push-11 {
    left: 45.83333333%; }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%; }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%; }
  .ant-col-xxl-order-11 {
    -ms-flex-order: 11;
    order: 11; }
  .ant-col-xxl-10 {
    display: block;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .ant-col-xxl-push-10 {
    left: 41.66666667%; }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%; }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%; }
  .ant-col-xxl-order-10 {
    -ms-flex-order: 10;
    order: 10; }
  .ant-col-xxl-9 {
    display: block;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .ant-col-xxl-push-9 {
    left: 37.5%; }
  .ant-col-xxl-pull-9 {
    right: 37.5%; }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%; }
  .ant-col-xxl-order-9 {
    -ms-flex-order: 9;
    order: 9; }
  .ant-col-xxl-8 {
    display: block;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .ant-col-xxl-push-8 {
    left: 33.33333333%; }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%; }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%; }
  .ant-col-xxl-order-8 {
    -ms-flex-order: 8;
    order: 8; }
  .ant-col-xxl-7 {
    display: block;
    -ms-flex: 0 0 29.16666667%;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%; }
  .ant-col-xxl-push-7 {
    left: 29.16666667%; }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%; }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%; }
  .ant-col-xxl-order-7 {
    -ms-flex-order: 7;
    order: 7; }
  .ant-col-xxl-6 {
    display: block;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .ant-col-xxl-push-6 {
    left: 25%; }
  .ant-col-xxl-pull-6 {
    right: 25%; }
  .ant-col-xxl-offset-6 {
    margin-left: 25%; }
  .ant-col-xxl-order-6 {
    -ms-flex-order: 6;
    order: 6; }
  .ant-col-xxl-5 {
    display: block;
    -ms-flex: 0 0 20.83333333%;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%; }
  .ant-col-xxl-push-5 {
    left: 20.83333333%; }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%; }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%; }
  .ant-col-xxl-order-5 {
    -ms-flex-order: 5;
    order: 5; }
  .ant-col-xxl-4 {
    display: block;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .ant-col-xxl-push-4 {
    left: 16.66666667%; }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%; }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%; }
  .ant-col-xxl-order-4 {
    -ms-flex-order: 4;
    order: 4; }
  .ant-col-xxl-3 {
    display: block;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .ant-col-xxl-push-3 {
    left: 12.5%; }
  .ant-col-xxl-pull-3 {
    right: 12.5%; }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%; }
  .ant-col-xxl-order-3 {
    -ms-flex-order: 3;
    order: 3; }
  .ant-col-xxl-2 {
    display: block;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .ant-col-xxl-push-2 {
    left: 8.33333333%; }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%; }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%; }
  .ant-col-xxl-order-2 {
    -ms-flex-order: 2;
    order: 2; }
  .ant-col-xxl-1 {
    display: block;
    -ms-flex: 0 0 4.16666667%;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%; }
  .ant-col-xxl-push-1 {
    left: 4.16666667%; }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%; }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%; }
  .ant-col-xxl-order-1 {
    -ms-flex-order: 1;
    order: 1; }
  .ant-col-xxl-0 {
    display: none; }
  .ant-col-push-0 {
    left: auto; }
  .ant-col-pull-0 {
    right: auto; }
  .ant-col-xxl-push-0 {
    left: auto; }
  .ant-col-xxl-pull-0 {
    right: auto; }
  .ant-col-xxl-offset-0 {
    margin-left: 0; }
  .ant-col-xxl-order-0 {
    -ms-flex-order: 0;
    order: 0; }
  .ant-col-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto; }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto; }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0; }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto; }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%; }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0; }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto; }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%; }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0; }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto; }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%; }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0; }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto; }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%; }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0; }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto; }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%; }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0; }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto; }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%; }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0; }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto; }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%; }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0; }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto; }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%; }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0; }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto; }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%; }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0; }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto; }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%; }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0; }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto; }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%; }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0; }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto; }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%; }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0; }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto; }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%; }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0; }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto; }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%; }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0; }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto; }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%; }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0; }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto; }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%; }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0; }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto; }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%; }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0; }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto; }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%; }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0; }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto; }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%; }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0; }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto; }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%; }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0; }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto; }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%; }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0; }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto; }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%; }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0; }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto; }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%; }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0; }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto; }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%; }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0; } }

.ant-row-rtl {
  direction: rtl; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-carousel {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum"; }

.ant-carousel .slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden; }

.ant-carousel .slick-list:focus {
  outline: none; }

.ant-carousel .slick-list.dragging {
  cursor: pointer; }

.ant-carousel .slick-list .slick-slide {
  pointer-events: none; }

.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden; }

.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto; }

.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible; }

.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom; }

.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: ''; }

.ant-carousel .slick-track::after {
  clear: both; }

.slick-loading .ant-carousel .slick-track {
  visibility: hidden; }

.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.ant-carousel .slick-slide img {
  display: block; }

.ant-carousel .slick-slide.slick-loading img {
  display: none; }

.ant-carousel .slick-slide.dragging img {
  pointer-events: none; }

.ant-carousel .slick-initialized .slick-slide {
  display: block; }

.ant-carousel .slick-loading .slick-slide {
  visibility: hidden; }

.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto; }

.ant-carousel .slick-arrow.slick-hidden {
  display: none; }

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer; }

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none; }

.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1; }

.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25; }

.ant-carousel .slick-prev {
  left: -25px; }

.ant-carousel .slick-prev::before {
  content: '\2190'; }

.ant-carousel .slick-next {
  right: -25px; }

.ant-carousel .slick-next::before {
  content: '\2192'; }

.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none; }

.ant-carousel .slick-dots-bottom {
  bottom: 12px; }

.ant-carousel .slick-dots-top {
  top: 12px; }

.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.ant-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75; }

.ant-carousel .slick-dots li.slick-active {
  width: 24px; }

.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1; }

.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1; }

.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px; }

.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto; }

.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline; }

.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px; }

.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px; }

.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px; }

.ant-carousel-rtl {
  direction: rtl; }

.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto; }

.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto; }

.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '\2192'; }

.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px; }

.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '\2190'; }

.ant-carousel-rtl.ant-carousel .slick-dots {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  -ms-flex-direction: column;
  flex-direction: column; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-cascader {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum"; }

.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer; }

.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative; }

.ant-cascader-picker {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent; }

.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed; }

.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed; }

.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-cascader-picker-borderless .ant-cascader-input {
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25); }

.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease; }

.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1; }

.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px; }

.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px; }

.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  list-style: none; }

.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none; }

.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn; }

.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn; }

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut; }

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut; }

.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.ant-cascader-menu:first-child {
  border-radius: 2px 0 0 2px; }

.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 2px 2px 0; }

.ant-cascader-menu:only-child {
  border-radius: 2px; }

.ant-cascader-menu-item {
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-cascader-menu-item:hover {
  background: #f5f5f5; }

.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-cascader-menu-item-disabled:hover {
  background: transparent; }

.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none; }

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #e6f7ff; }

.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px; }

.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 10px;
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45); }

.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25); }

.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #ff4d4f; }

.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
  padding-right: 11px;
  padding-left: 24px;
  text-align: right; }

.ant-cascader-picker-rtl {
  direction: rtl; }

.ant-cascader-picker-rtl .ant-cascader-picker-label {
  padding: 0 12px 0 20px;
  text-align: right; }

.ant-cascader-picker-rtl .ant-cascader-picker-clear {
  right: auto;
  left: 12px; }

.ant-cascader-picker-rtl .ant-cascader-picker-arrow {
  right: auto;
  left: 12px; }

.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: auto;
  left: 8px; }

.ant-cascader-menu-rtl .ant-cascader-menu {
  direction: rtl;
  border-right: none;
  border-left: 1px solid #f0f0f0; }

.ant-cascader-menu-rtl .ant-cascader-menu:first-child {
  border-radius: 0 2px 2px 0; }

.ant-cascader-menu-rtl .ant-cascader-menu:last-child {
  margin-right: 0;
  margin-left: -1px;
  border-left-color: transparent;
  border-radius: 2px 0 0 2px; }

.ant-cascader-menu-rtl .ant-cascader-menu:only-child {
  border-radius: 2px; }

.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
  padding-right: 12px;
  padding-left: 24px; }

.ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  right: auto;
  left: 12px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.ant-input-affix-wrapper::-moz-placeholder {
  opacity: 1; }

.ant-input-affix-wrapper::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input-affix-wrapper::-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf; }

.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis; }

.ant-input-affix-wrapper:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important; }

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important; }

.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s; }

.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px; }

.ant-input-affix-wrapper-sm {
  padding: 0px 7px; }

.ant-input-affix-wrapper-rtl {
  direction: rtl; }

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent; }

.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none; }

.ant-input-affix-wrapper > input.ant-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\A0'; }

.ant-input-prefix,
.ant-input-suffix {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
  flex: none;
  -ms-flex-align: center;
  align-items: center; }

.ant-input-prefix {
  margin-right: 4px; }

.ant-input-suffix {
  margin-left: 4px; }

.ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  margin: 0 4px;
  vertical-align: -1px; }

.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85); }

.ant-input-clear-icon + i {
  margin-left: 6px; }

.ant-input-clear-icon-hidden {
  visibility: hidden; }

.ant-input-clear-icon:last-child {
  margin-right: 0; }

.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important; }

.ant-input-textarea-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin: 8px 8px 0 0; }

.ant-input-textarea-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-input-textarea-clear-icon:active {
  color: rgba(0, 0, 0, 0.85); }

.ant-input-textarea-clear-icon + i {
  margin-left: 6px; }

.ant-input-textarea-clear-icon-hidden {
  visibility: hidden; }

.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-input::-moz-placeholder {
  opacity: 1; }

.ant-input::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-input:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input::-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input::placeholder {
  color: #bfbfbf; }

.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-input:placeholder-shown {
  text-overflow: ellipsis; }

.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important; }

.ant-input:focus,
.ant-input-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important; }

.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s; }

.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px; }

.ant-input-sm {
  padding: 0px 7px; }

.ant-input-rtl {
  direction: rtl; }

.ant-input-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; }

.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0; }

.ant-input-group > [class*='col-'] {
  padding-right: 8px; }

.ant-input-group > [class*='col-']:last-child {
  padding-right: 0; }

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell; }

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0; }

.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle; }

.ant-input-group-wrap > * {
  display: block !important; }

.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit; }

.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px; }

.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px; }

.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-input-group-addon .ant-select {
  margin: -5px -11px; }

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #1890ff; }

.ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: ''; }

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.ant-input-group-addon:first-child {
  border-right: 0; }

.ant-input-group-addon:last-child {
  border-left: 0; }

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px; }

.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px; }

.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px; }

.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px; }

.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.ant-input-group.ant-input-group-compact {
  display: block; }

.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: ''; }

.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: ''; }

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px; }

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1; }

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1; }

.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0; }

.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: -ms-inline-flexbox;
  display: inline-flex; }

.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: -ms-inline-flexbox;
  display: inline-flex; }

.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px; }

.ant-input-group.ant-input-group-compact .ant-input {
  float: none; }

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0; }

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1; }

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1; }

.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1; }

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1; }

.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top; }

.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0; }

.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0; }

.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9; }

.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px; }

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px; }

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0; }

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px; }

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-radius: 0 2px 2px 0; }

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px; }

.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top; }

.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-input[type='color'] {
  height: 32px; }

.ant-input[type='color'].ant-input-lg {
  height: 40px; }

.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px; }

.ant-input-search-icon {
  padding: 0 9px; }

.ant-input-search-icon::before {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px); }

.ant-input-search-icon::after {
  width: 32px; }

.ant-input-affix-wrapper-lg .ant-input-search-icon {
  padding: 0 12px; }

.ant-input-affix-wrapper-lg .ant-input-search-icon::before {
  -webkit-transform: translateX(-13px);
  transform: translateX(-13px); }

.ant-input-affix-wrapper-lg .ant-input-search-icon::after {
  width: 40px; }

.ant-input-affix-wrapper-sm .ant-input-search-icon {
  padding: 0 6px; }

.ant-input-affix-wrapper-sm .ant-input-search-icon::before {
  -webkit-transform: translateX(-7px);
  transform: translateX(-7px); }

.ant-input-affix-wrapper-sm .ant-input-search-icon::after {
  width: 24px; }

.ant-input-search-icon {
  margin-left: 0.5em;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-input-search-icon::before {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  border-left: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: ''; }

.ant-input-search-icon::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  content: ''; }

.ant-input-search:not(.ant-input-search-enter-button) {
  padding-right: 0; }

.ant-input-search-enter-button input {
  border-right: 0; }

.ant-input-search-enter-button input:hover,
.ant-input-search-enter-button input:focus {
  border-color: #40a9ff; }

.ant-input-search-enter-button.ant-input-affix-wrapper {
  border-right: 0; }

.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0; }

.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.ant-input-group-wrapper-rtl {
  direction: rtl; }

.ant-input-group-rtl {
  direction: rtl; }

.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none; }

.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px; }

.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0; }

.ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
  margin-right: 4px;
  margin-left: 0; }

.ant-input-affix-wrapper-rtl .ant-input-textarea-clear-icon {
  right: auto;
  left: 0;
  margin: 8px 0 0 8px; }

.ant-input-search-rtl {
  direction: rtl; }

.ant-input-search-rtl .ant-input-search-icon {
  margin-right: 0.5em;
  margin-left: 0; }

.ant-input-search-rtl .ant-input-search-icon::before {
  border-left: none; }

.ant-input-search-rtl .ant-input-search-icon::after {
  right: auto;
  left: 0;
  border-right: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-input-search-rtl.ant-input-search:not(.ant-input-search-enter-button) {
  padding-right: 11px;
  padding-left: 0; }

.ant-input-search-rtl.ant-input-search-enter-button input {
  border-right: 1px solid #d9d9d9;
  border-left: 0; }

.ant-input-search-rtl.ant-input-search-enter-button input:hover,
.ant-input-search-rtl.ant-input-search-enter-button input:focus {
  border-color: #40a9ff; }

.ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper {
  border-right: 1px solid #d9d9d9;
  border-left: 0; }

.ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:hover,
.ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:focus {
  border-color: #40a9ff; }

.ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0; }

.ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
.ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  width: 100%;
  border-radius: 2px 0 0 2px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px; }
  .ant-input-lg {
    height: 40px; }
  .ant-input-sm {
    height: 24px; }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

.ant-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff; }

.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: ''; }

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible; }

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' '; }

.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0; }

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' '; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff; }

.ant-checkbox-disabled {
  cursor: not-allowed; }

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none; }

.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed; }

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important; }

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none; }

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden; }

.ant-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer; }

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed; }

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px; }

.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px; }

.ant-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block; }

.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px; }

.ant-checkbox-group-item:last-child {
  margin-right: 0; }

.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0; }

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9; }

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' '; }

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25); }

.ant-checkbox-rtl {
  direction: rtl; }

.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px; }

.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important; }

.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-collapse {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px; }

.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9; }

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
  display: table;
  content: ''; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: table;
  clear: both;
  content: ''; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none; }

.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px; }

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px; }

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto; }

.ant-collapse-anim-active {
  -webkit-transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }

.ant-collapse-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9; }

.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px; }

.ant-collapse-content-inactive {
  display: none; }

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px; }

.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0; }

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9; }

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0; }

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0; }

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px; }

.ant-collapse-ghost {
  background-color: transparent;
  border: 0; }

.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0; }

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0; }

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px; }

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-collapse-rtl {
  direction: rtl; }

.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px; }

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: left; }

.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-comment {
  position: relative;
  background-color: inherit; }

.ant-comment-inner {
  display: -ms-flexbox;
  display: flex;
  padding: 16px 0; }

.ant-comment-avatar {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer; }

.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%; }

.ant-comment-content {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word; }

.ant-comment-content-author {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px; }

.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px; }

.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45); }

.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto; }

.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap; }

.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0; }

.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45); }

.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-comment-actions > li > span:hover {
  color: #595959; }

.ant-comment-nested {
  margin-left: 44px; }

.ant-comment-rtl {
  direction: rtl; }

.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px; }

.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px; }

.ant-comment-rtl .ant-comment-actions {
  padding-right: 0; }

.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px; }

.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-descriptions-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px; }

.ant-descriptions-title {
  -ms-flex: auto;
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px; }

.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 2px; }

.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed; }

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px; }

.ant-descriptions-row:last-child {
  border-bottom: none; }

.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start; }

.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px; }

.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' '; }

.ant-descriptions-item-no-label::after {
  margin: 0;
  content: ''; }

.ant-descriptions-item-content {
  display: table-cell;
  -ms-flex: 1;
  flex: 1 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715; }

.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top; }

.ant-descriptions-item > span {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: baseline;
  align-items: baseline; }

.ant-descriptions-item-container {
  display: -ms-flexbox;
  display: flex; }

.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px; }

.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px; }

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0; }

.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto; }

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0; }

.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none; }

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa; }

.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none; }

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0; }

.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none; }

.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px; }

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px; }

.ant-descriptions-rtl {
  direction: rtl; }

.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-divider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06); }

.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06); }

.ant-divider-horizontal {
  display: -ms-flexbox;
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0; }

.ant-divider-horizontal.ant-divider-with-text {
  display: -ms-flexbox;
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0; }

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  content: ''; }

.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%; }

.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%; }

.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%; }

.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%; }

.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em; }

.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0; }

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
  border-top: 0; }

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none; }

.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px; }

.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px; }

.ant-divider-rtl {
  direction: rtl; }

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%; }

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%; }

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%; }

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  -webkit-transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1); }

.ant-drawer > * {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1); }

.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%; }

.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%; }

.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%; }

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%; }

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1); }

.ant-drawer-left {
  left: 0; }

.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0; }

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03); }

.ant-drawer-right {
  right: 0; }

.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0; }

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03); }

.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
  transform: translateX(1px); }

.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%; }

.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%; }

.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1); }

.ant-drawer-top {
  top: 0; }

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03); }

.ant-drawer-bottom {
  bottom: 0; }

.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0; }

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03); }

.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px); }

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto; }

.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px; }

.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0; }

.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-rendering: auto; }

.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none; }

.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: 0;
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - 0);
  padding-right: calc(20px - var(--scroll-bar)); }

.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0; }

.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.85);
  background: #fff; }

.ant-drawer-wrapper-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%; }

.ant-drawer-body {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word; }

.ant-drawer-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 10px 10px;
  border-top: 1px solid #f0f0f0; }

.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  -webkit-transition: opacity 0.3s linear, height 0s ease 0.3s;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none; }

.ant-drawer-open-content {
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-drawer .ant-picker-clear {
  background: #fff; }

@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.ant-drawer-rtl {
  direction: rtl; }

.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  height: auto; }

.ant-form-item .ant-upload {
  background: transparent; }

.ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa; }

.ant-form-item input[type='radio'],
.ant-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px; }

.ant-form-item .ant-radio-inline,
.ant-form-item .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer; }

.ant-form-item .ant-radio-inline:first-child,
.ant-form-item .ant-checkbox-inline:first-child {
  margin-left: 0; }

.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
  display: block; }

.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0; }

.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px; }

.ant-form-item .ant-input-number-handler-wrap {
  z-index: 2; }

.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: 100%; }

.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker {
  width: auto; }

.ant-form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.ant-form-inline .ant-form-item {
  -ms-flex: none;
  flex: none;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0; }

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px; }

.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top; }

.ant-form-inline .ant-form-item > .ant-form-item-label {
  -ms-flex: none;
  flex: none; }

.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block; }

.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block; }

.ant-form-horizontal .ant-form-item-label {
  -ms-flex-positive: 0;
  flex-grow: 0; }

.ant-form-horizontal .ant-form-item-control {
  -ms-flex: 1 1 0px;
  flex: 1 1; }

.ant-form-vertical .ant-form-item {
  -ms-flex-direction: column;
  flex-direction: column; }

.ant-form-vertical .ant-form-item-label > label {
  height: auto; }

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left; }

.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0; }

.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none; }

.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right; }

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left; }
  .ant-form-item .ant-form-item-label > label {
    margin: 0; }
  .ant-form-item .ant-form-item-label > label::after {
    display: none; }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right; }
  .ant-form .ant-form-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left; }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0; }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none; }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right; } }

@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left; }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0; }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none; }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right; } }

@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left; }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0; }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none; }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right; } }

@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left; }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0; }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none; }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right; } }

@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left; }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0; }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none; }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right; } }

.ant-form-item {
  /* Some non-status related component style is in `components.less` */ }

.ant-form-item-has-feedback .ant-input {
  padding-right: 24px; }

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px; }

.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px; }

.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px; }

.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: 32px; }

.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px; }

.ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px; }

.ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: 32px; }

.ant-form-item-has-feedback .ant-picker {
  padding-right: 29.2px; }

.ant-form-item-has-feedback .ant-picker-large {
  padding-right: 29.2px; }

.ant-form-item-has-feedback .ant-picker-small {
  padding-right: 25.2px; }

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none; }

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon svg,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon svg,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon svg,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
  animation-name: diffZoomIn1 !important; }

.ant-form-item-has-warning .ant-form-item-explain,
.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14; }

.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
  border-color: #faad14; }

.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }

.ant-form-item-has-warning .ant-input:not(.ant-form-item-has-warning .ant-input-disabled) {
  background-color: #fff; }

.ant-form-item-has-warning .ant-input-affix-wrapper:not(.ant-form-item-has-warning .ant-input-affix-wrapper-disabled) {
  background-color: #fff; }

.ant-form-item-has-warning .ant-input-affix-wrapper input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }

.ant-form-item-has-warning .ant-input-prefix {
  color: #faad14; }

.ant-form-item-has-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14; }

.ant-form-item-has-warning .has-feedback {
  color: #faad14; }

.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #faad14;
  -webkit-animation-name: diffZoomIn3 !important;
  animation-name: diffZoomIn3 !important; }

.ant-form-item-has-warning .ant-select:not(.ant-select-borderless) .ant-select-selector {
  border-color: #faad14 !important; }

.ant-form-item-has-warning .ant-select:not(.ant-select-borderless).ant-select-open .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-borderless).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }

.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
  border-color: #faad14; }

.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }

.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
  border-color: #faad14; }

.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f; }

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #ff4d4f; }

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
  background-color: #fff; }

.ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-form-item-has-error .ant-input-affix-wrapper-disabled) {
  background-color: #fff; }

.ant-form-item-has-error .ant-input-affix-wrapper input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.ant-form-item-has-error .ant-input-prefix {
  color: #ff4d4f; }

.ant-form-item-has-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f; }

.ant-form-item-has-error .has-feedback {
  color: #ff4d4f; }

.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ff4d4f;
  -webkit-animation-name: diffZoomIn2 !important;
  animation-name: diffZoomIn2 !important; }

.ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
  border-color: #ff4d4f !important; }

.ant-form-item-has-error .ant-select:not(.ant-select-borderless).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-borderless).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0; }

.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff4d4f; }

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  border-color: #ff4d4f; }

.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  border-color: #ff4d4f; }

.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #ff4d4f; }

.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.ant-form-item-has-error .ant-transfer-list {
  border-color: #ff4d4f; }

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9; }

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-form-item-has-error-leave .ant-form-item-explain {
  color: #ff4d4f; }

.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #1890ff; }

.ant-form {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum"; }

.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9; }

.ant-form label {
  font-size: 14px; }

.ant-form input[type='search'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal; }

.ant-form input[type='file'] {
  display: block; }

.ant-form input[type='range'] {
  display: block;
  width: 100%; }

.ant-form select[multiple],
.ant-form select[size] {
  height: auto; }

.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715; }

.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px; }

.ant-form-small .ant-form-item-label > label {
  height: 24px; }

.ant-form-small .ant-form-item-control-input {
  min-height: 24px; }

.ant-form-large .ant-form-item-label > label {
  height: 40px; }

.ant-form-large .ant-form-item-control-input {
  min-height: 40px; }

.ant-form-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top; }

.ant-form-item-with-help {
  margin-bottom: 0; }

.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none; }

.ant-form-item-label {
  display: inline-block;
  -ms-flex-positive: 0;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle; }

.ant-form-item-label-left {
  text-align: left; }

.ant-form-item-label > label {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px; }

.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top; }

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*'; }

.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none; }

.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45); }

.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none; }

.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px; }

.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' '; }

.ant-form-item-control {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%; }

.ant-form-item-control-input {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: 32px; }

.ant-form-item-control-input-content {
  -ms-flex: auto;
  flex: auto;
  max-width: 100%; }

.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  min-height: 24px;
  padding-top: 0px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }

.show-help-enter,
.show-help-appear {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.show-help-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
  animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.show-help-leave.show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
  animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.show-help-enter,
.show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }

.show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }

@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0; } }

@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0; } }

@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.ant-form-rtl {
  direction: rtl; }

.ant-form-rtl .ant-form-item-label {
  text-align: left; }

.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px; }

.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px; }

.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0; }

.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%; }

.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px; }

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px; }

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0; }

.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px; }

.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px; }

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px; }

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px; }

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px; }

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px; }

.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px; }

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px; }

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px; }

.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0; }

.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-image {
  position: relative;
  display: inline-block; }

.ant-image-img {
  width: 100%;
  height: auto; }

.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%; }

.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.ant-image-preview {
  height: 100%;
  text-align: center; }

.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden; }

.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  cursor: -webkit-grab;
  cursor: grab;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto; }

.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; }

.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: ''; }

.ant-image-preview-moving .ant-image-preview-img {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.ant-image-preview-moving .ant-image-preview-img-wrapper {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.ant-image-preview-wrap {
  z-index: 1080; }

.ant-image-preview-operations {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto; }

.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer; }

.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.45);
  pointer-events: none; }

.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0; }

.ant-image-preview-operations-icon {
  font-size: 18px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-input-number {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px; }

.ant-input-number::-moz-placeholder {
  opacity: 1; }

.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input-number::-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input-number::placeholder {
  color: #bfbfbf; }

.ant-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-input-number:placeholder-shown {
  text-overflow: ellipsis; }

.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s; }

.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px; }

.ant-input-number-sm {
  padding: 0px 7px; }

.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear; }

.ant-input-number-handler:active {
  background: #f4f4f4; }

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #40a9ff; }

.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1; }

.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block; }

.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none; }

.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block; }

.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  -webkit-transition: opacity 0.24s linear 0.24s;
  transition: opacity 0.24s linear 0.24s; }

.ant-input-number-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed; }

.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none; }

.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none; }

.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important; }

.ant-input-number-input::-moz-placeholder {
  opacity: 1; }

.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input-number-input::-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input-number-input::placeholder {
  color: #bfbfbf; }

.ant-input-number-input:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-input-number-input:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis; }

.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.ant-input-number-lg {
  padding: 0;
  font-size: 16px; }

.ant-input-number-lg input {
  height: 38px; }

.ant-input-number-sm {
  padding: 0; }

.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px; }

.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  -webkit-transition: opacity 0.24s linear 0.1s;
  transition: opacity 0.24s linear 0.1s; }

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 7px;
  min-width: auto;
  margin-right: 0; }

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%; }

.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1; }

.ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer; }

.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center; }

.ant-input-number-handler-up:hover {
  height: 60% !important; }

.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer; }

.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.ant-input-number-handler-down:hover {
  height: 60% !important; }

.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed; }

.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25); }

.ant-input-number-rtl {
  direction: rtl; }

.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 2px 0 0 2px; }

.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-layout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5; }

.ant-layout,
.ant-layout * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.ant-layout.ant-layout-has-sider {
  -ms-flex-direction: row;
  flex-direction: row; }

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden; }

.ant-layout-header,
.ant-layout-footer {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529; }

.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5; }

.ant-layout-content {
  -ms-flex: auto;
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0; }

.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px; }

.ant-layout-sider-has-trigger {
  padding-bottom: 48px; }

.ant-layout-sider-right {
  -ms-flex-order: 1;
  order: 1; }

.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.ant-layout-sider-zero-width > * {
  overflow: hidden; }

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease; }

.ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e; }

.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px; }

.ant-layout-sider-light {
  background: #fff; }

.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff; }

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff; }

.ant-layout-rtl {
  direction: rtl; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative; }

.ant-list * {
  outline: none; }

.ant-list-pagination {
  margin-top: 24px;
  text-align: right; }

.ant-list-pagination .ant-pagination-options {
  text-align: left; }

.ant-list-more {
  margin-top: 12px;
  text-align: center; }

.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px; }

.ant-list-spin {
  min-height: 40px;
  text-align: center; }

.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center; }

.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none; }

.ant-list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85); }

.ant-list-item-meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1 1;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: 100%; }

.ant-list-item-meta-avatar {
  margin-right: 16px; }

.ant-list-item-meta-content {
  -ms-flex: 1 0;
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85); }

.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715; }

.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-list-item-meta-title > a:hover {
  color: #1890ff; }

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715; }

.ant-list-item-action {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none; }

.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center; }

.ant-list-item-action > li:first-child {
  padding-left: 0; }

.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0; }

.ant-list-header {
  background: transparent; }

.ant-list-footer {
  background: transparent; }

.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px; }

.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center; }

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0; }

.ant-list-split .ant-list-item:last-child {
  border-bottom: none; }

.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0; }

.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0; }

.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px; }

.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0; }

.ant-list-lg .ant-list-item {
  padding: 16px 24px; }

.ant-list-sm .ant-list-item {
  padding: 8px 16px; }

.ant-list-vertical .ant-list-item {
  -ms-flex-align: initial;
  align-items: initial; }

.ant-list-vertical .ant-list-item-main {
  display: block;
  -ms-flex: 1;
  flex: 1 1; }

.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px; }

.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px; }

.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px; }

.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto; }

.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px; }

.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0; }

.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none; }

.ant-list-item-no-flex {
  display: block; }

.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right; }

.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px; }

.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px; }

.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px; }

.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px; }

.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px; }

.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px; }

.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px; }

.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px; }

.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px; }

@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px; }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px; } }

@media screen and (max-width: 576px) {
  .ant-list-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ant-list-item-action {
    margin-left: 12px; }
  .ant-list-vertical .ant-list-item {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse; }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px; }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px; } }

.ant-list-rtl {
  direction: rtl;
  text-align: right; }

.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl; }

.ant-list-rtl .ant-list-pagination {
  text-align: left; }

.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px; }

.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0; }

.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 8px; }

.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0; }

.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0; }

.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto; }

.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px; }

.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left; }

@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0; }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0; } }

@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0; }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-spin {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1; }

.ant-spin-nested-loading {
  position: relative; }

.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px; }

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px; }

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff; }

.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px; }

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px; }

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px; }

.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px; }

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px; }

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px; }

.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px; }

.ant-spin-container {
  position: relative;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s; }

.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
  pointer-events: none; }

.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none; }

.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto; }

.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45); }

.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em; }

.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #1890ff;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
  animation: antSpinMove 1s infinite linear alternate; }

.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0; }

.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
  animation: antRotate 1.2s infinite linear; }

.ant-spin-sm .ant-spin-dot {
  font-size: 14px; }

.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px; }

.ant-spin-lg .ant-spin-dot {
  font-size: 32px; }

.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px; }

.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5; } }

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1; } }

@keyframes antSpinMove {
  to {
    opacity: 1; } }

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); } }

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); } }

.ant-spin-rtl {
  direction: rtl; }

.ant-spin-rtl .ant-spin-dot-spin {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation-name: antRotateRtl;
  animation-name: antRotateRtl; }

@-webkit-keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); } }

@keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-pagination {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum"; }

.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' '; }

.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle; }

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  -webkit-transition: none;
  transition: none; }

.ant-pagination-item a:hover {
  text-decoration: none; }

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #1890ff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1890ff; }

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff; }

.ant-pagination-item-active a {
  color: #1890ff; }

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #40a9ff; }

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #40a9ff; }

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0; }

.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative; }

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1890ff;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1; }

.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0; }

.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px; }

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0; }

.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #40a9ff; }

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff; }

.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed; }

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-pagination-slash {
  margin: 0 10px 0 5px; }

.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle; }

@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top; } }

.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px; }

.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top; }

.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px; }

.ant-pagination-options-quick-jumper input::-moz-placeholder {
  opacity: 1; }

.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-pagination-options-quick-jumper input::-ms-input-placeholder {
  color: #bfbfbf; }

.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf; }

.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis; }

.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s; }

.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px; }

.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px; }

.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top; }

.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0; }

.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px; }

.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px; }

.ant-pagination-simple .ant-pagination-simple-pager input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s; }

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff; }

.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px; }

.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px; }

.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent; }

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px; }

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent; }

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px; }

.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px; }

.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px; }

.ant-pagination.mini .ant-pagination-options-size-changer {
  top: 0px; }

.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px; }

.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px; }

.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed; }

.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed; }

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent; }

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff; }

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent; }

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0; }

.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1; }

.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25); }

@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none; } }

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none; } }

.ant-pagination-rtl {
  direction: rtl; }

.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px; }

.ant-pagination-rtl .ant-pagination-item {
  margin-right: 0;
  margin-left: 8px; }

.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px; }

.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px; }

.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0; }

.ant-pagination-rtl .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px; }

.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px; }

.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px; }

.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-mentions {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom; }

.ant-mentions::-moz-placeholder {
  opacity: 1; }

.ant-mentions::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-mentions::-ms-input-placeholder {
  color: #bfbfbf; }

.ant-mentions::placeholder {
  color: #bfbfbf; }

.ant-mentions:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-mentions:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-mentions:placeholder-shown {
  text-overflow: ellipsis; }

.ant-mentions:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s; }

.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px; }

.ant-mentions-sm {
  padding: 0px 7px; }

.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-mentions-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
  -o-tab-size: inherit;
  tab-size: inherit; }

.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none; }

.ant-mentions > textarea::-moz-placeholder {
  opacity: 1; }

.ant-mentions > textarea::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-mentions > textarea::-ms-input-placeholder {
  color: #bfbfbf; }

.ant-mentions > textarea::placeholder {
  color: #bfbfbf; }

.ant-mentions > textarea:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-mentions > textarea:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis; }

.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none; }

.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em; }

.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-mentions-dropdown-hidden {
  display: none; }

.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none; }

.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease; }

.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5; }

.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0; }

.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px; }

.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed; }

.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fafafa; }

.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5; }

.ant-mentions-rtl {
  direction: rtl; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-message {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none; }

.ant-message-notice {
  padding: 8px;
  text-align: center; }

.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all; }

.ant-message-success .anticon {
  color: #52c41a; }

.ant-message-error .anticon {
  color: #ff4d4f; }

.ant-message-warning .anticon {
  color: #faad14; }

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff; }

.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px; }

.ant-message-notice.move-up-leave.move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s; }

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1; }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0; } }

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1; }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0; } }

.ant-message-rtl {
  direction: rtl; }

.ant-message-rtl span {
  direction: rtl; }

.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px; }

.ant-modal-wrap {
  z-index: 1000; }

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word; }

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto; }

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto; }

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none; }

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0; }

.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word; }

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px; }

.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px; }

.ant-modal-open {
  overflow: hidden; }

.ant-modal-centered {
  text-align: center; }

.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: ''; }

.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto; }
  .ant-modal-centered .ant-modal {
    -ms-flex: 1;
    flex: 1 1; } }

.ant-modal-confirm .ant-modal-header {
  display: none; }

.ant-modal-confirm .ant-modal-close {
  display: none; }

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px; }

.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: ''; }

.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: ''; }

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4; }

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px; }

.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px; }

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px; }

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px; }

.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px; }

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f; }

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14; }

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff; }

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a; }

.ant-modal-wrap-rtl {
  direction: rtl; }

.ant-modal-wrap-rtl .ant-modal-close {
  right: auto;
  right: initial;
  left: 0; }

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left; }

.ant-modal-wrap-rtl .ant-modal-footer button + button {
  margin-right: 8px;
  margin-left: 0; }

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl; }

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px; }

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0; }

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left; }

.ant-modal-wrap-rtl .ant-modal-confirm-btns button + button {
  margin-right: 8px;
  margin-left: 0; }

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-notification {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  max-width: calc(100vw - 32px);
  margin-right: 24px; }

.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px; }

.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
  animation-name: NotificationLeftFadeIn; }

.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer; }

.ant-notification-hook-holder,
.ant-notification-notice {
  position: relative;
  width: 384px;
  margin-bottom: 16px;
  margin-left: auto;
  overflow: hidden;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-notification-topLeft .ant-notification-hook-holder,
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-hook-holder,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0; }

.ant-notification-hook-holder > .ant-notification-notice {
  margin-bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-notification-notice {
  padding: 16px 24px;
  line-height: 1.5715; }

.ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px; }

.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none; }

.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: ''; }

.ant-notification-notice-description {
  font-size: 14px; }

.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px; }

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px; }

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px; }

.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px; }

.anticon.ant-notification-notice-icon-success {
  color: #52c41a; }

.anticon.ant-notification-notice-icon-info {
  color: #1890ff; }

.anticon.ant-notification-notice-icon-warning {
  color: #faad14; }

.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f; }

.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none; }

.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67); }

.ant-notification-notice-btn {
  float: right;
  margin-top: 16px; }

.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
  animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
  animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0; }
  100% {
    right: 0;
    opacity: 1; } }

@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0; }
  100% {
    right: 0;
    opacity: 1; } }

@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1; }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0; } }

@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1; }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0; } }

.ant-notification-rtl {
  direction: rtl; }

.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px; }

.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0; }

.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0; }

.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0; }

.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px; }

.ant-notification-rtl .ant-notification-notice-btn {
  float: left; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-page-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff; }

.ant-page-header-ghost {
  background-color: inherit; }

.ant-page-header.has-breadcrumb {
  padding-top: 12px; }

.ant-page-header.has-footer {
  padding-bottom: 0; }

.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1; }

.ant-page-header-back-button {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #000;
  cursor: pointer; }

.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #40a9ff; }

.ant-page-header-back-button:active {
  color: #096dd9; }

.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle; }

.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px; }

.ant-page-header-heading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.ant-page-header-heading-left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 4px 0;
  overflow: hidden; }

.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-page-header-heading .ant-avatar {
  margin-right: 12px; }

.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap; }

.ant-page-header-heading-extra > * {
  margin-left: 12px;
  white-space: unset; }

.ant-page-header-heading-extra > *:first-child {
  margin-left: 0; }

.ant-page-header-content {
  padding-top: 12px; }

.ant-page-header-footer {
  margin-top: 16px; }

.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0; }

.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none; }

.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding: 8px 0;
  font-size: 16px; }

.ant-page-header-compact .ant-page-header-heading {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.ant-page-header-rtl {
  direction: rtl; }

.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px; }

.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px; }

.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px; }

.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px; }

.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right; }

.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left; }

.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0; }

.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0; }

.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-popconfirm {
  z-index: 1060; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-progress {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block; }

.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px; }

.ant-progress-steps {
  display: inline-block; }

.ant-progress-steps-outer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center; }

.ant-progress-steps-item {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-progress-steps-item-active {
  background: #1890ff; }

.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px; }

.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0; }

.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px); }

.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px; }

.ant-progress-circle-trail {
  stroke: #f5f5f5; }

.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
  animation: ant-progress-appear 0.3s; }

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff; }

.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s; }

.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a; }

.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal; }

.ant-progress-text .anticon {
  font-size: 14px; }

.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: ''; }

.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f; }

.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f; }

.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f; }

.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a; }

.ant-progress-status-success .ant-progress-text {
  color: #52c41a; }

.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a; }

.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent; }

.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em; }

.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f; }

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a; }

@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1; }
  20% {
    width: 0;
    opacity: 0.5; }
  100% {
    width: 100%;
    opacity: 0; } }

@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1; }
  20% {
    width: 0;
    opacity: 0.5; }
  100% {
    width: 100%;
    opacity: 0; } }

.ant-progress-rtl {
  direction: rtl; }

.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px); }

.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto; }

.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-rate {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none; }

.ant-rate-disabled .ant-rate-star {
  cursor: default; }

.ant-rate-disabled .ant-rate-star:hover {
  -webkit-transform: scale(1);
  transform: scale(1); }

.ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-rate-star:not(:last-child) {
  margin-right: 8px; }

.ant-rate-star > div:focus {
  outline: 0; }

.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle; }

.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0; }

.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1; }

.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit; }

.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px; }

.ant-rate-rtl {
  direction: rtl; }

.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px; }

.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-result {
  padding: 48px 32px; }

.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a; }

.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f; }

.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff; }

.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14; }

.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto; }

.ant-result-icon {
  margin-bottom: 24px;
  text-align: center; }

.ant-result-icon > .anticon {
  font-size: 72px; }

.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center; }

.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center; }

.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center; }

.ant-result-extra > * {
  margin-right: 8px; }

.ant-result-extra > *:last-child {
  margin-right: 0; }

.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa; }

.ant-result-rtl {
  direction: rtl; }

.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px; }

.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-skeleton {
  display: table;
  width: 100%; }

.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top; }

.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px; }

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%; }

.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px; }

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%; }

.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px; }

.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%; }

.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top; }

.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2; }

.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px; }

.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0; }

.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2; }

.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%; }

.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px; }

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px; }

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px; }

.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px; }

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite; }

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite; }

.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite; }

.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite; }

.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite; }

.ant-skeleton-element {
  display: inline-block;
  width: auto; }

.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  border-radius: 2px;
  width: 64px;
  height: 32px;
  line-height: 32px; }

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  border-radius: 50%; }

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px; }

.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  height: 40px;
  line-height: 40px; }

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  border-radius: 50%; }

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px; }

.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  height: 24px;
  line-height: 24px; }

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  border-radius: 50%; }

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px; }

.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px; }

.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%; }

.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px; }

.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%; }

.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px; }

.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%; }

.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 100%;
  height: 32px;
  line-height: 32px; }

.ant-skeleton-element .ant-skeleton-input-lg {
  width: 100%;
  height: 40px;
  line-height: 40px; }

.ant-skeleton-element .ant-skeleton-input-sm {
  width: 100%;
  height: 24px;
  line-height: 24px; }

.ant-skeleton-element .ant-skeleton-image {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: top;
  background: #f2f2f2;
  width: 96px;
  height: 96px;
  line-height: 96px; }

.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%; }

.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf; }

.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px; }

.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%; }

@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

.ant-skeleton-rtl {
  direction: rtl; }

.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px; }

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  -webkit-animation-name: ant-skeleton-loading-rtl;
  animation-name: ant-skeleton-loading-rtl; }

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  -webkit-animation-name: ant-skeleton-loading-rtl;
  animation-name: ant-skeleton-loading-rtl; }

@-webkit-keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none; }

.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px; }

.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%; }

.ant-slider-vertical .ant-slider-track {
  width: 4px; }

.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px; }

.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%; }

.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap; }

.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%; }

.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px; }

.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset; }

.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0; }

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto; }

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto; }

.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto; }

.ant-slider-with-marks {
  margin-bottom: 28px; }

.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #91d5ff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  cursor: pointer;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28); }

.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #46a6ff;
  -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12); }

.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12); }

.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff; }

.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1; }

.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff; }

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff; }

.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px; }

.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85); }

.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer; }

.ant-slider-dot:first-child {
  margin-left: -4px; }

.ant-slider-dot:last-child {
  margin-left: -4px; }

.ant-slider-dot-active {
  border-color: #8cc8ff; }

.ant-slider-disabled {
  cursor: not-allowed; }

.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important; }

.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed; }

.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important; }

.ant-slider-rtl {
  direction: rtl; }

.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto; }

.ant-slider-rtl .ant-slider-dot {
  margin-right: -4px;
  margin-left: 0; }

.ant-slider-rtl .ant-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0; }

.ant-slider-rtl .ant-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-space {
  display: -ms-inline-flexbox;
  display: inline-flex; }

.ant-space-vertical {
  -ms-flex-direction: column;
  flex-direction: column; }

.ant-space-align-center {
  -ms-flex-align: center;
  align-items: center; }

.ant-space-align-start {
  -ms-flex-align: start;
  align-items: flex-start; }

.ant-space-align-end {
  -ms-flex-align: end;
  align-items: flex-end; }

.ant-space-align-baseline {
  -ms-flex-align: baseline;
  align-items: baseline; }

.ant-space-rtl {
  direction: rtl; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-statistic {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum"; }

.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px; }

.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; }

.ant-statistic-content-value {
  display: inline-block;
  direction: ltr; }

.ant-statistic-content-value-decimal {
  font-size: 16px; }

.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block; }

.ant-statistic-content-prefix {
  margin-right: 4px; }

.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px; }

.ant-statistic-rtl {
  direction: rtl; }

.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px; }

.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-steps {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: left;
  text-align: initial; }

.ant-steps-item {
  position: relative;
  display: inline-block;
  -ms-flex: 1;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top; }

.ant-steps-item-container {
  outline: none; }

.ant-steps-item:last-child {
  -ms-flex: none;
  flex: none; }

.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none; }

.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top; }

.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s; }

.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #1890ff;
  line-height: 1; }

.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px; }

.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  content: ''; }

.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px; }

.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: ''; }

.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px; }

.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px; }

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25); }

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25); }

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25); }

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45); }

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0; }

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45); }

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0; }

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff; }

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff; }

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff; }

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85); }

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0; }

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.85); }

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0; }

.ant-steps-item-process .ant-steps-item-icon {
  background: #1890ff; }

.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
  color: #fff; }

.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500; }

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff; }

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff; }

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff; }

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85); }

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1890ff; }

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45); }

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #1890ff; }

.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f; }

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f; }

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f; }

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f; }

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0; }

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f; }

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0; }

.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f; }

.ant-steps-item-disabled {
  cursor: not-allowed; }

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer; }

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #1890ff; }

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #1890ff; }

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #1890ff; }

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap; }

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0; }

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0; }

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none; }

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal; }

.ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0; }

.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px; }

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff; }

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none; }

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px; }

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0; }

.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px; }

.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px; }

.ant-steps-small .ant-steps-item-title::after {
  top: 12px; }

.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px; }

.ant-steps-small .ant-steps-item-tail {
  top: 8px; }

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0; }

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
  transform: none; }

.ant-steps-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.ant-steps-vertical .ant-steps-item {
  display: block;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  overflow: visible; }

.ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px; }

.ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden; }

.ant-steps-vertical .ant-steps-item-title {
  line-height: 32px; }

.ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px; }

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px; }

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%; }

.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block; }

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none; }

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px; }

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px; }

.ant-steps-rtl.ant-steps-vertical .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px; }

.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto; }

.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto; }

@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column; }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    overflow: visible; }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px; }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden; }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px; }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px; }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px; }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%; }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: block; }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none; }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px; }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px; }
  .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: right;
    margin-right: 0;
    margin-left: 16px; }
  .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    right: 16px;
    left: auto; }
  .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    right: 12px;
    left: auto; } }

.ant-steps-label-vertical .ant-steps-item {
  overflow: visible; }

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px; }

.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center; }

.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px; }

.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0; }

.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none; }

.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715; }

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px; }

.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715; }

.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0; }

.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px; }

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px; }

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0; }

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /* expand hover area */ }

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: ''; }

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px; }

.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
  background: none; }

.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px; }

.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0; }

.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px; }

.ant-steps-navigation {
  padding-top: 12px; }

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px; }

.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center; }

.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s; }

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto; }

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none; }

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer; }

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85; }

.ant-steps-navigation .ant-steps-item:last-child {
  -ms-flex: 1;
  flex: 1 1; }

.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none; }

.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: ''; }

.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #1890ff;
  -webkit-transition: width 0.3s, left 0.3s;
  transition: width 0.3s, left 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  content: ''; }

.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%; }

@media (max-width: 480px) {
  .ant-steps-navigation > .ant-steps-item {
    margin-right: 0 !important; }
  .ant-steps-navigation > .ant-steps-item::before {
    display: none; }
  .ant-steps-navigation > .ant-steps-item.ant-steps-item-active::before {
    top: 0;
    right: 0;
    left: unset;
    display: block;
    width: 3px;
    height: calc(100% - 24px); }
  .ant-steps-navigation > .ant-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }
  .ant-steps-navigation > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    visibility: hidden; } }

.ant-steps-rtl {
  direction: rtl; }

.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px; }

.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto; }

.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px; }

.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto; }

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0; }

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0; }

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0; }

.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto; }

.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0; }

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right; }

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0; }

.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg); }

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0; }

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0; }

.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px; }

.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0; }

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0; }

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0; }

.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto; }

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0; }

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */ }

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right; }

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto; }

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px; }

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto; }

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto; }

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto; }

.ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
  padding-top: 4px; }

.ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation) .ant-steps-item .ant-steps-item-icon {
  position: relative; }

.ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation) .ant-steps-item .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-switch:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1); }

.ant-switch-checked:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-switch:focus:hover {
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-switch-checked {
  background-color: #1890ff; }

.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4; }

.ant-switch-loading *,
.ant-switch-disabled * {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed; }

.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  -webkit-transition: margin 0.2s;
  transition: margin 0.2s; }

.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px; }

.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  content: ''; }

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px); }

.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0; }

.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%; }

.ant-switch-loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.ant-switch-checked .ant-switch-loading-icon {
  color: #1890ff; }

.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px; }

.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px; }

.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px; }

.ant-switch-small .ant-switch-loading-icon {
  -webkit-transform: translate(-50%, -50%) scale(0.66667);
  transform: translate(-50%, -50%) scale(0.66667); }

.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px; }

.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px); }

.ant-switch-rtl {
  direction: rtl; }

.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px; }

.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto; }

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%; }

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0; }

.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px; }

.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px); }

.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px); }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-table.ant-table-middle {
  font-size: 14px; }

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px; }

.ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0; }

.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
  margin: -12px -8px; }

.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
  padding: 12px 2.3em 12px 8px; }

.ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
  padding: 12px 8px; }

.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px; }

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 25px; }

.ant-table.ant-table-small {
  font-size: 14px; }

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px; }

.ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0; }

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: -8px -8px; }

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding: 8px 2.3em 8px 8px; }

.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 8px 8px; }

.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px; }

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 25px; }

.ant-table-small .ant-table-thead > tr > th {
  background-color: #fafafa; }

.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px; }

.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0; }

.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0; }

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0; }

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0; }

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0; }

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px; }

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: ''; }

.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0; }

.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px; }

.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px; }

.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0; }

.ant-table-cell .ant-table-container:first-child {
  border-top: 0; }

.ant-table-cell-scrollbar {
  -webkit-box-shadow: 0 1px 0 1px #fafafa;
  box-shadow: 0 1px 0 1px #fafafa; }

.ant-table-wrapper {
  clear: both;
  max-width: 100%; }

.ant-table-wrapper::before {
  display: table;
  content: ''; }

.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: ''; }

.ant-table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  z-index: 0;
  font-size: 14px;
  background: #fff;
  border-radius: 2px; }

.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0; }

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word; }

.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all; }

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible; }

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis; }

.ant-table-title {
  padding: 16px 16px; }

.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa; }

.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease; }

.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center; }

.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0; }

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  -webkit-transition: background 0.3s;
  transition: background 0.3s; }

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 33px; }

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0; }

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0; }

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafafa; }

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e6f7ff;
  border-color: rgba(0, 0, 0, 0.03); }

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #dcf4ff; }

.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-bottom: 1px solid #f0f0f0; }

.ant-table-pagination.ant-pagination {
  margin: 16px 0; }

.ant-table-pagination-left {
  float: left; }

.ant-table-pagination-center {
  text-align: center; }

.ant-table-pagination-right {
  float: right; }

.ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f2f2f2; }

.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: #f7f7f7; }

.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5; }

td.ant-table-column-sort {
  background: #fafafa; }

.ant-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%; }

.ant-table-column-sorters {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 16px; }

.ant-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #bfbfbf; }

.ant-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0; }

.ant-table-column-sorter-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center; }

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  display: inline-block;
  font-size: 11px; }

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #1890ff; }

.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em; }

.ant-table-filter-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: -16px -16px; }

.ant-table-filter-column-title {
  -ms-flex: auto;
  flex: auto;
  padding: 16px 2.3em 16px 16px; }

.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
  margin: 0; }

.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 2.3em 0 0; }

.ant-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
  flex: none;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: #e5e5e5; }

.ant-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #bfbfbf;
  font-size: 12px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-table-filter-trigger.active {
  color: #1890ff; }

.ant-table-filter-dropdown {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto; }

.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px; }

.ant-table-filter-dropdown-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0; }

.ant-table .ant-table-selection-col {
  width: 60px; }

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center; }

table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0; }

.ant-table-selection {
  position: relative; }

.ant-table-selection-extra {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-table-selection-extra .anticon {
  display: inline-block;
  font-size: 10px;
  color: #bfbfbf; }

.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6; }

.ant-table-expand-icon-col {
  width: 48px; }

.ant-table-row-expand-icon-cell {
  text-align: center; }

.ant-table-row-indent {
  float: left;
  height: 1px; }

.ant-table-row-expand-icon {
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #40a9ff; }

.ant-table-row-expand-icon:active {
  color: #096dd9; }

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor; }

.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentColor;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  content: ''; }

.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px; }

.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.ant-table-row-expand-icon-collapsed::before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg); }

.ant-table-row-expand-icon-collapsed::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg); }

.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden; }

.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none; }

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px; }

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb; }

tr.ant-table-expanded-row .ant-descriptions-view table {
  width: auto; }

.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px; }

.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center; }

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25); }

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff; }

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff; }

.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none; }

.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none; }

.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none; }

.ant-table .ant-table-container::before {
  left: 0; }

.ant-table .ant-table-container::after {
  right: 0; }

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
  position: relative; }

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  -webkit-box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15); }

.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  -webkit-box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15); }

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
  position: relative; }

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  -webkit-box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15); }

.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  -webkit-box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15); }

.ant-table-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 3; }

.ant-table-sticky-scroll {
  position: fixed;
  bottom: 0;
  z-index: 3;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6; }

.ant-table-sticky-scroll:hover {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px; }

.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8); }

.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8); }

@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; } }

.ant-table {
  /* title + table */
  /* table */
  /* table + footer */ }

.ant-table-title {
  border-radius: 2px 2px 0 0; }

.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0; }

.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0; }

.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px; }

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px; }

.ant-table-footer {
  border-radius: 0 0 2px 2px; }

.ant-table-wrapper-rtl {
  direction: rtl; }

.ant-table-rtl {
  direction: rtl; }

.ant-table-wrapper-rtl .ant-table table {
  text-align: right; }

.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center; }

.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right; }

.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px; }

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination {
  float: left; }

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  float: left; }

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  float: right; }

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-center {
  float: none;
  float: initial;
  text-align: center; }

.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 8px;
  margin-left: 0; }

.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em; }

.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em; }

.ant-table-wrapper-rtl .ant-table-filter-trigger-container {
  right: auto;
  left: 0; }

.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0; }

.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center; }

.ant-table-wrapper-rtl .ant-table-selection-extra {
  right: auto;
  left: 0; }

.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right; }

.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right; }

.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px; }

.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg); }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-timeline {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none; }

.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none; }

.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0; }

.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent; }

.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none; }

.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px; }

.ant-timeline-item-head-blue {
  color: #1890ff;
  border-color: #1890ff; }

.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f; }

.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a; }

.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25); }

.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word; }

.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none; }

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px; }

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%; }

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px; }

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px; }

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left; }

.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right; }

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px); }

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px); }

.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0; }

.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none; }

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0; }

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px; }

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right; }

.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left; }

.ant-timeline-rtl {
  direction: rtl; }

.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none; }

.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%); }

.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0; }

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto; }

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0; }

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0; }

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right; }

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left; }

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto; }

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right; }

.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none; }

.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none; }

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left; }

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

.ant-transfer-customize-list .ant-transfer-list {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px; }

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0; }

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa; }

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0; }

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0; }

.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px; }

.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent; }

.ant-transfer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch; }

.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5; }

.ant-transfer-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px; }

.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto; }

.ant-transfer-list-search {
  padding-right: 24px;
  padding-left: 8px; }

.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align: center; }

.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45); }

span.ant-transfer-list-search-action {
  pointer-events: none; }

.ant-transfer-list-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
  flex: none;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0; }

.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px; }

.ant-transfer-list-header > * {
  -ms-flex: none;
  flex: none; }

.ant-transfer-list-header-title {
  -ms-flex: auto;
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis; }

.ant-transfer-list-header-dropdown {
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  cursor: pointer;
  display: inline-block;
  font-size: 10px; }

.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed; }

.ant-transfer-list-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px; }

.ant-transfer-list-body-search-wrapper {
  position: relative;
  -ms-flex: none;
  flex: none;
  padding: 12px; }

.ant-transfer-list-content {
  -ms-flex: auto;
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none; }

.ant-transfer-list-content-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  line-height: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px; }

.ant-transfer-list-content-item > * {
  -ms-flex: none;
  flex: none; }

.ant-transfer-list-content-item-text {
  -ms-flex: auto;
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-transfer-list-content-item-remove {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  position: relative;
  color: #d9d9d9; }

.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: #40a9ff; }

.ant-transfer-list-content-item-remove:active {
  color: #096dd9; }

.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: ''; }

.ant-transfer-list-content-item-remove:hover {
  color: #40a9ff; }

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer; }

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #dcf4ff; }

.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default; }

.ant-transfer-list-content-item-checked {
  background-color: #e6f7ff; }

.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0; }

.ant-transfer-list-body-not-found {
  -ms-flex: none;
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center; }

.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0; }

.ant-transfer-operation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
  flex: none;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: center;
  align-self: center;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle; }

.ant-transfer-operation .ant-btn {
  display: block; }

.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px; }

.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px; }

.ant-transfer .ant-empty-image {
  max-height: -2px; }

.ant-transfer-rtl {
  direction: rtl; }

.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px; }

.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px; }

.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px; }

.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto; }

.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left; }

.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px; }

.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  height: 14px !important; }

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

.ant-select-tree-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer; }

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #1890ff; }

.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: ''; }

.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible; }

.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' '; }

.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0; }

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' '; }

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff; }

.ant-select-tree-checkbox-disabled {
  cursor: not-allowed; }

.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none; }

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed; }

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important; }

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none; }

.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden; }

.ant-select-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer; }

.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed; }

.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px; }

.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px; }

.ant-select-tree-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block; }

.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px; }

.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0; }

.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0; }

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9; }

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' '; }

.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25); }

.ant-tree-select-dropdown {
  padding: 8px 4px 0; }

.ant-tree-select-dropdown-rtl {
  direction: rtl; }

.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0; }

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  -ms-flex-align: stretch;
  align-items: stretch; }

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  padding-bottom: 8px; }

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  -ms-flex: auto;
  flex: auto; }

.ant-select-tree {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #e6f7ff; }

.ant-select-tree-list-holder-inner {
  -ms-flex-align: start;
  align-items: flex-start; }

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  -ms-flex-align: stretch;
  align-items: stretch; }

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  -ms-flex: auto;
  flex: auto; }

.ant-select-tree .ant-select-tree-treenode {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none; }

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent; }

.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5; }

.ant-select-tree-indent {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px; }

.ant-select-tree .ant-select-tree-switcher {
  -ms-flex: none;
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }

.ant-select-tree .ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 10px;
  display: inline-block;
  vertical-align: baseline; }

.ant-select-tree .ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.ant-select-tree .ant-select-tree-switcher-noop {
  cursor: default; }

.ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.ant-select-tree .ant-select-tree-switcher-loading-icon {
  color: #1890ff; }

.ant-select-tree .ant-select-tree-switcher-leaf-line {
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%; }

.ant-select-tree .ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  height: 24px;
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
  content: ' '; }

.ant-select-tree .ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #d9d9d9;
  content: ' '; }

.ant-select-tree .ant-select-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0; }

.ant-select-tree .ant-select-tree-node-content-wrapper {
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s, border 0s, line-height 0s;
  transition: all 0.3s, border 0s, line-height 0s; }

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5; }

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #bae7ff; }

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top; }

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none; }

.ant-select-tree-node-content-wrapper[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-select-tree .ant-select-tree-treenode.drag-over > [draggable] {
  color: white;
  background-color: #1890ff;
  opacity: 0.8; }

.ant-select-tree .ant-select-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: #1890ff; }

.ant-select-tree .ant-select-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: #1890ff; }

.ant-select-tree-show-line {
  /* Motion should hide line of measure */ }

.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%; }

.ant-select-tree-show-line .ant-select-tree-indent-unit:first-child::after {
  position: absolute;
  top: calc(100% - 24px - 4px);
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: ''; }

.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: calc(100% - 4px);
  right: -12px;
  bottom: -28px;
  border-right: 1px solid #d9d9d9;
  content: ''; }

.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before,
.ant-select-tree-show-line .ant-select-tree-indent-unit-end-first-level::after {
  display: none; }

.ant-select-tree-show-line .ant-select-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active) .ant-select-tree-indent-unit::after,
.ant-select-tree-show-line .ant-select-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active) .ant-select-tree-indent-unit::before {
  display: none; }

.ant-select-tree-show-line .ant-select-tree-switcher {
  z-index: 1;
  background: #fff; }

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  height: 14px !important; }

.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative; }

.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none; }

.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5; }

.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1; }

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent; }

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent; }

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #1890ff; }

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff; }

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent; }

.ant-tree-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer; }

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1890ff; }

.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: ''; }

.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible; }

.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' '; }

.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0; }

.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' '; }

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff; }

.ant-tree-checkbox-disabled {
  cursor: not-allowed; }

.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none; }

.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed; }

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important; }

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none; }

.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden; }

.ant-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer; }

.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed; }

.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px; }

.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px; }

.ant-tree-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  display: inline-block; }

.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px; }

.ant-tree-checkbox-group-item:last-child {
  margin-right: 0; }

.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0; }

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9; }

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' '; }

.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25); }

.ant-tree {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #e6f7ff; }

.ant-tree-list-holder-inner {
  -ms-flex-align: start;
  align-items: flex-start; }

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  -ms-flex-align: stretch;
  align-items: stretch; }

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  -ms-flex: auto;
  flex: auto; }

.ant-tree .ant-tree-treenode {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none; }

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent; }

.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5; }

.ant-tree-indent {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-tree-indent-unit {
  display: inline-block;
  width: 24px; }

.ant-tree .ant-tree-switcher {
  -ms-flex: none;
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }

.ant-tree .ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 10px;
  display: inline-block;
  vertical-align: baseline; }

.ant-tree .ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.ant-tree .ant-tree-switcher-noop {
  cursor: default; }

.ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.ant-tree .ant-tree-switcher-loading-icon {
  color: #1890ff; }

.ant-tree .ant-tree-switcher-leaf-line {
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%; }

.ant-tree .ant-tree-switcher-leaf-line::before {
  position: absolute;
  height: 24px;
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
  content: ' '; }

.ant-tree .ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #d9d9d9;
  content: ' '; }

.ant-tree .ant-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0; }

.ant-tree .ant-tree-node-content-wrapper {
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s, border 0s, line-height 0s;
  transition: all 0.3s, border 0s, line-height 0s; }

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5; }

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff; }

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top; }

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none; }

.ant-tree-node-content-wrapper[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-tree .ant-tree-treenode.drag-over > [draggable] {
  color: white;
  background-color: #1890ff;
  opacity: 0.8; }

.ant-tree .ant-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: #1890ff; }

.ant-tree .ant-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: #1890ff; }

.ant-tree-show-line {
  /* Motion should hide line of measure */ }

.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%; }

.ant-tree-show-line .ant-tree-indent-unit:first-child::after {
  position: absolute;
  top: calc(100% - 24px - 4px);
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: ''; }

.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: calc(100% - 4px);
  right: -12px;
  bottom: -28px;
  border-right: 1px solid #d9d9d9;
  content: ''; }

.ant-tree-show-line .ant-tree-indent-unit-end::before,
.ant-tree-show-line .ant-tree-indent-unit-end-first-level::after {
  display: none; }

.ant-tree-show-line .ant-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active) .ant-tree-indent-unit::after,
.ant-tree-show-line .ant-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active) .ant-tree-indent-unit::before {
  display: none; }

.ant-tree-show-line .ant-tree-switcher {
  z-index: 1;
  background: #fff; }

.ant-tree-rtl {
  direction: rtl; }

.ant-tree .ant-tree-treenode-rtl {
  direction: rtl; }

.ant-tree-rtl.ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9; }

.ant-tree-rtl.ant-tree .ant-tree-checkbox {
  margin: 4px 0 0 8px; }

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-checkbox {
  margin: 4px 0 0 8px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-typography {
  color: rgba(0, 0, 0, 0.85);
  overflow-wrap: break-word; }

.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45); }

.ant-typography.ant-typography-success {
  color: #52c41a; }

.ant-typography.ant-typography-warning {
  color: #faad14; }

.ant-typography.ant-typography-danger {
  color: #ff4d4f; }

.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

div.ant-typography,
.ant-typography p {
  margin-bottom: 1em; }

h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23; }

h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35; }

h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35; }

h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4; }

h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5; }

.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em; }

.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em; }

a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block; }

a.ant-typography,
.ant-typography a {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #40a9ff; }

a.ant-typography:active,
.ant-typography a:active {
  color: #096dd9; }

a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none; }

a.ant-typography[disabled],
.ant-typography a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none; }

.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px; }

.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px; }

.ant-typography mark {
  padding: 0;
  background-color: #ffe58f; }

.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto; }

.ant-typography s,
.ant-typography del {
  text-decoration: line-through; }

.ant-typography strong {
  font-weight: 600; }

.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  margin-left: 4px; }

.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #40a9ff; }

.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #096dd9; }

.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a; }

.ant-typography-edit-content {
  position: relative; }

div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px); }

.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none; }

.ant-typography-edit-content textarea {
  -moz-transition: none; }

.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0; }

.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px; }

.ant-typography ul {
  list-style-type: circle; }

.ant-typography ul ul {
  list-style-type: disc; }

.ant-typography ol {
  list-style-type: decimal; }

.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom; }

.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden; }

.ant-typography-rtl {
  direction: rtl; }

.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0; }

.ant-typography-rtl .ant-typography-expand {
  float: left; }

div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto; }

.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px; }

.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal,
.ant-image-preview {
  pointer-events: none; }

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50); }

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none; }

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch; }

.ant-upload {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  outline: 0; }

.ant-upload p {
  margin: 0; }

.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none; }

.ant-upload input[type='file'] {
  cursor: pointer; }

.ant-upload.ant-upload-select {
  display: inline-block; }

.ant-upload.ant-upload-disabled {
  cursor: not-allowed; }

.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s; }

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  text-align: center; }

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff; }

.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9; }

.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s; }

.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0; }

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9; }

.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed; }

.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%; }

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle; }

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff; }

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px; }

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px; }

.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px; }

.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px; }

.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45); }

.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%; }

.ant-upload-picture-card-wrapper::before {
  display: table;
  content: ''; }

.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: ''; }

.ant-upload-list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715; }

.ant-upload-list::before {
  display: table;
  content: ''; }

.ant-upload-list::after {
  display: table;
  clear: both;
  content: ''; }

.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px; }

.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 28px; }

.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px; }

.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px; }

.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0; }

.ant-upload-list-item-card-actions-btn {
  opacity: 0; }

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1; }

.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0; }

.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1; }

.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45); }

.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%; }

.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px; }

.ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 10px;
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5; }

.ant-upload-list-item:hover .anticon-close {
  opacity: 1; }

.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1; }

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f; }

.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f; }

.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1; }

.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0; }

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px; }

.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent; }

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f; }

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0; }

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent; }

.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed; }

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8; }

.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px; }

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0; }

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #ff4d4f; }

.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px; }

.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%; }

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden; }

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 18px; }

.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 36px; }

.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px; }

.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px; }

.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1; }

.ant-upload-list-picture-card.ant-upload-list::after {
  display: none; }

.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top; }

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0; }

.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden; }

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: ' '; }

.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1; }

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff; }

.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1; }

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; }

.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center; }

.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block; }

.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa; }

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto; }

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none; }

.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0; }

.ant-upload-list .ant-upload-success-icon {
  color: #52c41a;
  font-weight: bold; }

.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ant-upload-list .ant-upload-animate-enter {
  -webkit-animation-name: uploadAnimateIn;
  animation-name: uploadAnimateIn; }

.ant-upload-list .ant-upload-animate-leave {
  -webkit-animation-name: uploadAnimateOut;
  animation-name: uploadAnimateOut; }

.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
  animation-name: uploadAnimateInlineIn; }

.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
  animation-name: uploadAnimateInlineOut; }

@-webkit-keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@-webkit-keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

.ant-upload-rtl {
  direction: rtl; }

.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  float: right;
  margin-right: 0;
  margin-left: 8px; }

.ant-upload-list-rtl {
  direction: rtl; }

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px; }

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px; }

.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0; }

.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px; }

.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0; }

.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px; }

.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px; }

.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
  right: auto;
  left: 4px; }

.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px; }

.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0; }

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto; }

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%); }

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px; }

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px; }

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px; }

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 56px;
  padding-left: 0; }

.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
  right: auto;
  left: 8px; }

.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  float: right;
  margin: 0 0 8px 8px; }

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item {
  float: right;
  margin: 0 0 8px 8px; }

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%); }

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0; }

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0; }

/*# sourceMappingURL=antd.css.map*/

.cc-window {
  opacity: 1;
  transition: opacity 1s ease; }

.cc-window.cc-invisible {
  opacity: 0; }

.cc-animate.cc-revoke {
  transition: transform 1s ease; }

.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em); }

.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em); }

.cc-animate.cc-revoke.cc-active.cc-top {
  transform: translateY(0); }

.cc-animate.cc-revoke.cc-active.cc-bottom {
  transform: translateY(0); }

.cc-revoke:hover {
  transform: translateY(0); }

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s; }

.cc-revoke, .cc-window {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-family: Helvetica,Calibri,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  display: flex;
  flex-wrap: nowrap;
  z-index: 9999; }

.cc-window.cc-static {
  position: static; }

.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em;
  flex-direction: column; }

.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  flex-direction: row; }

.cc-revoke {
  padding: .5em; }

.cc-revoke:hover {
  text-decoration: underline; }

.cc-header {
  font-size: 18px;
  font-weight: 700; }

.cc-btn, .cc-close, .cc-link, .cc-revoke {
  cursor: pointer; }

.cc-link {
  opacity: .8;
  display: inline-block;
  padding: .2em;
  text-decoration: underline; }

.cc-link:hover {
  opacity: 1; }

.cc-link:active, .cc-link:visited {
  color: #000;
  color: initial; }

.cc-btn {
  display: block;
  padding: .4em .8em;
  font-size: .9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap; }

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent; }

.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
  background-color: transparent;
  text-decoration: underline; }

.cc-close {
  display: block;
  position: absolute;
  top: .5em;
  right: .5em;
  font-size: 1.6em;
  opacity: .9;
  line-height: .75; }

.cc-close:focus, .cc-close:hover {
  opacity: 1; }

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em; }

.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em; }

.cc-revoke.cc-left {
  left: 3em;
  right: unset; }

.cc-revoke.cc-right {
  right: 3em;
  left: unset; }

.cc-top {
  top: 1em; }

.cc-left {
  left: 1em; }

.cc-right {
  right: 1em; }

.cc-bottom {
  bottom: 1em; }

.cc-floating > .cc-link {
  margin-bottom: 1em; }

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em; }

.cc-window.cc-floating .cc-compliance {
  flex: 1 0 auto; }

.cc-window.cc-banner {
  align-items: center; }

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0; }

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-banner .cc-message {
  display: block;
  flex: 1 1 auto;
  max-width: 100%;
  margin-right: 1em; }

.cc-compliance {
  display: flex;
  align-items: center;
  align-content: space-between; }

.cc-floating .cc-compliance > .cc-btn {
  flex: 1 1; }

.cc-btn + .cc-btn {
  margin-left: .5em; }

@media print {
  .cc-revoke, .cc-window {
    display: none; } }

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal; } }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0; }
  .cc-window.cc-bottom {
    bottom: 0; }
  .cc-window.cc-banner, .cc-window.cc-floating, .cc-window.cc-left, .cc-window.cc-right {
    left: 0;
    right: 0; }
  .cc-window.cc-banner {
    flex-direction: column; }
  .cc-window.cc-banner .cc-compliance {
    flex: 1 1 auto; }
  .cc-window.cc-floating {
    max-width: none; }
  .cc-window .cc-message {
    margin-bottom: 1em; }
  .cc-window.cc-banner {
    align-items: unset; }
  .cc-window.cc-banner .cc-message {
    margin-right: 0; } }

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  flex: none; }

.cc-theme-classic .cc-btn {
  border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block; }

.cc-theme-edgeless.cc-window {
  padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em;
  margin-bottom: 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: .8em 1.8em;
  height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0; }

.intl-tel-input {
  position: relative;
  display: inline-block; }

.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.intl-tel-input .hide {
  display: none; }

.intl-tel-input .v-hide {
  visibility: hidden; }

.intl-tel-input input, .intl-tel-input input[type=tel], .intl-tel-input input[type=text] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0; }

.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px; }

.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  width: 36px;
  height: 100%;
  padding: 0 0 0 8px; }

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto; }

.intl-tel-input .selected-flag .iti-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555; }

.intl-tel-input .selected-flag .iti-arrow.up {
  border-top: none;
  border-bottom: 4px solid #555; }

.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #ccc;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px; }

.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 20px; }

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal; } }

.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc; }

.intl-tel-input .country-list .country {
  padding: 5px 10px; }

.intl-tel-input .country-list .country .dial-code {
  color: #999; }

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(0, 0, 0, 0.05); }

.intl-tel-input .country-list .country-name, .intl-tel-input .country-list .dial-code, .intl-tel-input .country-list .flag-box {
  vertical-align: middle; }

.intl-tel-input .country-list .country-name, .intl-tel-input .country-list .flag-box {
  margin-right: 6px; }

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.allow-dropdown input[type=text] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0; }

.intl-tel-input.allow-dropdown .flag-container {
  right: auto;
  left: 0;
  width: 100%; }

.intl-tel-input.allow-dropdown .selected-flag {
  width: 46px; }

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer; }

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(0, 0, 0, 0.05); }

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
  cursor: default; }

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
  background-color: transparent; }

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
  display: table; }

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px; }

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=tel], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=text] {
  padding-left: 76px; }

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 70px; }

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text] {
  padding-left: 84px; }

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 78px; }

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text] {
  padding-left: 92px; }

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 86px; }

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=tel], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=text] {
  padding-left: 100px; }

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 94px; }

.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px; }

.intl-tel-input.iti-container:hover {
  cursor: pointer; }

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch; }

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px;
  line-height: 1.5em; }

.iti-flag.be {
  width: 18px; }

.iti-flag.ch {
  width: 15px; }

.iti-flag.mc {
  width: 19px; }

.iti-flag.ne {
  width: 18px; }

.iti-flag.np {
  width: 13px; }

.iti-flag.va {
  width: 15px; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 192dpi) {
  .iti-flag {
    background-size: 5630px 15px; } }

.iti-flag.ac {
  height: 10px;
  background-position: 0 0; }

.iti-flag.ad {
  height: 14px;
  background-position: -22px 0; }

.iti-flag.ae {
  height: 10px;
  background-position: -44px 0; }

.iti-flag.af {
  height: 14px;
  background-position: -66px 0; }

.iti-flag.ag {
  height: 14px;
  background-position: -88px 0; }

.iti-flag.ai {
  height: 10px;
  background-position: -110px 0; }

.iti-flag.al {
  height: 15px;
  background-position: -132px 0; }

.iti-flag.am {
  height: 10px;
  background-position: -154px 0; }

.iti-flag.ao {
  height: 14px;
  background-position: -176px 0; }

.iti-flag.aq {
  height: 14px;
  background-position: -198px 0; }

.iti-flag.ar {
  height: 13px;
  background-position: -220px 0; }

.iti-flag.as {
  height: 10px;
  background-position: -242px 0; }

.iti-flag.at {
  height: 14px;
  background-position: -264px 0; }

.iti-flag.au {
  height: 10px;
  background-position: -286px 0; }

.iti-flag.aw {
  height: 14px;
  background-position: -308px 0; }

.iti-flag.ax {
  height: 13px;
  background-position: -330px 0; }

.iti-flag.az {
  height: 10px;
  background-position: -352px 0; }

.iti-flag.ba {
  height: 10px;
  background-position: -374px 0; }

.iti-flag.bb {
  height: 14px;
  background-position: -396px 0; }

.iti-flag.bd {
  height: 12px;
  background-position: -418px 0; }

.iti-flag.be {
  height: 15px;
  background-position: -440px 0; }

.iti-flag.bf {
  height: 14px;
  background-position: -460px 0; }

.iti-flag.bg {
  height: 12px;
  background-position: -482px 0; }

.iti-flag.bh {
  height: 12px;
  background-position: -504px 0; }

.iti-flag.bi {
  height: 12px;
  background-position: -526px 0; }

.iti-flag.bj {
  height: 14px;
  background-position: -548px 0; }

.iti-flag.bl {
  height: 14px;
  background-position: -570px 0; }

.iti-flag.bm {
  height: 10px;
  background-position: -592px 0; }

.iti-flag.bn {
  height: 10px;
  background-position: -614px 0; }

.iti-flag.bo {
  height: 14px;
  background-position: -636px 0; }

.iti-flag.bq {
  height: 14px;
  background-position: -658px 0; }

.iti-flag.br {
  height: 14px;
  background-position: -680px 0; }

.iti-flag.bs {
  height: 10px;
  background-position: -702px 0; }

.iti-flag.bt {
  height: 14px;
  background-position: -724px 0; }

.iti-flag.bv {
  height: 15px;
  background-position: -746px 0; }

.iti-flag.bw {
  height: 14px;
  background-position: -768px 0; }

.iti-flag.by {
  height: 10px;
  background-position: -790px 0; }

.iti-flag.bz {
  height: 14px;
  background-position: -812px 0; }

.iti-flag.ca {
  height: 10px;
  background-position: -834px 0; }

.iti-flag.cc {
  height: 10px;
  background-position: -856px 0; }

.iti-flag.cd {
  height: 15px;
  background-position: -878px 0; }

.iti-flag.cf {
  height: 14px;
  background-position: -900px 0; }

.iti-flag.cg {
  height: 14px;
  background-position: -922px 0; }

.iti-flag.ch {
  height: 15px;
  background-position: -944px 0; }

.iti-flag.ci {
  height: 14px;
  background-position: -961px 0; }

.iti-flag.ck {
  height: 10px;
  background-position: -983px 0; }

.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0; }

.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0; }

.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0; }

.iti-flag.co {
  height: 14px;
  background-position: -1071px 0; }

.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0; }

.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0; }

.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0; }

.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0; }

.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0; }

.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0; }

.iti-flag.cy {
  height: 14px;
  background-position: -1225px 0; }

.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0; }

.iti-flag.de {
  height: 12px;
  background-position: -1269px 0; }

.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0; }

.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0; }

.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0; }

.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0; }

.iti-flag.do {
  height: 13px;
  background-position: -1379px 0; }

.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0; }

.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0; }

.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0; }

.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0; }

.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0; }

.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0; }

.iti-flag.er {
  height: 10px;
  background-position: -1533px 0; }

.iti-flag.es {
  height: 14px;
  background-position: -1555px 0; }

.iti-flag.et {
  height: 10px;
  background-position: -1577px 0; }

.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0; }

.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0; }

.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0; }

.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0; }

.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0; }

.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0; }

.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0; }

.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0; }

.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0; }

.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0; }

.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0; }

.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0; }

.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0; }

.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0; }

.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0; }

.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0; }

.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0; }

.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0; }

.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0; }

.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0; }

.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0; }

.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0; }

.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0; }

.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0; }

.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0; }

.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0; }

.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0; }

.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0; }

.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0; }

.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0; }

.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0; }

.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0; }

.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0; }

.iti-flag.id {
  height: 14px;
  background-position: -2325px 0; }

.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0; }

.iti-flag.il {
  height: 15px;
  background-position: -2369px 0; }

.iti-flag.im {
  height: 10px;
  background-position: -2391px 0; }

.iti-flag.in {
  height: 14px;
  background-position: -2413px 0; }

.iti-flag.io {
  height: 10px;
  background-position: -2435px 0; }

.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0; }

.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0; }

.iti-flag.is {
  height: 15px;
  background-position: -2501px 0; }

.iti-flag.it {
  height: 14px;
  background-position: -2523px 0; }

.iti-flag.je {
  height: 12px;
  background-position: -2545px 0; }

.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0; }

.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0; }

.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0; }

.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0; }

.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0; }

.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0; }

.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0; }

.iti-flag.km {
  height: 12px;
  background-position: -2721px 0; }

.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0; }

.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0; }

.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0; }

.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0; }

.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0; }

.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0; }

.iti-flag.la {
  height: 14px;
  background-position: -2875px 0; }

.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0; }

.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0; }

.iti-flag.li {
  height: 12px;
  background-position: -2941px 0; }

.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0; }

.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0; }

.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0; }

.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0; }

.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0; }

.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0; }

.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0; }

.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0; }

.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0; }

.iti-flag.md {
  height: 10px;
  background-position: -3160px 0; }

.iti-flag.me {
  height: 10px;
  background-position: -3182px 0; }

.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0; }

.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0; }

.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0; }

.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0; }

.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0; }

.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0; }

.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0; }

.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0; }

.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0; }

.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0; }

.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0; }

.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0; }

.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0; }

.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0; }

.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0; }

.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0; }

.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0; }

.iti-flag.my {
  height: 10px;
  background-position: -3578px 0; }

.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0; }

.iti-flag.na {
  height: 14px;
  background-position: -3622px 0; }

.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0; }

.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0; }

.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0; }

.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0; }

.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0; }

.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0; }

.iti-flag.no {
  height: 15px;
  background-position: -3774px 0; }

.iti-flag.np {
  height: 15px;
  background-position: -3796px 0; }

.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0; }

.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0; }

.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0; }

.iti-flag.om {
  height: 10px;
  background-position: -3877px 0; }

.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0; }

.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0; }

.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0; }

.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0; }

.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0; }

.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0; }

.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0; }

.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0; }

.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0; }

.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0; }

.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0; }

.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0; }

.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0; }

.iti-flag.py {
  height: 11px;
  background-position: -4185px 0; }

.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0; }

.iti-flag.re {
  height: 14px;
  background-position: -4229px 0; }

.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0; }

.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0; }

.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0; }

.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0; }

.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0; }

.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0; }

.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0; }

.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0; }

.iti-flag.se {
  height: 13px;
  background-position: -4427px 0; }

.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0; }

.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0; }

.iti-flag.si {
  height: 10px;
  background-position: -4493px 0; }

.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0; }

.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0; }

.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0; }

.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0; }

.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0; }

.iti-flag.so {
  height: 14px;
  background-position: -4625px 0; }

.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0; }

.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0; }

.iti-flag.st {
  height: 10px;
  background-position: -4691px 0; }

.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0; }

.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0; }

.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0; }

.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0; }

.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0; }

.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0; }

.iti-flag.td {
  height: 14px;
  background-position: -4845px 0; }

.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0; }

.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0; }

.iti-flag.th {
  height: 14px;
  background-position: -4911px 0; }

.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0; }

.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0; }

.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0; }

.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0; }

.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0; }

.iti-flag.to {
  height: 10px;
  background-position: -5043px 0; }

.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0; }

.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0; }

.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0; }

.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0; }

.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0; }

.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0; }

.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0; }

.iti-flag.um {
  height: 11px;
  background-position: -5219px 0; }

.iti-flag.us {
  height: 11px;
  background-position: -5241px 0; }

.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0; }

.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0; }

.iti-flag.va {
  height: 15px;
  background-position: -5307px 0; }

.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0; }

.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0; }

.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0; }

.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0; }

.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0; }

.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0; }

.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0; }

.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0; }

.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0; }

.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0; }

.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0; }

.iti-flag.za {
  height: 14px;
  background-position: -5566px 0; }

.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0; }

.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0; }

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0 0 1px 0 #888;
  background-image: url(/static/media/flags.80a4ed47.png);
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 192dpi) {
  .iti-flag {
    background-image: url(/static/media/flags@2x.7de34bf2.png); } }

.iti-flag.np {
  background-color: transparent; }

@font-face {
  font-family: "Material Design Icons";
  src: url(/static/media/materialdesignicons-webfont.2d0a0d8f.eot);
  src: url(/static/media/materialdesignicons-webfont.2d0a0d8f.eot?#iefix&v=4.9.95) format("embedded-opentype"), url(/static/media/materialdesignicons-webfont.d0066537.woff2) format("woff2"), url(/static/media/materialdesignicons-webfont.b4917be2.woff) format("woff"), url(/static/media/materialdesignicons-webfont.f5111234.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

.mdi:before, .mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mdi-ab-testing::before {
  content: "\F001C"; }

.mdi-abjad-arabic::before {
  content: "\F0353"; }

.mdi-abjad-hebrew::before {
  content: "\F0354"; }

.mdi-abugida-devanagari::before {
  content: "\F0355"; }

.mdi-abugida-thai::before {
  content: "\F0356"; }

.mdi-access-point::before {
  content: "\F002"; }

.mdi-access-point-network::before {
  content: "\F003"; }

.mdi-access-point-network-off::before {
  content: "\FBBD"; }

.mdi-account::before {
  content: "\F004"; }

.mdi-account-alert::before {
  content: "\F005"; }

.mdi-account-alert-outline::before {
  content: "\FB2C"; }

.mdi-account-arrow-left::before {
  content: "\FB2D"; }

.mdi-account-arrow-left-outline::before {
  content: "\FB2E"; }

.mdi-account-arrow-right::before {
  content: "\FB2F"; }

.mdi-account-arrow-right-outline::before {
  content: "\FB30"; }

.mdi-account-badge::before {
  content: "\FD83"; }

.mdi-account-badge-alert::before {
  content: "\FD84"; }

.mdi-account-badge-alert-outline::before {
  content: "\FD85"; }

.mdi-account-badge-horizontal::before {
  content: "\FDF0"; }

.mdi-account-badge-horizontal-outline::before {
  content: "\FDF1"; }

.mdi-account-badge-outline::before {
  content: "\FD86"; }

.mdi-account-box::before {
  content: "\F006"; }

.mdi-account-box-multiple::before {
  content: "\F933"; }

.mdi-account-box-multiple-outline::before {
  content: "\F002C"; }

.mdi-account-box-outline::before {
  content: "\F007"; }

.mdi-account-cancel::before {
  content: "\F030A"; }

.mdi-account-cancel-outline::before {
  content: "\F030B"; }

.mdi-account-card-details::before {
  content: "\F5D2"; }

.mdi-account-card-details-outline::before {
  content: "\FD87"; }

.mdi-account-cash::before {
  content: "\F00C2"; }

.mdi-account-cash-outline::before {
  content: "\F00C3"; }

.mdi-account-check::before {
  content: "\F008"; }

.mdi-account-check-outline::before {
  content: "\FBBE"; }

.mdi-account-child::before {
  content: "\FA88"; }

.mdi-account-child-circle::before {
  content: "\FA89"; }

.mdi-account-child-outline::before {
  content: "\F00F3"; }

.mdi-account-circle::before {
  content: "\F009"; }

.mdi-account-circle-outline::before {
  content: "\FB31"; }

.mdi-account-clock::before {
  content: "\FB32"; }

.mdi-account-clock-outline::before {
  content: "\FB33"; }

.mdi-account-cog::before {
  content: "\F039B"; }

.mdi-account-cog-outline::before {
  content: "\F039C"; }

.mdi-account-convert::before {
  content: "\F00A"; }

.mdi-account-convert-outline::before {
  content: "\F032C"; }

.mdi-account-details::before {
  content: "\F631"; }

.mdi-account-details-outline::before {
  content: "\F039D"; }

.mdi-account-edit::before {
  content: "\F6BB"; }

.mdi-account-edit-outline::before {
  content: "\F001D"; }

.mdi-account-group::before {
  content: "\F848"; }

.mdi-account-group-outline::before {
  content: "\FB34"; }

.mdi-account-heart::before {
  content: "\F898"; }

.mdi-account-heart-outline::before {
  content: "\FBBF"; }

.mdi-account-key::before {
  content: "\F00B"; }

.mdi-account-key-outline::before {
  content: "\FBC0"; }

.mdi-account-lock::before {
  content: "\F0189"; }

.mdi-account-lock-outline::before {
  content: "\F018A"; }

.mdi-account-minus::before {
  content: "\F00D"; }

.mdi-account-minus-outline::before {
  content: "\FAEB"; }

.mdi-account-multiple::before {
  content: "\F00E"; }

.mdi-account-multiple-check::before {
  content: "\F8C4"; }

.mdi-account-multiple-check-outline::before {
  content: "\F0229"; }

.mdi-account-multiple-minus::before {
  content: "\F5D3"; }

.mdi-account-multiple-minus-outline::before {
  content: "\FBC1"; }

.mdi-account-multiple-outline::before {
  content: "\F00F"; }

.mdi-account-multiple-plus::before {
  content: "\F010"; }

.mdi-account-multiple-plus-outline::before {
  content: "\F7FF"; }

.mdi-account-multiple-remove::before {
  content: "\F0235"; }

.mdi-account-multiple-remove-outline::before {
  content: "\F0236"; }

.mdi-account-network::before {
  content: "\F011"; }

.mdi-account-network-outline::before {
  content: "\FBC2"; }

.mdi-account-off::before {
  content: "\F012"; }

.mdi-account-off-outline::before {
  content: "\FBC3"; }

.mdi-account-outline::before {
  content: "\F013"; }

.mdi-account-plus::before {
  content: "\F014"; }

.mdi-account-plus-outline::before {
  content: "\F800"; }

.mdi-account-question::before {
  content: "\FB35"; }

.mdi-account-question-outline::before {
  content: "\FB36"; }

.mdi-account-remove::before {
  content: "\F015"; }

.mdi-account-remove-outline::before {
  content: "\FAEC"; }

.mdi-account-search::before {
  content: "\F016"; }

.mdi-account-search-outline::before {
  content: "\F934"; }

.mdi-account-settings::before {
  content: "\F630"; }

.mdi-account-settings-outline::before {
  content: "\F00F4"; }

.mdi-account-star::before {
  content: "\F017"; }

.mdi-account-star-outline::before {
  content: "\FBC4"; }

.mdi-account-supervisor::before {
  content: "\FA8A"; }

.mdi-account-supervisor-circle::before {
  content: "\FA8B"; }

.mdi-account-supervisor-outline::before {
  content: "\F0158"; }

.mdi-account-switch::before {
  content: "\F019"; }

.mdi-account-tie::before {
  content: "\FCBF"; }

.mdi-account-tie-outline::before {
  content: "\F00F5"; }

.mdi-account-tie-voice::before {
  content: "\F0333"; }

.mdi-account-tie-voice-off::before {
  content: "\F0335"; }

.mdi-account-tie-voice-off-outline::before {
  content: "\F0336"; }

.mdi-account-tie-voice-outline::before {
  content: "\F0334"; }

.mdi-accusoft::before {
  content: "\F849"; }

.mdi-adjust::before {
  content: "\F01A"; }

.mdi-adobe::before {
  content: "\F935"; }

.mdi-adobe-acrobat::before {
  content: "\FFBD"; }

.mdi-air-conditioner::before {
  content: "\F01B"; }

.mdi-air-filter::before {
  content: "\FD1F"; }

.mdi-air-horn::before {
  content: "\FD88"; }

.mdi-air-humidifier::before {
  content: "\F00C4"; }

.mdi-air-purifier::before {
  content: "\FD20"; }

.mdi-airbag::before {
  content: "\FBC5"; }

.mdi-airballoon::before {
  content: "\F01C"; }

.mdi-airballoon-outline::before {
  content: "\F002D"; }

.mdi-airplane::before {
  content: "\F01D"; }

.mdi-airplane-landing::before {
  content: "\F5D4"; }

.mdi-airplane-off::before {
  content: "\F01E"; }

.mdi-airplane-takeoff::before {
  content: "\F5D5"; }

.mdi-airplay::before {
  content: "\F01F"; }

.mdi-airport::before {
  content: "\F84A"; }

.mdi-alarm::before {
  content: "\F020"; }

.mdi-alarm-bell::before {
  content: "\F78D"; }

.mdi-alarm-check::before {
  content: "\F021"; }

.mdi-alarm-light::before {
  content: "\F78E"; }

.mdi-alarm-light-outline::before {
  content: "\FBC6"; }

.mdi-alarm-multiple::before {
  content: "\F022"; }

.mdi-alarm-note::before {
  content: "\FE8E"; }

.mdi-alarm-note-off::before {
  content: "\FE8F"; }

.mdi-alarm-off::before {
  content: "\F023"; }

.mdi-alarm-plus::before {
  content: "\F024"; }

.mdi-alarm-snooze::before {
  content: "\F68D"; }

.mdi-album::before {
  content: "\F025"; }

.mdi-alert::before {
  content: "\F026"; }

.mdi-alert-box::before {
  content: "\F027"; }

.mdi-alert-box-outline::before {
  content: "\FCC0"; }

.mdi-alert-circle::before {
  content: "\F028"; }

.mdi-alert-circle-check::before {
  content: "\F0218"; }

.mdi-alert-circle-check-outline::before {
  content: "\F0219"; }

.mdi-alert-circle-outline::before {
  content: "\F5D6"; }

.mdi-alert-decagram::before {
  content: "\F6BC"; }

.mdi-alert-decagram-outline::before {
  content: "\FCC1"; }

.mdi-alert-octagon::before {
  content: "\F029"; }

.mdi-alert-octagon-outline::before {
  content: "\FCC2"; }

.mdi-alert-octagram::before {
  content: "\F766"; }

.mdi-alert-octagram-outline::before {
  content: "\FCC3"; }

.mdi-alert-outline::before {
  content: "\F02A"; }

.mdi-alert-rhombus::before {
  content: "\F01F9"; }

.mdi-alert-rhombus-outline::before {
  content: "\F01FA"; }

.mdi-alien::before {
  content: "\F899"; }

.mdi-alien-outline::before {
  content: "\F00F6"; }

.mdi-align-horizontal-center::before {
  content: "\F01EE"; }

.mdi-align-horizontal-left::before {
  content: "\F01ED"; }

.mdi-align-horizontal-right::before {
  content: "\F01EF"; }

.mdi-align-vertical-bottom::before {
  content: "\F01F0"; }

.mdi-align-vertical-center::before {
  content: "\F01F1"; }

.mdi-align-vertical-top::before {
  content: "\F01F2"; }

.mdi-all-inclusive::before {
  content: "\F6BD"; }

.mdi-allergy::before {
  content: "\F0283"; }

.mdi-alpha::before {
  content: "\F02B"; }

.mdi-alpha-a::before {
  content: "A"; }

.mdi-alpha-a-box::before {
  content: "\FAED"; }

.mdi-alpha-a-box-outline::before {
  content: "\FBC7"; }

.mdi-alpha-a-circle::before {
  content: "\FBC8"; }

.mdi-alpha-a-circle-outline::before {
  content: "\FBC9"; }

.mdi-alpha-b::before {
  content: "B"; }

.mdi-alpha-b-box::before {
  content: "\FAEE"; }

.mdi-alpha-b-box-outline::before {
  content: "\FBCA"; }

.mdi-alpha-b-circle::before {
  content: "\FBCB"; }

.mdi-alpha-b-circle-outline::before {
  content: "\FBCC"; }

.mdi-alpha-c::before {
  content: "C"; }

.mdi-alpha-c-box::before {
  content: "\FAEF"; }

.mdi-alpha-c-box-outline::before {
  content: "\FBCD"; }

.mdi-alpha-c-circle::before {
  content: "\FBCE"; }

.mdi-alpha-c-circle-outline::before {
  content: "\FBCF"; }

.mdi-alpha-d::before {
  content: "D"; }

.mdi-alpha-d-box::before {
  content: "\FAF0"; }

.mdi-alpha-d-box-outline::before {
  content: "\FBD0"; }

.mdi-alpha-d-circle::before {
  content: "\FBD1"; }

.mdi-alpha-d-circle-outline::before {
  content: "\FBD2"; }

.mdi-alpha-e::before {
  content: "E"; }

.mdi-alpha-e-box::before {
  content: "\FAF1"; }

.mdi-alpha-e-box-outline::before {
  content: "\FBD3"; }

.mdi-alpha-e-circle::before {
  content: "\FBD4"; }

.mdi-alpha-e-circle-outline::before {
  content: "\FBD5"; }

.mdi-alpha-f::before {
  content: "F"; }

.mdi-alpha-f-box::before {
  content: "\FAF2"; }

.mdi-alpha-f-box-outline::before {
  content: "\FBD6"; }

.mdi-alpha-f-circle::before {
  content: "\FBD7"; }

.mdi-alpha-f-circle-outline::before {
  content: "\FBD8"; }

.mdi-alpha-g::before {
  content: "G"; }

.mdi-alpha-g-box::before {
  content: "\FAF3"; }

.mdi-alpha-g-box-outline::before {
  content: "\FBD9"; }

.mdi-alpha-g-circle::before {
  content: "\FBDA"; }

.mdi-alpha-g-circle-outline::before {
  content: "\FBDB"; }

.mdi-alpha-h::before {
  content: "H"; }

.mdi-alpha-h-box::before {
  content: "\FAF4"; }

.mdi-alpha-h-box-outline::before {
  content: "\FBDC"; }

.mdi-alpha-h-circle::before {
  content: "\FBDD"; }

.mdi-alpha-h-circle-outline::before {
  content: "\FBDE"; }

.mdi-alpha-i::before {
  content: "I"; }

.mdi-alpha-i-box::before {
  content: "\FAF5"; }

.mdi-alpha-i-box-outline::before {
  content: "\FBDF"; }

.mdi-alpha-i-circle::before {
  content: "\FBE0"; }

.mdi-alpha-i-circle-outline::before {
  content: "\FBE1"; }

.mdi-alpha-j::before {
  content: "J"; }

.mdi-alpha-j-box::before {
  content: "\FAF6"; }

.mdi-alpha-j-box-outline::before {
  content: "\FBE2"; }

.mdi-alpha-j-circle::before {
  content: "\FBE3"; }

.mdi-alpha-j-circle-outline::before {
  content: "\FBE4"; }

.mdi-alpha-k::before {
  content: "K"; }

.mdi-alpha-k-box::before {
  content: "\FAF7"; }

.mdi-alpha-k-box-outline::before {
  content: "\FBE5"; }

.mdi-alpha-k-circle::before {
  content: "\FBE6"; }

.mdi-alpha-k-circle-outline::before {
  content: "\FBE7"; }

.mdi-alpha-l::before {
  content: "L"; }

.mdi-alpha-l-box::before {
  content: "\FAF8"; }

.mdi-alpha-l-box-outline::before {
  content: "\FBE8"; }

.mdi-alpha-l-circle::before {
  content: "\FBE9"; }

.mdi-alpha-l-circle-outline::before {
  content: "\FBEA"; }

.mdi-alpha-m::before {
  content: "M"; }

.mdi-alpha-m-box::before {
  content: "\FAF9"; }

.mdi-alpha-m-box-outline::before {
  content: "\FBEB"; }

.mdi-alpha-m-circle::before {
  content: "\FBEC"; }

.mdi-alpha-m-circle-outline::before {
  content: "\FBED"; }

.mdi-alpha-n::before {
  content: "N"; }

.mdi-alpha-n-box::before {
  content: "\FAFA"; }

.mdi-alpha-n-box-outline::before {
  content: "\FBEE"; }

.mdi-alpha-n-circle::before {
  content: "\FBEF"; }

.mdi-alpha-n-circle-outline::before {
  content: "\FBF0"; }

.mdi-alpha-o::before {
  content: "O"; }

.mdi-alpha-o-box::before {
  content: "\FAFB"; }

.mdi-alpha-o-box-outline::before {
  content: "\FBF1"; }

.mdi-alpha-o-circle::before {
  content: "\FBF2"; }

.mdi-alpha-o-circle-outline::before {
  content: "\FBF3"; }

.mdi-alpha-p::before {
  content: "P"; }

.mdi-alpha-p-box::before {
  content: "\FAFC"; }

.mdi-alpha-p-box-outline::before {
  content: "\FBF4"; }

.mdi-alpha-p-circle::before {
  content: "\FBF5"; }

.mdi-alpha-p-circle-outline::before {
  content: "\FBF6"; }

.mdi-alpha-q::before {
  content: "Q"; }

.mdi-alpha-q-box::before {
  content: "\FAFD"; }

.mdi-alpha-q-box-outline::before {
  content: "\FBF7"; }

.mdi-alpha-q-circle::before {
  content: "\FBF8"; }

.mdi-alpha-q-circle-outline::before {
  content: "\FBF9"; }

.mdi-alpha-r::before {
  content: "R"; }

.mdi-alpha-r-box::before {
  content: "\FAFE"; }

.mdi-alpha-r-box-outline::before {
  content: "\FBFA"; }

.mdi-alpha-r-circle::before {
  content: "\FBFB"; }

.mdi-alpha-r-circle-outline::before {
  content: "\FBFC"; }

.mdi-alpha-s::before {
  content: "S"; }

.mdi-alpha-s-box::before {
  content: "\FAFF"; }

.mdi-alpha-s-box-outline::before {
  content: "\FBFD"; }

.mdi-alpha-s-circle::before {
  content: "\FBFE"; }

.mdi-alpha-s-circle-outline::before {
  content: "\FBFF"; }

.mdi-alpha-t::before {
  content: "T"; }

.mdi-alpha-t-box::before {
  content: "\FB00"; }

.mdi-alpha-t-box-outline::before {
  content: "\FC00"; }

.mdi-alpha-t-circle::before {
  content: "\FC01"; }

.mdi-alpha-t-circle-outline::before {
  content: "\FC02"; }

.mdi-alpha-u::before {
  content: "U"; }

.mdi-alpha-u-box::before {
  content: "\FB01"; }

.mdi-alpha-u-box-outline::before {
  content: "\FC03"; }

.mdi-alpha-u-circle::before {
  content: "\FC04"; }

.mdi-alpha-u-circle-outline::before {
  content: "\FC05"; }

.mdi-alpha-v::before {
  content: "V"; }

.mdi-alpha-v-box::before {
  content: "\FB02"; }

.mdi-alpha-v-box-outline::before {
  content: "\FC06"; }

.mdi-alpha-v-circle::before {
  content: "\FC07"; }

.mdi-alpha-v-circle-outline::before {
  content: "\FC08"; }

.mdi-alpha-w::before {
  content: "W"; }

.mdi-alpha-w-box::before {
  content: "\FB03"; }

.mdi-alpha-w-box-outline::before {
  content: "\FC09"; }

.mdi-alpha-w-circle::before {
  content: "\FC0A"; }

.mdi-alpha-w-circle-outline::before {
  content: "\FC0B"; }

.mdi-alpha-x::before {
  content: "X"; }

.mdi-alpha-x-box::before {
  content: "\FB04"; }

.mdi-alpha-x-box-outline::before {
  content: "\FC0C"; }

.mdi-alpha-x-circle::before {
  content: "\FC0D"; }

.mdi-alpha-x-circle-outline::before {
  content: "\FC0E"; }

.mdi-alpha-y::before {
  content: "Y"; }

.mdi-alpha-y-box::before {
  content: "\FB05"; }

.mdi-alpha-y-box-outline::before {
  content: "\FC0F"; }

.mdi-alpha-y-circle::before {
  content: "\FC10"; }

.mdi-alpha-y-circle-outline::before {
  content: "\FC11"; }

.mdi-alpha-z::before {
  content: "Z"; }

.mdi-alpha-z-box::before {
  content: "\FB06"; }

.mdi-alpha-z-box-outline::before {
  content: "\FC12"; }

.mdi-alpha-z-circle::before {
  content: "\FC13"; }

.mdi-alpha-z-circle-outline::before {
  content: "\FC14"; }

.mdi-alphabet-aurebesh::before {
  content: "\F0357"; }

.mdi-alphabet-cyrillic::before {
  content: "\F0358"; }

.mdi-alphabet-greek::before {
  content: "\F0359"; }

.mdi-alphabet-latin::before {
  content: "\F035A"; }

.mdi-alphabet-piqad::before {
  content: "\F035B"; }

.mdi-alphabet-tengwar::before {
  content: "\F0362"; }

.mdi-alphabetical::before {
  content: "\F02C"; }

.mdi-alphabetical-off::before {
  content: "\F002E"; }

.mdi-alphabetical-variant::before {
  content: "\F002F"; }

.mdi-alphabetical-variant-off::before {
  content: "\F0030"; }

.mdi-altimeter::before {
  content: "\F5D7"; }

.mdi-amazon::before {
  content: "\F02D"; }

.mdi-amazon-alexa::before {
  content: "\F8C5"; }

.mdi-amazon-drive::before {
  content: "\F02E"; }

.mdi-ambulance::before {
  content: "\F02F"; }

.mdi-ammunition::before {
  content: "\FCC4"; }

.mdi-ampersand::before {
  content: "\FA8C"; }

.mdi-amplifier::before {
  content: "\F030"; }

.mdi-amplifier-off::before {
  content: "\F01E0"; }

.mdi-anchor::before {
  content: "\F031"; }

.mdi-android::before {
  content: "\F032"; }

.mdi-android-auto::before {
  content: "\FA8D"; }

.mdi-android-debug-bridge::before {
  content: "\F033"; }

.mdi-android-head::before {
  content: "\F78F"; }

.mdi-android-messages::before {
  content: "\FD21"; }

.mdi-android-studio::before {
  content: "\F034"; }

.mdi-angle-acute::before {
  content: "\F936"; }

.mdi-angle-obtuse::before {
  content: "\F937"; }

.mdi-angle-right::before {
  content: "\F938"; }

.mdi-angular::before {
  content: "\F6B1"; }

.mdi-angularjs::before {
  content: "\F6BE"; }

.mdi-animation::before {
  content: "\F5D8"; }

.mdi-animation-outline::before {
  content: "\FA8E"; }

.mdi-animation-play::before {
  content: "\F939"; }

.mdi-animation-play-outline::before {
  content: "\FA8F"; }

.mdi-ansible::before {
  content: "\F00C5"; }

.mdi-antenna::before {
  content: "\F0144"; }

.mdi-anvil::before {
  content: "\F89A"; }

.mdi-apache-kafka::before {
  content: "\F0031"; }

.mdi-api::before {
  content: "\F00C6"; }

.mdi-api-off::before {
  content: "\F0282"; }

.mdi-apple::before {
  content: "\F035"; }

.mdi-apple-finder::before {
  content: "\F036"; }

.mdi-apple-icloud::before {
  content: "\F038"; }

.mdi-apple-ios::before {
  content: "\F037"; }

.mdi-apple-keyboard-caps::before {
  content: "\F632"; }

.mdi-apple-keyboard-command::before {
  content: "\F633"; }

.mdi-apple-keyboard-control::before {
  content: "\F634"; }

.mdi-apple-keyboard-option::before {
  content: "\F635"; }

.mdi-apple-keyboard-shift::before {
  content: "\F636"; }

.mdi-apple-safari::before {
  content: "\F039"; }

.mdi-application::before {
  content: "\F614"; }

.mdi-application-export::before {
  content: "\FD89"; }

.mdi-application-import::before {
  content: "\FD8A"; }

.mdi-approximately-equal::before {
  content: "\FFBE"; }

.mdi-approximately-equal-box::before {
  content: "\FFBF"; }

.mdi-apps::before {
  content: "\F03B"; }

.mdi-apps-box::before {
  content: "\FD22"; }

.mdi-arch::before {
  content: "\F8C6"; }

.mdi-archive::before {
  content: "\F03C"; }

.mdi-archive-arrow-down::before {
  content: "\F0284"; }

.mdi-archive-arrow-down-outline::before {
  content: "\F0285"; }

.mdi-archive-arrow-up::before {
  content: "\F0286"; }

.mdi-archive-arrow-up-outline::before {
  content: "\F0287"; }

.mdi-archive-outline::before {
  content: "\F0239"; }

.mdi-arm-flex::before {
  content: "\F008F"; }

.mdi-arm-flex-outline::before {
  content: "\F0090"; }

.mdi-arrange-bring-forward::before {
  content: "\F03D"; }

.mdi-arrange-bring-to-front::before {
  content: "\F03E"; }

.mdi-arrange-send-backward::before {
  content: "\F03F"; }

.mdi-arrange-send-to-back::before {
  content: "\F040"; }

.mdi-arrow-all::before {
  content: "\F041"; }

.mdi-arrow-bottom-left::before {
  content: "\F042"; }

.mdi-arrow-bottom-left-bold-outline::before {
  content: "\F9B6"; }

.mdi-arrow-bottom-left-thick::before {
  content: "\F9B7"; }

.mdi-arrow-bottom-right::before {
  content: "\F043"; }

.mdi-arrow-bottom-right-bold-outline::before {
  content: "\F9B8"; }

.mdi-arrow-bottom-right-thick::before {
  content: "\F9B9"; }

.mdi-arrow-collapse::before {
  content: "\F615"; }

.mdi-arrow-collapse-all::before {
  content: "\F044"; }

.mdi-arrow-collapse-down::before {
  content: "\F791"; }

.mdi-arrow-collapse-horizontal::before {
  content: "\F84B"; }

.mdi-arrow-collapse-left::before {
  content: "\F792"; }

.mdi-arrow-collapse-right::before {
  content: "\F793"; }

.mdi-arrow-collapse-up::before {
  content: "\F794"; }

.mdi-arrow-collapse-vertical::before {
  content: "\F84C"; }

.mdi-arrow-decision::before {
  content: "\F9BA"; }

.mdi-arrow-decision-auto::before {
  content: "\F9BB"; }

.mdi-arrow-decision-auto-outline::before {
  content: "\F9BC"; }

.mdi-arrow-decision-outline::before {
  content: "\F9BD"; }

.mdi-arrow-down::before {
  content: "\F045"; }

.mdi-arrow-down-bold::before {
  content: "\F72D"; }

.mdi-arrow-down-bold-box::before {
  content: "\F72E"; }

.mdi-arrow-down-bold-box-outline::before {
  content: "\F72F"; }

.mdi-arrow-down-bold-circle::before {
  content: "\F047"; }

.mdi-arrow-down-bold-circle-outline::before {
  content: "\F048"; }

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\F049"; }

.mdi-arrow-down-bold-outline::before {
  content: "\F9BE"; }

.mdi-arrow-down-box::before {
  content: "\F6BF"; }

.mdi-arrow-down-circle::before {
  content: "\FCB7"; }

.mdi-arrow-down-circle-outline::before {
  content: "\FCB8"; }

.mdi-arrow-down-drop-circle::before {
  content: "\F04A"; }

.mdi-arrow-down-drop-circle-outline::before {
  content: "\F04B"; }

.mdi-arrow-down-thick::before {
  content: "\F046"; }

.mdi-arrow-expand::before {
  content: "\F616"; }

.mdi-arrow-expand-all::before {
  content: "\F04C"; }

.mdi-arrow-expand-down::before {
  content: "\F795"; }

.mdi-arrow-expand-horizontal::before {
  content: "\F84D"; }

.mdi-arrow-expand-left::before {
  content: "\F796"; }

.mdi-arrow-expand-right::before {
  content: "\F797"; }

.mdi-arrow-expand-up::before {
  content: "\F798"; }

.mdi-arrow-expand-vertical::before {
  content: "\F84E"; }

.mdi-arrow-horizontal-lock::before {
  content: "\F0186"; }

.mdi-arrow-left::before {
  content: "\F04D"; }

.mdi-arrow-left-bold::before {
  content: "\F730"; }

.mdi-arrow-left-bold-box::before {
  content: "\F731"; }

.mdi-arrow-left-bold-box-outline::before {
  content: "\F732"; }

.mdi-arrow-left-bold-circle::before {
  content: "\F04F"; }

.mdi-arrow-left-bold-circle-outline::before {
  content: "\F050"; }

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\F051"; }

.mdi-arrow-left-bold-outline::before {
  content: "\F9BF"; }

.mdi-arrow-left-box::before {
  content: "\F6C0"; }

.mdi-arrow-left-circle::before {
  content: "\FCB9"; }

.mdi-arrow-left-circle-outline::before {
  content: "\FCBA"; }

.mdi-arrow-left-drop-circle::before {
  content: "\F052"; }

.mdi-arrow-left-drop-circle-outline::before {
  content: "\F053"; }

.mdi-arrow-left-right::before {
  content: "\FE90"; }

.mdi-arrow-left-right-bold::before {
  content: "\FE91"; }

.mdi-arrow-left-right-bold-outline::before {
  content: "\F9C0"; }

.mdi-arrow-left-thick::before {
  content: "\F04E"; }

.mdi-arrow-right::before {
  content: "\F054"; }

.mdi-arrow-right-bold::before {
  content: "\F733"; }

.mdi-arrow-right-bold-box::before {
  content: "\F734"; }

.mdi-arrow-right-bold-box-outline::before {
  content: "\F735"; }

.mdi-arrow-right-bold-circle::before {
  content: "\F056"; }

.mdi-arrow-right-bold-circle-outline::before {
  content: "\F057"; }

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\F058"; }

.mdi-arrow-right-bold-outline::before {
  content: "\F9C1"; }

.mdi-arrow-right-box::before {
  content: "\F6C1"; }

.mdi-arrow-right-circle::before {
  content: "\FCBB"; }

.mdi-arrow-right-circle-outline::before {
  content: "\FCBC"; }

.mdi-arrow-right-drop-circle::before {
  content: "\F059"; }

.mdi-arrow-right-drop-circle-outline::before {
  content: "\F05A"; }

.mdi-arrow-right-thick::before {
  content: "\F055"; }

.mdi-arrow-split-horizontal::before {
  content: "\F93A"; }

.mdi-arrow-split-vertical::before {
  content: "\F93B"; }

.mdi-arrow-top-left::before {
  content: "\F05B"; }

.mdi-arrow-top-left-bold-outline::before {
  content: "\F9C2"; }

.mdi-arrow-top-left-bottom-right::before {
  content: "\FE92"; }

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "\FE93"; }

.mdi-arrow-top-left-thick::before {
  content: "\F9C3"; }

.mdi-arrow-top-right::before {
  content: "\F05C"; }

.mdi-arrow-top-right-bold-outline::before {
  content: "\F9C4"; }

.mdi-arrow-top-right-bottom-left::before {
  content: "\FE94"; }

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "\FE95"; }

.mdi-arrow-top-right-thick::before {
  content: "\F9C5"; }

.mdi-arrow-up::before {
  content: "\F05D"; }

.mdi-arrow-up-bold::before {
  content: "\F736"; }

.mdi-arrow-up-bold-box::before {
  content: "\F737"; }

.mdi-arrow-up-bold-box-outline::before {
  content: "\F738"; }

.mdi-arrow-up-bold-circle::before {
  content: "\F05F"; }

.mdi-arrow-up-bold-circle-outline::before {
  content: "\F060"; }

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\F061"; }

.mdi-arrow-up-bold-outline::before {
  content: "\F9C6"; }

.mdi-arrow-up-box::before {
  content: "\F6C2"; }

.mdi-arrow-up-circle::before {
  content: "\FCBD"; }

.mdi-arrow-up-circle-outline::before {
  content: "\FCBE"; }

.mdi-arrow-up-down::before {
  content: "\FE96"; }

.mdi-arrow-up-down-bold::before {
  content: "\FE97"; }

.mdi-arrow-up-down-bold-outline::before {
  content: "\F9C7"; }

.mdi-arrow-up-drop-circle::before {
  content: "\F062"; }

.mdi-arrow-up-drop-circle-outline::before {
  content: "\F063"; }

.mdi-arrow-up-thick::before {
  content: "\F05E"; }

.mdi-arrow-vertical-lock::before {
  content: "\F0187"; }

.mdi-artist::before {
  content: "\F802"; }

.mdi-artist-outline::before {
  content: "\FCC5"; }

.mdi-artstation::before {
  content: "\FB37"; }

.mdi-aspect-ratio::before {
  content: "\FA23"; }

.mdi-assistant::before {
  content: "\F064"; }

.mdi-asterisk::before {
  content: "\F6C3"; }

.mdi-at::before {
  content: "\F065"; }

.mdi-atlassian::before {
  content: "\F803"; }

.mdi-atm::before {
  content: "\FD23"; }

.mdi-atom::before {
  content: "\F767"; }

.mdi-atom-variant::before {
  content: "\FE98"; }

.mdi-attachment::before {
  content: "\F066"; }

.mdi-audio-video::before {
  content: "\F93C"; }

.mdi-audio-video-off::before {
  content: "\F01E1"; }

.mdi-audiobook::before {
  content: "\F067"; }

.mdi-augmented-reality::before {
  content: "\F84F"; }

.mdi-auto-download::before {
  content: "\F03A9"; }

.mdi-auto-fix::before {
  content: "\F068"; }

.mdi-auto-upload::before {
  content: "\F069"; }

.mdi-autorenew::before {
  content: "\F06A"; }

.mdi-av-timer::before {
  content: "\F06B"; }

.mdi-aws::before {
  content: "\FDF2"; }

.mdi-axe::before {
  content: "\F8C7"; }

.mdi-axis::before {
  content: "\FD24"; }

.mdi-axis-arrow::before {
  content: "\FD25"; }

.mdi-axis-arrow-lock::before {
  content: "\FD26"; }

.mdi-axis-lock::before {
  content: "\FD27"; }

.mdi-axis-x-arrow::before {
  content: "\FD28"; }

.mdi-axis-x-arrow-lock::before {
  content: "\FD29"; }

.mdi-axis-x-rotate-clockwise::before {
  content: "\FD2A"; }

.mdi-axis-x-rotate-counterclockwise::before {
  content: "\FD2B"; }

.mdi-axis-x-y-arrow-lock::before {
  content: "\FD2C"; }

.mdi-axis-y-arrow::before {
  content: "\FD2D"; }

.mdi-axis-y-arrow-lock::before {
  content: "\FD2E"; }

.mdi-axis-y-rotate-clockwise::before {
  content: "\FD2F"; }

.mdi-axis-y-rotate-counterclockwise::before {
  content: "\FD30"; }

.mdi-axis-z-arrow::before {
  content: "\FD31"; }

.mdi-axis-z-arrow-lock::before {
  content: "\FD32"; }

.mdi-axis-z-rotate-clockwise::before {
  content: "\FD33"; }

.mdi-axis-z-rotate-counterclockwise::before {
  content: "\FD34"; }

.mdi-azure::before {
  content: "\F804"; }

.mdi-azure-devops::before {
  content: "\F0091"; }

.mdi-babel::before {
  content: "\FA24"; }

.mdi-baby::before {
  content: "\F06C"; }

.mdi-baby-bottle::before {
  content: "\FF56"; }

.mdi-baby-bottle-outline::before {
  content: "\FF57"; }

.mdi-baby-carriage::before {
  content: "\F68E"; }

.mdi-baby-carriage-off::before {
  content: "\FFC0"; }

.mdi-baby-face::before {
  content: "\FE99"; }

.mdi-baby-face-outline::before {
  content: "\FE9A"; }

.mdi-backburger::before {
  content: "\F06D"; }

.mdi-backspace::before {
  content: "\F06E"; }

.mdi-backspace-outline::before {
  content: "\FB38"; }

.mdi-backspace-reverse::before {
  content: "\FE9B"; }

.mdi-backspace-reverse-outline::before {
  content: "\FE9C"; }

.mdi-backup-restore::before {
  content: "\F06F"; }

.mdi-bacteria::before {
  content: "\FEF2"; }

.mdi-bacteria-outline::before {
  content: "\FEF3"; }

.mdi-badminton::before {
  content: "\F850"; }

.mdi-bag-carry-on::before {
  content: "\FF58"; }

.mdi-bag-carry-on-check::before {
  content: "\FD41"; }

.mdi-bag-carry-on-off::before {
  content: "\FF59"; }

.mdi-bag-checked::before {
  content: "\FF5A"; }

.mdi-bag-personal::before {
  content: "\FDF3"; }

.mdi-bag-personal-off::before {
  content: "\FDF4"; }

.mdi-bag-personal-off-outline::before {
  content: "\FDF5"; }

.mdi-bag-personal-outline::before {
  content: "\FDF6"; }

.mdi-baguette::before {
  content: "\FF5B"; }

.mdi-balloon::before {
  content: "\FA25"; }

.mdi-ballot::before {
  content: "\F9C8"; }

.mdi-ballot-outline::before {
  content: "\F9C9"; }

.mdi-ballot-recount::before {
  content: "\FC15"; }

.mdi-ballot-recount-outline::before {
  content: "\FC16"; }

.mdi-bandage::before {
  content: "\FD8B"; }

.mdi-bandcamp::before {
  content: "\F674"; }

.mdi-bank::before {
  content: "\F070"; }

.mdi-bank-minus::before {
  content: "\FD8C"; }

.mdi-bank-outline::before {
  content: "\FE9D"; }

.mdi-bank-plus::before {
  content: "\FD8D"; }

.mdi-bank-remove::before {
  content: "\FD8E"; }

.mdi-bank-transfer::before {
  content: "\FA26"; }

.mdi-bank-transfer-in::before {
  content: "\FA27"; }

.mdi-bank-transfer-out::before {
  content: "\FA28"; }

.mdi-barcode::before {
  content: "\F071"; }

.mdi-barcode-off::before {
  content: "\F0261"; }

.mdi-barcode-scan::before {
  content: "\F072"; }

.mdi-barley::before {
  content: "\F073"; }

.mdi-barley-off::before {
  content: "\FB39"; }

.mdi-barn::before {
  content: "\FB3A"; }

.mdi-barrel::before {
  content: "\F074"; }

.mdi-baseball::before {
  content: "\F851"; }

.mdi-baseball-bat::before {
  content: "\F852"; }

.mdi-basecamp::before {
  content: "\F075"; }

.mdi-bash::before {
  content: "\F01AE"; }

.mdi-basket::before {
  content: "\F076"; }

.mdi-basket-fill::before {
  content: "\F077"; }

.mdi-basket-outline::before {
  content: "\F01AC"; }

.mdi-basket-unfill::before {
  content: "\F078"; }

.mdi-basketball::before {
  content: "\F805"; }

.mdi-basketball-hoop::before {
  content: "\FC17"; }

.mdi-basketball-hoop-outline::before {
  content: "\FC18"; }

.mdi-bat::before {
  content: "\FB3B"; }

.mdi-battery::before {
  content: "\F079"; }

.mdi-battery-10::before {
  content: "\F07A"; }

.mdi-battery-10-bluetooth::before {
  content: "\F93D"; }

.mdi-battery-20::before {
  content: "\F07B"; }

.mdi-battery-20-bluetooth::before {
  content: "\F93E"; }

.mdi-battery-30::before {
  content: "\F07C"; }

.mdi-battery-30-bluetooth::before {
  content: "\F93F"; }

.mdi-battery-40::before {
  content: "\F07D"; }

.mdi-battery-40-bluetooth::before {
  content: "\F940"; }

.mdi-battery-50::before {
  content: "\F07E"; }

.mdi-battery-50-bluetooth::before {
  content: "\F941"; }

.mdi-battery-60::before {
  content: "\F07F"; }

.mdi-battery-60-bluetooth::before {
  content: "\F942"; }

.mdi-battery-70::before {
  content: "\F080"; }

.mdi-battery-70-bluetooth::before {
  content: "\F943"; }

.mdi-battery-80::before {
  content: "\F081"; }

.mdi-battery-80-bluetooth::before {
  content: "\F944"; }

.mdi-battery-90::before {
  content: "\F082"; }

.mdi-battery-90-bluetooth::before {
  content: "\F945"; }

.mdi-battery-alert::before {
  content: "\F083"; }

.mdi-battery-alert-bluetooth::before {
  content: "\F946"; }

.mdi-battery-alert-variant::before {
  content: "\F00F7"; }

.mdi-battery-alert-variant-outline::before {
  content: "\F00F8"; }

.mdi-battery-bluetooth::before {
  content: "\F947"; }

.mdi-battery-bluetooth-variant::before {
  content: "\F948"; }

.mdi-battery-charging::before {
  content: "\F084"; }

.mdi-battery-charging-10::before {
  content: "\F89B"; }

.mdi-battery-charging-100::before {
  content: "\F085"; }

.mdi-battery-charging-20::before {
  content: "\F086"; }

.mdi-battery-charging-30::before {
  content: "\F087"; }

.mdi-battery-charging-40::before {
  content: "\F088"; }

.mdi-battery-charging-50::before {
  content: "\F89C"; }

.mdi-battery-charging-60::before {
  content: "\F089"; }

.mdi-battery-charging-70::before {
  content: "\F89D"; }

.mdi-battery-charging-80::before {
  content: "\F08A"; }

.mdi-battery-charging-90::before {
  content: "\F08B"; }

.mdi-battery-charging-high::before {
  content: "\F02D1"; }

.mdi-battery-charging-low::before {
  content: "\F02CF"; }

.mdi-battery-charging-medium::before {
  content: "\F02D0"; }

.mdi-battery-charging-outline::before {
  content: "\F89E"; }

.mdi-battery-charging-wireless::before {
  content: "\F806"; }

.mdi-battery-charging-wireless-10::before {
  content: "\F807"; }

.mdi-battery-charging-wireless-20::before {
  content: "\F808"; }

.mdi-battery-charging-wireless-30::before {
  content: "\F809"; }

.mdi-battery-charging-wireless-40::before {
  content: "\F80A"; }

.mdi-battery-charging-wireless-50::before {
  content: "\F80B"; }

.mdi-battery-charging-wireless-60::before {
  content: "\F80C"; }

.mdi-battery-charging-wireless-70::before {
  content: "\F80D"; }

.mdi-battery-charging-wireless-80::before {
  content: "\F80E"; }

.mdi-battery-charging-wireless-90::before {
  content: "\F80F"; }

.mdi-battery-charging-wireless-alert::before {
  content: "\F810"; }

.mdi-battery-charging-wireless-outline::before {
  content: "\F811"; }

.mdi-battery-heart::before {
  content: "\F023A"; }

.mdi-battery-heart-outline::before {
  content: "\F023B"; }

.mdi-battery-heart-variant::before {
  content: "\F023C"; }

.mdi-battery-high::before {
  content: "\F02CE"; }

.mdi-battery-low::before {
  content: "\F02CC"; }

.mdi-battery-medium::before {
  content: "\F02CD"; }

.mdi-battery-minus::before {
  content: "\F08C"; }

.mdi-battery-negative::before {
  content: "\F08D"; }

.mdi-battery-off::before {
  content: "\F0288"; }

.mdi-battery-off-outline::before {
  content: "\F0289"; }

.mdi-battery-outline::before {
  content: "\F08E"; }

.mdi-battery-plus::before {
  content: "\F08F"; }

.mdi-battery-positive::before {
  content: "\F090"; }

.mdi-battery-unknown::before {
  content: "\F091"; }

.mdi-battery-unknown-bluetooth::before {
  content: "\F949"; }

.mdi-battlenet::before {
  content: "\FB3C"; }

.mdi-beach::before {
  content: "\F092"; }

.mdi-beaker::before {
  content: "\FCC6"; }

.mdi-beaker-alert::before {
  content: "\F0254"; }

.mdi-beaker-alert-outline::before {
  content: "\F0255"; }

.mdi-beaker-check::before {
  content: "\F0256"; }

.mdi-beaker-check-outline::before {
  content: "\F0257"; }

.mdi-beaker-minus::before {
  content: "\F0258"; }

.mdi-beaker-minus-outline::before {
  content: "\F0259"; }

.mdi-beaker-outline::before {
  content: "\F68F"; }

.mdi-beaker-plus::before {
  content: "\F025A"; }

.mdi-beaker-plus-outline::before {
  content: "\F025B"; }

.mdi-beaker-question::before {
  content: "\F025C"; }

.mdi-beaker-question-outline::before {
  content: "\F025D"; }

.mdi-beaker-remove::before {
  content: "\F025E"; }

.mdi-beaker-remove-outline::before {
  content: "\F025F"; }

.mdi-beats::before {
  content: "\F097"; }

.mdi-bed-double::before {
  content: "\F0092"; }

.mdi-bed-double-outline::before {
  content: "\F0093"; }

.mdi-bed-empty::before {
  content: "\F89F"; }

.mdi-bed-king::before {
  content: "\F0094"; }

.mdi-bed-king-outline::before {
  content: "\F0095"; }

.mdi-bed-queen::before {
  content: "\F0096"; }

.mdi-bed-queen-outline::before {
  content: "\F0097"; }

.mdi-bed-single::before {
  content: "\F0098"; }

.mdi-bed-single-outline::before {
  content: "\F0099"; }

.mdi-bee::before {
  content: "\FFC1"; }

.mdi-bee-flower::before {
  content: "\FFC2"; }

.mdi-beehive-outline::before {
  content: "\F00F9"; }

.mdi-beer::before {
  content: "\F098"; }

.mdi-beer-outline::before {
  content: "\F0337"; }

.mdi-behance::before {
  content: "\F099"; }

.mdi-bell::before {
  content: "\F09A"; }

.mdi-bell-alert::before {
  content: "\FD35"; }

.mdi-bell-alert-outline::before {
  content: "\FE9E"; }

.mdi-bell-check::before {
  content: "\F0210"; }

.mdi-bell-check-outline::before {
  content: "\F0211"; }

.mdi-bell-circle::before {
  content: "\FD36"; }

.mdi-bell-circle-outline::before {
  content: "\FD37"; }

.mdi-bell-off::before {
  content: "\F09B"; }

.mdi-bell-off-outline::before {
  content: "\FA90"; }

.mdi-bell-outline::before {
  content: "\F09C"; }

.mdi-bell-plus::before {
  content: "\F09D"; }

.mdi-bell-plus-outline::before {
  content: "\FA91"; }

.mdi-bell-ring::before {
  content: "\F09E"; }

.mdi-bell-ring-outline::before {
  content: "\F09F"; }

.mdi-bell-sleep::before {
  content: "\F0A0"; }

.mdi-bell-sleep-outline::before {
  content: "\FA92"; }

.mdi-beta::before {
  content: "\F0A1"; }

.mdi-betamax::before {
  content: "\F9CA"; }

.mdi-biathlon::before {
  content: "\FDF7"; }

.mdi-bible::before {
  content: "\F0A2"; }

.mdi-bicycle::before {
  content: "\F00C7"; }

.mdi-bicycle-basket::before {
  content: "\F0260"; }

.mdi-bike::before {
  content: "\F0A3"; }

.mdi-bike-fast::before {
  content: "\F014A"; }

.mdi-billboard::before {
  content: "\F0032"; }

.mdi-billiards::before {
  content: "\FB3D"; }

.mdi-billiards-rack::before {
  content: "\FB3E"; }

.mdi-bing::before {
  content: "\F0A4"; }

.mdi-binoculars::before {
  content: "\F0A5"; }

.mdi-bio::before {
  content: "\F0A6"; }

.mdi-biohazard::before {
  content: "\F0A7"; }

.mdi-bitbucket::before {
  content: "\F0A8"; }

.mdi-bitcoin::before {
  content: "\F812"; }

.mdi-black-mesa::before {
  content: "\F0A9"; }

.mdi-blackberry::before {
  content: "\F0AA"; }

.mdi-blender::before {
  content: "\FCC7"; }

.mdi-blender-software::before {
  content: "\F0AB"; }

.mdi-blinds::before {
  content: "\F0AC"; }

.mdi-blinds-open::before {
  content: "\F0033"; }

.mdi-block-helper::before {
  content: "\F0AD"; }

.mdi-blogger::before {
  content: "\F0AE"; }

.mdi-blood-bag::before {
  content: "\FCC8"; }

.mdi-bluetooth::before {
  content: "\F0AF"; }

.mdi-bluetooth-audio::before {
  content: "\F0B0"; }

.mdi-bluetooth-connect::before {
  content: "\F0B1"; }

.mdi-bluetooth-off::before {
  content: "\F0B2"; }

.mdi-bluetooth-settings::before {
  content: "\F0B3"; }

.mdi-bluetooth-transfer::before {
  content: "\F0B4"; }

.mdi-blur::before {
  content: "\F0B5"; }

.mdi-blur-linear::before {
  content: "\F0B6"; }

.mdi-blur-off::before {
  content: "\F0B7"; }

.mdi-blur-radial::before {
  content: "\F0B8"; }

.mdi-bolnisi-cross::before {
  content: "\FCC9"; }

.mdi-bolt::before {
  content: "\FD8F"; }

.mdi-bomb::before {
  content: "\F690"; }

.mdi-bomb-off::before {
  content: "\F6C4"; }

.mdi-bone::before {
  content: "\F0B9"; }

.mdi-book::before {
  content: "\F0BA"; }

.mdi-book-information-variant::before {
  content: "\F009A"; }

.mdi-book-lock::before {
  content: "\F799"; }

.mdi-book-lock-open::before {
  content: "\F79A"; }

.mdi-book-minus::before {
  content: "\F5D9"; }

.mdi-book-minus-multiple::before {
  content: "\FA93"; }

.mdi-book-multiple::before {
  content: "\F0BB"; }

.mdi-book-open::before {
  content: "\F0BD"; }

.mdi-book-open-outline::before {
  content: "\FB3F"; }

.mdi-book-open-page-variant::before {
  content: "\F5DA"; }

.mdi-book-open-variant::before {
  content: "\F0BE"; }

.mdi-book-outline::before {
  content: "\FB40"; }

.mdi-book-play::before {
  content: "\FE9F"; }

.mdi-book-play-outline::before {
  content: "\FEA0"; }

.mdi-book-plus::before {
  content: "\F5DB"; }

.mdi-book-plus-multiple::before {
  content: "\FA94"; }

.mdi-book-remove::before {
  content: "\FA96"; }

.mdi-book-remove-multiple::before {
  content: "\FA95"; }

.mdi-book-search::before {
  content: "\FEA1"; }

.mdi-book-search-outline::before {
  content: "\FEA2"; }

.mdi-book-variant::before {
  content: "\F0BF"; }

.mdi-book-variant-multiple::before {
  content: "\F0BC"; }

.mdi-bookmark::before {
  content: "\F0C0"; }

.mdi-bookmark-check::before {
  content: "\F0C1"; }

.mdi-bookmark-check-outline::before {
  content: "\F03A6"; }

.mdi-bookmark-minus::before {
  content: "\F9CB"; }

.mdi-bookmark-minus-outline::before {
  content: "\F9CC"; }

.mdi-bookmark-multiple::before {
  content: "\FDF8"; }

.mdi-bookmark-multiple-outline::before {
  content: "\FDF9"; }

.mdi-bookmark-music::before {
  content: "\F0C2"; }

.mdi-bookmark-music-outline::before {
  content: "\F03A4"; }

.mdi-bookmark-off::before {
  content: "\F9CD"; }

.mdi-bookmark-off-outline::before {
  content: "\F9CE"; }

.mdi-bookmark-outline::before {
  content: "\F0C3"; }

.mdi-bookmark-plus::before {
  content: "\F0C5"; }

.mdi-bookmark-plus-outline::before {
  content: "\F0C4"; }

.mdi-bookmark-remove::before {
  content: "\F0C6"; }

.mdi-bookmark-remove-outline::before {
  content: "\F03A5"; }

.mdi-bookshelf::before {
  content: "\F028A"; }

.mdi-boom-gate::before {
  content: "\FEA3"; }

.mdi-boom-gate-alert::before {
  content: "\FEA4"; }

.mdi-boom-gate-alert-outline::before {
  content: "\FEA5"; }

.mdi-boom-gate-down::before {
  content: "\FEA6"; }

.mdi-boom-gate-down-outline::before {
  content: "\FEA7"; }

.mdi-boom-gate-outline::before {
  content: "\FEA8"; }

.mdi-boom-gate-up::before {
  content: "\FEA9"; }

.mdi-boom-gate-up-outline::before {
  content: "\FEAA"; }

.mdi-boombox::before {
  content: "\F5DC"; }

.mdi-boomerang::before {
  content: "\F00FA"; }

.mdi-bootstrap::before {
  content: "\F6C5"; }

.mdi-border-all::before {
  content: "\F0C7"; }

.mdi-border-all-variant::before {
  content: "\F8A0"; }

.mdi-border-bottom::before {
  content: "\F0C8"; }

.mdi-border-bottom-variant::before {
  content: "\F8A1"; }

.mdi-border-color::before {
  content: "\F0C9"; }

.mdi-border-horizontal::before {
  content: "\F0CA"; }

.mdi-border-inside::before {
  content: "\F0CB"; }

.mdi-border-left::before {
  content: "\F0CC"; }

.mdi-border-left-variant::before {
  content: "\F8A2"; }

.mdi-border-none::before {
  content: "\F0CD"; }

.mdi-border-none-variant::before {
  content: "\F8A3"; }

.mdi-border-outside::before {
  content: "\F0CE"; }

.mdi-border-right::before {
  content: "\F0CF"; }

.mdi-border-right-variant::before {
  content: "\F8A4"; }

.mdi-border-style::before {
  content: "\F0D0"; }

.mdi-border-top::before {
  content: "\F0D1"; }

.mdi-border-top-variant::before {
  content: "\F8A5"; }

.mdi-border-vertical::before {
  content: "\F0D2"; }

.mdi-bottle-soda::before {
  content: "\F009B"; }

.mdi-bottle-soda-classic::before {
  content: "\F009C"; }

.mdi-bottle-soda-classic-outline::before {
  content: "\F038E"; }

.mdi-bottle-soda-outline::before {
  content: "\F009D"; }

.mdi-bottle-tonic::before {
  content: "\F0159"; }

.mdi-bottle-tonic-outline::before {
  content: "\F015A"; }

.mdi-bottle-tonic-plus::before {
  content: "\F015B"; }

.mdi-bottle-tonic-plus-outline::before {
  content: "\F015C"; }

.mdi-bottle-tonic-skull::before {
  content: "\F015D"; }

.mdi-bottle-tonic-skull-outline::before {
  content: "\F015E"; }

.mdi-bottle-wine::before {
  content: "\F853"; }

.mdi-bottle-wine-outline::before {
  content: "\F033B"; }

.mdi-bow-tie::before {
  content: "\F677"; }

.mdi-bowl::before {
  content: "\F617"; }

.mdi-bowling::before {
  content: "\F0D3"; }

.mdi-box::before {
  content: "\F0D4"; }

.mdi-box-cutter::before {
  content: "\F0D5"; }

.mdi-box-shadow::before {
  content: "\F637"; }

.mdi-boxing-glove::before {
  content: "\FB41"; }

.mdi-braille::before {
  content: "\F9CF"; }

.mdi-brain::before {
  content: "\F9D0"; }

.mdi-bread-slice::before {
  content: "\FCCA"; }

.mdi-bread-slice-outline::before {
  content: "\FCCB"; }

.mdi-bridge::before {
  content: "\F618"; }

.mdi-briefcase::before {
  content: "\F0D6"; }

.mdi-briefcase-account::before {
  content: "\FCCC"; }

.mdi-briefcase-account-outline::before {
  content: "\FCCD"; }

.mdi-briefcase-check::before {
  content: "\F0D7"; }

.mdi-briefcase-check-outline::before {
  content: "\F0349"; }

.mdi-briefcase-clock::before {
  content: "\F00FB"; }

.mdi-briefcase-clock-outline::before {
  content: "\F00FC"; }

.mdi-briefcase-download::before {
  content: "\F0D8"; }

.mdi-briefcase-download-outline::before {
  content: "\FC19"; }

.mdi-briefcase-edit::before {
  content: "\FA97"; }

.mdi-briefcase-edit-outline::before {
  content: "\FC1A"; }

.mdi-briefcase-minus::before {
  content: "\FA29"; }

.mdi-briefcase-minus-outline::before {
  content: "\FC1B"; }

.mdi-briefcase-outline::before {
  content: "\F813"; }

.mdi-briefcase-plus::before {
  content: "\FA2A"; }

.mdi-briefcase-plus-outline::before {
  content: "\FC1C"; }

.mdi-briefcase-remove::before {
  content: "\FA2B"; }

.mdi-briefcase-remove-outline::before {
  content: "\FC1D"; }

.mdi-briefcase-search::before {
  content: "\FA2C"; }

.mdi-briefcase-search-outline::before {
  content: "\FC1E"; }

.mdi-briefcase-upload::before {
  content: "\F0D9"; }

.mdi-briefcase-upload-outline::before {
  content: "\FC1F"; }

.mdi-brightness-1::before {
  content: "\F0DA"; }

.mdi-brightness-2::before {
  content: "\F0DB"; }

.mdi-brightness-3::before {
  content: "\F0DC"; }

.mdi-brightness-4::before {
  content: "\F0DD"; }

.mdi-brightness-5::before {
  content: "\F0DE"; }

.mdi-brightness-6::before {
  content: "\F0DF"; }

.mdi-brightness-7::before {
  content: "\F0E0"; }

.mdi-brightness-auto::before {
  content: "\F0E1"; }

.mdi-brightness-percent::before {
  content: "\FCCE"; }

.mdi-broom::before {
  content: "\F0E2"; }

.mdi-brush::before {
  content: "\F0E3"; }

.mdi-buddhism::before {
  content: "\F94A"; }

.mdi-buffer::before {
  content: "\F619"; }

.mdi-bug::before {
  content: "\F0E4"; }

.mdi-bug-check::before {
  content: "\FA2D"; }

.mdi-bug-check-outline::before {
  content: "\FA2E"; }

.mdi-bug-outline::before {
  content: "\FA2F"; }

.mdi-bugle::before {
  content: "\FD90"; }

.mdi-bulldozer::before {
  content: "\FB07"; }

.mdi-bullet::before {
  content: "\FCCF"; }

.mdi-bulletin-board::before {
  content: "\F0E5"; }

.mdi-bullhorn::before {
  content: "\F0E6"; }

.mdi-bullhorn-outline::before {
  content: "\FB08"; }

.mdi-bullseye::before {
  content: "\F5DD"; }

.mdi-bullseye-arrow::before {
  content: "\F8C8"; }

.mdi-bulma::before {
  content: "\F0312"; }

.mdi-bunk-bed::before {
  content: "\F032D"; }

.mdi-bus::before {
  content: "\F0E7"; }

.mdi-bus-alert::before {
  content: "\FA98"; }

.mdi-bus-articulated-end::before {
  content: "\F79B"; }

.mdi-bus-articulated-front::before {
  content: "\F79C"; }

.mdi-bus-clock::before {
  content: "\F8C9"; }

.mdi-bus-double-decker::before {
  content: "\F79D"; }

.mdi-bus-marker::before {
  content: "\F023D"; }

.mdi-bus-multiple::before {
  content: "\FF5C"; }

.mdi-bus-school::before {
  content: "\F79E"; }

.mdi-bus-side::before {
  content: "\F79F"; }

.mdi-bus-stop::before {
  content: "\F0034"; }

.mdi-bus-stop-covered::before {
  content: "\F0035"; }

.mdi-bus-stop-uncovered::before {
  content: "\F0036"; }

.mdi-cached::before {
  content: "\F0E8"; }

.mdi-cactus::before {
  content: "\FD91"; }

.mdi-cake::before {
  content: "\F0E9"; }

.mdi-cake-layered::before {
  content: "\F0EA"; }

.mdi-cake-variant::before {
  content: "\F0EB"; }

.mdi-calculator::before {
  content: "\F0EC"; }

.mdi-calculator-variant::before {
  content: "\FA99"; }

.mdi-calendar::before {
  content: "\F0ED"; }

.mdi-calendar-account::before {
  content: "\FEF4"; }

.mdi-calendar-account-outline::before {
  content: "\FEF5"; }

.mdi-calendar-alert::before {
  content: "\FA30"; }

.mdi-calendar-arrow-left::before {
  content: "\F015F"; }

.mdi-calendar-arrow-right::before {
  content: "\F0160"; }

.mdi-calendar-blank::before {
  content: "\F0EE"; }

.mdi-calendar-blank-multiple::before {
  content: "\F009E"; }

.mdi-calendar-blank-outline::before {
  content: "\FB42"; }

.mdi-calendar-check::before {
  content: "\F0EF"; }

.mdi-calendar-check-outline::before {
  content: "\FC20"; }

.mdi-calendar-clock::before {
  content: "\F0F0"; }

.mdi-calendar-edit::before {
  content: "\F8A6"; }

.mdi-calendar-export::before {
  content: "\FB09"; }

.mdi-calendar-heart::before {
  content: "\F9D1"; }

.mdi-calendar-import::before {
  content: "\FB0A"; }

.mdi-calendar-minus::before {
  content: "\FD38"; }

.mdi-calendar-month::before {
  content: "\FDFA"; }

.mdi-calendar-month-outline::before {
  content: "\FDFB"; }

.mdi-calendar-multiple::before {
  content: "\F0F1"; }

.mdi-calendar-multiple-check::before {
  content: "\F0F2"; }

.mdi-calendar-multiselect::before {
  content: "\FA31"; }

.mdi-calendar-outline::before {
  content: "\FB43"; }

.mdi-calendar-plus::before {
  content: "\F0F3"; }

.mdi-calendar-question::before {
  content: "\F691"; }

.mdi-calendar-range::before {
  content: "\F678"; }

.mdi-calendar-range-outline::before {
  content: "\FB44"; }

.mdi-calendar-remove::before {
  content: "\F0F4"; }

.mdi-calendar-remove-outline::before {
  content: "\FC21"; }

.mdi-calendar-repeat::before {
  content: "\FEAB"; }

.mdi-calendar-repeat-outline::before {
  content: "\FEAC"; }

.mdi-calendar-search::before {
  content: "\F94B"; }

.mdi-calendar-star::before {
  content: "\F9D2"; }

.mdi-calendar-text::before {
  content: "\F0F5"; }

.mdi-calendar-text-outline::before {
  content: "\FC22"; }

.mdi-calendar-today::before {
  content: "\F0F6"; }

.mdi-calendar-week::before {
  content: "\FA32"; }

.mdi-calendar-week-begin::before {
  content: "\FA33"; }

.mdi-calendar-weekend::before {
  content: "\FEF6"; }

.mdi-calendar-weekend-outline::before {
  content: "\FEF7"; }

.mdi-call-made::before {
  content: "\F0F7"; }

.mdi-call-merge::before {
  content: "\F0F8"; }

.mdi-call-missed::before {
  content: "\F0F9"; }

.mdi-call-received::before {
  content: "\F0FA"; }

.mdi-call-split::before {
  content: "\F0FB"; }

.mdi-camcorder::before {
  content: "\F0FC"; }

.mdi-camcorder-box::before {
  content: "\F0FD"; }

.mdi-camcorder-box-off::before {
  content: "\F0FE"; }

.mdi-camcorder-off::before {
  content: "\F0FF"; }

.mdi-camera::before {
  content: "\F100"; }

.mdi-camera-account::before {
  content: "\F8CA"; }

.mdi-camera-burst::before {
  content: "\F692"; }

.mdi-camera-control::before {
  content: "\FB45"; }

.mdi-camera-enhance::before {
  content: "\F101"; }

.mdi-camera-enhance-outline::before {
  content: "\FB46"; }

.mdi-camera-front::before {
  content: "\F102"; }

.mdi-camera-front-variant::before {
  content: "\F103"; }

.mdi-camera-gopro::before {
  content: "\F7A0"; }

.mdi-camera-image::before {
  content: "\F8CB"; }

.mdi-camera-iris::before {
  content: "\F104"; }

.mdi-camera-metering-center::before {
  content: "\F7A1"; }

.mdi-camera-metering-matrix::before {
  content: "\F7A2"; }

.mdi-camera-metering-partial::before {
  content: "\F7A3"; }

.mdi-camera-metering-spot::before {
  content: "\F7A4"; }

.mdi-camera-off::before {
  content: "\F5DF"; }

.mdi-camera-outline::before {
  content: "\FD39"; }

.mdi-camera-party-mode::before {
  content: "\F105"; }

.mdi-camera-plus::before {
  content: "\FEF8"; }

.mdi-camera-plus-outline::before {
  content: "\FEF9"; }

.mdi-camera-rear::before {
  content: "\F106"; }

.mdi-camera-rear-variant::before {
  content: "\F107"; }

.mdi-camera-retake::before {
  content: "\FDFC"; }

.mdi-camera-retake-outline::before {
  content: "\FDFD"; }

.mdi-camera-switch::before {
  content: "\F108"; }

.mdi-camera-timer::before {
  content: "\F109"; }

.mdi-camera-wireless::before {
  content: "\FD92"; }

.mdi-camera-wireless-outline::before {
  content: "\FD93"; }

.mdi-campfire::before {
  content: "\FEFA"; }

.mdi-cancel::before {
  content: "\F739"; }

.mdi-candle::before {
  content: "\F5E2"; }

.mdi-candycane::before {
  content: "\F10A"; }

.mdi-cannabis::before {
  content: "\F7A5"; }

.mdi-caps-lock::before {
  content: "\FA9A"; }

.mdi-car::before {
  content: "\F10B"; }

.mdi-car-2-plus::before {
  content: "\F0037"; }

.mdi-car-3-plus::before {
  content: "\F0038"; }

.mdi-car-back::before {
  content: "\FDFE"; }

.mdi-car-battery::before {
  content: "\F10C"; }

.mdi-car-brake-abs::before {
  content: "\FC23"; }

.mdi-car-brake-alert::before {
  content: "\FC24"; }

.mdi-car-brake-hold::before {
  content: "\FD3A"; }

.mdi-car-brake-parking::before {
  content: "\FD3B"; }

.mdi-car-brake-retarder::before {
  content: "\F0039"; }

.mdi-car-child-seat::before {
  content: "\FFC3"; }

.mdi-car-clutch::before {
  content: "\F003A"; }

.mdi-car-connected::before {
  content: "\F10D"; }

.mdi-car-convertible::before {
  content: "\F7A6"; }

.mdi-car-coolant-level::before {
  content: "\F003B"; }

.mdi-car-cruise-control::before {
  content: "\FD3C"; }

.mdi-car-defrost-front::before {
  content: "\FD3D"; }

.mdi-car-defrost-rear::before {
  content: "\FD3E"; }

.mdi-car-door::before {
  content: "\FB47"; }

.mdi-car-door-lock::before {
  content: "\F00C8"; }

.mdi-car-electric::before {
  content: "\FB48"; }

.mdi-car-esp::before {
  content: "\FC25"; }

.mdi-car-estate::before {
  content: "\F7A7"; }

.mdi-car-hatchback::before {
  content: "\F7A8"; }

.mdi-car-info::before {
  content: "\F01E9"; }

.mdi-car-key::before {
  content: "\FB49"; }

.mdi-car-light-dimmed::before {
  content: "\FC26"; }

.mdi-car-light-fog::before {
  content: "\FC27"; }

.mdi-car-light-high::before {
  content: "\FC28"; }

.mdi-car-limousine::before {
  content: "\F8CC"; }

.mdi-car-multiple::before {
  content: "\FB4A"; }

.mdi-car-off::before {
  content: "\FDFF"; }

.mdi-car-parking-lights::before {
  content: "\FD3F"; }

.mdi-car-pickup::before {
  content: "\F7A9"; }

.mdi-car-seat::before {
  content: "\FFC4"; }

.mdi-car-seat-cooler::before {
  content: "\FFC5"; }

.mdi-car-seat-heater::before {
  content: "\FFC6"; }

.mdi-car-shift-pattern::before {
  content: "\FF5D"; }

.mdi-car-side::before {
  content: "\F7AA"; }

.mdi-car-sports::before {
  content: "\F7AB"; }

.mdi-car-tire-alert::before {
  content: "\FC29"; }

.mdi-car-traction-control::before {
  content: "\FD40"; }

.mdi-car-turbocharger::before {
  content: "\F003C"; }

.mdi-car-wash::before {
  content: "\F10E"; }

.mdi-car-windshield::before {
  content: "\F003D"; }

.mdi-car-windshield-outline::before {
  content: "\F003E"; }

.mdi-caravan::before {
  content: "\F7AC"; }

.mdi-card::before {
  content: "\FB4B"; }

.mdi-card-bulleted::before {
  content: "\FB4C"; }

.mdi-card-bulleted-off::before {
  content: "\FB4D"; }

.mdi-card-bulleted-off-outline::before {
  content: "\FB4E"; }

.mdi-card-bulleted-outline::before {
  content: "\FB4F"; }

.mdi-card-bulleted-settings::before {
  content: "\FB50"; }

.mdi-card-bulleted-settings-outline::before {
  content: "\FB51"; }

.mdi-card-outline::before {
  content: "\FB52"; }

.mdi-card-plus::before {
  content: "\F022A"; }

.mdi-card-plus-outline::before {
  content: "\F022B"; }

.mdi-card-search::before {
  content: "\F009F"; }

.mdi-card-search-outline::before {
  content: "\F00A0"; }

.mdi-card-text::before {
  content: "\FB53"; }

.mdi-card-text-outline::before {
  content: "\FB54"; }

.mdi-cards::before {
  content: "\F638"; }

.mdi-cards-club::before {
  content: "\F8CD"; }

.mdi-cards-diamond::before {
  content: "\F8CE"; }

.mdi-cards-diamond-outline::before {
  content: "\F003F"; }

.mdi-cards-heart::before {
  content: "\F8CF"; }

.mdi-cards-outline::before {
  content: "\F639"; }

.mdi-cards-playing-outline::before {
  content: "\F63A"; }

.mdi-cards-spade::before {
  content: "\F8D0"; }

.mdi-cards-variant::before {
  content: "\F6C6"; }

.mdi-carrot::before {
  content: "\F10F"; }

.mdi-cart::before {
  content: "\F110"; }

.mdi-cart-arrow-down::before {
  content: "\FD42"; }

.mdi-cart-arrow-right::before {
  content: "\FC2A"; }

.mdi-cart-arrow-up::before {
  content: "\FD43"; }

.mdi-cart-minus::before {
  content: "\FD44"; }

.mdi-cart-off::before {
  content: "\F66B"; }

.mdi-cart-outline::before {
  content: "\F111"; }

.mdi-cart-plus::before {
  content: "\F112"; }

.mdi-cart-remove::before {
  content: "\FD45"; }

.mdi-case-sensitive-alt::before {
  content: "\F113"; }

.mdi-cash::before {
  content: "\F114"; }

.mdi-cash-100::before {
  content: "\F115"; }

.mdi-cash-marker::before {
  content: "\FD94"; }

.mdi-cash-minus::before {
  content: "\F028B"; }

.mdi-cash-multiple::before {
  content: "\F116"; }

.mdi-cash-plus::before {
  content: "\F028C"; }

.mdi-cash-refund::before {
  content: "\FA9B"; }

.mdi-cash-register::before {
  content: "\FCD0"; }

.mdi-cash-remove::before {
  content: "\F028D"; }

.mdi-cash-usd::before {
  content: "\F01A1"; }

.mdi-cash-usd-outline::before {
  content: "\F117"; }

.mdi-cassette::before {
  content: "\F9D3"; }

.mdi-cast::before {
  content: "\F118"; }

.mdi-cast-audio::before {
  content: "\F0040"; }

.mdi-cast-connected::before {
  content: "\F119"; }

.mdi-cast-education::before {
  content: "\FE6D"; }

.mdi-cast-off::before {
  content: "\F789"; }

.mdi-castle::before {
  content: "\F11A"; }

.mdi-cat::before {
  content: "\F11B"; }

.mdi-cctv::before {
  content: "\F7AD"; }

.mdi-ceiling-light::before {
  content: "\F768"; }

.mdi-cellphone::before {
  content: "\F11C"; }

.mdi-cellphone-android::before {
  content: "\F11D"; }

.mdi-cellphone-arrow-down::before {
  content: "\F9D4"; }

.mdi-cellphone-basic::before {
  content: "\F11E"; }

.mdi-cellphone-dock::before {
  content: "\F11F"; }

.mdi-cellphone-erase::before {
  content: "\F94C"; }

.mdi-cellphone-information::before {
  content: "\FF5E"; }

.mdi-cellphone-iphone::before {
  content: "\F120"; }

.mdi-cellphone-key::before {
  content: "\F94D"; }

.mdi-cellphone-link::before {
  content: "\F121"; }

.mdi-cellphone-link-off::before {
  content: "\F122"; }

.mdi-cellphone-lock::before {
  content: "\F94E"; }

.mdi-cellphone-message::before {
  content: "\F8D2"; }

.mdi-cellphone-message-off::before {
  content: "\F00FD"; }

.mdi-cellphone-nfc::before {
  content: "\FEAD"; }

.mdi-cellphone-nfc-off::before {
  content: "\F0303"; }

.mdi-cellphone-off::before {
  content: "\F94F"; }

.mdi-cellphone-play::before {
  content: "\F0041"; }

.mdi-cellphone-screenshot::before {
  content: "\FA34"; }

.mdi-cellphone-settings::before {
  content: "\F123"; }

.mdi-cellphone-settings-variant::before {
  content: "\F950"; }

.mdi-cellphone-sound::before {
  content: "\F951"; }

.mdi-cellphone-text::before {
  content: "\F8D1"; }

.mdi-cellphone-wireless::before {
  content: "\F814"; }

.mdi-celtic-cross::before {
  content: "\FCD1"; }

.mdi-centos::before {
  content: "\F0145"; }

.mdi-certificate::before {
  content: "\F124"; }

.mdi-certificate-outline::before {
  content: "\F01B3"; }

.mdi-chair-rolling::before {
  content: "\FFBA"; }

.mdi-chair-school::before {
  content: "\F125"; }

.mdi-charity::before {
  content: "\FC2B"; }

.mdi-chart-arc::before {
  content: "\F126"; }

.mdi-chart-areaspline::before {
  content: "\F127"; }

.mdi-chart-areaspline-variant::before {
  content: "\FEAE"; }

.mdi-chart-bar::before {
  content: "\F128"; }

.mdi-chart-bar-stacked::before {
  content: "\F769"; }

.mdi-chart-bell-curve::before {
  content: "\FC2C"; }

.mdi-chart-bell-curve-cumulative::before {
  content: "\FFC7"; }

.mdi-chart-bubble::before {
  content: "\F5E3"; }

.mdi-chart-donut::before {
  content: "\F7AE"; }

.mdi-chart-donut-variant::before {
  content: "\F7AF"; }

.mdi-chart-gantt::before {
  content: "\F66C"; }

.mdi-chart-histogram::before {
  content: "\F129"; }

.mdi-chart-line::before {
  content: "\F12A"; }

.mdi-chart-line-stacked::before {
  content: "\F76A"; }

.mdi-chart-line-variant::before {
  content: "\F7B0"; }

.mdi-chart-multiline::before {
  content: "\F8D3"; }

.mdi-chart-multiple::before {
  content: "\F023E"; }

.mdi-chart-pie::before {
  content: "\F12B"; }

.mdi-chart-ppf::before {
  content: "\F03AB"; }

.mdi-chart-scatter-plot::before {
  content: "\FEAF"; }

.mdi-chart-scatter-plot-hexbin::before {
  content: "\F66D"; }

.mdi-chart-snakey::before {
  content: "\F020A"; }

.mdi-chart-snakey-variant::before {
  content: "\F020B"; }

.mdi-chart-timeline::before {
  content: "\F66E"; }

.mdi-chart-timeline-variant::before {
  content: "\FEB0"; }

.mdi-chart-tree::before {
  content: "\FEB1"; }

.mdi-chat::before {
  content: "\FB55"; }

.mdi-chat-alert::before {
  content: "\FB56"; }

.mdi-chat-alert-outline::before {
  content: "\F02F4"; }

.mdi-chat-outline::before {
  content: "\FEFB"; }

.mdi-chat-processing::before {
  content: "\FB57"; }

.mdi-chat-processing-outline::before {
  content: "\F02F5"; }

.mdi-chat-sleep::before {
  content: "\F02FC"; }

.mdi-chat-sleep-outline::before {
  content: "\F02FD"; }

.mdi-check::before {
  content: "\F12C"; }

.mdi-check-all::before {
  content: "\F12D"; }

.mdi-check-bold::before {
  content: "\FE6E"; }

.mdi-check-box-multiple-outline::before {
  content: "\FC2D"; }

.mdi-check-box-outline::before {
  content: "\FC2E"; }

.mdi-check-circle::before {
  content: "\F5E0"; }

.mdi-check-circle-outline::before {
  content: "\F5E1"; }

.mdi-check-decagram::before {
  content: "\F790"; }

.mdi-check-network::before {
  content: "\FC2F"; }

.mdi-check-network-outline::before {
  content: "\FC30"; }

.mdi-check-outline::before {
  content: "\F854"; }

.mdi-check-underline::before {
  content: "\FE70"; }

.mdi-check-underline-circle::before {
  content: "\FE71"; }

.mdi-check-underline-circle-outline::before {
  content: "\FE72"; }

.mdi-checkbook::before {
  content: "\FA9C"; }

.mdi-checkbox-blank::before {
  content: "\F12E"; }

.mdi-checkbox-blank-circle::before {
  content: "\F12F"; }

.mdi-checkbox-blank-circle-outline::before {
  content: "\F130"; }

.mdi-checkbox-blank-off::before {
  content: "\F0317"; }

.mdi-checkbox-blank-off-outline::before {
  content: "\F0318"; }

.mdi-checkbox-blank-outline::before {
  content: "\F131"; }

.mdi-checkbox-intermediate::before {
  content: "\F855"; }

.mdi-checkbox-marked::before {
  content: "\F132"; }

.mdi-checkbox-marked-circle::before {
  content: "\F133"; }

.mdi-checkbox-marked-circle-outline::before {
  content: "\F134"; }

.mdi-checkbox-marked-outline::before {
  content: "\F135"; }

.mdi-checkbox-multiple-blank::before {
  content: "\F136"; }

.mdi-checkbox-multiple-blank-circle::before {
  content: "\F63B"; }

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\F63C"; }

.mdi-checkbox-multiple-blank-outline::before {
  content: "\F137"; }

.mdi-checkbox-multiple-marked::before {
  content: "\F138"; }

.mdi-checkbox-multiple-marked-circle::before {
  content: "\F63D"; }

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\F63E"; }

.mdi-checkbox-multiple-marked-outline::before {
  content: "\F139"; }

.mdi-checkerboard::before {
  content: "\F13A"; }

.mdi-checkerboard-minus::before {
  content: "\F022D"; }

.mdi-checkerboard-plus::before {
  content: "\F022C"; }

.mdi-checkerboard-remove::before {
  content: "\F022E"; }

.mdi-cheese::before {
  content: "\F02E4"; }

.mdi-chef-hat::before {
  content: "\FB58"; }

.mdi-chemical-weapon::before {
  content: "\F13B"; }

.mdi-chess-bishop::before {
  content: "\F85B"; }

.mdi-chess-king::before {
  content: "\F856"; }

.mdi-chess-knight::before {
  content: "\F857"; }

.mdi-chess-pawn::before {
  content: "\F858"; }

.mdi-chess-queen::before {
  content: "\F859"; }

.mdi-chess-rook::before {
  content: "\F85A"; }

.mdi-chevron-double-down::before {
  content: "\F13C"; }

.mdi-chevron-double-left::before {
  content: "\F13D"; }

.mdi-chevron-double-right::before {
  content: "\F13E"; }

.mdi-chevron-double-up::before {
  content: "\F13F"; }

.mdi-chevron-down::before {
  content: "\F140"; }

.mdi-chevron-down-box::before {
  content: "\F9D5"; }

.mdi-chevron-down-box-outline::before {
  content: "\F9D6"; }

.mdi-chevron-down-circle::before {
  content: "\FB0B"; }

.mdi-chevron-down-circle-outline::before {
  content: "\FB0C"; }

.mdi-chevron-left::before {
  content: "\F141"; }

.mdi-chevron-left-box::before {
  content: "\F9D7"; }

.mdi-chevron-left-box-outline::before {
  content: "\F9D8"; }

.mdi-chevron-left-circle::before {
  content: "\FB0D"; }

.mdi-chevron-left-circle-outline::before {
  content: "\FB0E"; }

.mdi-chevron-right::before {
  content: "\F142"; }

.mdi-chevron-right-box::before {
  content: "\F9D9"; }

.mdi-chevron-right-box-outline::before {
  content: "\F9DA"; }

.mdi-chevron-right-circle::before {
  content: "\FB0F"; }

.mdi-chevron-right-circle-outline::before {
  content: "\FB10"; }

.mdi-chevron-triple-down::before {
  content: "\FD95"; }

.mdi-chevron-triple-left::before {
  content: "\FD96"; }

.mdi-chevron-triple-right::before {
  content: "\FD97"; }

.mdi-chevron-triple-up::before {
  content: "\FD98"; }

.mdi-chevron-up::before {
  content: "\F143"; }

.mdi-chevron-up-box::before {
  content: "\F9DB"; }

.mdi-chevron-up-box-outline::before {
  content: "\F9DC"; }

.mdi-chevron-up-circle::before {
  content: "\FB11"; }

.mdi-chevron-up-circle-outline::before {
  content: "\FB12"; }

.mdi-chili-hot::before {
  content: "\F7B1"; }

.mdi-chili-medium::before {
  content: "\F7B2"; }

.mdi-chili-mild::before {
  content: "\F7B3"; }

.mdi-chip::before {
  content: "\F61A"; }

.mdi-christianity::before {
  content: "\F952"; }

.mdi-christianity-outline::before {
  content: "\FCD2"; }

.mdi-church::before {
  content: "\F144"; }

.mdi-cigar::before {
  content: "\F01B4"; }

.mdi-circle::before {
  content: "\F764"; }

.mdi-circle-double::before {
  content: "\FEB2"; }

.mdi-circle-edit-outline::before {
  content: "\F8D4"; }

.mdi-circle-expand::before {
  content: "\FEB3"; }

.mdi-circle-medium::before {
  content: "\F9DD"; }

.mdi-circle-off-outline::before {
  content: "\F00FE"; }

.mdi-circle-outline::before {
  content: "\F765"; }

.mdi-circle-slice-1::before {
  content: "\FA9D"; }

.mdi-circle-slice-2::before {
  content: "\FA9E"; }

.mdi-circle-slice-3::before {
  content: "\FA9F"; }

.mdi-circle-slice-4::before {
  content: "\FAA0"; }

.mdi-circle-slice-5::before {
  content: "\FAA1"; }

.mdi-circle-slice-6::before {
  content: "\FAA2"; }

.mdi-circle-slice-7::before {
  content: "\FAA3"; }

.mdi-circle-slice-8::before {
  content: "\FAA4"; }

.mdi-circle-small::before {
  content: "\F9DE"; }

.mdi-circular-saw::before {
  content: "\FE73"; }

.mdi-cisco-webex::before {
  content: "\F145"; }

.mdi-city::before {
  content: "\F146"; }

.mdi-city-variant::before {
  content: "\FA35"; }

.mdi-city-variant-outline::before {
  content: "\FA36"; }

.mdi-clipboard::before {
  content: "\F147"; }

.mdi-clipboard-account::before {
  content: "\F148"; }

.mdi-clipboard-account-outline::before {
  content: "\FC31"; }

.mdi-clipboard-alert::before {
  content: "\F149"; }

.mdi-clipboard-alert-outline::before {
  content: "\FCD3"; }

.mdi-clipboard-arrow-down::before {
  content: "\F14A"; }

.mdi-clipboard-arrow-down-outline::before {
  content: "\FC32"; }

.mdi-clipboard-arrow-left::before {
  content: "\F14B"; }

.mdi-clipboard-arrow-left-outline::before {
  content: "\FCD4"; }

.mdi-clipboard-arrow-right::before {
  content: "\FCD5"; }

.mdi-clipboard-arrow-right-outline::before {
  content: "\FCD6"; }

.mdi-clipboard-arrow-up::before {
  content: "\FC33"; }

.mdi-clipboard-arrow-up-outline::before {
  content: "\FC34"; }

.mdi-clipboard-check::before {
  content: "\F14C"; }

.mdi-clipboard-check-multiple::before {
  content: "\F028E"; }

.mdi-clipboard-check-multiple-outline::before {
  content: "\F028F"; }

.mdi-clipboard-check-outline::before {
  content: "\F8A7"; }

.mdi-clipboard-file::before {
  content: "\F0290"; }

.mdi-clipboard-file-outline::before {
  content: "\F0291"; }

.mdi-clipboard-flow::before {
  content: "\F6C7"; }

.mdi-clipboard-flow-outline::before {
  content: "\F0142"; }

.mdi-clipboard-list::before {
  content: "\F00FF"; }

.mdi-clipboard-list-outline::before {
  content: "\F0100"; }

.mdi-clipboard-multiple::before {
  content: "\F0292"; }

.mdi-clipboard-multiple-outline::before {
  content: "\F0293"; }

.mdi-clipboard-outline::before {
  content: "\F14D"; }

.mdi-clipboard-play::before {
  content: "\FC35"; }

.mdi-clipboard-play-multiple::before {
  content: "\F0294"; }

.mdi-clipboard-play-multiple-outline::before {
  content: "\F0295"; }

.mdi-clipboard-play-outline::before {
  content: "\FC36"; }

.mdi-clipboard-plus::before {
  content: "\F750"; }

.mdi-clipboard-plus-outline::before {
  content: "\F034A"; }

.mdi-clipboard-pulse::before {
  content: "\F85C"; }

.mdi-clipboard-pulse-outline::before {
  content: "\F85D"; }

.mdi-clipboard-text::before {
  content: "\F14E"; }

.mdi-clipboard-text-multiple::before {
  content: "\F0296"; }

.mdi-clipboard-text-multiple-outline::before {
  content: "\F0297"; }

.mdi-clipboard-text-outline::before {
  content: "\FA37"; }

.mdi-clipboard-text-play::before {
  content: "\FC37"; }

.mdi-clipboard-text-play-outline::before {
  content: "\FC38"; }

.mdi-clippy::before {
  content: "\F14F"; }

.mdi-clock::before {
  content: "\F953"; }

.mdi-clock-alert::before {
  content: "\F954"; }

.mdi-clock-alert-outline::before {
  content: "\F5CE"; }

.mdi-clock-check::before {
  content: "\FFC8"; }

.mdi-clock-check-outline::before {
  content: "\FFC9"; }

.mdi-clock-digital::before {
  content: "\FEB4"; }

.mdi-clock-end::before {
  content: "\F151"; }

.mdi-clock-fast::before {
  content: "\F152"; }

.mdi-clock-in::before {
  content: "\F153"; }

.mdi-clock-out::before {
  content: "\F154"; }

.mdi-clock-outline::before {
  content: "\F150"; }

.mdi-clock-start::before {
  content: "\F155"; }

.mdi-close::before {
  content: "\F156"; }

.mdi-close-box::before {
  content: "\F157"; }

.mdi-close-box-multiple::before {
  content: "\FC39"; }

.mdi-close-box-multiple-outline::before {
  content: "\FC3A"; }

.mdi-close-box-outline::before {
  content: "\F158"; }

.mdi-close-circle::before {
  content: "\F159"; }

.mdi-close-circle-outline::before {
  content: "\F15A"; }

.mdi-close-network::before {
  content: "\F15B"; }

.mdi-close-network-outline::before {
  content: "\FC3B"; }

.mdi-close-octagon::before {
  content: "\F15C"; }

.mdi-close-octagon-outline::before {
  content: "\F15D"; }

.mdi-close-outline::before {
  content: "\F6C8"; }

.mdi-closed-caption::before {
  content: "\F15E"; }

.mdi-closed-caption-outline::before {
  content: "\FD99"; }

.mdi-cloud::before {
  content: "\F15F"; }

.mdi-cloud-alert::before {
  content: "\F9DF"; }

.mdi-cloud-braces::before {
  content: "\F7B4"; }

.mdi-cloud-check::before {
  content: "\F160"; }

.mdi-cloud-check-outline::before {
  content: "\F02F7"; }

.mdi-cloud-circle::before {
  content: "\F161"; }

.mdi-cloud-download::before {
  content: "\F162"; }

.mdi-cloud-download-outline::before {
  content: "\FB59"; }

.mdi-cloud-lock::before {
  content: "\F021C"; }

.mdi-cloud-lock-outline::before {
  content: "\F021D"; }

.mdi-cloud-off-outline::before {
  content: "\F164"; }

.mdi-cloud-outline::before {
  content: "\F163"; }

.mdi-cloud-print::before {
  content: "\F165"; }

.mdi-cloud-print-outline::before {
  content: "\F166"; }

.mdi-cloud-question::before {
  content: "\FA38"; }

.mdi-cloud-search::before {
  content: "\F955"; }

.mdi-cloud-search-outline::before {
  content: "\F956"; }

.mdi-cloud-sync::before {
  content: "\F63F"; }

.mdi-cloud-sync-outline::before {
  content: "\F0301"; }

.mdi-cloud-tags::before {
  content: "\F7B5"; }

.mdi-cloud-upload::before {
  content: "\F167"; }

.mdi-cloud-upload-outline::before {
  content: "\FB5A"; }

.mdi-clover::before {
  content: "\F815"; }

.mdi-coach-lamp::before {
  content: "\F0042"; }

.mdi-coat-rack::before {
  content: "\F00C9"; }

.mdi-code-array::before {
  content: "\F168"; }

.mdi-code-braces::before {
  content: "\F169"; }

.mdi-code-braces-box::before {
  content: "\F0101"; }

.mdi-code-brackets::before {
  content: "\F16A"; }

.mdi-code-equal::before {
  content: "\F16B"; }

.mdi-code-greater-than::before {
  content: "\F16C"; }

.mdi-code-greater-than-or-equal::before {
  content: "\F16D"; }

.mdi-code-less-than::before {
  content: "\F16E"; }

.mdi-code-less-than-or-equal::before {
  content: "\F16F"; }

.mdi-code-not-equal::before {
  content: "\F170"; }

.mdi-code-not-equal-variant::before {
  content: "\F171"; }

.mdi-code-parentheses::before {
  content: "\F172"; }

.mdi-code-parentheses-box::before {
  content: "\F0102"; }

.mdi-code-string::before {
  content: "\F173"; }

.mdi-code-tags::before {
  content: "\F174"; }

.mdi-code-tags-check::before {
  content: "\F693"; }

.mdi-codepen::before {
  content: "\F175"; }

.mdi-coffee::before {
  content: "\F176"; }

.mdi-coffee-maker::before {
  content: "\F00CA"; }

.mdi-coffee-off::before {
  content: "\FFCA"; }

.mdi-coffee-off-outline::before {
  content: "\FFCB"; }

.mdi-coffee-outline::before {
  content: "\F6C9"; }

.mdi-coffee-to-go::before {
  content: "\F177"; }

.mdi-coffee-to-go-outline::before {
  content: "\F0339"; }

.mdi-coffin::before {
  content: "\FB5B"; }

.mdi-cog-clockwise::before {
  content: "\F0208"; }

.mdi-cog-counterclockwise::before {
  content: "\F0209"; }

.mdi-cogs::before {
  content: "\F8D5"; }

.mdi-coin::before {
  content: "\F0196"; }

.mdi-coin-outline::before {
  content: "\F178"; }

.mdi-coins::before {
  content: "\F694"; }

.mdi-collage::before {
  content: "\F640"; }

.mdi-collapse-all::before {
  content: "\FAA5"; }

.mdi-collapse-all-outline::before {
  content: "\FAA6"; }

.mdi-color-helper::before {
  content: "\F179"; }

.mdi-comma::before {
  content: "\FE74"; }

.mdi-comma-box::before {
  content: "\FE75"; }

.mdi-comma-box-outline::before {
  content: "\FE76"; }

.mdi-comma-circle::before {
  content: "\FE77"; }

.mdi-comma-circle-outline::before {
  content: "\FE78"; }

.mdi-comment::before {
  content: "\F17A"; }

.mdi-comment-account::before {
  content: "\F17B"; }

.mdi-comment-account-outline::before {
  content: "\F17C"; }

.mdi-comment-alert::before {
  content: "\F17D"; }

.mdi-comment-alert-outline::before {
  content: "\F17E"; }

.mdi-comment-arrow-left::before {
  content: "\F9E0"; }

.mdi-comment-arrow-left-outline::before {
  content: "\F9E1"; }

.mdi-comment-arrow-right::before {
  content: "\F9E2"; }

.mdi-comment-arrow-right-outline::before {
  content: "\F9E3"; }

.mdi-comment-check::before {
  content: "\F17F"; }

.mdi-comment-check-outline::before {
  content: "\F180"; }

.mdi-comment-edit::before {
  content: "\F01EA"; }

.mdi-comment-edit-outline::before {
  content: "\F02EF"; }

.mdi-comment-eye::before {
  content: "\FA39"; }

.mdi-comment-eye-outline::before {
  content: "\FA3A"; }

.mdi-comment-multiple::before {
  content: "\F85E"; }

.mdi-comment-multiple-outline::before {
  content: "\F181"; }

.mdi-comment-outline::before {
  content: "\F182"; }

.mdi-comment-plus::before {
  content: "\F9E4"; }

.mdi-comment-plus-outline::before {
  content: "\F183"; }

.mdi-comment-processing::before {
  content: "\F184"; }

.mdi-comment-processing-outline::before {
  content: "\F185"; }

.mdi-comment-question::before {
  content: "\F816"; }

.mdi-comment-question-outline::before {
  content: "\F186"; }

.mdi-comment-quote::before {
  content: "\F0043"; }

.mdi-comment-quote-outline::before {
  content: "\F0044"; }

.mdi-comment-remove::before {
  content: "\F5DE"; }

.mdi-comment-remove-outline::before {
  content: "\F187"; }

.mdi-comment-search::before {
  content: "\FA3B"; }

.mdi-comment-search-outline::before {
  content: "\FA3C"; }

.mdi-comment-text::before {
  content: "\F188"; }

.mdi-comment-text-multiple::before {
  content: "\F85F"; }

.mdi-comment-text-multiple-outline::before {
  content: "\F860"; }

.mdi-comment-text-outline::before {
  content: "\F189"; }

.mdi-compare::before {
  content: "\F18A"; }

.mdi-compass::before {
  content: "\F18B"; }

.mdi-compass-off::before {
  content: "\FB5C"; }

.mdi-compass-off-outline::before {
  content: "\FB5D"; }

.mdi-compass-outline::before {
  content: "\F18C"; }

.mdi-compass-rose::before {
  content: "\F03AD"; }

.mdi-concourse-ci::before {
  content: "\F00CB"; }

.mdi-console::before {
  content: "\F18D"; }

.mdi-console-line::before {
  content: "\F7B6"; }

.mdi-console-network::before {
  content: "\F8A8"; }

.mdi-console-network-outline::before {
  content: "\FC3C"; }

.mdi-consolidate::before {
  content: "\F0103"; }

.mdi-contact-mail::before {
  content: "\F18E"; }

.mdi-contact-mail-outline::before {
  content: "\FEB5"; }

.mdi-contact-phone::before {
  content: "\FEB6"; }

.mdi-contact-phone-outline::before {
  content: "\FEB7"; }

.mdi-contactless-payment::before {
  content: "\FD46"; }

.mdi-contacts::before {
  content: "\F6CA"; }

.mdi-contain::before {
  content: "\FA3D"; }

.mdi-contain-end::before {
  content: "\FA3E"; }

.mdi-contain-start::before {
  content: "\FA3F"; }

.mdi-content-copy::before {
  content: "\F18F"; }

.mdi-content-cut::before {
  content: "\F190"; }

.mdi-content-duplicate::before {
  content: "\F191"; }

.mdi-content-paste::before {
  content: "\F192"; }

.mdi-content-save::before {
  content: "\F193"; }

.mdi-content-save-alert::before {
  content: "\FF5F"; }

.mdi-content-save-alert-outline::before {
  content: "\FF60"; }

.mdi-content-save-all::before {
  content: "\F194"; }

.mdi-content-save-all-outline::before {
  content: "\FF61"; }

.mdi-content-save-edit::before {
  content: "\FCD7"; }

.mdi-content-save-edit-outline::before {
  content: "\FCD8"; }

.mdi-content-save-move::before {
  content: "\FE79"; }

.mdi-content-save-move-outline::before {
  content: "\FE7A"; }

.mdi-content-save-outline::before {
  content: "\F817"; }

.mdi-content-save-settings::before {
  content: "\F61B"; }

.mdi-content-save-settings-outline::before {
  content: "\FB13"; }

.mdi-contrast::before {
  content: "\F195"; }

.mdi-contrast-box::before {
  content: "\F196"; }

.mdi-contrast-circle::before {
  content: "\F197"; }

.mdi-controller-classic::before {
  content: "\FB5E"; }

.mdi-controller-classic-outline::before {
  content: "\FB5F"; }

.mdi-cookie::before {
  content: "\F198"; }

.mdi-coolant-temperature::before {
  content: "\F3C8"; }

.mdi-copyright::before {
  content: "\F5E6"; }

.mdi-cordova::before {
  content: "\F957"; }

.mdi-corn::before {
  content: "\F7B7"; }

.mdi-counter::before {
  content: "\F199"; }

.mdi-cow::before {
  content: "\F19A"; }

.mdi-cowboy::before {
  content: "\FEB8"; }

.mdi-cpu-32-bit::before {
  content: "\FEFC"; }

.mdi-cpu-64-bit::before {
  content: "\FEFD"; }

.mdi-crane::before {
  content: "\F861"; }

.mdi-creation::before {
  content: "\F1C9"; }

.mdi-creative-commons::before {
  content: "\FD47"; }

.mdi-credit-card::before {
  content: "\F0010"; }

.mdi-credit-card-clock::before {
  content: "\FEFE"; }

.mdi-credit-card-clock-outline::before {
  content: "\FFBC"; }

.mdi-credit-card-marker::before {
  content: "\F6A7"; }

.mdi-credit-card-marker-outline::before {
  content: "\FD9A"; }

.mdi-credit-card-minus::before {
  content: "\FFCC"; }

.mdi-credit-card-minus-outline::before {
  content: "\FFCD"; }

.mdi-credit-card-multiple::before {
  content: "\F0011"; }

.mdi-credit-card-multiple-outline::before {
  content: "\F19C"; }

.mdi-credit-card-off::before {
  content: "\F0012"; }

.mdi-credit-card-off-outline::before {
  content: "\F5E4"; }

.mdi-credit-card-outline::before {
  content: "\F19B"; }

.mdi-credit-card-plus::before {
  content: "\F0013"; }

.mdi-credit-card-plus-outline::before {
  content: "\F675"; }

.mdi-credit-card-refund::before {
  content: "\F0014"; }

.mdi-credit-card-refund-outline::before {
  content: "\FAA7"; }

.mdi-credit-card-remove::before {
  content: "\FFCE"; }

.mdi-credit-card-remove-outline::before {
  content: "\FFCF"; }

.mdi-credit-card-scan::before {
  content: "\F0015"; }

.mdi-credit-card-scan-outline::before {
  content: "\F19D"; }

.mdi-credit-card-settings::before {
  content: "\F0016"; }

.mdi-credit-card-settings-outline::before {
  content: "\F8D6"; }

.mdi-credit-card-wireless::before {
  content: "\F801"; }

.mdi-credit-card-wireless-outline::before {
  content: "\FD48"; }

.mdi-cricket::before {
  content: "\FD49"; }

.mdi-crop::before {
  content: "\F19E"; }

.mdi-crop-free::before {
  content: "\F19F"; }

.mdi-crop-landscape::before {
  content: "\F1A0"; }

.mdi-crop-portrait::before {
  content: "\F1A1"; }

.mdi-crop-rotate::before {
  content: "\F695"; }

.mdi-crop-square::before {
  content: "\F1A2"; }

.mdi-crosshairs::before {
  content: "\F1A3"; }

.mdi-crosshairs-gps::before {
  content: "\F1A4"; }

.mdi-crosshairs-off::before {
  content: "\FF62"; }

.mdi-crosshairs-question::before {
  content: "\F0161"; }

.mdi-crown::before {
  content: "\F1A5"; }

.mdi-crown-outline::before {
  content: "\F01FB"; }

.mdi-cryengine::before {
  content: "\F958"; }

.mdi-crystal-ball::before {
  content: "\FB14"; }

.mdi-cube::before {
  content: "\F1A6"; }

.mdi-cube-outline::before {
  content: "\F1A7"; }

.mdi-cube-scan::before {
  content: "\FB60"; }

.mdi-cube-send::before {
  content: "\F1A8"; }

.mdi-cube-unfolded::before {
  content: "\F1A9"; }

.mdi-cup::before {
  content: "\F1AA"; }

.mdi-cup-off::before {
  content: "\F5E5"; }

.mdi-cup-off-outline::before {
  content: "\F03A8"; }

.mdi-cup-outline::before {
  content: "\F033A"; }

.mdi-cup-water::before {
  content: "\F1AB"; }

.mdi-cupboard::before {
  content: "\FF63"; }

.mdi-cupboard-outline::before {
  content: "\FF64"; }

.mdi-cupcake::before {
  content: "\F959"; }

.mdi-curling::before {
  content: "\F862"; }

.mdi-currency-bdt::before {
  content: "\F863"; }

.mdi-currency-brl::before {
  content: "\FB61"; }

.mdi-currency-btc::before {
  content: "\F1AC"; }

.mdi-currency-cny::before {
  content: "\F7B9"; }

.mdi-currency-eth::before {
  content: "\F7BA"; }

.mdi-currency-eur::before {
  content: "\F1AD"; }

.mdi-currency-eur-off::before {
  content: "\F0340"; }

.mdi-currency-gbp::before {
  content: "\F1AE"; }

.mdi-currency-ils::before {
  content: "\FC3D"; }

.mdi-currency-inr::before {
  content: "\F1AF"; }

.mdi-currency-jpy::before {
  content: "\F7BB"; }

.mdi-currency-krw::before {
  content: "\F7BC"; }

.mdi-currency-kzt::before {
  content: "\F864"; }

.mdi-currency-ngn::before {
  content: "\F1B0"; }

.mdi-currency-php::before {
  content: "\F9E5"; }

.mdi-currency-rial::before {
  content: "\FEB9"; }

.mdi-currency-rub::before {
  content: "\F1B1"; }

.mdi-currency-sign::before {
  content: "\F7BD"; }

.mdi-currency-try::before {
  content: "\F1B2"; }

.mdi-currency-twd::before {
  content: "\F7BE"; }

.mdi-currency-usd::before {
  content: "\F1B3"; }

.mdi-currency-usd-off::before {
  content: "\F679"; }

.mdi-current-ac::before {
  content: "\F95A"; }

.mdi-current-dc::before {
  content: "\F95B"; }

.mdi-cursor-default::before {
  content: "\F1B4"; }

.mdi-cursor-default-click::before {
  content: "\FCD9"; }

.mdi-cursor-default-click-outline::before {
  content: "\FCDA"; }

.mdi-cursor-default-gesture::before {
  content: "\F0152"; }

.mdi-cursor-default-gesture-outline::before {
  content: "\F0153"; }

.mdi-cursor-default-outline::before {
  content: "\F1B5"; }

.mdi-cursor-move::before {
  content: "\F1B6"; }

.mdi-cursor-pointer::before {
  content: "\F1B7"; }

.mdi-cursor-text::before {
  content: "\F5E7"; }

.mdi-database::before {
  content: "\F1B8"; }

.mdi-database-check::before {
  content: "\FAA8"; }

.mdi-database-edit::before {
  content: "\FB62"; }

.mdi-database-export::before {
  content: "\F95D"; }

.mdi-database-import::before {
  content: "\F95C"; }

.mdi-database-lock::before {
  content: "\FAA9"; }

.mdi-database-marker::before {
  content: "\F0321"; }

.mdi-database-minus::before {
  content: "\F1B9"; }

.mdi-database-plus::before {
  content: "\F1BA"; }

.mdi-database-refresh::before {
  content: "\FCDB"; }

.mdi-database-remove::before {
  content: "\FCDC"; }

.mdi-database-search::before {
  content: "\F865"; }

.mdi-database-settings::before {
  content: "\FCDD"; }

.mdi-death-star::before {
  content: "\F8D7"; }

.mdi-death-star-variant::before {
  content: "\F8D8"; }

.mdi-deathly-hallows::before {
  content: "\FB63"; }

.mdi-debian::before {
  content: "\F8D9"; }

.mdi-debug-step-into::before {
  content: "\F1BB"; }

.mdi-debug-step-out::before {
  content: "\F1BC"; }

.mdi-debug-step-over::before {
  content: "\F1BD"; }

.mdi-decagram::before {
  content: "\F76B"; }

.mdi-decagram-outline::before {
  content: "\F76C"; }

.mdi-decimal::before {
  content: "\F00CC"; }

.mdi-decimal-comma::before {
  content: "\F00CD"; }

.mdi-decimal-comma-decrease::before {
  content: "\F00CE"; }

.mdi-decimal-comma-increase::before {
  content: "\F00CF"; }

.mdi-decimal-decrease::before {
  content: "\F1BE"; }

.mdi-decimal-increase::before {
  content: "\F1BF"; }

.mdi-delete::before {
  content: "\F1C0"; }

.mdi-delete-alert::before {
  content: "\F00D0"; }

.mdi-delete-alert-outline::before {
  content: "\F00D1"; }

.mdi-delete-circle::before {
  content: "\F682"; }

.mdi-delete-circle-outline::before {
  content: "\FB64"; }

.mdi-delete-empty::before {
  content: "\F6CB"; }

.mdi-delete-empty-outline::before {
  content: "\FEBA"; }

.mdi-delete-forever::before {
  content: "\F5E8"; }

.mdi-delete-forever-outline::before {
  content: "\FB65"; }

.mdi-delete-off::before {
  content: "\F00D2"; }

.mdi-delete-off-outline::before {
  content: "\F00D3"; }

.mdi-delete-outline::before {
  content: "\F9E6"; }

.mdi-delete-restore::before {
  content: "\F818"; }

.mdi-delete-sweep::before {
  content: "\F5E9"; }

.mdi-delete-sweep-outline::before {
  content: "\FC3E"; }

.mdi-delete-variant::before {
  content: "\F1C1"; }

.mdi-delta::before {
  content: "\F1C2"; }

.mdi-desk::before {
  content: "\F0264"; }

.mdi-desk-lamp::before {
  content: "\F95E"; }

.mdi-deskphone::before {
  content: "\F1C3"; }

.mdi-desktop-classic::before {
  content: "\F7BF"; }

.mdi-desktop-mac::before {
  content: "\F1C4"; }

.mdi-desktop-mac-dashboard::before {
  content: "\F9E7"; }

.mdi-desktop-tower::before {
  content: "\F1C5"; }

.mdi-desktop-tower-monitor::before {
  content: "\FAAA"; }

.mdi-details::before {
  content: "\F1C6"; }

.mdi-dev-to::before {
  content: "\FD4A"; }

.mdi-developer-board::before {
  content: "\F696"; }

.mdi-deviantart::before {
  content: "\F1C7"; }

.mdi-devices::before {
  content: "\FFD0"; }

.mdi-diabetes::before {
  content: "\F0151"; }

.mdi-dialpad::before {
  content: "\F61C"; }

.mdi-diameter::before {
  content: "\FC3F"; }

.mdi-diameter-outline::before {
  content: "\FC40"; }

.mdi-diameter-variant::before {
  content: "\FC41"; }

.mdi-diamond::before {
  content: "\FB66"; }

.mdi-diamond-outline::before {
  content: "\FB67"; }

.mdi-diamond-stone::before {
  content: "\F1C8"; }

.mdi-dice-1::before {
  content: "\F1CA"; }

.mdi-dice-1-outline::before {
  content: "\F0175"; }

.mdi-dice-2::before {
  content: "\F1CB"; }

.mdi-dice-2-outline::before {
  content: "\F0176"; }

.mdi-dice-3::before {
  content: "\F1CC"; }

.mdi-dice-3-outline::before {
  content: "\F0177"; }

.mdi-dice-4::before {
  content: "\F1CD"; }

.mdi-dice-4-outline::before {
  content: "\F0178"; }

.mdi-dice-5::before {
  content: "\F1CE"; }

.mdi-dice-5-outline::before {
  content: "\F0179"; }

.mdi-dice-6::before {
  content: "\F1CF"; }

.mdi-dice-6-outline::before {
  content: "\F017A"; }

.mdi-dice-d10::before {
  content: "\F017E"; }

.mdi-dice-d10-outline::before {
  content: "\F76E"; }

.mdi-dice-d12::before {
  content: "\F017F"; }

.mdi-dice-d12-outline::before {
  content: "\F866"; }

.mdi-dice-d20::before {
  content: "\F0180"; }

.mdi-dice-d20-outline::before {
  content: "\F5EA"; }

.mdi-dice-d4::before {
  content: "\F017B"; }

.mdi-dice-d4-outline::before {
  content: "\F5EB"; }

.mdi-dice-d6::before {
  content: "\F017C"; }

.mdi-dice-d6-outline::before {
  content: "\F5EC"; }

.mdi-dice-d8::before {
  content: "\F017D"; }

.mdi-dice-d8-outline::before {
  content: "\F5ED"; }

.mdi-dice-multiple::before {
  content: "\F76D"; }

.mdi-dice-multiple-outline::before {
  content: "\F0181"; }

.mdi-dictionary::before {
  content: "\F61D"; }

.mdi-digital-ocean::before {
  content: "\F0262"; }

.mdi-dip-switch::before {
  content: "\F7C0"; }

.mdi-directions::before {
  content: "\F1D0"; }

.mdi-directions-fork::before {
  content: "\F641"; }

.mdi-disc::before {
  content: "\F5EE"; }

.mdi-disc-alert::before {
  content: "\F1D1"; }

.mdi-disc-player::before {
  content: "\F95F"; }

.mdi-discord::before {
  content: "\F66F"; }

.mdi-dishwasher::before {
  content: "\FAAB"; }

.mdi-dishwasher-alert::before {
  content: "\F01E3"; }

.mdi-dishwasher-off::before {
  content: "\F01E4"; }

.mdi-disqus::before {
  content: "\F1D2"; }

.mdi-disqus-outline::before {
  content: "\F1D3"; }

.mdi-distribute-horizontal-center::before {
  content: "\F01F4"; }

.mdi-distribute-horizontal-left::before {
  content: "\F01F3"; }

.mdi-distribute-horizontal-right::before {
  content: "\F01F5"; }

.mdi-distribute-vertical-bottom::before {
  content: "\F01F6"; }

.mdi-distribute-vertical-center::before {
  content: "\F01F7"; }

.mdi-distribute-vertical-top::before {
  content: "\F01F8"; }

.mdi-diving-flippers::before {
  content: "\FD9B"; }

.mdi-diving-helmet::before {
  content: "\FD9C"; }

.mdi-diving-scuba::before {
  content: "\FD9D"; }

.mdi-diving-scuba-flag::before {
  content: "\FD9E"; }

.mdi-diving-scuba-tank::before {
  content: "\FD9F"; }

.mdi-diving-scuba-tank-multiple::before {
  content: "\FDA0"; }

.mdi-diving-snorkel::before {
  content: "\FDA1"; }

.mdi-division::before {
  content: "\F1D4"; }

.mdi-division-box::before {
  content: "\F1D5"; }

.mdi-dlna::before {
  content: "\FA40"; }

.mdi-dna::before {
  content: "\F683"; }

.mdi-dns::before {
  content: "\F1D6"; }

.mdi-dns-outline::before {
  content: "\FB68"; }

.mdi-do-not-disturb::before {
  content: "\F697"; }

.mdi-do-not-disturb-off::before {
  content: "\F698"; }

.mdi-dock-bottom::before {
  content: "\F00D4"; }

.mdi-dock-left::before {
  content: "\F00D5"; }

.mdi-dock-right::before {
  content: "\F00D6"; }

.mdi-dock-window::before {
  content: "\F00D7"; }

.mdi-docker::before {
  content: "\F867"; }

.mdi-doctor::before {
  content: "\FA41"; }

.mdi-dog::before {
  content: "\FA42"; }

.mdi-dog-service::before {
  content: "\FAAC"; }

.mdi-dog-side::before {
  content: "\FA43"; }

.mdi-dolby::before {
  content: "\F6B2"; }

.mdi-dolly::before {
  content: "\FEBB"; }

.mdi-domain::before {
  content: "\F1D7"; }

.mdi-domain-off::before {
  content: "\FD4B"; }

.mdi-domain-plus::before {
  content: "\F00D8"; }

.mdi-domain-remove::before {
  content: "\F00D9"; }

.mdi-domino-mask::before {
  content: "\F0045"; }

.mdi-donkey::before {
  content: "\F7C1"; }

.mdi-door::before {
  content: "\F819"; }

.mdi-door-closed::before {
  content: "\F81A"; }

.mdi-door-closed-lock::before {
  content: "\F00DA"; }

.mdi-door-open::before {
  content: "\F81B"; }

.mdi-doorbell::before {
  content: "\F0311"; }

.mdi-doorbell-video::before {
  content: "\F868"; }

.mdi-dot-net::before {
  content: "\FAAD"; }

.mdi-dots-horizontal::before {
  content: "\F1D8"; }

.mdi-dots-horizontal-circle::before {
  content: "\F7C2"; }

.mdi-dots-horizontal-circle-outline::before {
  content: "\FB69"; }

.mdi-dots-vertical::before {
  content: "\F1D9"; }

.mdi-dots-vertical-circle::before {
  content: "\F7C3"; }

.mdi-dots-vertical-circle-outline::before {
  content: "\FB6A"; }

.mdi-douban::before {
  content: "\F699"; }

.mdi-download::before {
  content: "\F1DA"; }

.mdi-download-lock::before {
  content: "\F034B"; }

.mdi-download-lock-outline::before {
  content: "\F034C"; }

.mdi-download-multiple::before {
  content: "\F9E8"; }

.mdi-download-network::before {
  content: "\F6F3"; }

.mdi-download-network-outline::before {
  content: "\FC42"; }

.mdi-download-off::before {
  content: "\F00DB"; }

.mdi-download-off-outline::before {
  content: "\F00DC"; }

.mdi-download-outline::before {
  content: "\FB6B"; }

.mdi-drag::before {
  content: "\F1DB"; }

.mdi-drag-horizontal::before {
  content: "\F1DC"; }

.mdi-drag-horizontal-variant::before {
  content: "\F031B"; }

.mdi-drag-variant::before {
  content: "\FB6C"; }

.mdi-drag-vertical::before {
  content: "\F1DD"; }

.mdi-drag-vertical-variant::before {
  content: "\F031C"; }

.mdi-drama-masks::before {
  content: "\FCDE"; }

.mdi-draw::before {
  content: "\FF66"; }

.mdi-drawing::before {
  content: "\F1DE"; }

.mdi-drawing-box::before {
  content: "\F1DF"; }

.mdi-dresser::before {
  content: "\FF67"; }

.mdi-dresser-outline::before {
  content: "\FF68"; }

.mdi-dribbble::before {
  content: "\F1E0"; }

.mdi-dribbble-box::before {
  content: "\F1E1"; }

.mdi-drone::before {
  content: "\F1E2"; }

.mdi-dropbox::before {
  content: "\F1E3"; }

.mdi-drupal::before {
  content: "\F1E4"; }

.mdi-duck::before {
  content: "\F1E5"; }

.mdi-dumbbell::before {
  content: "\F1E6"; }

.mdi-dump-truck::before {
  content: "\FC43"; }

.mdi-ear-hearing::before {
  content: "\F7C4"; }

.mdi-ear-hearing-off::before {
  content: "\FA44"; }

.mdi-earth::before {
  content: "\F1E7"; }

.mdi-earth-arrow-right::before {
  content: "\F033C"; }

.mdi-earth-box::before {
  content: "\F6CC"; }

.mdi-earth-box-off::before {
  content: "\F6CD"; }

.mdi-earth-off::before {
  content: "\F1E8"; }

.mdi-edge::before {
  content: "\F1E9"; }

.mdi-edge-legacy::before {
  content: "\F027B"; }

.mdi-egg::before {
  content: "\FAAE"; }

.mdi-egg-easter::before {
  content: "\FAAF"; }

.mdi-eight-track::before {
  content: "\F9E9"; }

.mdi-eject::before {
  content: "\F1EA"; }

.mdi-eject-outline::before {
  content: "\FB6D"; }

.mdi-electric-switch::before {
  content: "\FEBC"; }

.mdi-electric-switch-closed::before {
  content: "\F0104"; }

.mdi-electron-framework::before {
  content: "\F0046"; }

.mdi-elephant::before {
  content: "\F7C5"; }

.mdi-elevation-decline::before {
  content: "\F1EB"; }

.mdi-elevation-rise::before {
  content: "\F1EC"; }

.mdi-elevator::before {
  content: "\F1ED"; }

.mdi-elevator-down::before {
  content: "\F02ED"; }

.mdi-elevator-passenger::before {
  content: "\F03AC"; }

.mdi-elevator-up::before {
  content: "\F02EC"; }

.mdi-ellipse::before {
  content: "\FEBD"; }

.mdi-ellipse-outline::before {
  content: "\FEBE"; }

.mdi-email::before {
  content: "\F1EE"; }

.mdi-email-alert::before {
  content: "\F6CE"; }

.mdi-email-alert-outline::before {
  content: "\FD1E"; }

.mdi-email-box::before {
  content: "\FCDF"; }

.mdi-email-check::before {
  content: "\FAB0"; }

.mdi-email-check-outline::before {
  content: "\FAB1"; }

.mdi-email-edit::before {
  content: "\FF00"; }

.mdi-email-edit-outline::before {
  content: "\FF01"; }

.mdi-email-lock::before {
  content: "\F1F1"; }

.mdi-email-mark-as-unread::before {
  content: "\FB6E"; }

.mdi-email-minus::before {
  content: "\FF02"; }

.mdi-email-minus-outline::before {
  content: "\FF03"; }

.mdi-email-multiple::before {
  content: "\FF04"; }

.mdi-email-multiple-outline::before {
  content: "\FF05"; }

.mdi-email-newsletter::before {
  content: "\FFD1"; }

.mdi-email-open::before {
  content: "\F1EF"; }

.mdi-email-open-multiple::before {
  content: "\FF06"; }

.mdi-email-open-multiple-outline::before {
  content: "\FF07"; }

.mdi-email-open-outline::before {
  content: "\F5EF"; }

.mdi-email-outline::before {
  content: "\F1F0"; }

.mdi-email-plus::before {
  content: "\F9EA"; }

.mdi-email-plus-outline::before {
  content: "\F9EB"; }

.mdi-email-receive::before {
  content: "\F0105"; }

.mdi-email-receive-outline::before {
  content: "\F0106"; }

.mdi-email-search::before {
  content: "\F960"; }

.mdi-email-search-outline::before {
  content: "\F961"; }

.mdi-email-send::before {
  content: "\F0107"; }

.mdi-email-send-outline::before {
  content: "\F0108"; }

.mdi-email-sync::before {
  content: "\F02F2"; }

.mdi-email-sync-outline::before {
  content: "\F02F3"; }

.mdi-email-variant::before {
  content: "\F5F0"; }

.mdi-ember::before {
  content: "\FB15"; }

.mdi-emby::before {
  content: "\F6B3"; }

.mdi-emoticon::before {
  content: "\FC44"; }

.mdi-emoticon-angry::before {
  content: "\FC45"; }

.mdi-emoticon-angry-outline::before {
  content: "\FC46"; }

.mdi-emoticon-confused::before {
  content: "\F0109"; }

.mdi-emoticon-confused-outline::before {
  content: "\F010A"; }

.mdi-emoticon-cool::before {
  content: "\FC47"; }

.mdi-emoticon-cool-outline::before {
  content: "\F1F3"; }

.mdi-emoticon-cry::before {
  content: "\FC48"; }

.mdi-emoticon-cry-outline::before {
  content: "\FC49"; }

.mdi-emoticon-dead::before {
  content: "\FC4A"; }

.mdi-emoticon-dead-outline::before {
  content: "\F69A"; }

.mdi-emoticon-devil::before {
  content: "\FC4B"; }

.mdi-emoticon-devil-outline::before {
  content: "\F1F4"; }

.mdi-emoticon-excited::before {
  content: "\FC4C"; }

.mdi-emoticon-excited-outline::before {
  content: "\F69B"; }

.mdi-emoticon-frown::before {
  content: "\FF69"; }

.mdi-emoticon-frown-outline::before {
  content: "\FF6A"; }

.mdi-emoticon-happy::before {
  content: "\FC4D"; }

.mdi-emoticon-happy-outline::before {
  content: "\F1F5"; }

.mdi-emoticon-kiss::before {
  content: "\FC4E"; }

.mdi-emoticon-kiss-outline::before {
  content: "\FC4F"; }

.mdi-emoticon-lol::before {
  content: "\F023F"; }

.mdi-emoticon-lol-outline::before {
  content: "\F0240"; }

.mdi-emoticon-neutral::before {
  content: "\FC50"; }

.mdi-emoticon-neutral-outline::before {
  content: "\F1F6"; }

.mdi-emoticon-outline::before {
  content: "\F1F2"; }

.mdi-emoticon-poop::before {
  content: "\F1F7"; }

.mdi-emoticon-poop-outline::before {
  content: "\FC51"; }

.mdi-emoticon-sad::before {
  content: "\FC52"; }

.mdi-emoticon-sad-outline::before {
  content: "\F1F8"; }

.mdi-emoticon-tongue::before {
  content: "\F1F9"; }

.mdi-emoticon-tongue-outline::before {
  content: "\FC53"; }

.mdi-emoticon-wink::before {
  content: "\FC54"; }

.mdi-emoticon-wink-outline::before {
  content: "\FC55"; }

.mdi-engine::before {
  content: "\F1FA"; }

.mdi-engine-off::before {
  content: "\FA45"; }

.mdi-engine-off-outline::before {
  content: "\FA46"; }

.mdi-engine-outline::before {
  content: "\F1FB"; }

.mdi-epsilon::before {
  content: "\F010B"; }

.mdi-equal::before {
  content: "\F1FC"; }

.mdi-equal-box::before {
  content: "\F1FD"; }

.mdi-equalizer::before {
  content: "\FEBF"; }

.mdi-equalizer-outline::before {
  content: "\FEC0"; }

.mdi-eraser::before {
  content: "\F1FE"; }

.mdi-eraser-variant::before {
  content: "\F642"; }

.mdi-escalator::before {
  content: "\F1FF"; }

.mdi-escalator-down::before {
  content: "\F02EB"; }

.mdi-escalator-up::before {
  content: "\F02EA"; }

.mdi-eslint::before {
  content: "\FC56"; }

.mdi-et::before {
  content: "\FAB2"; }

.mdi-ethereum::before {
  content: "\F869"; }

.mdi-ethernet::before {
  content: "\F200"; }

.mdi-ethernet-cable::before {
  content: "\F201"; }

.mdi-ethernet-cable-off::before {
  content: "\F202"; }

.mdi-etsy::before {
  content: "\F203"; }

.mdi-ev-station::before {
  content: "\F5F1"; }

.mdi-eventbrite::before {
  content: "\F7C6"; }

.mdi-evernote::before {
  content: "\F204"; }

.mdi-excavator::before {
  content: "\F0047"; }

.mdi-exclamation::before {
  content: "\F205"; }

.mdi-exclamation-thick::before {
  content: "\F0263"; }

.mdi-exit-run::before {
  content: "\FA47"; }

.mdi-exit-to-app::before {
  content: "\F206"; }

.mdi-expand-all::before {
  content: "\FAB3"; }

.mdi-expand-all-outline::before {
  content: "\FAB4"; }

.mdi-expansion-card::before {
  content: "\F8AD"; }

.mdi-expansion-card-variant::before {
  content: "\FFD2"; }

.mdi-exponent::before {
  content: "\F962"; }

.mdi-exponent-box::before {
  content: "\F963"; }

.mdi-export::before {
  content: "\F207"; }

.mdi-export-variant::before {
  content: "\FB6F"; }

.mdi-eye::before {
  content: "\F208"; }

.mdi-eye-check::before {
  content: "\FCE0"; }

.mdi-eye-check-outline::before {
  content: "\FCE1"; }

.mdi-eye-circle::before {
  content: "\FB70"; }

.mdi-eye-circle-outline::before {
  content: "\FB71"; }

.mdi-eye-minus::before {
  content: "\F0048"; }

.mdi-eye-minus-outline::before {
  content: "\F0049"; }

.mdi-eye-off::before {
  content: "\F209"; }

.mdi-eye-off-outline::before {
  content: "\F6D0"; }

.mdi-eye-outline::before {
  content: "\F6CF"; }

.mdi-eye-plus::before {
  content: "\F86A"; }

.mdi-eye-plus-outline::before {
  content: "\F86B"; }

.mdi-eye-settings::before {
  content: "\F86C"; }

.mdi-eye-settings-outline::before {
  content: "\F86D"; }

.mdi-eyedropper::before {
  content: "\F20A"; }

.mdi-eyedropper-variant::before {
  content: "\F20B"; }

.mdi-face::before {
  content: "\F643"; }

.mdi-face-agent::before {
  content: "\FD4C"; }

.mdi-face-outline::before {
  content: "\FB72"; }

.mdi-face-profile::before {
  content: "\F644"; }

.mdi-face-profile-woman::before {
  content: "\F00A1"; }

.mdi-face-recognition::before {
  content: "\FC57"; }

.mdi-face-woman::before {
  content: "\F00A2"; }

.mdi-face-woman-outline::before {
  content: "\F00A3"; }

.mdi-facebook::before {
  content: "\F20C"; }

.mdi-facebook-box::before {
  content: "\F20D"; }

.mdi-facebook-messenger::before {
  content: "\F20E"; }

.mdi-facebook-workplace::before {
  content: "\FB16"; }

.mdi-factory::before {
  content: "\F20F"; }

.mdi-fan::before {
  content: "\F210"; }

.mdi-fan-off::before {
  content: "\F81C"; }

.mdi-fast-forward::before {
  content: "\F211"; }

.mdi-fast-forward-10::before {
  content: "\FD4D"; }

.mdi-fast-forward-30::before {
  content: "\FCE2"; }

.mdi-fast-forward-5::before {
  content: "\F0223"; }

.mdi-fast-forward-outline::before {
  content: "\F6D1"; }

.mdi-fax::before {
  content: "\F212"; }

.mdi-feather::before {
  content: "\F6D2"; }

.mdi-feature-search::before {
  content: "\FA48"; }

.mdi-feature-search-outline::before {
  content: "\FA49"; }

.mdi-fedora::before {
  content: "\F8DA"; }

.mdi-ferris-wheel::before {
  content: "\FEC1"; }

.mdi-ferry::before {
  content: "\F213"; }

.mdi-file::before {
  content: "\F214"; }

.mdi-file-account::before {
  content: "\F73A"; }

.mdi-file-account-outline::before {
  content: "\F004A"; }

.mdi-file-alert::before {
  content: "\FA4A"; }

.mdi-file-alert-outline::before {
  content: "\FA4B"; }

.mdi-file-cabinet::before {
  content: "\FAB5"; }

.mdi-file-cad::before {
  content: "\FF08"; }

.mdi-file-cad-box::before {
  content: "\FF09"; }

.mdi-file-cancel::before {
  content: "\FDA2"; }

.mdi-file-cancel-outline::before {
  content: "\FDA3"; }

.mdi-file-certificate::before {
  content: "\F01B1"; }

.mdi-file-certificate-outline::before {
  content: "\F01B2"; }

.mdi-file-chart::before {
  content: "\F215"; }

.mdi-file-chart-outline::before {
  content: "\F004B"; }

.mdi-file-check::before {
  content: "\F216"; }

.mdi-file-check-outline::before {
  content: "\FE7B"; }

.mdi-file-clock::before {
  content: "\F030C"; }

.mdi-file-clock-outline::before {
  content: "\F030D"; }

.mdi-file-cloud::before {
  content: "\F217"; }

.mdi-file-cloud-outline::before {
  content: "\F004C"; }

.mdi-file-code::before {
  content: "\F22E"; }

.mdi-file-code-outline::before {
  content: "\F004D"; }

.mdi-file-compare::before {
  content: "\F8A9"; }

.mdi-file-delimited::before {
  content: "\F218"; }

.mdi-file-delimited-outline::before {
  content: "\FEC2"; }

.mdi-file-document::before {
  content: "\F219"; }

.mdi-file-document-box::before {
  content: "\F21A"; }

.mdi-file-document-box-check::before {
  content: "\FEC3"; }

.mdi-file-document-box-check-outline::before {
  content: "\FEC4"; }

.mdi-file-document-box-minus::before {
  content: "\FEC5"; }

.mdi-file-document-box-minus-outline::before {
  content: "\FEC6"; }

.mdi-file-document-box-multiple::before {
  content: "\FAB6"; }

.mdi-file-document-box-multiple-outline::before {
  content: "\FAB7"; }

.mdi-file-document-box-outline::before {
  content: "\F9EC"; }

.mdi-file-document-box-plus::before {
  content: "\FEC7"; }

.mdi-file-document-box-plus-outline::before {
  content: "\FEC8"; }

.mdi-file-document-box-remove::before {
  content: "\FEC9"; }

.mdi-file-document-box-remove-outline::before {
  content: "\FECA"; }

.mdi-file-document-box-search::before {
  content: "\FECB"; }

.mdi-file-document-box-search-outline::before {
  content: "\FECC"; }

.mdi-file-document-edit::before {
  content: "\FDA4"; }

.mdi-file-document-edit-outline::before {
  content: "\FDA5"; }

.mdi-file-document-outline::before {
  content: "\F9ED"; }

.mdi-file-download::before {
  content: "\F964"; }

.mdi-file-download-outline::before {
  content: "\F965"; }

.mdi-file-edit::before {
  content: "\F0212"; }

.mdi-file-edit-outline::before {
  content: "\F0213"; }

.mdi-file-excel::before {
  content: "\F21B"; }

.mdi-file-excel-box::before {
  content: "\F21C"; }

.mdi-file-excel-box-outline::before {
  content: "\F004E"; }

.mdi-file-excel-outline::before {
  content: "\F004F"; }

.mdi-file-export::before {
  content: "\F21D"; }

.mdi-file-export-outline::before {
  content: "\F0050"; }

.mdi-file-eye::before {
  content: "\FDA6"; }

.mdi-file-eye-outline::before {
  content: "\FDA7"; }

.mdi-file-find::before {
  content: "\F21E"; }

.mdi-file-find-outline::before {
  content: "\FB73"; }

.mdi-file-hidden::before {
  content: "\F613"; }

.mdi-file-image::before {
  content: "\F21F"; }

.mdi-file-image-outline::before {
  content: "\FECD"; }

.mdi-file-import::before {
  content: "\F220"; }

.mdi-file-import-outline::before {
  content: "\F0051"; }

.mdi-file-key::before {
  content: "\F01AF"; }

.mdi-file-key-outline::before {
  content: "\F01B0"; }

.mdi-file-link::before {
  content: "\F01A2"; }

.mdi-file-link-outline::before {
  content: "\F01A3"; }

.mdi-file-lock::before {
  content: "\F221"; }

.mdi-file-lock-outline::before {
  content: "\F0052"; }

.mdi-file-move::before {
  content: "\FAB8"; }

.mdi-file-move-outline::before {
  content: "\F0053"; }

.mdi-file-multiple::before {
  content: "\F222"; }

.mdi-file-multiple-outline::before {
  content: "\F0054"; }

.mdi-file-music::before {
  content: "\F223"; }

.mdi-file-music-outline::before {
  content: "\FE7C"; }

.mdi-file-outline::before {
  content: "\F224"; }

.mdi-file-pdf::before {
  content: "\F225"; }

.mdi-file-pdf-box::before {
  content: "\F226"; }

.mdi-file-pdf-box-outline::before {
  content: "\FFD3"; }

.mdi-file-pdf-outline::before {
  content: "\FE7D"; }

.mdi-file-percent::before {
  content: "\F81D"; }

.mdi-file-percent-outline::before {
  content: "\F0055"; }

.mdi-file-phone::before {
  content: "\F01A4"; }

.mdi-file-phone-outline::before {
  content: "\F01A5"; }

.mdi-file-plus::before {
  content: "\F751"; }

.mdi-file-plus-outline::before {
  content: "\FF0A"; }

.mdi-file-powerpoint::before {
  content: "\F227"; }

.mdi-file-powerpoint-box::before {
  content: "\F228"; }

.mdi-file-powerpoint-box-outline::before {
  content: "\F0056"; }

.mdi-file-powerpoint-outline::before {
  content: "\F0057"; }

.mdi-file-presentation-box::before {
  content: "\F229"; }

.mdi-file-question::before {
  content: "\F86E"; }

.mdi-file-question-outline::before {
  content: "\F0058"; }

.mdi-file-remove::before {
  content: "\FB74"; }

.mdi-file-remove-outline::before {
  content: "\F0059"; }

.mdi-file-replace::before {
  content: "\FB17"; }

.mdi-file-replace-outline::before {
  content: "\FB18"; }

.mdi-file-restore::before {
  content: "\F670"; }

.mdi-file-restore-outline::before {
  content: "\F005A"; }

.mdi-file-search::before {
  content: "\FC58"; }

.mdi-file-search-outline::before {
  content: "\FC59"; }

.mdi-file-send::before {
  content: "\F22A"; }

.mdi-file-send-outline::before {
  content: "\F005B"; }

.mdi-file-settings::before {
  content: "\F00A4"; }

.mdi-file-settings-outline::before {
  content: "\F00A5"; }

.mdi-file-settings-variant::before {
  content: "\F00A6"; }

.mdi-file-settings-variant-outline::before {
  content: "\F00A7"; }

.mdi-file-star::before {
  content: "\F005C"; }

.mdi-file-star-outline::before {
  content: "\F005D"; }

.mdi-file-swap::before {
  content: "\FFD4"; }

.mdi-file-swap-outline::before {
  content: "\FFD5"; }

.mdi-file-sync::before {
  content: "\F0241"; }

.mdi-file-sync-outline::before {
  content: "\F0242"; }

.mdi-file-table::before {
  content: "\FC5A"; }

.mdi-file-table-box::before {
  content: "\F010C"; }

.mdi-file-table-box-multiple::before {
  content: "\F010D"; }

.mdi-file-table-box-multiple-outline::before {
  content: "\F010E"; }

.mdi-file-table-box-outline::before {
  content: "\F010F"; }

.mdi-file-table-outline::before {
  content: "\FC5B"; }

.mdi-file-tree::before {
  content: "\F645"; }

.mdi-file-undo::before {
  content: "\F8DB"; }

.mdi-file-undo-outline::before {
  content: "\F005E"; }

.mdi-file-upload::before {
  content: "\FA4C"; }

.mdi-file-upload-outline::before {
  content: "\FA4D"; }

.mdi-file-video::before {
  content: "\F22B"; }

.mdi-file-video-outline::before {
  content: "\FE10"; }

.mdi-file-word::before {
  content: "\F22C"; }

.mdi-file-word-box::before {
  content: "\F22D"; }

.mdi-file-word-box-outline::before {
  content: "\F005F"; }

.mdi-file-word-outline::before {
  content: "\F0060"; }

.mdi-film::before {
  content: "\F22F"; }

.mdi-filmstrip::before {
  content: "\F230"; }

.mdi-filmstrip-off::before {
  content: "\F231"; }

.mdi-filter::before {
  content: "\F232"; }

.mdi-filter-menu::before {
  content: "\F0110"; }

.mdi-filter-menu-outline::before {
  content: "\F0111"; }

.mdi-filter-minus::before {
  content: "\FF0B"; }

.mdi-filter-minus-outline::before {
  content: "\FF0C"; }

.mdi-filter-outline::before {
  content: "\F233"; }

.mdi-filter-plus::before {
  content: "\FF0D"; }

.mdi-filter-plus-outline::before {
  content: "\FF0E"; }

.mdi-filter-remove::before {
  content: "\F234"; }

.mdi-filter-remove-outline::before {
  content: "\F235"; }

.mdi-filter-variant::before {
  content: "\F236"; }

.mdi-filter-variant-minus::before {
  content: "\F013D"; }

.mdi-filter-variant-plus::before {
  content: "\F013E"; }

.mdi-filter-variant-remove::before {
  content: "\F0061"; }

.mdi-finance::before {
  content: "\F81E"; }

.mdi-find-replace::before {
  content: "\F6D3"; }

.mdi-fingerprint::before {
  content: "\F237"; }

.mdi-fingerprint-off::before {
  content: "\FECE"; }

.mdi-fire::before {
  content: "\F238"; }

.mdi-fire-extinguisher::before {
  content: "\FF0F"; }

.mdi-fire-hydrant::before {
  content: "\F0162"; }

.mdi-fire-hydrant-alert::before {
  content: "\F0163"; }

.mdi-fire-hydrant-off::before {
  content: "\F0164"; }

.mdi-fire-truck::before {
  content: "\F8AA"; }

.mdi-firebase::before {
  content: "\F966"; }

.mdi-firefox::before {
  content: "\F239"; }

.mdi-fireplace::before {
  content: "\FE11"; }

.mdi-fireplace-off::before {
  content: "\FE12"; }

.mdi-firework::before {
  content: "\FE13"; }

.mdi-fish::before {
  content: "\F23A"; }

.mdi-fishbowl::before {
  content: "\FF10"; }

.mdi-fishbowl-outline::before {
  content: "\FF11"; }

.mdi-fit-to-page::before {
  content: "\FF12"; }

.mdi-fit-to-page-outline::before {
  content: "\FF13"; }

.mdi-flag::before {
  content: "\F23B"; }

.mdi-flag-checkered::before {
  content: "\F23C"; }

.mdi-flag-minus::before {
  content: "\FB75"; }

.mdi-flag-minus-outline::before {
  content: "\F00DD"; }

.mdi-flag-outline::before {
  content: "\F23D"; }

.mdi-flag-plus::before {
  content: "\FB76"; }

.mdi-flag-plus-outline::before {
  content: "\F00DE"; }

.mdi-flag-remove::before {
  content: "\FB77"; }

.mdi-flag-remove-outline::before {
  content: "\F00DF"; }

.mdi-flag-triangle::before {
  content: "\F23F"; }

.mdi-flag-variant::before {
  content: "\F240"; }

.mdi-flag-variant-outline::before {
  content: "\F23E"; }

.mdi-flare::before {
  content: "\FD4E"; }

.mdi-flash::before {
  content: "\F241"; }

.mdi-flash-alert::before {
  content: "\FF14"; }

.mdi-flash-alert-outline::before {
  content: "\FF15"; }

.mdi-flash-auto::before {
  content: "\F242"; }

.mdi-flash-circle::before {
  content: "\F81F"; }

.mdi-flash-off::before {
  content: "\F243"; }

.mdi-flash-outline::before {
  content: "\F6D4"; }

.mdi-flash-red-eye::before {
  content: "\F67A"; }

.mdi-flashlight::before {
  content: "\F244"; }

.mdi-flashlight-off::before {
  content: "\F245"; }

.mdi-flask::before {
  content: "\F093"; }

.mdi-flask-empty::before {
  content: "\F094"; }

.mdi-flask-empty-minus::before {
  content: "\F0265"; }

.mdi-flask-empty-minus-outline::before {
  content: "\F0266"; }

.mdi-flask-empty-outline::before {
  content: "\F095"; }

.mdi-flask-empty-plus::before {
  content: "\F0267"; }

.mdi-flask-empty-plus-outline::before {
  content: "\F0268"; }

.mdi-flask-empty-remove::before {
  content: "\F0269"; }

.mdi-flask-empty-remove-outline::before {
  content: "\F026A"; }

.mdi-flask-minus::before {
  content: "\F026B"; }

.mdi-flask-minus-outline::before {
  content: "\F026C"; }

.mdi-flask-outline::before {
  content: "\F096"; }

.mdi-flask-plus::before {
  content: "\F026D"; }

.mdi-flask-plus-outline::before {
  content: "\F026E"; }

.mdi-flask-remove::before {
  content: "\F026F"; }

.mdi-flask-remove-outline::before {
  content: "\F0270"; }

.mdi-flask-round-bottom::before {
  content: "\F0276"; }

.mdi-flask-round-bottom-empty::before {
  content: "\F0277"; }

.mdi-flask-round-bottom-empty-outline::before {
  content: "\F0278"; }

.mdi-flask-round-bottom-outline::before {
  content: "\F0279"; }

.mdi-flattr::before {
  content: "\F246"; }

.mdi-fleur-de-lis::before {
  content: "\F032E"; }

.mdi-flickr::before {
  content: "\FCE3"; }

.mdi-flip-horizontal::before {
  content: "\F0112"; }

.mdi-flip-to-back::before {
  content: "\F247"; }

.mdi-flip-to-front::before {
  content: "\F248"; }

.mdi-flip-vertical::before {
  content: "\F0113"; }

.mdi-floor-lamp::before {
  content: "\F8DC"; }

.mdi-floor-lamp-dual::before {
  content: "\F0062"; }

.mdi-floor-lamp-variant::before {
  content: "\F0063"; }

.mdi-floor-plan::before {
  content: "\F820"; }

.mdi-floppy::before {
  content: "\F249"; }

.mdi-floppy-variant::before {
  content: "\F9EE"; }

.mdi-flower::before {
  content: "\F24A"; }

.mdi-flower-outline::before {
  content: "\F9EF"; }

.mdi-flower-poppy::before {
  content: "\FCE4"; }

.mdi-flower-tulip::before {
  content: "\F9F0"; }

.mdi-flower-tulip-outline::before {
  content: "\F9F1"; }

.mdi-focus-auto::before {
  content: "\FF6B"; }

.mdi-focus-field::before {
  content: "\FF6C"; }

.mdi-focus-field-horizontal::before {
  content: "\FF6D"; }

.mdi-focus-field-vertical::before {
  content: "\FF6E"; }

.mdi-folder::before {
  content: "\F24B"; }

.mdi-folder-account::before {
  content: "\F24C"; }

.mdi-folder-account-outline::before {
  content: "\FB78"; }

.mdi-folder-alert::before {
  content: "\FDA8"; }

.mdi-folder-alert-outline::before {
  content: "\FDA9"; }

.mdi-folder-clock::before {
  content: "\FAB9"; }

.mdi-folder-clock-outline::before {
  content: "\FABA"; }

.mdi-folder-download::before {
  content: "\F24D"; }

.mdi-folder-download-outline::before {
  content: "\F0114"; }

.mdi-folder-edit::before {
  content: "\F8DD"; }

.mdi-folder-edit-outline::before {
  content: "\FDAA"; }

.mdi-folder-google-drive::before {
  content: "\F24E"; }

.mdi-folder-heart::before {
  content: "\F0115"; }

.mdi-folder-heart-outline::before {
  content: "\F0116"; }

.mdi-folder-home::before {
  content: "\F00E0"; }

.mdi-folder-home-outline::before {
  content: "\F00E1"; }

.mdi-folder-image::before {
  content: "\F24F"; }

.mdi-folder-information::before {
  content: "\F00E2"; }

.mdi-folder-information-outline::before {
  content: "\F00E3"; }

.mdi-folder-key::before {
  content: "\F8AB"; }

.mdi-folder-key-network::before {
  content: "\F8AC"; }

.mdi-folder-key-network-outline::before {
  content: "\FC5C"; }

.mdi-folder-key-outline::before {
  content: "\F0117"; }

.mdi-folder-lock::before {
  content: "\F250"; }

.mdi-folder-lock-open::before {
  content: "\F251"; }

.mdi-folder-marker::before {
  content: "\F0298"; }

.mdi-folder-marker-outline::before {
  content: "\F0299"; }

.mdi-folder-move::before {
  content: "\F252"; }

.mdi-folder-move-outline::before {
  content: "\F0271"; }

.mdi-folder-multiple::before {
  content: "\F253"; }

.mdi-folder-multiple-image::before {
  content: "\F254"; }

.mdi-folder-multiple-outline::before {
  content: "\F255"; }

.mdi-folder-music::before {
  content: "\F0384"; }

.mdi-folder-music-outline::before {
  content: "\F0385"; }

.mdi-folder-network::before {
  content: "\F86F"; }

.mdi-folder-network-outline::before {
  content: "\FC5D"; }

.mdi-folder-open::before {
  content: "\F76F"; }

.mdi-folder-open-outline::before {
  content: "\FDAB"; }

.mdi-folder-outline::before {
  content: "\F256"; }

.mdi-folder-plus::before {
  content: "\F257"; }

.mdi-folder-plus-outline::before {
  content: "\FB79"; }

.mdi-folder-pound::before {
  content: "\FCE5"; }

.mdi-folder-pound-outline::before {
  content: "\FCE6"; }

.mdi-folder-remove::before {
  content: "\F258"; }

.mdi-folder-remove-outline::before {
  content: "\FB7A"; }

.mdi-folder-search::before {
  content: "\F967"; }

.mdi-folder-search-outline::before {
  content: "\F968"; }

.mdi-folder-settings::before {
  content: "\F00A8"; }

.mdi-folder-settings-outline::before {
  content: "\F00A9"; }

.mdi-folder-settings-variant::before {
  content: "\F00AA"; }

.mdi-folder-settings-variant-outline::before {
  content: "\F00AB"; }

.mdi-folder-star::before {
  content: "\F69C"; }

.mdi-folder-star-outline::before {
  content: "\FB7B"; }

.mdi-folder-swap::before {
  content: "\FFD6"; }

.mdi-folder-swap-outline::before {
  content: "\FFD7"; }

.mdi-folder-sync::before {
  content: "\FCE7"; }

.mdi-folder-sync-outline::before {
  content: "\FCE8"; }

.mdi-folder-table::before {
  content: "\F030E"; }

.mdi-folder-table-outline::before {
  content: "\F030F"; }

.mdi-folder-text::before {
  content: "\FC5E"; }

.mdi-folder-text-outline::before {
  content: "\FC5F"; }

.mdi-folder-upload::before {
  content: "\F259"; }

.mdi-folder-upload-outline::before {
  content: "\F0118"; }

.mdi-folder-zip::before {
  content: "\F6EA"; }

.mdi-folder-zip-outline::before {
  content: "\F7B8"; }

.mdi-font-awesome::before {
  content: "\F03A"; }

.mdi-food::before {
  content: "\F25A"; }

.mdi-food-apple::before {
  content: "\F25B"; }

.mdi-food-apple-outline::before {
  content: "\FC60"; }

.mdi-food-croissant::before {
  content: "\F7C7"; }

.mdi-food-fork-drink::before {
  content: "\F5F2"; }

.mdi-food-off::before {
  content: "\F5F3"; }

.mdi-food-variant::before {
  content: "\F25C"; }

.mdi-foot-print::before {
  content: "\FF6F"; }

.mdi-football::before {
  content: "\F25D"; }

.mdi-football-australian::before {
  content: "\F25E"; }

.mdi-football-helmet::before {
  content: "\F25F"; }

.mdi-forklift::before {
  content: "\F7C8"; }

.mdi-format-align-bottom::before {
  content: "\F752"; }

.mdi-format-align-center::before {
  content: "\F260"; }

.mdi-format-align-justify::before {
  content: "\F261"; }

.mdi-format-align-left::before {
  content: "\F262"; }

.mdi-format-align-middle::before {
  content: "\F753"; }

.mdi-format-align-right::before {
  content: "\F263"; }

.mdi-format-align-top::before {
  content: "\F754"; }

.mdi-format-annotation-minus::before {
  content: "\FABB"; }

.mdi-format-annotation-plus::before {
  content: "\F646"; }

.mdi-format-bold::before {
  content: "\F264"; }

.mdi-format-clear::before {
  content: "\F265"; }

.mdi-format-color-fill::before {
  content: "\F266"; }

.mdi-format-color-highlight::before {
  content: "\FE14"; }

.mdi-format-color-marker-cancel::before {
  content: "\F033E"; }

.mdi-format-color-text::before {
  content: "\F69D"; }

.mdi-format-columns::before {
  content: "\F8DE"; }

.mdi-format-float-center::before {
  content: "\F267"; }

.mdi-format-float-left::before {
  content: "\F268"; }

.mdi-format-float-none::before {
  content: "\F269"; }

.mdi-format-float-right::before {
  content: "\F26A"; }

.mdi-format-font::before {
  content: "\F6D5"; }

.mdi-format-font-size-decrease::before {
  content: "\F9F2"; }

.mdi-format-font-size-increase::before {
  content: "\F9F3"; }

.mdi-format-header-1::before {
  content: "\F26B"; }

.mdi-format-header-2::before {
  content: "\F26C"; }

.mdi-format-header-3::before {
  content: "\F26D"; }

.mdi-format-header-4::before {
  content: "\F26E"; }

.mdi-format-header-5::before {
  content: "\F26F"; }

.mdi-format-header-6::before {
  content: "\F270"; }

.mdi-format-header-decrease::before {
  content: "\F271"; }

.mdi-format-header-equal::before {
  content: "\F272"; }

.mdi-format-header-increase::before {
  content: "\F273"; }

.mdi-format-header-pound::before {
  content: "\F274"; }

.mdi-format-horizontal-align-center::before {
  content: "\F61E"; }

.mdi-format-horizontal-align-left::before {
  content: "\F61F"; }

.mdi-format-horizontal-align-right::before {
  content: "\F620"; }

.mdi-format-indent-decrease::before {
  content: "\F275"; }

.mdi-format-indent-increase::before {
  content: "\F276"; }

.mdi-format-italic::before {
  content: "\F277"; }

.mdi-format-letter-case::before {
  content: "\FB19"; }

.mdi-format-letter-case-lower::before {
  content: "\FB1A"; }

.mdi-format-letter-case-upper::before {
  content: "\FB1B"; }

.mdi-format-letter-ends-with::before {
  content: "\FFD8"; }

.mdi-format-letter-matches::before {
  content: "\FFD9"; }

.mdi-format-letter-starts-with::before {
  content: "\FFDA"; }

.mdi-format-line-spacing::before {
  content: "\F278"; }

.mdi-format-line-style::before {
  content: "\F5C8"; }

.mdi-format-line-weight::before {
  content: "\F5C9"; }

.mdi-format-list-bulleted::before {
  content: "\F279"; }

.mdi-format-list-bulleted-square::before {
  content: "\FDAC"; }

.mdi-format-list-bulleted-triangle::before {
  content: "\FECF"; }

.mdi-format-list-bulleted-type::before {
  content: "\F27A"; }

.mdi-format-list-checkbox::before {
  content: "\F969"; }

.mdi-format-list-checks::before {
  content: "\F755"; }

.mdi-format-list-numbered::before {
  content: "\F27B"; }

.mdi-format-list-numbered-rtl::before {
  content: "\FCE9"; }

.mdi-format-list-text::before {
  content: "\F029A"; }

.mdi-format-overline::before {
  content: "\FED0"; }

.mdi-format-page-break::before {
  content: "\F6D6"; }

.mdi-format-paint::before {
  content: "\F27C"; }

.mdi-format-paragraph::before {
  content: "\F27D"; }

.mdi-format-pilcrow::before {
  content: "\F6D7"; }

.mdi-format-quote-close::before {
  content: "\F27E"; }

.mdi-format-quote-close-outline::before {
  content: "\F01D3"; }

.mdi-format-quote-open::before {
  content: "\F756"; }

.mdi-format-quote-open-outline::before {
  content: "\F01D2"; }

.mdi-format-rotate-90::before {
  content: "\F6A9"; }

.mdi-format-section::before {
  content: "\F69E"; }

.mdi-format-size::before {
  content: "\F27F"; }

.mdi-format-strikethrough::before {
  content: "\F280"; }

.mdi-format-strikethrough-variant::before {
  content: "\F281"; }

.mdi-format-subscript::before {
  content: "\F282"; }

.mdi-format-superscript::before {
  content: "\F283"; }

.mdi-format-text::before {
  content: "\F284"; }

.mdi-format-text-rotation-angle-down::before {
  content: "\FFDB"; }

.mdi-format-text-rotation-angle-up::before {
  content: "\FFDC"; }

.mdi-format-text-rotation-down::before {
  content: "\FD4F"; }

.mdi-format-text-rotation-down-vertical::before {
  content: "\FFDD"; }

.mdi-format-text-rotation-none::before {
  content: "\FD50"; }

.mdi-format-text-rotation-up::before {
  content: "\FFDE"; }

.mdi-format-text-rotation-vertical::before {
  content: "\FFDF"; }

.mdi-format-text-variant::before {
  content: "\FE15"; }

.mdi-format-text-wrapping-clip::before {
  content: "\FCEA"; }

.mdi-format-text-wrapping-overflow::before {
  content: "\FCEB"; }

.mdi-format-text-wrapping-wrap::before {
  content: "\FCEC"; }

.mdi-format-textbox::before {
  content: "\FCED"; }

.mdi-format-textdirection-l-to-r::before {
  content: "\F285"; }

.mdi-format-textdirection-r-to-l::before {
  content: "\F286"; }

.mdi-format-title::before {
  content: "\F5F4"; }

.mdi-format-underline::before {
  content: "\F287"; }

.mdi-format-vertical-align-bottom::before {
  content: "\F621"; }

.mdi-format-vertical-align-center::before {
  content: "\F622"; }

.mdi-format-vertical-align-top::before {
  content: "\F623"; }

.mdi-format-wrap-inline::before {
  content: "\F288"; }

.mdi-format-wrap-square::before {
  content: "\F289"; }

.mdi-format-wrap-tight::before {
  content: "\F28A"; }

.mdi-format-wrap-top-bottom::before {
  content: "\F28B"; }

.mdi-forum::before {
  content: "\F28C"; }

.mdi-forum-outline::before {
  content: "\F821"; }

.mdi-forward::before {
  content: "\F28D"; }

.mdi-forwardburger::before {
  content: "\FD51"; }

.mdi-fountain::before {
  content: "\F96A"; }

.mdi-fountain-pen::before {
  content: "\FCEE"; }

.mdi-fountain-pen-tip::before {
  content: "\FCEF"; }

.mdi-foursquare::before {
  content: "\F28E"; }

.mdi-freebsd::before {
  content: "\F8DF"; }

.mdi-frequently-asked-questions::before {
  content: "\FED1"; }

.mdi-fridge::before {
  content: "\F290"; }

.mdi-fridge-alert::before {
  content: "\F01DC"; }

.mdi-fridge-alert-outline::before {
  content: "\F01DD"; }

.mdi-fridge-bottom::before {
  content: "\F292"; }

.mdi-fridge-off::before {
  content: "\F01DA"; }

.mdi-fridge-off-outline::before {
  content: "\F01DB"; }

.mdi-fridge-outline::before {
  content: "\F28F"; }

.mdi-fridge-top::before {
  content: "\F291"; }

.mdi-fruit-cherries::before {
  content: "\F0064"; }

.mdi-fruit-citrus::before {
  content: "\F0065"; }

.mdi-fruit-grapes::before {
  content: "\F0066"; }

.mdi-fruit-grapes-outline::before {
  content: "\F0067"; }

.mdi-fruit-pineapple::before {
  content: "\F0068"; }

.mdi-fruit-watermelon::before {
  content: "\F0069"; }

.mdi-fuel::before {
  content: "\F7C9"; }

.mdi-fullscreen::before {
  content: "\F293"; }

.mdi-fullscreen-exit::before {
  content: "\F294"; }

.mdi-function::before {
  content: "\F295"; }

.mdi-function-variant::before {
  content: "\F870"; }

.mdi-furigana-horizontal::before {
  content: "\F00AC"; }

.mdi-furigana-vertical::before {
  content: "\F00AD"; }

.mdi-fuse::before {
  content: "\FC61"; }

.mdi-fuse-blade::before {
  content: "\FC62"; }

.mdi-gamepad::before {
  content: "\F296"; }

.mdi-gamepad-circle::before {
  content: "\FE16"; }

.mdi-gamepad-circle-down::before {
  content: "\FE17"; }

.mdi-gamepad-circle-left::before {
  content: "\FE18"; }

.mdi-gamepad-circle-outline::before {
  content: "\FE19"; }

.mdi-gamepad-circle-right::before {
  content: "\FE1A"; }

.mdi-gamepad-circle-up::before {
  content: "\FE1B"; }

.mdi-gamepad-down::before {
  content: "\FE1C"; }

.mdi-gamepad-left::before {
  content: "\FE1D"; }

.mdi-gamepad-right::before {
  content: "\FE1E"; }

.mdi-gamepad-round::before {
  content: "\FE1F"; }

.mdi-gamepad-round-down::before {
  content: "\FE7E"; }

.mdi-gamepad-round-left::before {
  content: "\FE7F"; }

.mdi-gamepad-round-outline::before {
  content: "\FE80"; }

.mdi-gamepad-round-right::before {
  content: "\FE81"; }

.mdi-gamepad-round-up::before {
  content: "\FE82"; }

.mdi-gamepad-square::before {
  content: "\FED2"; }

.mdi-gamepad-square-outline::before {
  content: "\FED3"; }

.mdi-gamepad-up::before {
  content: "\FE83"; }

.mdi-gamepad-variant::before {
  content: "\F297"; }

.mdi-gamepad-variant-outline::before {
  content: "\FED4"; }

.mdi-gamma::before {
  content: "\F0119"; }

.mdi-gantry-crane::before {
  content: "\FDAD"; }

.mdi-garage::before {
  content: "\F6D8"; }

.mdi-garage-alert::before {
  content: "\F871"; }

.mdi-garage-alert-variant::before {
  content: "\F0300"; }

.mdi-garage-open::before {
  content: "\F6D9"; }

.mdi-garage-open-variant::before {
  content: "\F02FF"; }

.mdi-garage-variant::before {
  content: "\F02FE"; }

.mdi-gas-cylinder::before {
  content: "\F647"; }

.mdi-gas-station::before {
  content: "\F298"; }

.mdi-gas-station-outline::before {
  content: "\FED5"; }

.mdi-gate::before {
  content: "\F299"; }

.mdi-gate-and::before {
  content: "\F8E0"; }

.mdi-gate-arrow-right::before {
  content: "\F0194"; }

.mdi-gate-nand::before {
  content: "\F8E1"; }

.mdi-gate-nor::before {
  content: "\F8E2"; }

.mdi-gate-not::before {
  content: "\F8E3"; }

.mdi-gate-open::before {
  content: "\F0195"; }

.mdi-gate-or::before {
  content: "\F8E4"; }

.mdi-gate-xnor::before {
  content: "\F8E5"; }

.mdi-gate-xor::before {
  content: "\F8E6"; }

.mdi-gatsby::before {
  content: "\FE84"; }

.mdi-gauge::before {
  content: "\F29A"; }

.mdi-gauge-empty::before {
  content: "\F872"; }

.mdi-gauge-full::before {
  content: "\F873"; }

.mdi-gauge-low::before {
  content: "\F874"; }

.mdi-gavel::before {
  content: "\F29B"; }

.mdi-gender-female::before {
  content: "\F29C"; }

.mdi-gender-male::before {
  content: "\F29D"; }

.mdi-gender-male-female::before {
  content: "\F29E"; }

.mdi-gender-male-female-variant::before {
  content: "\F016A"; }

.mdi-gender-non-binary::before {
  content: "\F016B"; }

.mdi-gender-transgender::before {
  content: "\F29F"; }

.mdi-gentoo::before {
  content: "\F8E7"; }

.mdi-gesture::before {
  content: "\F7CA"; }

.mdi-gesture-double-tap::before {
  content: "\F73B"; }

.mdi-gesture-pinch::before {
  content: "\FABC"; }

.mdi-gesture-spread::before {
  content: "\FABD"; }

.mdi-gesture-swipe::before {
  content: "\FD52"; }

.mdi-gesture-swipe-down::before {
  content: "\F73C"; }

.mdi-gesture-swipe-horizontal::before {
  content: "\FABE"; }

.mdi-gesture-swipe-left::before {
  content: "\F73D"; }

.mdi-gesture-swipe-right::before {
  content: "\F73E"; }

.mdi-gesture-swipe-up::before {
  content: "\F73F"; }

.mdi-gesture-swipe-vertical::before {
  content: "\FABF"; }

.mdi-gesture-tap::before {
  content: "\F740"; }

.mdi-gesture-tap-box::before {
  content: "\F02D4"; }

.mdi-gesture-tap-button::before {
  content: "\F02D3"; }

.mdi-gesture-tap-hold::before {
  content: "\FD53"; }

.mdi-gesture-two-double-tap::before {
  content: "\F741"; }

.mdi-gesture-two-tap::before {
  content: "\F742"; }

.mdi-ghost::before {
  content: "\F2A0"; }

.mdi-ghost-off::before {
  content: "\F9F4"; }

.mdi-gif::before {
  content: "\FD54"; }

.mdi-gift::before {
  content: "\FE85"; }

.mdi-gift-outline::before {
  content: "\F2A1"; }

.mdi-git::before {
  content: "\F2A2"; }

.mdi-github-box::before {
  content: "\F2A3"; }

.mdi-github-circle::before {
  content: "\F2A4"; }

.mdi-github-face::before {
  content: "\F6DA"; }

.mdi-gitlab::before {
  content: "\FB7C"; }

.mdi-glass-cocktail::before {
  content: "\F356"; }

.mdi-glass-flute::before {
  content: "\F2A5"; }

.mdi-glass-mug::before {
  content: "\F2A6"; }

.mdi-glass-mug-variant::before {
  content: "\F0141"; }

.mdi-glass-pint-outline::before {
  content: "\F0338"; }

.mdi-glass-stange::before {
  content: "\F2A7"; }

.mdi-glass-tulip::before {
  content: "\F2A8"; }

.mdi-glass-wine::before {
  content: "\F875"; }

.mdi-glassdoor::before {
  content: "\F2A9"; }

.mdi-glasses::before {
  content: "\F2AA"; }

.mdi-globe-light::before {
  content: "\F0302"; }

.mdi-globe-model::before {
  content: "\F8E8"; }

.mdi-gmail::before {
  content: "\F2AB"; }

.mdi-gnome::before {
  content: "\F2AC"; }

.mdi-go-kart::before {
  content: "\FD55"; }

.mdi-go-kart-track::before {
  content: "\FD56"; }

.mdi-gog::before {
  content: "\FB7D"; }

.mdi-gold::before {
  content: "\F027A"; }

.mdi-golf::before {
  content: "\F822"; }

.mdi-golf-cart::before {
  content: "\F01CF"; }

.mdi-golf-tee::before {
  content: "\F00AE"; }

.mdi-gondola::before {
  content: "\F685"; }

.mdi-goodreads::before {
  content: "\FD57"; }

.mdi-google::before {
  content: "\F2AD"; }

.mdi-google-adwords::before {
  content: "\FC63"; }

.mdi-google-analytics::before {
  content: "\F7CB"; }

.mdi-google-assistant::before {
  content: "\F7CC"; }

.mdi-google-cardboard::before {
  content: "\F2AE"; }

.mdi-google-chrome::before {
  content: "\F2AF"; }

.mdi-google-circles::before {
  content: "\F2B0"; }

.mdi-google-circles-communities::before {
  content: "\F2B1"; }

.mdi-google-circles-extended::before {
  content: "\F2B2"; }

.mdi-google-circles-group::before {
  content: "\F2B3"; }

.mdi-google-classroom::before {
  content: "\F2C0"; }

.mdi-google-cloud::before {
  content: "\F0221"; }

.mdi-google-controller::before {
  content: "\F2B4"; }

.mdi-google-controller-off::before {
  content: "\F2B5"; }

.mdi-google-downasaur::before {
  content: "\F038D"; }

.mdi-google-drive::before {
  content: "\F2B6"; }

.mdi-google-earth::before {
  content: "\F2B7"; }

.mdi-google-fit::before {
  content: "\F96B"; }

.mdi-google-glass::before {
  content: "\F2B8"; }

.mdi-google-hangouts::before {
  content: "\F2C9"; }

.mdi-google-home::before {
  content: "\F823"; }

.mdi-google-keep::before {
  content: "\F6DB"; }

.mdi-google-lens::before {
  content: "\F9F5"; }

.mdi-google-maps::before {
  content: "\F5F5"; }

.mdi-google-my-business::before {
  content: "\F006A"; }

.mdi-google-nearby::before {
  content: "\F2B9"; }

.mdi-google-pages::before {
  content: "\F2BA"; }

.mdi-google-photos::before {
  content: "\F6DC"; }

.mdi-google-physical-web::before {
  content: "\F2BB"; }

.mdi-google-play::before {
  content: "\F2BC"; }

.mdi-google-plus::before {
  content: "\F2BD"; }

.mdi-google-plus-box::before {
  content: "\F2BE"; }

.mdi-google-podcast::before {
  content: "\FED6"; }

.mdi-google-spreadsheet::before {
  content: "\F9F6"; }

.mdi-google-street-view::before {
  content: "\FC64"; }

.mdi-google-translate::before {
  content: "\F2BF"; }

.mdi-gradient::before {
  content: "\F69F"; }

.mdi-grain::before {
  content: "\FD58"; }

.mdi-graph::before {
  content: "\F006B"; }

.mdi-graph-outline::before {
  content: "\F006C"; }

.mdi-graphql::before {
  content: "\F876"; }

.mdi-grave-stone::before {
  content: "\FB7E"; }

.mdi-grease-pencil::before {
  content: "\F648"; }

.mdi-greater-than::before {
  content: "\F96C"; }

.mdi-greater-than-or-equal::before {
  content: "\F96D"; }

.mdi-grid::before {
  content: "\F2C1"; }

.mdi-grid-large::before {
  content: "\F757"; }

.mdi-grid-off::before {
  content: "\F2C2"; }

.mdi-grill::before {
  content: "\FE86"; }

.mdi-grill-outline::before {
  content: "\F01B5"; }

.mdi-group::before {
  content: "\F2C3"; }

.mdi-guitar-acoustic::before {
  content: "\F770"; }

.mdi-guitar-electric::before {
  content: "\F2C4"; }

.mdi-guitar-pick::before {
  content: "\F2C5"; }

.mdi-guitar-pick-outline::before {
  content: "\F2C6"; }

.mdi-guy-fawkes-mask::before {
  content: "\F824"; }

.mdi-hackernews::before {
  content: "\F624"; }

.mdi-hail::before {
  content: "\FAC0"; }

.mdi-hair-dryer::before {
  content: "\F011A"; }

.mdi-hair-dryer-outline::before {
  content: "\F011B"; }

.mdi-halloween::before {
  content: "\FB7F"; }

.mdi-hamburger::before {
  content: "\F684"; }

.mdi-hammer::before {
  content: "\F8E9"; }

.mdi-hammer-screwdriver::before {
  content: "\F034D"; }

.mdi-hammer-wrench::before {
  content: "\F034E"; }

.mdi-hand::before {
  content: "\FA4E"; }

.mdi-hand-heart::before {
  content: "\F011C"; }

.mdi-hand-left::before {
  content: "\FE87"; }

.mdi-hand-okay::before {
  content: "\FA4F"; }

.mdi-hand-peace::before {
  content: "\FA50"; }

.mdi-hand-peace-variant::before {
  content: "\FA51"; }

.mdi-hand-pointing-down::before {
  content: "\FA52"; }

.mdi-hand-pointing-left::before {
  content: "\FA53"; }

.mdi-hand-pointing-right::before {
  content: "\F2C7"; }

.mdi-hand-pointing-up::before {
  content: "\FA54"; }

.mdi-hand-right::before {
  content: "\FE88"; }

.mdi-hand-saw::before {
  content: "\FE89"; }

.mdi-handball::before {
  content: "\FF70"; }

.mdi-handcuffs::before {
  content: "\F0169"; }

.mdi-handshake::before {
  content: "\F0243"; }

.mdi-hanger::before {
  content: "\F2C8"; }

.mdi-hard-hat::before {
  content: "\F96E"; }

.mdi-harddisk::before {
  content: "\F2CA"; }

.mdi-harddisk-plus::before {
  content: "\F006D"; }

.mdi-harddisk-remove::before {
  content: "\F006E"; }

.mdi-hat-fedora::before {
  content: "\FB80"; }

.mdi-hazard-lights::before {
  content: "\FC65"; }

.mdi-hdr::before {
  content: "\FD59"; }

.mdi-hdr-off::before {
  content: "\FD5A"; }

.mdi-head::before {
  content: "\F0389"; }

.mdi-head-alert::before {
  content: "\F0363"; }

.mdi-head-alert-outline::before {
  content: "\F0364"; }

.mdi-head-check::before {
  content: "\F0365"; }

.mdi-head-check-outline::before {
  content: "\F0366"; }

.mdi-head-cog::before {
  content: "\F0367"; }

.mdi-head-cog-outline::before {
  content: "\F0368"; }

.mdi-head-dots-horizontal::before {
  content: "\F0369"; }

.mdi-head-dots-horizontal-outline::before {
  content: "\F036A"; }

.mdi-head-flash::before {
  content: "\F036B"; }

.mdi-head-flash-outline::before {
  content: "\F036C"; }

.mdi-head-heart::before {
  content: "\F036D"; }

.mdi-head-heart-outline::before {
  content: "\F036E"; }

.mdi-head-lightbulb::before {
  content: "\F036F"; }

.mdi-head-lightbulb-outline::before {
  content: "\F0370"; }

.mdi-head-minus::before {
  content: "\F0371"; }

.mdi-head-minus-outline::before {
  content: "\F0372"; }

.mdi-head-outline::before {
  content: "\F038A"; }

.mdi-head-plus::before {
  content: "\F0373"; }

.mdi-head-plus-outline::before {
  content: "\F0374"; }

.mdi-head-question::before {
  content: "\F0375"; }

.mdi-head-question-outline::before {
  content: "\F0376"; }

.mdi-head-remove::before {
  content: "\F0377"; }

.mdi-head-remove-outline::before {
  content: "\F0378"; }

.mdi-head-snowflake::before {
  content: "\F0379"; }

.mdi-head-snowflake-outline::before {
  content: "\F037A"; }

.mdi-head-sync::before {
  content: "\F037B"; }

.mdi-head-sync-outline::before {
  content: "\F037C"; }

.mdi-headphones::before {
  content: "\F2CB"; }

.mdi-headphones-bluetooth::before {
  content: "\F96F"; }

.mdi-headphones-box::before {
  content: "\F2CC"; }

.mdi-headphones-off::before {
  content: "\F7CD"; }

.mdi-headphones-settings::before {
  content: "\F2CD"; }

.mdi-headset::before {
  content: "\F2CE"; }

.mdi-headset-dock::before {
  content: "\F2CF"; }

.mdi-headset-off::before {
  content: "\F2D0"; }

.mdi-heart::before {
  content: "\F2D1"; }

.mdi-heart-box::before {
  content: "\F2D2"; }

.mdi-heart-box-outline::before {
  content: "\F2D3"; }

.mdi-heart-broken::before {
  content: "\F2D4"; }

.mdi-heart-broken-outline::before {
  content: "\FCF0"; }

.mdi-heart-circle::before {
  content: "\F970"; }

.mdi-heart-circle-outline::before {
  content: "\F971"; }

.mdi-heart-flash::before {
  content: "\FF16"; }

.mdi-heart-half::before {
  content: "\F6DE"; }

.mdi-heart-half-full::before {
  content: "\F6DD"; }

.mdi-heart-half-outline::before {
  content: "\F6DF"; }

.mdi-heart-multiple::before {
  content: "\FA55"; }

.mdi-heart-multiple-outline::before {
  content: "\FA56"; }

.mdi-heart-off::before {
  content: "\F758"; }

.mdi-heart-outline::before {
  content: "\F2D5"; }

.mdi-heart-pulse::before {
  content: "\F5F6"; }

.mdi-helicopter::before {
  content: "\FAC1"; }

.mdi-help::before {
  content: "\F2D6"; }

.mdi-help-box::before {
  content: "\F78A"; }

.mdi-help-circle::before {
  content: "\F2D7"; }

.mdi-help-circle-outline::before {
  content: "\F625"; }

.mdi-help-network::before {
  content: "\F6F4"; }

.mdi-help-network-outline::before {
  content: "\FC66"; }

.mdi-help-rhombus::before {
  content: "\FB81"; }

.mdi-help-rhombus-outline::before {
  content: "\FB82"; }

.mdi-hexadecimal::before {
  content: "\F02D2"; }

.mdi-hexagon::before {
  content: "\F2D8"; }

.mdi-hexagon-multiple::before {
  content: "\F6E0"; }

.mdi-hexagon-multiple-outline::before {
  content: "\F011D"; }

.mdi-hexagon-outline::before {
  content: "\F2D9"; }

.mdi-hexagon-slice-1::before {
  content: "\FAC2"; }

.mdi-hexagon-slice-2::before {
  content: "\FAC3"; }

.mdi-hexagon-slice-3::before {
  content: "\FAC4"; }

.mdi-hexagon-slice-4::before {
  content: "\FAC5"; }

.mdi-hexagon-slice-5::before {
  content: "\FAC6"; }

.mdi-hexagon-slice-6::before {
  content: "\FAC7"; }

.mdi-hexagram::before {
  content: "\FAC8"; }

.mdi-hexagram-outline::before {
  content: "\FAC9"; }

.mdi-high-definition::before {
  content: "\F7CE"; }

.mdi-high-definition-box::before {
  content: "\F877"; }

.mdi-highway::before {
  content: "\F5F7"; }

.mdi-hiking::before {
  content: "\FD5B"; }

.mdi-hinduism::before {
  content: "\F972"; }

.mdi-history::before {
  content: "\F2DA"; }

.mdi-hockey-puck::before {
  content: "\F878"; }

.mdi-hockey-sticks::before {
  content: "\F879"; }

.mdi-hololens::before {
  content: "\F2DB"; }

.mdi-home::before {
  content: "\F2DC"; }

.mdi-home-account::before {
  content: "\F825"; }

.mdi-home-alert::before {
  content: "\F87A"; }

.mdi-home-analytics::before {
  content: "\FED7"; }

.mdi-home-assistant::before {
  content: "\F7CF"; }

.mdi-home-automation::before {
  content: "\F7D0"; }

.mdi-home-circle::before {
  content: "\F7D1"; }

.mdi-home-circle-outline::before {
  content: "\F006F"; }

.mdi-home-city::before {
  content: "\FCF1"; }

.mdi-home-city-outline::before {
  content: "\FCF2"; }

.mdi-home-currency-usd::before {
  content: "\F8AE"; }

.mdi-home-edit::before {
  content: "\F0184"; }

.mdi-home-edit-outline::before {
  content: "\F0185"; }

.mdi-home-export-outline::before {
  content: "\FFB8"; }

.mdi-home-flood::before {
  content: "\FF17"; }

.mdi-home-floor-0::before {
  content: "\FDAE"; }

.mdi-home-floor-1::before {
  content: "\FD5C"; }

.mdi-home-floor-2::before {
  content: "\FD5D"; }

.mdi-home-floor-3::before {
  content: "\FD5E"; }

.mdi-home-floor-a::before {
  content: "\FD5F"; }

.mdi-home-floor-b::before {
  content: "\FD60"; }

.mdi-home-floor-g::before {
  content: "\FD61"; }

.mdi-home-floor-l::before {
  content: "\FD62"; }

.mdi-home-floor-negative-1::before {
  content: "\FDAF"; }

.mdi-home-group::before {
  content: "\FDB0"; }

.mdi-home-heart::before {
  content: "\F826"; }

.mdi-home-import-outline::before {
  content: "\FFB9"; }

.mdi-home-lightbulb::before {
  content: "\F027C"; }

.mdi-home-lightbulb-outline::before {
  content: "\F027D"; }

.mdi-home-lock::before {
  content: "\F8EA"; }

.mdi-home-lock-open::before {
  content: "\F8EB"; }

.mdi-home-map-marker::before {
  content: "\F5F8"; }

.mdi-home-minus::before {
  content: "\F973"; }

.mdi-home-modern::before {
  content: "\F2DD"; }

.mdi-home-outline::before {
  content: "\F6A0"; }

.mdi-home-plus::before {
  content: "\F974"; }

.mdi-home-remove::before {
  content: "\F0272"; }

.mdi-home-roof::before {
  content: "\F0156"; }

.mdi-home-thermometer::before {
  content: "\FF71"; }

.mdi-home-thermometer-outline::before {
  content: "\FF72"; }

.mdi-home-variant::before {
  content: "\F2DE"; }

.mdi-home-variant-outline::before {
  content: "\FB83"; }

.mdi-hook::before {
  content: "\F6E1"; }

.mdi-hook-off::before {
  content: "\F6E2"; }

.mdi-hops::before {
  content: "\F2DF"; }

.mdi-horizontal-rotate-clockwise::before {
  content: "\F011E"; }

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\F011F"; }

.mdi-horseshoe::before {
  content: "\FA57"; }

.mdi-hospital::before {
  content: "\F0017"; }

.mdi-hospital-box::before {
  content: "\F2E0"; }

.mdi-hospital-box-outline::before {
  content: "\F0018"; }

.mdi-hospital-building::before {
  content: "\F2E1"; }

.mdi-hospital-marker::before {
  content: "\F2E2"; }

.mdi-hot-tub::before {
  content: "\F827"; }

.mdi-hotel::before {
  content: "\F2E3"; }

.mdi-houzz::before {
  content: "\F2E4"; }

.mdi-houzz-box::before {
  content: "\F2E5"; }

.mdi-hubspot::before {
  content: "\FCF3"; }

.mdi-hulu::before {
  content: "\F828"; }

.mdi-human::before {
  content: "\F2E6"; }

.mdi-human-child::before {
  content: "\F2E7"; }

.mdi-human-female::before {
  content: "\F649"; }

.mdi-human-female-boy::before {
  content: "\FA58"; }

.mdi-human-female-female::before {
  content: "\FA59"; }

.mdi-human-female-girl::before {
  content: "\FA5A"; }

.mdi-human-greeting::before {
  content: "\F64A"; }

.mdi-human-handsdown::before {
  content: "\F64B"; }

.mdi-human-handsup::before {
  content: "\F64C"; }

.mdi-human-male::before {
  content: "\F64D"; }

.mdi-human-male-boy::before {
  content: "\FA5B"; }

.mdi-human-male-female::before {
  content: "\F2E8"; }

.mdi-human-male-girl::before {
  content: "\FA5C"; }

.mdi-human-male-height::before {
  content: "\FF18"; }

.mdi-human-male-height-variant::before {
  content: "\FF19"; }

.mdi-human-male-male::before {
  content: "\FA5D"; }

.mdi-human-pregnant::before {
  content: "\F5CF"; }

.mdi-humble-bundle::before {
  content: "\F743"; }

.mdi-hvac::before {
  content: "\F037D"; }

.mdi-hydraulic-oil-level::before {
  content: "\F034F"; }

.mdi-hydraulic-oil-temperature::before {
  content: "\F0350"; }

.mdi-hydro-power::before {
  content: "\F0310"; }

.mdi-ice-cream::before {
  content: "\F829"; }

.mdi-ice-pop::before {
  content: "\FF1A"; }

.mdi-id-card::before {
  content: "\FFE0"; }

.mdi-identifier::before {
  content: "\FF1B"; }

.mdi-ideogram-cjk::before {
  content: "\F035C"; }

.mdi-ideogram-cjk-variant::before {
  content: "\F035D"; }

.mdi-iframe::before {
  content: "\FC67"; }

.mdi-iframe-array::before {
  content: "\F0120"; }

.mdi-iframe-array-outline::before {
  content: "\F0121"; }

.mdi-iframe-braces::before {
  content: "\F0122"; }

.mdi-iframe-braces-outline::before {
  content: "\F0123"; }

.mdi-iframe-outline::before {
  content: "\FC68"; }

.mdi-iframe-parentheses::before {
  content: "\F0124"; }

.mdi-iframe-parentheses-outline::before {
  content: "\F0125"; }

.mdi-iframe-variable::before {
  content: "\F0126"; }

.mdi-iframe-variable-outline::before {
  content: "\F0127"; }

.mdi-image::before {
  content: "\F2E9"; }

.mdi-image-album::before {
  content: "\F2EA"; }

.mdi-image-area::before {
  content: "\F2EB"; }

.mdi-image-area-close::before {
  content: "\F2EC"; }

.mdi-image-auto-adjust::before {
  content: "\FFE1"; }

.mdi-image-broken::before {
  content: "\F2ED"; }

.mdi-image-broken-variant::before {
  content: "\F2EE"; }

.mdi-image-edit::before {
  content: "\F020E"; }

.mdi-image-edit-outline::before {
  content: "\F020F"; }

.mdi-image-filter::before {
  content: "\F2EF"; }

.mdi-image-filter-black-white::before {
  content: "\F2F0"; }

.mdi-image-filter-center-focus::before {
  content: "\F2F1"; }

.mdi-image-filter-center-focus-strong::before {
  content: "\FF1C"; }

.mdi-image-filter-center-focus-strong-outline::before {
  content: "\FF1D"; }

.mdi-image-filter-center-focus-weak::before {
  content: "\F2F2"; }

.mdi-image-filter-drama::before {
  content: "\F2F3"; }

.mdi-image-filter-frames::before {
  content: "\F2F4"; }

.mdi-image-filter-hdr::before {
  content: "\F2F5"; }

.mdi-image-filter-none::before {
  content: "\F2F6"; }

.mdi-image-filter-tilt-shift::before {
  content: "\F2F7"; }

.mdi-image-filter-vintage::before {
  content: "\F2F8"; }

.mdi-image-frame::before {
  content: "\FE8A"; }

.mdi-image-move::before {
  content: "\F9F7"; }

.mdi-image-multiple::before {
  content: "\F2F9"; }

.mdi-image-off::before {
  content: "\F82A"; }

.mdi-image-off-outline::before {
  content: "\F01FC"; }

.mdi-image-outline::before {
  content: "\F975"; }

.mdi-image-plus::before {
  content: "\F87B"; }

.mdi-image-search::before {
  content: "\F976"; }

.mdi-image-search-outline::before {
  content: "\F977"; }

.mdi-image-size-select-actual::before {
  content: "\FC69"; }

.mdi-image-size-select-large::before {
  content: "\FC6A"; }

.mdi-image-size-select-small::before {
  content: "\FC6B"; }

.mdi-import::before {
  content: "\F2FA"; }

.mdi-inbox::before {
  content: "\F686"; }

.mdi-inbox-arrow-down::before {
  content: "\F2FB"; }

.mdi-inbox-arrow-down-outline::before {
  content: "\F029B"; }

.mdi-inbox-arrow-up::before {
  content: "\F3D1"; }

.mdi-inbox-arrow-up-outline::before {
  content: "\F029C"; }

.mdi-inbox-full::before {
  content: "\F029D"; }

.mdi-inbox-full-outline::before {
  content: "\F029E"; }

.mdi-inbox-multiple::before {
  content: "\F8AF"; }

.mdi-inbox-multiple-outline::before {
  content: "\FB84"; }

.mdi-inbox-outline::before {
  content: "\F029F"; }

.mdi-incognito::before {
  content: "\F5F9"; }

.mdi-infinity::before {
  content: "\F6E3"; }

.mdi-information::before {
  content: "\F2FC"; }

.mdi-information-outline::before {
  content: "\F2FD"; }

.mdi-information-variant::before {
  content: "\F64E"; }

.mdi-instagram::before {
  content: "\F2FE"; }

.mdi-instapaper::before {
  content: "\F2FF"; }

.mdi-instrument-triangle::before {
  content: "\F0070"; }

.mdi-internet-explorer::before {
  content: "\F300"; }

.mdi-invert-colors::before {
  content: "\F301"; }

.mdi-invert-colors-off::before {
  content: "\FE8B"; }

.mdi-iobroker::before {
  content: "\F0313"; }

.mdi-ip::before {
  content: "\FA5E"; }

.mdi-ip-network::before {
  content: "\FA5F"; }

.mdi-ip-network-outline::before {
  content: "\FC6C"; }

.mdi-ipod::before {
  content: "\FC6D"; }

.mdi-islam::before {
  content: "\F978"; }

.mdi-island::before {
  content: "\F0071"; }

.mdi-itunes::before {
  content: "\F676"; }

.mdi-iv-bag::before {
  content: "\F00E4"; }

.mdi-jabber::before {
  content: "\FDB1"; }

.mdi-jeepney::before {
  content: "\F302"; }

.mdi-jellyfish::before {
  content: "\FF1E"; }

.mdi-jellyfish-outline::before {
  content: "\FF1F"; }

.mdi-jira::before {
  content: "\F303"; }

.mdi-jquery::before {
  content: "\F87C"; }

.mdi-jsfiddle::before {
  content: "\F304"; }

.mdi-json::before {
  content: "\F626"; }

.mdi-judaism::before {
  content: "\F979"; }

.mdi-jump-rope::before {
  content: "\F032A"; }

.mdi-kabaddi::before {
  content: "\FD63"; }

.mdi-karate::before {
  content: "\F82B"; }

.mdi-keg::before {
  content: "\F305"; }

.mdi-kettle::before {
  content: "\F5FA"; }

.mdi-kettle-alert::before {
  content: "\F0342"; }

.mdi-kettle-alert-outline::before {
  content: "\F0343"; }

.mdi-kettle-off::before {
  content: "\F0346"; }

.mdi-kettle-off-outline::before {
  content: "\F0347"; }

.mdi-kettle-outline::before {
  content: "\FF73"; }

.mdi-kettle-steam::before {
  content: "\F0344"; }

.mdi-kettle-steam-outline::before {
  content: "\F0345"; }

.mdi-kettlebell::before {
  content: "\F032B"; }

.mdi-key::before {
  content: "\F306"; }

.mdi-key-arrow-right::before {
  content: "\F033D"; }

.mdi-key-change::before {
  content: "\F307"; }

.mdi-key-link::before {
  content: "\F01CA"; }

.mdi-key-minus::before {
  content: "\F308"; }

.mdi-key-outline::before {
  content: "\FDB2"; }

.mdi-key-plus::before {
  content: "\F309"; }

.mdi-key-remove::before {
  content: "\F30A"; }

.mdi-key-star::before {
  content: "\F01C9"; }

.mdi-key-variant::before {
  content: "\F30B"; }

.mdi-key-wireless::before {
  content: "\FFE2"; }

.mdi-keyboard::before {
  content: "\F30C"; }

.mdi-keyboard-backspace::before {
  content: "\F30D"; }

.mdi-keyboard-caps::before {
  content: "\F30E"; }

.mdi-keyboard-close::before {
  content: "\F30F"; }

.mdi-keyboard-esc::before {
  content: "\F02E2"; }

.mdi-keyboard-f1::before {
  content: "\F02D6"; }

.mdi-keyboard-f10::before {
  content: "\F02DF"; }

.mdi-keyboard-f11::before {
  content: "\F02E0"; }

.mdi-keyboard-f12::before {
  content: "\F02E1"; }

.mdi-keyboard-f2::before {
  content: "\F02D7"; }

.mdi-keyboard-f3::before {
  content: "\F02D8"; }

.mdi-keyboard-f4::before {
  content: "\F02D9"; }

.mdi-keyboard-f5::before {
  content: "\F02DA"; }

.mdi-keyboard-f6::before {
  content: "\F02DB"; }

.mdi-keyboard-f7::before {
  content: "\F02DC"; }

.mdi-keyboard-f8::before {
  content: "\F02DD"; }

.mdi-keyboard-f9::before {
  content: "\F02DE"; }

.mdi-keyboard-off::before {
  content: "\F310"; }

.mdi-keyboard-off-outline::before {
  content: "\FE8C"; }

.mdi-keyboard-outline::before {
  content: "\F97A"; }

.mdi-keyboard-return::before {
  content: "\F311"; }

.mdi-keyboard-settings::before {
  content: "\F9F8"; }

.mdi-keyboard-settings-outline::before {
  content: "\F9F9"; }

.mdi-keyboard-space::before {
  content: "\F0072"; }

.mdi-keyboard-tab::before {
  content: "\F312"; }

.mdi-keyboard-variant::before {
  content: "\F313"; }

.mdi-khanda::before {
  content: "\F0128"; }

.mdi-kickstarter::before {
  content: "\F744"; }

.mdi-klingon::before {
  content: "\F0386"; }

.mdi-knife::before {
  content: "\F9FA"; }

.mdi-knife-military::before {
  content: "\F9FB"; }

.mdi-kodi::before {
  content: "\F314"; }

.mdi-kotlin::before {
  content: "\F0244"; }

.mdi-kubernetes::before {
  content: "\F0129"; }

.mdi-label::before {
  content: "\F315"; }

.mdi-label-multiple::before {
  content: "\F03A0"; }

.mdi-label-multiple-outline::before {
  content: "\F03A1"; }

.mdi-label-off::before {
  content: "\FACA"; }

.mdi-label-off-outline::before {
  content: "\FACB"; }

.mdi-label-outline::before {
  content: "\F316"; }

.mdi-label-percent::before {
  content: "\F0315"; }

.mdi-label-percent-outline::before {
  content: "\F0316"; }

.mdi-label-variant::before {
  content: "\FACC"; }

.mdi-label-variant-outline::before {
  content: "\FACD"; }

.mdi-ladybug::before {
  content: "\F82C"; }

.mdi-lambda::before {
  content: "\F627"; }

.mdi-lamp::before {
  content: "\F6B4"; }

.mdi-lan::before {
  content: "\F317"; }

.mdi-lan-check::before {
  content: "\F02D5"; }

.mdi-lan-connect::before {
  content: "\F318"; }

.mdi-lan-disconnect::before {
  content: "\F319"; }

.mdi-lan-pending::before {
  content: "\F31A"; }

.mdi-language-c::before {
  content: "\F671"; }

.mdi-language-cpp::before {
  content: "\F672"; }

.mdi-language-csharp::before {
  content: "\F31B"; }

.mdi-language-css3::before {
  content: "\F31C"; }

.mdi-language-fortran::before {
  content: "\F0245"; }

.mdi-language-go::before {
  content: "\F7D2"; }

.mdi-language-haskell::before {
  content: "\FC6E"; }

.mdi-language-html5::before {
  content: "\F31D"; }

.mdi-language-java::before {
  content: "\FB1C"; }

.mdi-language-javascript::before {
  content: "\F31E"; }

.mdi-language-lua::before {
  content: "\F8B0"; }

.mdi-language-php::before {
  content: "\F31F"; }

.mdi-language-python::before {
  content: "\F320"; }

.mdi-language-python-text::before {
  content: "\F321"; }

.mdi-language-r::before {
  content: "\F7D3"; }

.mdi-language-ruby-on-rails::before {
  content: "\FACE"; }

.mdi-language-swift::before {
  content: "\F6E4"; }

.mdi-language-typescript::before {
  content: "\F6E5"; }

.mdi-laptop::before {
  content: "\F322"; }

.mdi-laptop-chromebook::before {
  content: "\F323"; }

.mdi-laptop-mac::before {
  content: "\F324"; }

.mdi-laptop-off::before {
  content: "\F6E6"; }

.mdi-laptop-windows::before {
  content: "\F325"; }

.mdi-laravel::before {
  content: "\FACF"; }

.mdi-lasso::before {
  content: "\FF20"; }

.mdi-lastfm::before {
  content: "\F326"; }

.mdi-lastpass::before {
  content: "\F446"; }

.mdi-latitude::before {
  content: "\FF74"; }

.mdi-launch::before {
  content: "\F327"; }

.mdi-lava-lamp::before {
  content: "\F7D4"; }

.mdi-layers::before {
  content: "\F328"; }

.mdi-layers-minus::before {
  content: "\FE8D"; }

.mdi-layers-off::before {
  content: "\F329"; }

.mdi-layers-off-outline::before {
  content: "\F9FC"; }

.mdi-layers-outline::before {
  content: "\F9FD"; }

.mdi-layers-plus::before {
  content: "\FE30"; }

.mdi-layers-remove::before {
  content: "\FE31"; }

.mdi-layers-search::before {
  content: "\F0231"; }

.mdi-layers-search-outline::before {
  content: "\F0232"; }

.mdi-layers-triple::before {
  content: "\FF75"; }

.mdi-layers-triple-outline::before {
  content: "\FF76"; }

.mdi-lead-pencil::before {
  content: "\F64F"; }

.mdi-leaf::before {
  content: "\F32A"; }

.mdi-leaf-maple::before {
  content: "\FC6F"; }

.mdi-leaf-maple-off::before {
  content: "\F0305"; }

.mdi-leaf-off::before {
  content: "\F0304"; }

.mdi-leak::before {
  content: "\FDB3"; }

.mdi-leak-off::before {
  content: "\FDB4"; }

.mdi-led-off::before {
  content: "\F32B"; }

.mdi-led-on::before {
  content: "\F32C"; }

.mdi-led-outline::before {
  content: "\F32D"; }

.mdi-led-strip::before {
  content: "\F7D5"; }

.mdi-led-strip-variant::before {
  content: "\F0073"; }

.mdi-led-variant-off::before {
  content: "\F32E"; }

.mdi-led-variant-on::before {
  content: "\F32F"; }

.mdi-led-variant-outline::before {
  content: "\F330"; }

.mdi-leek::before {
  content: "\F01A8"; }

.mdi-less-than::before {
  content: "\F97B"; }

.mdi-less-than-or-equal::before {
  content: "\F97C"; }

.mdi-library::before {
  content: "\F331"; }

.mdi-library-books::before {
  content: "\F332"; }

.mdi-library-movie::before {
  content: "\FCF4"; }

.mdi-library-music::before {
  content: "\F333"; }

.mdi-library-music-outline::before {
  content: "\FF21"; }

.mdi-library-shelves::before {
  content: "\FB85"; }

.mdi-library-video::before {
  content: "\FCF5"; }

.mdi-license::before {
  content: "\FFE3"; }

.mdi-lifebuoy::before {
  content: "\F87D"; }

.mdi-light-switch::before {
  content: "\F97D"; }

.mdi-lightbulb::before {
  content: "\F335"; }

.mdi-lightbulb-cfl::before {
  content: "\F0233"; }

.mdi-lightbulb-cfl-off::before {
  content: "\F0234"; }

.mdi-lightbulb-cfl-spiral::before {
  content: "\F02A0"; }

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\F02EE"; }

.mdi-lightbulb-group::before {
  content: "\F027E"; }

.mdi-lightbulb-group-off::before {
  content: "\F02F8"; }

.mdi-lightbulb-group-off-outline::before {
  content: "\F02F9"; }

.mdi-lightbulb-group-outline::before {
  content: "\F027F"; }

.mdi-lightbulb-multiple::before {
  content: "\F0280"; }

.mdi-lightbulb-multiple-off::before {
  content: "\F02FA"; }

.mdi-lightbulb-multiple-off-outline::before {
  content: "\F02FB"; }

.mdi-lightbulb-multiple-outline::before {
  content: "\F0281"; }

.mdi-lightbulb-off::before {
  content: "\FE32"; }

.mdi-lightbulb-off-outline::before {
  content: "\FE33"; }

.mdi-lightbulb-on::before {
  content: "\F6E7"; }

.mdi-lightbulb-on-outline::before {
  content: "\F6E8"; }

.mdi-lightbulb-outline::before {
  content: "\F336"; }

.mdi-lighthouse::before {
  content: "\F9FE"; }

.mdi-lighthouse-on::before {
  content: "\F9FF"; }

.mdi-link::before {
  content: "\F337"; }

.mdi-link-box::before {
  content: "\FCF6"; }

.mdi-link-box-outline::before {
  content: "\FCF7"; }

.mdi-link-box-variant::before {
  content: "\FCF8"; }

.mdi-link-box-variant-outline::before {
  content: "\FCF9"; }

.mdi-link-lock::before {
  content: "\F00E5"; }

.mdi-link-off::before {
  content: "\F338"; }

.mdi-link-plus::before {
  content: "\FC70"; }

.mdi-link-variant::before {
  content: "\F339"; }

.mdi-link-variant-minus::before {
  content: "\F012A"; }

.mdi-link-variant-off::before {
  content: "\F33A"; }

.mdi-link-variant-plus::before {
  content: "\F012B"; }

.mdi-link-variant-remove::before {
  content: "\F012C"; }

.mdi-linkedin::before {
  content: "\F33B"; }

.mdi-linkedin-box::before {
  content: "\F33C"; }

.mdi-linux::before {
  content: "\F33D"; }

.mdi-linux-mint::before {
  content: "\F8EC"; }

.mdi-litecoin::before {
  content: "\FA60"; }

.mdi-loading::before {
  content: "\F771"; }

.mdi-location-enter::before {
  content: "\FFE4"; }

.mdi-location-exit::before {
  content: "\FFE5"; }

.mdi-lock::before {
  content: "\F33E"; }

.mdi-lock-alert::before {
  content: "\F8ED"; }

.mdi-lock-clock::before {
  content: "\F97E"; }

.mdi-lock-open::before {
  content: "\F33F"; }

.mdi-lock-open-outline::before {
  content: "\F340"; }

.mdi-lock-open-variant::before {
  content: "\FFE6"; }

.mdi-lock-open-variant-outline::before {
  content: "\FFE7"; }

.mdi-lock-outline::before {
  content: "\F341"; }

.mdi-lock-pattern::before {
  content: "\F6E9"; }

.mdi-lock-plus::before {
  content: "\F5FB"; }

.mdi-lock-question::before {
  content: "\F8EE"; }

.mdi-lock-reset::before {
  content: "\F772"; }

.mdi-lock-smart::before {
  content: "\F8B1"; }

.mdi-locker::before {
  content: "\F7D6"; }

.mdi-locker-multiple::before {
  content: "\F7D7"; }

.mdi-login::before {
  content: "\F342"; }

.mdi-login-variant::before {
  content: "\F5FC"; }

.mdi-logout::before {
  content: "\F343"; }

.mdi-logout-variant::before {
  content: "\F5FD"; }

.mdi-longitude::before {
  content: "\FF77"; }

.mdi-looks::before {
  content: "\F344"; }

.mdi-loupe::before {
  content: "\F345"; }

.mdi-lumx::before {
  content: "\F346"; }

.mdi-lungs::before {
  content: "\F00AF"; }

.mdi-lyft::before {
  content: "\FB1D"; }

.mdi-magnet::before {
  content: "\F347"; }

.mdi-magnet-on::before {
  content: "\F348"; }

.mdi-magnify::before {
  content: "\F349"; }

.mdi-magnify-close::before {
  content: "\F97F"; }

.mdi-magnify-minus::before {
  content: "\F34A"; }

.mdi-magnify-minus-cursor::before {
  content: "\FA61"; }

.mdi-magnify-minus-outline::before {
  content: "\F6EB"; }

.mdi-magnify-plus::before {
  content: "\F34B"; }

.mdi-magnify-plus-cursor::before {
  content: "\FA62"; }

.mdi-magnify-plus-outline::before {
  content: "\F6EC"; }

.mdi-magnify-remove-cursor::before {
  content: "\F0237"; }

.mdi-magnify-remove-outline::before {
  content: "\F0238"; }

.mdi-magnify-scan::before {
  content: "\F02A1"; }

.mdi-mail::before {
  content: "\FED8"; }

.mdi-mail-ru::before {
  content: "\F34C"; }

.mdi-mailbox::before {
  content: "\F6ED"; }

.mdi-mailbox-open::before {
  content: "\FD64"; }

.mdi-mailbox-open-outline::before {
  content: "\FD65"; }

.mdi-mailbox-open-up::before {
  content: "\FD66"; }

.mdi-mailbox-open-up-outline::before {
  content: "\FD67"; }

.mdi-mailbox-outline::before {
  content: "\FD68"; }

.mdi-mailbox-up::before {
  content: "\FD69"; }

.mdi-mailbox-up-outline::before {
  content: "\FD6A"; }

.mdi-map::before {
  content: "\F34D"; }

.mdi-map-check::before {
  content: "\FED9"; }

.mdi-map-check-outline::before {
  content: "\FEDA"; }

.mdi-map-clock::before {
  content: "\FCFA"; }

.mdi-map-clock-outline::before {
  content: "\FCFB"; }

.mdi-map-legend::before {
  content: "\FA00"; }

.mdi-map-marker::before {
  content: "\F34E"; }

.mdi-map-marker-alert::before {
  content: "\FF22"; }

.mdi-map-marker-alert-outline::before {
  content: "\FF23"; }

.mdi-map-marker-check::before {
  content: "\FC71"; }

.mdi-map-marker-check-outline::before {
  content: "\F0326"; }

.mdi-map-marker-circle::before {
  content: "\F34F"; }

.mdi-map-marker-distance::before {
  content: "\F8EF"; }

.mdi-map-marker-down::before {
  content: "\F012D"; }

.mdi-map-marker-left::before {
  content: "\F0306"; }

.mdi-map-marker-left-outline::before {
  content: "\F0308"; }

.mdi-map-marker-minus::before {
  content: "\F650"; }

.mdi-map-marker-minus-outline::before {
  content: "\F0324"; }

.mdi-map-marker-multiple::before {
  content: "\F350"; }

.mdi-map-marker-multiple-outline::before {
  content: "\F02A2"; }

.mdi-map-marker-off::before {
  content: "\F351"; }

.mdi-map-marker-off-outline::before {
  content: "\F0328"; }

.mdi-map-marker-outline::before {
  content: "\F7D8"; }

.mdi-map-marker-path::before {
  content: "\FCFC"; }

.mdi-map-marker-plus::before {
  content: "\F651"; }

.mdi-map-marker-plus-outline::before {
  content: "\F0323"; }

.mdi-map-marker-question::before {
  content: "\FF24"; }

.mdi-map-marker-question-outline::before {
  content: "\FF25"; }

.mdi-map-marker-radius::before {
  content: "\F352"; }

.mdi-map-marker-radius-outline::before {
  content: "\F0327"; }

.mdi-map-marker-remove::before {
  content: "\FF26"; }

.mdi-map-marker-remove-outline::before {
  content: "\F0325"; }

.mdi-map-marker-remove-variant::before {
  content: "\FF27"; }

.mdi-map-marker-right::before {
  content: "\F0307"; }

.mdi-map-marker-right-outline::before {
  content: "\F0309"; }

.mdi-map-marker-up::before {
  content: "\F012E"; }

.mdi-map-minus::before {
  content: "\F980"; }

.mdi-map-outline::before {
  content: "\F981"; }

.mdi-map-plus::before {
  content: "\F982"; }

.mdi-map-search::before {
  content: "\F983"; }

.mdi-map-search-outline::before {
  content: "\F984"; }

.mdi-mapbox::before {
  content: "\FB86"; }

.mdi-margin::before {
  content: "\F353"; }

.mdi-markdown::before {
  content: "\F354"; }

.mdi-markdown-outline::before {
  content: "\FF78"; }

.mdi-marker::before {
  content: "\F652"; }

.mdi-marker-cancel::before {
  content: "\FDB5"; }

.mdi-marker-check::before {
  content: "\F355"; }

.mdi-mastodon::before {
  content: "\FAD0"; }

.mdi-mastodon-variant::before {
  content: "\FAD1"; }

.mdi-material-design::before {
  content: "\F985"; }

.mdi-material-ui::before {
  content: "\F357"; }

.mdi-math-compass::before {
  content: "\F358"; }

.mdi-math-cos::before {
  content: "\FC72"; }

.mdi-math-integral::before {
  content: "\FFE8"; }

.mdi-math-integral-box::before {
  content: "\FFE9"; }

.mdi-math-log::before {
  content: "\F00B0"; }

.mdi-math-norm::before {
  content: "\FFEA"; }

.mdi-math-norm-box::before {
  content: "\FFEB"; }

.mdi-math-sin::before {
  content: "\FC73"; }

.mdi-math-tan::before {
  content: "\FC74"; }

.mdi-matrix::before {
  content: "\F628"; }

.mdi-medal::before {
  content: "\F986"; }

.mdi-medal-outline::before {
  content: "\F0351"; }

.mdi-medical-bag::before {
  content: "\F6EE"; }

.mdi-meditation::before {
  content: "\F01A6"; }

.mdi-medium::before {
  content: "\F35A"; }

.mdi-meetup::before {
  content: "\FAD2"; }

.mdi-memory::before {
  content: "\F35B"; }

.mdi-menu::before {
  content: "\F35C"; }

.mdi-menu-down::before {
  content: "\F35D"; }

.mdi-menu-down-outline::before {
  content: "\F6B5"; }

.mdi-menu-left::before {
  content: "\F35E"; }

.mdi-menu-left-outline::before {
  content: "\FA01"; }

.mdi-menu-open::before {
  content: "\FB87"; }

.mdi-menu-right::before {
  content: "\F35F"; }

.mdi-menu-right-outline::before {
  content: "\FA02"; }

.mdi-menu-swap::before {
  content: "\FA63"; }

.mdi-menu-swap-outline::before {
  content: "\FA64"; }

.mdi-menu-up::before {
  content: "\F360"; }

.mdi-menu-up-outline::before {
  content: "\F6B6"; }

.mdi-merge::before {
  content: "\FF79"; }

.mdi-message::before {
  content: "\F361"; }

.mdi-message-alert::before {
  content: "\F362"; }

.mdi-message-alert-outline::before {
  content: "\FA03"; }

.mdi-message-arrow-left::before {
  content: "\F031D"; }

.mdi-message-arrow-left-outline::before {
  content: "\F031E"; }

.mdi-message-arrow-right::before {
  content: "\F031F"; }

.mdi-message-arrow-right-outline::before {
  content: "\F0320"; }

.mdi-message-bulleted::before {
  content: "\F6A1"; }

.mdi-message-bulleted-off::before {
  content: "\F6A2"; }

.mdi-message-draw::before {
  content: "\F363"; }

.mdi-message-image::before {
  content: "\F364"; }

.mdi-message-image-outline::before {
  content: "\F0197"; }

.mdi-message-lock::before {
  content: "\FFEC"; }

.mdi-message-lock-outline::before {
  content: "\F0198"; }

.mdi-message-minus::before {
  content: "\F0199"; }

.mdi-message-minus-outline::before {
  content: "\F019A"; }

.mdi-message-outline::before {
  content: "\F365"; }

.mdi-message-plus::before {
  content: "\F653"; }

.mdi-message-plus-outline::before {
  content: "\F00E6"; }

.mdi-message-processing::before {
  content: "\F366"; }

.mdi-message-processing-outline::before {
  content: "\F019B"; }

.mdi-message-reply::before {
  content: "\F367"; }

.mdi-message-reply-text::before {
  content: "\F368"; }

.mdi-message-settings::before {
  content: "\F6EF"; }

.mdi-message-settings-outline::before {
  content: "\F019C"; }

.mdi-message-settings-variant::before {
  content: "\F6F0"; }

.mdi-message-settings-variant-outline::before {
  content: "\F019D"; }

.mdi-message-text::before {
  content: "\F369"; }

.mdi-message-text-clock::before {
  content: "\F019E"; }

.mdi-message-text-clock-outline::before {
  content: "\F019F"; }

.mdi-message-text-lock::before {
  content: "\FFED"; }

.mdi-message-text-lock-outline::before {
  content: "\F01A0"; }

.mdi-message-text-outline::before {
  content: "\F36A"; }

.mdi-message-video::before {
  content: "\F36B"; }

.mdi-meteor::before {
  content: "\F629"; }

.mdi-metronome::before {
  content: "\F7D9"; }

.mdi-metronome-tick::before {
  content: "\F7DA"; }

.mdi-micro-sd::before {
  content: "\F7DB"; }

.mdi-microphone::before {
  content: "\F36C"; }

.mdi-microphone-minus::before {
  content: "\F8B2"; }

.mdi-microphone-off::before {
  content: "\F36D"; }

.mdi-microphone-outline::before {
  content: "\F36E"; }

.mdi-microphone-plus::before {
  content: "\F8B3"; }

.mdi-microphone-settings::before {
  content: "\F36F"; }

.mdi-microphone-variant::before {
  content: "\F370"; }

.mdi-microphone-variant-off::before {
  content: "\F371"; }

.mdi-microscope::before {
  content: "\F654"; }

.mdi-microsoft::before {
  content: "\F372"; }

.mdi-microsoft-dynamics::before {
  content: "\F987"; }

.mdi-microwave::before {
  content: "\FC75"; }

.mdi-middleware::before {
  content: "\FF7A"; }

.mdi-middleware-outline::before {
  content: "\FF7B"; }

.mdi-midi::before {
  content: "\F8F0"; }

.mdi-midi-port::before {
  content: "\F8F1"; }

.mdi-mine::before {
  content: "\FDB6"; }

.mdi-minecraft::before {
  content: "\F373"; }

.mdi-mini-sd::before {
  content: "\FA04"; }

.mdi-minidisc::before {
  content: "\FA05"; }

.mdi-minus::before {
  content: "\F374"; }

.mdi-minus-box::before {
  content: "\F375"; }

.mdi-minus-box-multiple::before {
  content: "\F016C"; }

.mdi-minus-box-multiple-outline::before {
  content: "\F016D"; }

.mdi-minus-box-outline::before {
  content: "\F6F1"; }

.mdi-minus-circle::before {
  content: "\F376"; }

.mdi-minus-circle-outline::before {
  content: "\F377"; }

.mdi-minus-network::before {
  content: "\F378"; }

.mdi-minus-network-outline::before {
  content: "\FC76"; }

.mdi-mirror::before {
  content: "\F0228"; }

.mdi-mixcloud::before {
  content: "\F62A"; }

.mdi-mixed-martial-arts::before {
  content: "\FD6B"; }

.mdi-mixed-reality::before {
  content: "\F87E"; }

.mdi-mixer::before {
  content: "\F7DC"; }

.mdi-molecule::before {
  content: "\FB88"; }

.mdi-monitor::before {
  content: "\F379"; }

.mdi-monitor-cellphone::before {
  content: "\F988"; }

.mdi-monitor-cellphone-star::before {
  content: "\F989"; }

.mdi-monitor-clean::before {
  content: "\F012F"; }

.mdi-monitor-dashboard::before {
  content: "\FA06"; }

.mdi-monitor-edit::before {
  content: "\F02F1"; }

.mdi-monitor-lock::before {
  content: "\FDB7"; }

.mdi-monitor-multiple::before {
  content: "\F37A"; }

.mdi-monitor-off::before {
  content: "\FD6C"; }

.mdi-monitor-screenshot::before {
  content: "\FE34"; }

.mdi-monitor-speaker::before {
  content: "\FF7C"; }

.mdi-monitor-speaker-off::before {
  content: "\FF7D"; }

.mdi-monitor-star::before {
  content: "\FDB8"; }

.mdi-moon-first-quarter::before {
  content: "\FF7E"; }

.mdi-moon-full::before {
  content: "\FF7F"; }

.mdi-moon-last-quarter::before {
  content: "\FF80"; }

.mdi-moon-new::before {
  content: "\FF81"; }

.mdi-moon-waning-crescent::before {
  content: "\FF82"; }

.mdi-moon-waning-gibbous::before {
  content: "\FF83"; }

.mdi-moon-waxing-crescent::before {
  content: "\FF84"; }

.mdi-moon-waxing-gibbous::before {
  content: "\FF85"; }

.mdi-moped::before {
  content: "\F00B1"; }

.mdi-more::before {
  content: "\F37B"; }

.mdi-mother-heart::before {
  content: "\F033F"; }

.mdi-mother-nurse::before {
  content: "\FCFD"; }

.mdi-motion-sensor::before {
  content: "\FD6D"; }

.mdi-motorbike::before {
  content: "\F37C"; }

.mdi-mouse::before {
  content: "\F37D"; }

.mdi-mouse-bluetooth::before {
  content: "\F98A"; }

.mdi-mouse-off::before {
  content: "\F37E"; }

.mdi-mouse-variant::before {
  content: "\F37F"; }

.mdi-mouse-variant-off::before {
  content: "\F380"; }

.mdi-move-resize::before {
  content: "\F655"; }

.mdi-move-resize-variant::before {
  content: "\F656"; }

.mdi-movie::before {
  content: "\F381"; }

.mdi-movie-edit::before {
  content: "\F014D"; }

.mdi-movie-edit-outline::before {
  content: "\F014E"; }

.mdi-movie-filter::before {
  content: "\F014F"; }

.mdi-movie-filter-outline::before {
  content: "\F0150"; }

.mdi-movie-open::before {
  content: "\FFEE"; }

.mdi-movie-open-outline::before {
  content: "\FFEF"; }

.mdi-movie-outline::before {
  content: "\FDB9"; }

.mdi-movie-roll::before {
  content: "\F7DD"; }

.mdi-movie-search::before {
  content: "\F01FD"; }

.mdi-movie-search-outline::before {
  content: "\F01FE"; }

.mdi-muffin::before {
  content: "\F98B"; }

.mdi-multiplication::before {
  content: "\F382"; }

.mdi-multiplication-box::before {
  content: "\F383"; }

.mdi-mushroom::before {
  content: "\F7DE"; }

.mdi-mushroom-outline::before {
  content: "\F7DF"; }

.mdi-music::before {
  content: "\F759"; }

.mdi-music-accidental-double-flat::before {
  content: "\FF86"; }

.mdi-music-accidental-double-sharp::before {
  content: "\FF87"; }

.mdi-music-accidental-flat::before {
  content: "\FF88"; }

.mdi-music-accidental-natural::before {
  content: "\FF89"; }

.mdi-music-accidental-sharp::before {
  content: "\FF8A"; }

.mdi-music-box::before {
  content: "\F384"; }

.mdi-music-box-outline::before {
  content: "\F385"; }

.mdi-music-circle::before {
  content: "\F386"; }

.mdi-music-circle-outline::before {
  content: "\FAD3"; }

.mdi-music-clef-alto::before {
  content: "\FF8B"; }

.mdi-music-clef-bass::before {
  content: "\FF8C"; }

.mdi-music-clef-treble::before {
  content: "\FF8D"; }

.mdi-music-note::before {
  content: "\F387"; }

.mdi-music-note-bluetooth::before {
  content: "\F5FE"; }

.mdi-music-note-bluetooth-off::before {
  content: "\F5FF"; }

.mdi-music-note-eighth::before {
  content: "\F388"; }

.mdi-music-note-eighth-dotted::before {
  content: "\FF8E"; }

.mdi-music-note-half::before {
  content: "\F389"; }

.mdi-music-note-half-dotted::before {
  content: "\FF8F"; }

.mdi-music-note-off::before {
  content: "\F38A"; }

.mdi-music-note-off-outline::before {
  content: "\FF90"; }

.mdi-music-note-outline::before {
  content: "\FF91"; }

.mdi-music-note-plus::before {
  content: "\FDBA"; }

.mdi-music-note-quarter::before {
  content: "\F38B"; }

.mdi-music-note-quarter-dotted::before {
  content: "\FF92"; }

.mdi-music-note-sixteenth::before {
  content: "\F38C"; }

.mdi-music-note-sixteenth-dotted::before {
  content: "\FF93"; }

.mdi-music-note-whole::before {
  content: "\F38D"; }

.mdi-music-note-whole-dotted::before {
  content: "\FF94"; }

.mdi-music-off::before {
  content: "\F75A"; }

.mdi-music-rest-eighth::before {
  content: "\FF95"; }

.mdi-music-rest-half::before {
  content: "\FF96"; }

.mdi-music-rest-quarter::before {
  content: "\FF97"; }

.mdi-music-rest-sixteenth::before {
  content: "\FF98"; }

.mdi-music-rest-whole::before {
  content: "\FF99"; }

.mdi-nail::before {
  content: "\FDBB"; }

.mdi-nas::before {
  content: "\F8F2"; }

.mdi-nativescript::before {
  content: "\F87F"; }

.mdi-nature::before {
  content: "\F38E"; }

.mdi-nature-people::before {
  content: "\F38F"; }

.mdi-navigation::before {
  content: "\F390"; }

.mdi-near-me::before {
  content: "\F5CD"; }

.mdi-necklace::before {
  content: "\FF28"; }

.mdi-needle::before {
  content: "\F391"; }

.mdi-netflix::before {
  content: "\F745"; }

.mdi-network::before {
  content: "\F6F2"; }

.mdi-network-off::before {
  content: "\FC77"; }

.mdi-network-off-outline::before {
  content: "\FC78"; }

.mdi-network-outline::before {
  content: "\FC79"; }

.mdi-network-router::before {
  content: "\F00B2"; }

.mdi-network-strength-1::before {
  content: "\F8F3"; }

.mdi-network-strength-1-alert::before {
  content: "\F8F4"; }

.mdi-network-strength-2::before {
  content: "\F8F5"; }

.mdi-network-strength-2-alert::before {
  content: "\F8F6"; }

.mdi-network-strength-3::before {
  content: "\F8F7"; }

.mdi-network-strength-3-alert::before {
  content: "\F8F8"; }

.mdi-network-strength-4::before {
  content: "\F8F9"; }

.mdi-network-strength-4-alert::before {
  content: "\F8FA"; }

.mdi-network-strength-off::before {
  content: "\F8FB"; }

.mdi-network-strength-off-outline::before {
  content: "\F8FC"; }

.mdi-network-strength-outline::before {
  content: "\F8FD"; }

.mdi-new-box::before {
  content: "\F394"; }

.mdi-newspaper::before {
  content: "\F395"; }

.mdi-newspaper-minus::before {
  content: "\FF29"; }

.mdi-newspaper-plus::before {
  content: "\FF2A"; }

.mdi-newspaper-variant::before {
  content: "\F0023"; }

.mdi-newspaper-variant-multiple::before {
  content: "\F0024"; }

.mdi-newspaper-variant-multiple-outline::before {
  content: "\F0025"; }

.mdi-newspaper-variant-outline::before {
  content: "\F0026"; }

.mdi-nfc::before {
  content: "\F396"; }

.mdi-nfc-off::before {
  content: "\FE35"; }

.mdi-nfc-search-variant::before {
  content: "\FE36"; }

.mdi-nfc-tap::before {
  content: "\F397"; }

.mdi-nfc-variant::before {
  content: "\F398"; }

.mdi-nfc-variant-off::before {
  content: "\FE37"; }

.mdi-ninja::before {
  content: "\F773"; }

.mdi-nintendo-switch::before {
  content: "\F7E0"; }

.mdi-nix::before {
  content: "\F0130"; }

.mdi-nodejs::before {
  content: "\F399"; }

.mdi-noodles::before {
  content: "\F01A9"; }

.mdi-not-equal::before {
  content: "\F98C"; }

.mdi-not-equal-variant::before {
  content: "\F98D"; }

.mdi-note::before {
  content: "\F39A"; }

.mdi-note-multiple::before {
  content: "\F6B7"; }

.mdi-note-multiple-outline::before {
  content: "\F6B8"; }

.mdi-note-outline::before {
  content: "\F39B"; }

.mdi-note-plus::before {
  content: "\F39C"; }

.mdi-note-plus-outline::before {
  content: "\F39D"; }

.mdi-note-text::before {
  content: "\F39E"; }

.mdi-note-text-outline::before {
  content: "\F0202"; }

.mdi-notebook::before {
  content: "\F82D"; }

.mdi-notebook-multiple::before {
  content: "\FE38"; }

.mdi-notebook-outline::before {
  content: "\FEDC"; }

.mdi-notification-clear-all::before {
  content: "\F39F"; }

.mdi-npm::before {
  content: "\F6F6"; }

.mdi-npm-variant::before {
  content: "\F98E"; }

.mdi-npm-variant-outline::before {
  content: "\F98F"; }

.mdi-nuke::before {
  content: "\F6A3"; }

.mdi-null::before {
  content: "\F7E1"; }

.mdi-numeric::before {
  content: "\F3A0"; }

.mdi-numeric-0::before {
  content: "0"; }

.mdi-numeric-0-box::before {
  content: "\F3A1"; }

.mdi-numeric-0-box-multiple::before {
  content: "\FF2B"; }

.mdi-numeric-0-box-multiple-outline::before {
  content: "\F3A2"; }

.mdi-numeric-0-box-outline::before {
  content: "\F3A3"; }

.mdi-numeric-0-circle::before {
  content: "\FC7A"; }

.mdi-numeric-0-circle-outline::before {
  content: "\FC7B"; }

.mdi-numeric-1::before {
  content: "1"; }

.mdi-numeric-1-box::before {
  content: "\F3A4"; }

.mdi-numeric-1-box-multiple::before {
  content: "\FF2C"; }

.mdi-numeric-1-box-multiple-outline::before {
  content: "\F3A5"; }

.mdi-numeric-1-box-outline::before {
  content: "\F3A6"; }

.mdi-numeric-1-circle::before {
  content: "\FC7C"; }

.mdi-numeric-1-circle-outline::before {
  content: "\FC7D"; }

.mdi-numeric-10::before {
  content: "\F000A"; }

.mdi-numeric-10-box::before {
  content: "\FF9A"; }

.mdi-numeric-10-box-multiple::before {
  content: "\F000B"; }

.mdi-numeric-10-box-multiple-outline::before {
  content: "\F000C"; }

.mdi-numeric-10-box-outline::before {
  content: "\FF9B"; }

.mdi-numeric-10-circle::before {
  content: "\F000D"; }

.mdi-numeric-10-circle-outline::before {
  content: "\F000E"; }

.mdi-numeric-2::before {
  content: "2"; }

.mdi-numeric-2-box::before {
  content: "\F3A7"; }

.mdi-numeric-2-box-multiple::before {
  content: "\FF2D"; }

.mdi-numeric-2-box-multiple-outline::before {
  content: "\F3A8"; }

.mdi-numeric-2-box-outline::before {
  content: "\F3A9"; }

.mdi-numeric-2-circle::before {
  content: "\FC7E"; }

.mdi-numeric-2-circle-outline::before {
  content: "\FC7F"; }

.mdi-numeric-3::before {
  content: "3"; }

.mdi-numeric-3-box::before {
  content: "\F3AA"; }

.mdi-numeric-3-box-multiple::before {
  content: "\FF2E"; }

.mdi-numeric-3-box-multiple-outline::before {
  content: "\F3AB"; }

.mdi-numeric-3-box-outline::before {
  content: "\F3AC"; }

.mdi-numeric-3-circle::before {
  content: "\FC80"; }

.mdi-numeric-3-circle-outline::before {
  content: "\FC81"; }

.mdi-numeric-4::before {
  content: "4"; }

.mdi-numeric-4-box::before {
  content: "\F3AD"; }

.mdi-numeric-4-box-multiple::before {
  content: "\FF2F"; }

.mdi-numeric-4-box-multiple-outline::before {
  content: "\F3AE"; }

.mdi-numeric-4-box-outline::before {
  content: "\F3AF"; }

.mdi-numeric-4-circle::before {
  content: "\FC82"; }

.mdi-numeric-4-circle-outline::before {
  content: "\FC83"; }

.mdi-numeric-5::before {
  content: "5"; }

.mdi-numeric-5-box::before {
  content: "\F3B0"; }

.mdi-numeric-5-box-multiple::before {
  content: "\FF30"; }

.mdi-numeric-5-box-multiple-outline::before {
  content: "\F3B1"; }

.mdi-numeric-5-box-outline::before {
  content: "\F3B2"; }

.mdi-numeric-5-circle::before {
  content: "\FC84"; }

.mdi-numeric-5-circle-outline::before {
  content: "\FC85"; }

.mdi-numeric-6::before {
  content: "6"; }

.mdi-numeric-6-box::before {
  content: "\F3B3"; }

.mdi-numeric-6-box-multiple::before {
  content: "\FF31"; }

.mdi-numeric-6-box-multiple-outline::before {
  content: "\F3B4"; }

.mdi-numeric-6-box-outline::before {
  content: "\F3B5"; }

.mdi-numeric-6-circle::before {
  content: "\FC86"; }

.mdi-numeric-6-circle-outline::before {
  content: "\FC87"; }

.mdi-numeric-7::before {
  content: "7"; }

.mdi-numeric-7-box::before {
  content: "\F3B6"; }

.mdi-numeric-7-box-multiple::before {
  content: "\FF32"; }

.mdi-numeric-7-box-multiple-outline::before {
  content: "\F3B7"; }

.mdi-numeric-7-box-outline::before {
  content: "\F3B8"; }

.mdi-numeric-7-circle::before {
  content: "\FC88"; }

.mdi-numeric-7-circle-outline::before {
  content: "\FC89"; }

.mdi-numeric-8::before {
  content: "8"; }

.mdi-numeric-8-box::before {
  content: "\F3B9"; }

.mdi-numeric-8-box-multiple::before {
  content: "\FF33"; }

.mdi-numeric-8-box-multiple-outline::before {
  content: "\F3BA"; }

.mdi-numeric-8-box-outline::before {
  content: "\F3BB"; }

.mdi-numeric-8-circle::before {
  content: "\FC8A"; }

.mdi-numeric-8-circle-outline::before {
  content: "\FC8B"; }

.mdi-numeric-9::before {
  content: "9"; }

.mdi-numeric-9-box::before {
  content: "\F3BC"; }

.mdi-numeric-9-box-multiple::before {
  content: "\FF34"; }

.mdi-numeric-9-box-multiple-outline::before {
  content: "\F3BD"; }

.mdi-numeric-9-box-outline::before {
  content: "\F3BE"; }

.mdi-numeric-9-circle::before {
  content: "\FC8C"; }

.mdi-numeric-9-circle-outline::before {
  content: "\FC8D"; }

.mdi-numeric-9-plus::before {
  content: "\F000F"; }

.mdi-numeric-9-plus-box::before {
  content: "\F3BF"; }

.mdi-numeric-9-plus-box-multiple::before {
  content: "\FF35"; }

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\F3C0"; }

.mdi-numeric-9-plus-box-outline::before {
  content: "\F3C1"; }

.mdi-numeric-9-plus-circle::before {
  content: "\FC8E"; }

.mdi-numeric-9-plus-circle-outline::before {
  content: "\FC8F"; }

.mdi-numeric-negative-1::before {
  content: "\F0074"; }

.mdi-nut::before {
  content: "\F6F7"; }

.mdi-nutrition::before {
  content: "\F3C2"; }

.mdi-nuxt::before {
  content: "\F0131"; }

.mdi-oar::before {
  content: "\F67B"; }

.mdi-ocarina::before {
  content: "\FDBC"; }

.mdi-oci::before {
  content: "\F0314"; }

.mdi-ocr::before {
  content: "\F0165"; }

.mdi-octagon::before {
  content: "\F3C3"; }

.mdi-octagon-outline::before {
  content: "\F3C4"; }

.mdi-octagram::before {
  content: "\F6F8"; }

.mdi-octagram-outline::before {
  content: "\F774"; }

.mdi-odnoklassniki::before {
  content: "\F3C5"; }

.mdi-offer::before {
  content: "\F0246"; }

.mdi-office::before {
  content: "\F3C6"; }

.mdi-office-building::before {
  content: "\F990"; }

.mdi-oil::before {
  content: "\F3C7"; }

.mdi-oil-lamp::before {
  content: "\FF36"; }

.mdi-oil-level::before {
  content: "\F0075"; }

.mdi-oil-temperature::before {
  content: "\F0019"; }

.mdi-omega::before {
  content: "\F3C9"; }

.mdi-one-up::before {
  content: "\FB89"; }

.mdi-onedrive::before {
  content: "\F3CA"; }

.mdi-onenote::before {
  content: "\F746"; }

.mdi-onepassword::before {
  content: "\F880"; }

.mdi-opacity::before {
  content: "\F5CC"; }

.mdi-open-in-app::before {
  content: "\F3CB"; }

.mdi-open-in-new::before {
  content: "\F3CC"; }

.mdi-open-source-initiative::before {
  content: "\FB8A"; }

.mdi-openid::before {
  content: "\F3CD"; }

.mdi-opera::before {
  content: "\F3CE"; }

.mdi-orbit::before {
  content: "\F018"; }

.mdi-origin::before {
  content: "\FB2B"; }

.mdi-ornament::before {
  content: "\F3CF"; }

.mdi-ornament-variant::before {
  content: "\F3D0"; }

.mdi-outdoor-lamp::before {
  content: "\F0076"; }

.mdi-outlook::before {
  content: "\FCFE"; }

.mdi-overscan::before {
  content: "\F0027"; }

.mdi-owl::before {
  content: "\F3D2"; }

.mdi-pac-man::before {
  content: "\FB8B"; }

.mdi-package::before {
  content: "\F3D3"; }

.mdi-package-down::before {
  content: "\F3D4"; }

.mdi-package-up::before {
  content: "\F3D5"; }

.mdi-package-variant::before {
  content: "\F3D6"; }

.mdi-package-variant-closed::before {
  content: "\F3D7"; }

.mdi-page-first::before {
  content: "\F600"; }

.mdi-page-last::before {
  content: "\F601"; }

.mdi-page-layout-body::before {
  content: "\F6F9"; }

.mdi-page-layout-footer::before {
  content: "\F6FA"; }

.mdi-page-layout-header::before {
  content: "\F6FB"; }

.mdi-page-layout-header-footer::before {
  content: "\FF9C"; }

.mdi-page-layout-sidebar-left::before {
  content: "\F6FC"; }

.mdi-page-layout-sidebar-right::before {
  content: "\F6FD"; }

.mdi-page-next::before {
  content: "\FB8C"; }

.mdi-page-next-outline::before {
  content: "\FB8D"; }

.mdi-page-previous::before {
  content: "\FB8E"; }

.mdi-page-previous-outline::before {
  content: "\FB8F"; }

.mdi-palette::before {
  content: "\F3D8"; }

.mdi-palette-advanced::before {
  content: "\F3D9"; }

.mdi-palette-outline::before {
  content: "\FE6C"; }

.mdi-palette-swatch::before {
  content: "\F8B4"; }

.mdi-palette-swatch-outline::before {
  content: "\F0387"; }

.mdi-palm-tree::before {
  content: "\F0077"; }

.mdi-pan::before {
  content: "\FB90"; }

.mdi-pan-bottom-left::before {
  content: "\FB91"; }

.mdi-pan-bottom-right::before {
  content: "\FB92"; }

.mdi-pan-down::before {
  content: "\FB93"; }

.mdi-pan-horizontal::before {
  content: "\FB94"; }

.mdi-pan-left::before {
  content: "\FB95"; }

.mdi-pan-right::before {
  content: "\FB96"; }

.mdi-pan-top-left::before {
  content: "\FB97"; }

.mdi-pan-top-right::before {
  content: "\FB98"; }

.mdi-pan-up::before {
  content: "\FB99"; }

.mdi-pan-vertical::before {
  content: "\FB9A"; }

.mdi-panda::before {
  content: "\F3DA"; }

.mdi-pandora::before {
  content: "\F3DB"; }

.mdi-panorama::before {
  content: "\F3DC"; }

.mdi-panorama-fisheye::before {
  content: "\F3DD"; }

.mdi-panorama-horizontal::before {
  content: "\F3DE"; }

.mdi-panorama-vertical::before {
  content: "\F3DF"; }

.mdi-panorama-wide-angle::before {
  content: "\F3E0"; }

.mdi-paper-cut-vertical::before {
  content: "\F3E1"; }

.mdi-paper-roll::before {
  content: "\F0182"; }

.mdi-paper-roll-outline::before {
  content: "\F0183"; }

.mdi-paperclip::before {
  content: "\F3E2"; }

.mdi-parachute::before {
  content: "\FC90"; }

.mdi-parachute-outline::before {
  content: "\FC91"; }

.mdi-parking::before {
  content: "\F3E3"; }

.mdi-party-popper::before {
  content: "\F0078"; }

.mdi-passport::before {
  content: "\F7E2"; }

.mdi-passport-biometric::before {
  content: "\FDBD"; }

.mdi-pasta::before {
  content: "\F018B"; }

.mdi-patio-heater::before {
  content: "\FF9D"; }

.mdi-patreon::before {
  content: "\F881"; }

.mdi-pause::before {
  content: "\F3E4"; }

.mdi-pause-circle::before {
  content: "\F3E5"; }

.mdi-pause-circle-outline::before {
  content: "\F3E6"; }

.mdi-pause-octagon::before {
  content: "\F3E7"; }

.mdi-pause-octagon-outline::before {
  content: "\F3E8"; }

.mdi-paw::before {
  content: "\F3E9"; }

.mdi-paw-off::before {
  content: "\F657"; }

.mdi-paypal::before {
  content: "\F882"; }

.mdi-pdf-box::before {
  content: "\FE39"; }

.mdi-peace::before {
  content: "\F883"; }

.mdi-peanut::before {
  content: "\F001E"; }

.mdi-peanut-off::before {
  content: "\F001F"; }

.mdi-peanut-off-outline::before {
  content: "\F0021"; }

.mdi-peanut-outline::before {
  content: "\F0020"; }

.mdi-pen::before {
  content: "\F3EA"; }

.mdi-pen-lock::before {
  content: "\FDBE"; }

.mdi-pen-minus::before {
  content: "\FDBF"; }

.mdi-pen-off::before {
  content: "\FDC0"; }

.mdi-pen-plus::before {
  content: "\FDC1"; }

.mdi-pen-remove::before {
  content: "\FDC2"; }

.mdi-pencil::before {
  content: "\F3EB"; }

.mdi-pencil-box::before {
  content: "\F3EC"; }

.mdi-pencil-box-multiple::before {
  content: "\F016F"; }

.mdi-pencil-box-multiple-outline::before {
  content: "\F0170"; }

.mdi-pencil-box-outline::before {
  content: "\F3ED"; }

.mdi-pencil-circle::before {
  content: "\F6FE"; }

.mdi-pencil-circle-outline::before {
  content: "\F775"; }

.mdi-pencil-lock::before {
  content: "\F3EE"; }

.mdi-pencil-lock-outline::before {
  content: "\FDC3"; }

.mdi-pencil-minus::before {
  content: "\FDC4"; }

.mdi-pencil-minus-outline::before {
  content: "\FDC5"; }

.mdi-pencil-off::before {
  content: "\F3EF"; }

.mdi-pencil-off-outline::before {
  content: "\FDC6"; }

.mdi-pencil-outline::before {
  content: "\FC92"; }

.mdi-pencil-plus::before {
  content: "\FDC7"; }

.mdi-pencil-plus-outline::before {
  content: "\FDC8"; }

.mdi-pencil-remove::before {
  content: "\FDC9"; }

.mdi-pencil-remove-outline::before {
  content: "\FDCA"; }

.mdi-pencil-ruler::before {
  content: "\F037E"; }

.mdi-penguin::before {
  content: "\FEDD"; }

.mdi-pentagon::before {
  content: "\F6FF"; }

.mdi-pentagon-outline::before {
  content: "\F700"; }

.mdi-percent::before {
  content: "\F3F0"; }

.mdi-percent-outline::before {
  content: "\F02A3"; }

.mdi-periodic-table::before {
  content: "\F8B5"; }

.mdi-periodic-table-co::before {
  content: "\F0329"; }

.mdi-periodic-table-co2::before {
  content: "\F7E3"; }

.mdi-periscope::before {
  content: "\F747"; }

.mdi-perspective-less::before {
  content: "\FCFF"; }

.mdi-perspective-more::before {
  content: "\FD00"; }

.mdi-pharmacy::before {
  content: "\F3F1"; }

.mdi-phone::before {
  content: "\F3F2"; }

.mdi-phone-alert::before {
  content: "\FF37"; }

.mdi-phone-alert-outline::before {
  content: "\F01B9"; }

.mdi-phone-bluetooth::before {
  content: "\F3F3"; }

.mdi-phone-bluetooth-outline::before {
  content: "\F01BA"; }

.mdi-phone-cancel::before {
  content: "\F00E7"; }

.mdi-phone-cancel-outline::before {
  content: "\F01BB"; }

.mdi-phone-check::before {
  content: "\F01D4"; }

.mdi-phone-check-outline::before {
  content: "\F01D5"; }

.mdi-phone-classic::before {
  content: "\F602"; }

.mdi-phone-classic-off::before {
  content: "\F02A4"; }

.mdi-phone-forward::before {
  content: "\F3F4"; }

.mdi-phone-forward-outline::before {
  content: "\F01BC"; }

.mdi-phone-hangup::before {
  content: "\F3F5"; }

.mdi-phone-hangup-outline::before {
  content: "\F01BD"; }

.mdi-phone-in-talk::before {
  content: "\F3F6"; }

.mdi-phone-in-talk-outline::before {
  content: "\F01AD"; }

.mdi-phone-incoming::before {
  content: "\F3F7"; }

.mdi-phone-incoming-outline::before {
  content: "\F01BE"; }

.mdi-phone-lock::before {
  content: "\F3F8"; }

.mdi-phone-lock-outline::before {
  content: "\F01BF"; }

.mdi-phone-log::before {
  content: "\F3F9"; }

.mdi-phone-log-outline::before {
  content: "\F01C0"; }

.mdi-phone-message::before {
  content: "\F01C1"; }

.mdi-phone-message-outline::before {
  content: "\F01C2"; }

.mdi-phone-minus::before {
  content: "\F658"; }

.mdi-phone-minus-outline::before {
  content: "\F01C3"; }

.mdi-phone-missed::before {
  content: "\F3FA"; }

.mdi-phone-missed-outline::before {
  content: "\F01D0"; }

.mdi-phone-off::before {
  content: "\FDCB"; }

.mdi-phone-off-outline::before {
  content: "\F01D1"; }

.mdi-phone-outgoing::before {
  content: "\F3FB"; }

.mdi-phone-outgoing-outline::before {
  content: "\F01C4"; }

.mdi-phone-outline::before {
  content: "\FDCC"; }

.mdi-phone-paused::before {
  content: "\F3FC"; }

.mdi-phone-paused-outline::before {
  content: "\F01C5"; }

.mdi-phone-plus::before {
  content: "\F659"; }

.mdi-phone-plus-outline::before {
  content: "\F01C6"; }

.mdi-phone-return::before {
  content: "\F82E"; }

.mdi-phone-return-outline::before {
  content: "\F01C7"; }

.mdi-phone-ring::before {
  content: "\F01D6"; }

.mdi-phone-ring-outline::before {
  content: "\F01D7"; }

.mdi-phone-rotate-landscape::before {
  content: "\F884"; }

.mdi-phone-rotate-portrait::before {
  content: "\F885"; }

.mdi-phone-settings::before {
  content: "\F3FD"; }

.mdi-phone-settings-outline::before {
  content: "\F01C8"; }

.mdi-phone-voip::before {
  content: "\F3FE"; }

.mdi-pi::before {
  content: "\F3FF"; }

.mdi-pi-box::before {
  content: "\F400"; }

.mdi-pi-hole::before {
  content: "\FDCD"; }

.mdi-piano::before {
  content: "\F67C"; }

.mdi-pickaxe::before {
  content: "\F8B6"; }

.mdi-picture-in-picture-bottom-right::before {
  content: "\FE3A"; }

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "\FE3B"; }

.mdi-picture-in-picture-top-right::before {
  content: "\FE3C"; }

.mdi-picture-in-picture-top-right-outline::before {
  content: "\FE3D"; }

.mdi-pier::before {
  content: "\F886"; }

.mdi-pier-crane::before {
  content: "\F887"; }

.mdi-pig::before {
  content: "\F401"; }

.mdi-pig-variant::before {
  content: "\F0028"; }

.mdi-piggy-bank::before {
  content: "\F0029"; }

.mdi-pill::before {
  content: "\F402"; }

.mdi-pillar::before {
  content: "\F701"; }

.mdi-pin::before {
  content: "\F403"; }

.mdi-pin-off::before {
  content: "\F404"; }

.mdi-pin-off-outline::before {
  content: "\F92F"; }

.mdi-pin-outline::before {
  content: "\F930"; }

.mdi-pine-tree::before {
  content: "\F405"; }

.mdi-pine-tree-box::before {
  content: "\F406"; }

.mdi-pinterest::before {
  content: "\F407"; }

.mdi-pinterest-box::before {
  content: "\F408"; }

.mdi-pinwheel::before {
  content: "\FAD4"; }

.mdi-pinwheel-outline::before {
  content: "\FAD5"; }

.mdi-pipe::before {
  content: "\F7E4"; }

.mdi-pipe-disconnected::before {
  content: "\F7E5"; }

.mdi-pipe-leak::before {
  content: "\F888"; }

.mdi-pipe-wrench::before {
  content: "\F037F"; }

.mdi-pirate::before {
  content: "\FA07"; }

.mdi-pistol::before {
  content: "\F702"; }

.mdi-piston::before {
  content: "\F889"; }

.mdi-pizza::before {
  content: "\F409"; }

.mdi-play::before {
  content: "\F40A"; }

.mdi-play-box::before {
  content: "\F02A5"; }

.mdi-play-box-outline::before {
  content: "\F40B"; }

.mdi-play-circle::before {
  content: "\F40C"; }

.mdi-play-circle-outline::before {
  content: "\F40D"; }

.mdi-play-network::before {
  content: "\F88A"; }

.mdi-play-network-outline::before {
  content: "\FC93"; }

.mdi-play-outline::before {
  content: "\FF38"; }

.mdi-play-pause::before {
  content: "\F40E"; }

.mdi-play-protected-content::before {
  content: "\F40F"; }

.mdi-play-speed::before {
  content: "\F8FE"; }

.mdi-playlist-check::before {
  content: "\F5C7"; }

.mdi-playlist-edit::before {
  content: "\F8FF"; }

.mdi-playlist-minus::before {
  content: "\F410"; }

.mdi-playlist-music::before {
  content: "\FC94"; }

.mdi-playlist-music-outline::before {
  content: "\FC95"; }

.mdi-playlist-play::before {
  content: "\F411"; }

.mdi-playlist-plus::before {
  content: "\F412"; }

.mdi-playlist-remove::before {
  content: "\F413"; }

.mdi-playlist-star::before {
  content: "\FDCE"; }

.mdi-playstation::before {
  content: "\F414"; }

.mdi-plex::before {
  content: "\F6B9"; }

.mdi-plus::before {
  content: "\F415"; }

.mdi-plus-box::before {
  content: "\F416"; }

.mdi-plus-box-multiple::before {
  content: "\F334"; }

.mdi-plus-box-multiple-outline::before {
  content: "\F016E"; }

.mdi-plus-box-outline::before {
  content: "\F703"; }

.mdi-plus-circle::before {
  content: "\F417"; }

.mdi-plus-circle-multiple-outline::before {
  content: "\F418"; }

.mdi-plus-circle-outline::before {
  content: "\F419"; }

.mdi-plus-minus::before {
  content: "\F991"; }

.mdi-plus-minus-box::before {
  content: "\F992"; }

.mdi-plus-network::before {
  content: "\F41A"; }

.mdi-plus-network-outline::before {
  content: "\FC96"; }

.mdi-plus-one::before {
  content: "\F41B"; }

.mdi-plus-outline::before {
  content: "\F704"; }

.mdi-plus-thick::before {
  content: "\F0217"; }

.mdi-pocket::before {
  content: "\F41C"; }

.mdi-podcast::before {
  content: "\F993"; }

.mdi-podium::before {
  content: "\FD01"; }

.mdi-podium-bronze::before {
  content: "\FD02"; }

.mdi-podium-gold::before {
  content: "\FD03"; }

.mdi-podium-silver::before {
  content: "\FD04"; }

.mdi-point-of-sale::before {
  content: "\FD6E"; }

.mdi-pokeball::before {
  content: "\F41D"; }

.mdi-pokemon-go::before {
  content: "\FA08"; }

.mdi-poker-chip::before {
  content: "\F82F"; }

.mdi-polaroid::before {
  content: "\F41E"; }

.mdi-police-badge::before {
  content: "\F0192"; }

.mdi-police-badge-outline::before {
  content: "\F0193"; }

.mdi-poll::before {
  content: "\F41F"; }

.mdi-poll-box::before {
  content: "\F420"; }

.mdi-poll-box-outline::before {
  content: "\F02A6"; }

.mdi-polymer::before {
  content: "\F421"; }

.mdi-pool::before {
  content: "\F606"; }

.mdi-popcorn::before {
  content: "\F422"; }

.mdi-post::before {
  content: "\F002A"; }

.mdi-post-outline::before {
  content: "\F002B"; }

.mdi-postage-stamp::before {
  content: "\FC97"; }

.mdi-pot::before {
  content: "\F65A"; }

.mdi-pot-mix::before {
  content: "\F65B"; }

.mdi-pound::before {
  content: "\F423"; }

.mdi-pound-box::before {
  content: "\F424"; }

.mdi-pound-box-outline::before {
  content: "\F01AA"; }

.mdi-power::before {
  content: "\F425"; }

.mdi-power-cycle::before {
  content: "\F900"; }

.mdi-power-off::before {
  content: "\F901"; }

.mdi-power-on::before {
  content: "\F902"; }

.mdi-power-plug::before {
  content: "\F6A4"; }

.mdi-power-plug-off::before {
  content: "\F6A5"; }

.mdi-power-settings::before {
  content: "\F426"; }

.mdi-power-sleep::before {
  content: "\F903"; }

.mdi-power-socket::before {
  content: "\F427"; }

.mdi-power-socket-au::before {
  content: "\F904"; }

.mdi-power-socket-de::before {
  content: "\F0132"; }

.mdi-power-socket-eu::before {
  content: "\F7E6"; }

.mdi-power-socket-fr::before {
  content: "\F0133"; }

.mdi-power-socket-jp::before {
  content: "\F0134"; }

.mdi-power-socket-uk::before {
  content: "\F7E7"; }

.mdi-power-socket-us::before {
  content: "\F7E8"; }

.mdi-power-standby::before {
  content: "\F905"; }

.mdi-powershell::before {
  content: "\FA09"; }

.mdi-prescription::before {
  content: "\F705"; }

.mdi-presentation::before {
  content: "\F428"; }

.mdi-presentation-play::before {
  content: "\F429"; }

.mdi-printer::before {
  content: "\F42A"; }

.mdi-printer-3d::before {
  content: "\F42B"; }

.mdi-printer-3d-nozzle::before {
  content: "\FE3E"; }

.mdi-printer-3d-nozzle-alert::before {
  content: "\F01EB"; }

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\F01EC"; }

.mdi-printer-3d-nozzle-outline::before {
  content: "\FE3F"; }

.mdi-printer-alert::before {
  content: "\F42C"; }

.mdi-printer-check::before {
  content: "\F0171"; }

.mdi-printer-off::before {
  content: "\FE40"; }

.mdi-printer-pos::before {
  content: "\F0079"; }

.mdi-printer-settings::before {
  content: "\F706"; }

.mdi-printer-wireless::before {
  content: "\FA0A"; }

.mdi-priority-high::before {
  content: "\F603"; }

.mdi-priority-low::before {
  content: "\F604"; }

.mdi-professional-hexagon::before {
  content: "\F42D"; }

.mdi-progress-alert::before {
  content: "\FC98"; }

.mdi-progress-check::before {
  content: "\F994"; }

.mdi-progress-clock::before {
  content: "\F995"; }

.mdi-progress-close::before {
  content: "\F0135"; }

.mdi-progress-download::before {
  content: "\F996"; }

.mdi-progress-upload::before {
  content: "\F997"; }

.mdi-progress-wrench::before {
  content: "\FC99"; }

.mdi-projector::before {
  content: "\F42E"; }

.mdi-projector-screen::before {
  content: "\F42F"; }

.mdi-propane-tank::before {
  content: "\F0382"; }

.mdi-propane-tank-outline::before {
  content: "\F0383"; }

.mdi-protocol::before {
  content: "\FFF9"; }

.mdi-publish::before {
  content: "\F6A6"; }

.mdi-pulse::before {
  content: "\F430"; }

.mdi-pumpkin::before {
  content: "\FB9B"; }

.mdi-purse::before {
  content: "\FF39"; }

.mdi-purse-outline::before {
  content: "\FF3A"; }

.mdi-puzzle::before {
  content: "\F431"; }

.mdi-puzzle-outline::before {
  content: "\FA65"; }

.mdi-qi::before {
  content: "\F998"; }

.mdi-qqchat::before {
  content: "\F605"; }

.mdi-qrcode::before {
  content: "\F432"; }

.mdi-qrcode-edit::before {
  content: "\F8B7"; }

.mdi-qrcode-minus::before {
  content: "\F01B7"; }

.mdi-qrcode-plus::before {
  content: "\F01B6"; }

.mdi-qrcode-remove::before {
  content: "\F01B8"; }

.mdi-qrcode-scan::before {
  content: "\F433"; }

.mdi-quadcopter::before {
  content: "\F434"; }

.mdi-quality-high::before {
  content: "\F435"; }

.mdi-quality-low::before {
  content: "\FA0B"; }

.mdi-quality-medium::before {
  content: "\FA0C"; }

.mdi-quicktime::before {
  content: "\F436"; }

.mdi-quora::before {
  content: "\FD05"; }

.mdi-rabbit::before {
  content: "\F906"; }

.mdi-racing-helmet::before {
  content: "\FD6F"; }

.mdi-racquetball::before {
  content: "\FD70"; }

.mdi-radar::before {
  content: "\F437"; }

.mdi-radiator::before {
  content: "\F438"; }

.mdi-radiator-disabled::before {
  content: "\FAD6"; }

.mdi-radiator-off::before {
  content: "\FAD7"; }

.mdi-radio::before {
  content: "\F439"; }

.mdi-radio-am::before {
  content: "\FC9A"; }

.mdi-radio-fm::before {
  content: "\FC9B"; }

.mdi-radio-handheld::before {
  content: "\F43A"; }

.mdi-radio-off::before {
  content: "\F0247"; }

.mdi-radio-tower::before {
  content: "\F43B"; }

.mdi-radioactive::before {
  content: "\F43C"; }

.mdi-radioactive-off::before {
  content: "\FEDE"; }

.mdi-radiobox-blank::before {
  content: "\F43D"; }

.mdi-radiobox-marked::before {
  content: "\F43E"; }

.mdi-radius::before {
  content: "\FC9C"; }

.mdi-radius-outline::before {
  content: "\FC9D"; }

.mdi-railroad-light::before {
  content: "\FF3B"; }

.mdi-raspberry-pi::before {
  content: "\F43F"; }

.mdi-ray-end::before {
  content: "\F440"; }

.mdi-ray-end-arrow::before {
  content: "\F441"; }

.mdi-ray-start::before {
  content: "\F442"; }

.mdi-ray-start-arrow::before {
  content: "\F443"; }

.mdi-ray-start-end::before {
  content: "\F444"; }

.mdi-ray-vertex::before {
  content: "\F445"; }

.mdi-react::before {
  content: "\F707"; }

.mdi-read::before {
  content: "\F447"; }

.mdi-receipt::before {
  content: "\F449"; }

.mdi-record::before {
  content: "\F44A"; }

.mdi-record-circle::before {
  content: "\FEDF"; }

.mdi-record-circle-outline::before {
  content: "\FEE0"; }

.mdi-record-player::before {
  content: "\F999"; }

.mdi-record-rec::before {
  content: "\F44B"; }

.mdi-rectangle::before {
  content: "\FE41"; }

.mdi-rectangle-outline::before {
  content: "\FE42"; }

.mdi-recycle::before {
  content: "\F44C"; }

.mdi-reddit::before {
  content: "\F44D"; }

.mdi-redhat::before {
  content: "\F0146"; }

.mdi-redo::before {
  content: "\F44E"; }

.mdi-redo-variant::before {
  content: "\F44F"; }

.mdi-reflect-horizontal::before {
  content: "\FA0D"; }

.mdi-reflect-vertical::before {
  content: "\FA0E"; }

.mdi-refresh::before {
  content: "\F450"; }

.mdi-refresh-circle::before {
  content: "\F03A2"; }

.mdi-regex::before {
  content: "\F451"; }

.mdi-registered-trademark::before {
  content: "\FA66"; }

.mdi-relative-scale::before {
  content: "\F452"; }

.mdi-reload::before {
  content: "\F453"; }

.mdi-reload-alert::before {
  content: "\F0136"; }

.mdi-reminder::before {
  content: "\F88B"; }

.mdi-remote::before {
  content: "\F454"; }

.mdi-remote-desktop::before {
  content: "\F8B8"; }

.mdi-remote-off::before {
  content: "\FEE1"; }

.mdi-remote-tv::before {
  content: "\FEE2"; }

.mdi-remote-tv-off::before {
  content: "\FEE3"; }

.mdi-rename-box::before {
  content: "\F455"; }

.mdi-reorder-horizontal::before {
  content: "\F687"; }

.mdi-reorder-vertical::before {
  content: "\F688"; }

.mdi-repeat::before {
  content: "\F456"; }

.mdi-repeat-off::before {
  content: "\F457"; }

.mdi-repeat-once::before {
  content: "\F458"; }

.mdi-replay::before {
  content: "\F459"; }

.mdi-reply::before {
  content: "\F45A"; }

.mdi-reply-all::before {
  content: "\F45B"; }

.mdi-reply-all-outline::before {
  content: "\FF3C"; }

.mdi-reply-circle::before {
  content: "\F01D9"; }

.mdi-reply-outline::before {
  content: "\FF3D"; }

.mdi-reproduction::before {
  content: "\F45C"; }

.mdi-resistor::before {
  content: "\FB1F"; }

.mdi-resistor-nodes::before {
  content: "\FB20"; }

.mdi-resize::before {
  content: "\FA67"; }

.mdi-resize-bottom-right::before {
  content: "\F45D"; }

.mdi-responsive::before {
  content: "\F45E"; }

.mdi-restart::before {
  content: "\F708"; }

.mdi-restart-alert::before {
  content: "\F0137"; }

.mdi-restart-off::before {
  content: "\FD71"; }

.mdi-restore::before {
  content: "\F99A"; }

.mdi-restore-alert::before {
  content: "\F0138"; }

.mdi-rewind::before {
  content: "\F45F"; }

.mdi-rewind-10::before {
  content: "\FD06"; }

.mdi-rewind-30::before {
  content: "\FD72"; }

.mdi-rewind-5::before {
  content: "\F0224"; }

.mdi-rewind-outline::before {
  content: "\F709"; }

.mdi-rhombus::before {
  content: "\F70A"; }

.mdi-rhombus-medium::before {
  content: "\FA0F"; }

.mdi-rhombus-outline::before {
  content: "\F70B"; }

.mdi-rhombus-split::before {
  content: "\FA10"; }

.mdi-ribbon::before {
  content: "\F460"; }

.mdi-rice::before {
  content: "\F7E9"; }

.mdi-ring::before {
  content: "\F7EA"; }

.mdi-rivet::before {
  content: "\FE43"; }

.mdi-road::before {
  content: "\F461"; }

.mdi-road-variant::before {
  content: "\F462"; }

.mdi-robber::before {
  content: "\F007A"; }

.mdi-robot::before {
  content: "\F6A8"; }

.mdi-robot-industrial::before {
  content: "\FB21"; }

.mdi-robot-mower::before {
  content: "\F0222"; }

.mdi-robot-mower-outline::before {
  content: "\F021E"; }

.mdi-robot-vacuum::before {
  content: "\F70C"; }

.mdi-robot-vacuum-variant::before {
  content: "\F907"; }

.mdi-rocket::before {
  content: "\F463"; }

.mdi-rodent::before {
  content: "\F0352"; }

.mdi-roller-skate::before {
  content: "\FD07"; }

.mdi-rollerblade::before {
  content: "\FD08"; }

.mdi-rollupjs::before {
  content: "\FB9C"; }

.mdi-roman-numeral-1::before {
  content: "\F00B3"; }

.mdi-roman-numeral-10::before {
  content: "\F00BC"; }

.mdi-roman-numeral-2::before {
  content: "\F00B4"; }

.mdi-roman-numeral-3::before {
  content: "\F00B5"; }

.mdi-roman-numeral-4::before {
  content: "\F00B6"; }

.mdi-roman-numeral-5::before {
  content: "\F00B7"; }

.mdi-roman-numeral-6::before {
  content: "\F00B8"; }

.mdi-roman-numeral-7::before {
  content: "\F00B9"; }

.mdi-roman-numeral-8::before {
  content: "\F00BA"; }

.mdi-roman-numeral-9::before {
  content: "\F00BB"; }

.mdi-room-service::before {
  content: "\F88C"; }

.mdi-room-service-outline::before {
  content: "\FD73"; }

.mdi-rotate-3d::before {
  content: "\FEE4"; }

.mdi-rotate-3d-variant::before {
  content: "\F464"; }

.mdi-rotate-left::before {
  content: "\F465"; }

.mdi-rotate-left-variant::before {
  content: "\F466"; }

.mdi-rotate-orbit::before {
  content: "\FD74"; }

.mdi-rotate-right::before {
  content: "\F467"; }

.mdi-rotate-right-variant::before {
  content: "\F468"; }

.mdi-rounded-corner::before {
  content: "\F607"; }

.mdi-router::before {
  content: "\F020D"; }

.mdi-router-wireless::before {
  content: "\F469"; }

.mdi-router-wireless-settings::before {
  content: "\FA68"; }

.mdi-routes::before {
  content: "\F46A"; }

.mdi-routes-clock::before {
  content: "\F007B"; }

.mdi-rowing::before {
  content: "\F608"; }

.mdi-rss::before {
  content: "\F46B"; }

.mdi-rss-box::before {
  content: "\F46C"; }

.mdi-rss-off::before {
  content: "\FF3E"; }

.mdi-ruby::before {
  content: "\FD09"; }

.mdi-rugby::before {
  content: "\FD75"; }

.mdi-ruler::before {
  content: "\F46D"; }

.mdi-ruler-square::before {
  content: "\FC9E"; }

.mdi-ruler-square-compass::before {
  content: "\FEDB"; }

.mdi-run::before {
  content: "\F70D"; }

.mdi-run-fast::before {
  content: "\F46E"; }

.mdi-rv-truck::before {
  content: "\F01FF"; }

.mdi-sack::before {
  content: "\FD0A"; }

.mdi-sack-percent::before {
  content: "\FD0B"; }

.mdi-safe::before {
  content: "\FA69"; }

.mdi-safe-square::before {
  content: "\F02A7"; }

.mdi-safe-square-outline::before {
  content: "\F02A8"; }

.mdi-safety-goggles::before {
  content: "\FD0C"; }

.mdi-sailing::before {
  content: "\FEE5"; }

.mdi-sale::before {
  content: "\F46F"; }

.mdi-salesforce::before {
  content: "\F88D"; }

.mdi-sass::before {
  content: "\F7EB"; }

.mdi-satellite::before {
  content: "\F470"; }

.mdi-satellite-uplink::before {
  content: "\F908"; }

.mdi-satellite-variant::before {
  content: "\F471"; }

.mdi-sausage::before {
  content: "\F8B9"; }

.mdi-saw-blade::before {
  content: "\FE44"; }

.mdi-saxophone::before {
  content: "\F609"; }

.mdi-scale::before {
  content: "\F472"; }

.mdi-scale-balance::before {
  content: "\F5D1"; }

.mdi-scale-bathroom::before {
  content: "\F473"; }

.mdi-scale-off::before {
  content: "\F007C"; }

.mdi-scanner::before {
  content: "\F6AA"; }

.mdi-scanner-off::before {
  content: "\F909"; }

.mdi-scatter-plot::before {
  content: "\FEE6"; }

.mdi-scatter-plot-outline::before {
  content: "\FEE7"; }

.mdi-school::before {
  content: "\F474"; }

.mdi-school-outline::before {
  content: "\F01AB"; }

.mdi-scissors-cutting::before {
  content: "\FA6A"; }

.mdi-scooter::before {
  content: "\F0214"; }

.mdi-scoreboard::before {
  content: "\F02A9"; }

.mdi-scoreboard-outline::before {
  content: "\F02AA"; }

.mdi-screen-rotation::before {
  content: "\F475"; }

.mdi-screen-rotation-lock::before {
  content: "\F476"; }

.mdi-screw-flat-top::before {
  content: "\FDCF"; }

.mdi-screw-lag::before {
  content: "\FE54"; }

.mdi-screw-machine-flat-top::before {
  content: "\FE55"; }

.mdi-screw-machine-round-top::before {
  content: "\FE56"; }

.mdi-screw-round-top::before {
  content: "\FE57"; }

.mdi-screwdriver::before {
  content: "\F477"; }

.mdi-script::before {
  content: "\FB9D"; }

.mdi-script-outline::before {
  content: "\F478"; }

.mdi-script-text::before {
  content: "\FB9E"; }

.mdi-script-text-outline::before {
  content: "\FB9F"; }

.mdi-sd::before {
  content: "\F479"; }

.mdi-seal::before {
  content: "\F47A"; }

.mdi-seal-variant::before {
  content: "\FFFA"; }

.mdi-search-web::before {
  content: "\F70E"; }

.mdi-seat::before {
  content: "\FC9F"; }

.mdi-seat-flat::before {
  content: "\F47B"; }

.mdi-seat-flat-angled::before {
  content: "\F47C"; }

.mdi-seat-individual-suite::before {
  content: "\F47D"; }

.mdi-seat-legroom-extra::before {
  content: "\F47E"; }

.mdi-seat-legroom-normal::before {
  content: "\F47F"; }

.mdi-seat-legroom-reduced::before {
  content: "\F480"; }

.mdi-seat-outline::before {
  content: "\FCA0"; }

.mdi-seat-passenger::before {
  content: "\F0274"; }

.mdi-seat-recline-extra::before {
  content: "\F481"; }

.mdi-seat-recline-normal::before {
  content: "\F482"; }

.mdi-seatbelt::before {
  content: "\FCA1"; }

.mdi-security::before {
  content: "\F483"; }

.mdi-security-network::before {
  content: "\F484"; }

.mdi-seed::before {
  content: "\FE45"; }

.mdi-seed-outline::before {
  content: "\FE46"; }

.mdi-segment::before {
  content: "\FEE8"; }

.mdi-select::before {
  content: "\F485"; }

.mdi-select-all::before {
  content: "\F486"; }

.mdi-select-color::before {
  content: "\FD0D"; }

.mdi-select-compare::before {
  content: "\FAD8"; }

.mdi-select-drag::before {
  content: "\FA6B"; }

.mdi-select-group::before {
  content: "\FF9F"; }

.mdi-select-inverse::before {
  content: "\F487"; }

.mdi-select-marker::before {
  content: "\F02AB"; }

.mdi-select-multiple::before {
  content: "\F02AC"; }

.mdi-select-multiple-marker::before {
  content: "\F02AD"; }

.mdi-select-off::before {
  content: "\F488"; }

.mdi-select-place::before {
  content: "\FFFB"; }

.mdi-select-search::before {
  content: "\F022F"; }

.mdi-selection::before {
  content: "\F489"; }

.mdi-selection-drag::before {
  content: "\FA6C"; }

.mdi-selection-ellipse::before {
  content: "\FD0E"; }

.mdi-selection-ellipse-arrow-inside::before {
  content: "\FF3F"; }

.mdi-selection-marker::before {
  content: "\F02AE"; }

.mdi-selection-multiple-marker::before {
  content: "\F02AF"; }

.mdi-selection-mutliple::before {
  content: "\F02B0"; }

.mdi-selection-off::before {
  content: "\F776"; }

.mdi-selection-search::before {
  content: "\F0230"; }

.mdi-semantic-web::before {
  content: "\F0341"; }

.mdi-send::before {
  content: "\F48A"; }

.mdi-send-check::before {
  content: "\F018C"; }

.mdi-send-check-outline::before {
  content: "\F018D"; }

.mdi-send-circle::before {
  content: "\FE58"; }

.mdi-send-circle-outline::before {
  content: "\FE59"; }

.mdi-send-clock::before {
  content: "\F018E"; }

.mdi-send-clock-outline::before {
  content: "\F018F"; }

.mdi-send-lock::before {
  content: "\F7EC"; }

.mdi-send-lock-outline::before {
  content: "\F0191"; }

.mdi-send-outline::before {
  content: "\F0190"; }

.mdi-serial-port::before {
  content: "\F65C"; }

.mdi-server::before {
  content: "\F48B"; }

.mdi-server-minus::before {
  content: "\F48C"; }

.mdi-server-network::before {
  content: "\F48D"; }

.mdi-server-network-off::before {
  content: "\F48E"; }

.mdi-server-off::before {
  content: "\F48F"; }

.mdi-server-plus::before {
  content: "\F490"; }

.mdi-server-remove::before {
  content: "\F491"; }

.mdi-server-security::before {
  content: "\F492"; }

.mdi-set-all::before {
  content: "\F777"; }

.mdi-set-center::before {
  content: "\F778"; }

.mdi-set-center-right::before {
  content: "\F779"; }

.mdi-set-left::before {
  content: "\F77A"; }

.mdi-set-left-center::before {
  content: "\F77B"; }

.mdi-set-left-right::before {
  content: "\F77C"; }

.mdi-set-none::before {
  content: "\F77D"; }

.mdi-set-right::before {
  content: "\F77E"; }

.mdi-set-top-box::before {
  content: "\F99E"; }

.mdi-settings::before {
  content: "\F493"; }

.mdi-settings-box::before {
  content: "\F494"; }

.mdi-settings-helper::before {
  content: "\FA6D"; }

.mdi-settings-outline::before {
  content: "\F8BA"; }

.mdi-settings-transfer::before {
  content: "\F007D"; }

.mdi-settings-transfer-outline::before {
  content: "\F007E"; }

.mdi-shaker::before {
  content: "\F0139"; }

.mdi-shaker-outline::before {
  content: "\F013A"; }

.mdi-shape::before {
  content: "\F830"; }

.mdi-shape-circle-plus::before {
  content: "\F65D"; }

.mdi-shape-outline::before {
  content: "\F831"; }

.mdi-shape-oval-plus::before {
  content: "\F0225"; }

.mdi-shape-plus::before {
  content: "\F495"; }

.mdi-shape-polygon-plus::before {
  content: "\F65E"; }

.mdi-shape-rectangle-plus::before {
  content: "\F65F"; }

.mdi-shape-square-plus::before {
  content: "\F660"; }

.mdi-share::before {
  content: "\F496"; }

.mdi-share-all::before {
  content: "\F021F"; }

.mdi-share-all-outline::before {
  content: "\F0220"; }

.mdi-share-circle::before {
  content: "\F01D8"; }

.mdi-share-off::before {
  content: "\FF40"; }

.mdi-share-off-outline::before {
  content: "\FF41"; }

.mdi-share-outline::before {
  content: "\F931"; }

.mdi-share-variant::before {
  content: "\F497"; }

.mdi-sheep::before {
  content: "\FCA2"; }

.mdi-shield::before {
  content: "\F498"; }

.mdi-shield-account::before {
  content: "\F88E"; }

.mdi-shield-account-outline::before {
  content: "\FA11"; }

.mdi-shield-airplane::before {
  content: "\F6BA"; }

.mdi-shield-airplane-outline::before {
  content: "\FCA3"; }

.mdi-shield-alert::before {
  content: "\FEE9"; }

.mdi-shield-alert-outline::before {
  content: "\FEEA"; }

.mdi-shield-car::before {
  content: "\FFA0"; }

.mdi-shield-check::before {
  content: "\F565"; }

.mdi-shield-check-outline::before {
  content: "\FCA4"; }

.mdi-shield-cross::before {
  content: "\FCA5"; }

.mdi-shield-cross-outline::before {
  content: "\FCA6"; }

.mdi-shield-edit::before {
  content: "\F01CB"; }

.mdi-shield-edit-outline::before {
  content: "\F01CC"; }

.mdi-shield-half::before {
  content: "\F038B"; }

.mdi-shield-half-full::before {
  content: "\F77F"; }

.mdi-shield-home::before {
  content: "\F689"; }

.mdi-shield-home-outline::before {
  content: "\FCA7"; }

.mdi-shield-key::before {
  content: "\FBA0"; }

.mdi-shield-key-outline::before {
  content: "\FBA1"; }

.mdi-shield-link-variant::before {
  content: "\FD0F"; }

.mdi-shield-link-variant-outline::before {
  content: "\FD10"; }

.mdi-shield-lock::before {
  content: "\F99C"; }

.mdi-shield-lock-outline::before {
  content: "\FCA8"; }

.mdi-shield-off::before {
  content: "\F99D"; }

.mdi-shield-off-outline::before {
  content: "\F99B"; }

.mdi-shield-outline::before {
  content: "\F499"; }

.mdi-shield-plus::before {
  content: "\FAD9"; }

.mdi-shield-plus-outline::before {
  content: "\FADA"; }

.mdi-shield-refresh::before {
  content: "\F01CD"; }

.mdi-shield-refresh-outline::before {
  content: "\F01CE"; }

.mdi-shield-remove::before {
  content: "\FADB"; }

.mdi-shield-remove-outline::before {
  content: "\FADC"; }

.mdi-shield-search::before {
  content: "\FD76"; }

.mdi-shield-star::before {
  content: "\F0166"; }

.mdi-shield-star-outline::before {
  content: "\F0167"; }

.mdi-shield-sun::before {
  content: "\F007F"; }

.mdi-shield-sun-outline::before {
  content: "\F0080"; }

.mdi-ship-wheel::before {
  content: "\F832"; }

.mdi-shoe-formal::before {
  content: "\FB22"; }

.mdi-shoe-heel::before {
  content: "\FB23"; }

.mdi-shoe-print::before {
  content: "\FE5A"; }

.mdi-shopify::before {
  content: "\FADD"; }

.mdi-shopping::before {
  content: "\F49A"; }

.mdi-shopping-music::before {
  content: "\F49B"; }

.mdi-shopping-outline::before {
  content: "\F0200"; }

.mdi-shopping-search::before {
  content: "\FFA1"; }

.mdi-shovel::before {
  content: "\F70F"; }

.mdi-shovel-off::before {
  content: "\F710"; }

.mdi-shower::before {
  content: "\F99F"; }

.mdi-shower-head::before {
  content: "\F9A0"; }

.mdi-shredder::before {
  content: "\F49C"; }

.mdi-shuffle::before {
  content: "\F49D"; }

.mdi-shuffle-disabled::before {
  content: "\F49E"; }

.mdi-shuffle-variant::before {
  content: "\F49F"; }

.mdi-shuriken::before {
  content: "\F03AA"; }

.mdi-sigma::before {
  content: "\F4A0"; }

.mdi-sigma-lower::before {
  content: "\F62B"; }

.mdi-sign-caution::before {
  content: "\F4A1"; }

.mdi-sign-direction::before {
  content: "\F780"; }

.mdi-sign-direction-minus::before {
  content: "\F0022"; }

.mdi-sign-direction-plus::before {
  content: "\FFFD"; }

.mdi-sign-direction-remove::before {
  content: "\FFFE"; }

.mdi-sign-real-estate::before {
  content: "\F0143"; }

.mdi-sign-text::before {
  content: "\F781"; }

.mdi-signal::before {
  content: "\F4A2"; }

.mdi-signal-2g::before {
  content: "\F711"; }

.mdi-signal-3g::before {
  content: "\F712"; }

.mdi-signal-4g::before {
  content: "\F713"; }

.mdi-signal-5g::before {
  content: "\FA6E"; }

.mdi-signal-cellular-1::before {
  content: "\F8BB"; }

.mdi-signal-cellular-2::before {
  content: "\F8BC"; }

.mdi-signal-cellular-3::before {
  content: "\F8BD"; }

.mdi-signal-cellular-outline::before {
  content: "\F8BE"; }

.mdi-signal-distance-variant::before {
  content: "\FE47"; }

.mdi-signal-hspa::before {
  content: "\F714"; }

.mdi-signal-hspa-plus::before {
  content: "\F715"; }

.mdi-signal-off::before {
  content: "\F782"; }

.mdi-signal-variant::before {
  content: "\F60A"; }

.mdi-signature::before {
  content: "\FE5B"; }

.mdi-signature-freehand::before {
  content: "\FE5C"; }

.mdi-signature-image::before {
  content: "\FE5D"; }

.mdi-signature-text::before {
  content: "\FE5E"; }

.mdi-silo::before {
  content: "\FB24"; }

.mdi-silverware::before {
  content: "\F4A3"; }

.mdi-silverware-clean::before {
  content: "\FFFF"; }

.mdi-silverware-fork::before {
  content: "\F4A4"; }

.mdi-silverware-fork-knife::before {
  content: "\FA6F"; }

.mdi-silverware-spoon::before {
  content: "\F4A5"; }

.mdi-silverware-variant::before {
  content: "\F4A6"; }

.mdi-sim::before {
  content: "\F4A7"; }

.mdi-sim-alert::before {
  content: "\F4A8"; }

.mdi-sim-off::before {
  content: "\F4A9"; }

.mdi-simple-icons::before {
  content: "\F0348"; }

.mdi-sina-weibo::before {
  content: "\FADE"; }

.mdi-sitemap::before {
  content: "\F4AA"; }

.mdi-skate::before {
  content: "\FD11"; }

.mdi-skew-less::before {
  content: "\FD12"; }

.mdi-skew-more::before {
  content: "\FD13"; }

.mdi-ski::before {
  content: "\F032F"; }

.mdi-ski-cross-country::before {
  content: "\F0330"; }

.mdi-ski-water::before {
  content: "\F0331"; }

.mdi-skip-backward::before {
  content: "\F4AB"; }

.mdi-skip-backward-outline::before {
  content: "\FF42"; }

.mdi-skip-forward::before {
  content: "\F4AC"; }

.mdi-skip-forward-outline::before {
  content: "\FF43"; }

.mdi-skip-next::before {
  content: "\F4AD"; }

.mdi-skip-next-circle::before {
  content: "\F661"; }

.mdi-skip-next-circle-outline::before {
  content: "\F662"; }

.mdi-skip-next-outline::before {
  content: "\FF44"; }

.mdi-skip-previous::before {
  content: "\F4AE"; }

.mdi-skip-previous-circle::before {
  content: "\F663"; }

.mdi-skip-previous-circle-outline::before {
  content: "\F664"; }

.mdi-skip-previous-outline::before {
  content: "\FF45"; }

.mdi-skull::before {
  content: "\F68B"; }

.mdi-skull-crossbones::before {
  content: "\FBA2"; }

.mdi-skull-crossbones-outline::before {
  content: "\FBA3"; }

.mdi-skull-outline::before {
  content: "\FBA4"; }

.mdi-skype::before {
  content: "\F4AF"; }

.mdi-skype-business::before {
  content: "\F4B0"; }

.mdi-slack::before {
  content: "\F4B1"; }

.mdi-slackware::before {
  content: "\F90A"; }

.mdi-slash-forward::before {
  content: "\F0000"; }

.mdi-slash-forward-box::before {
  content: "\F0001"; }

.mdi-sleep::before {
  content: "\F4B2"; }

.mdi-sleep-off::before {
  content: "\F4B3"; }

.mdi-slope-downhill::before {
  content: "\FE5F"; }

.mdi-slope-uphill::before {
  content: "\FE60"; }

.mdi-slot-machine::before {
  content: "\F013F"; }

.mdi-slot-machine-outline::before {
  content: "\F0140"; }

.mdi-smart-card::before {
  content: "\F00E8"; }

.mdi-smart-card-outline::before {
  content: "\F00E9"; }

.mdi-smart-card-reader::before {
  content: "\F00EA"; }

.mdi-smart-card-reader-outline::before {
  content: "\F00EB"; }

.mdi-smog::before {
  content: "\FA70"; }

.mdi-smoke-detector::before {
  content: "\F392"; }

.mdi-smoking::before {
  content: "\F4B4"; }

.mdi-smoking-off::before {
  content: "\F4B5"; }

.mdi-snapchat::before {
  content: "\F4B6"; }

.mdi-snowboard::before {
  content: "\F0332"; }

.mdi-snowflake::before {
  content: "\F716"; }

.mdi-snowflake-alert::before {
  content: "\FF46"; }

.mdi-snowflake-melt::before {
  content: "\F02F6"; }

.mdi-snowflake-variant::before {
  content: "\FF47"; }

.mdi-snowman::before {
  content: "\F4B7"; }

.mdi-soccer::before {
  content: "\F4B8"; }

.mdi-soccer-field::before {
  content: "\F833"; }

.mdi-sofa::before {
  content: "\F4B9"; }

.mdi-solar-panel::before {
  content: "\FD77"; }

.mdi-solar-panel-large::before {
  content: "\FD78"; }

.mdi-solar-power::before {
  content: "\FA71"; }

.mdi-soldering-iron::before {
  content: "\F00BD"; }

.mdi-solid::before {
  content: "\F68C"; }

.mdi-sort::before {
  content: "\F4BA"; }

.mdi-sort-alphabetical::before {
  content: "\F4BB"; }

.mdi-sort-alphabetical-ascending::before {
  content: "\F0173"; }

.mdi-sort-alphabetical-descending::before {
  content: "\F0174"; }

.mdi-sort-ascending::before {
  content: "\F4BC"; }

.mdi-sort-descending::before {
  content: "\F4BD"; }

.mdi-sort-numeric::before {
  content: "\F4BE"; }

.mdi-sort-variant::before {
  content: "\F4BF"; }

.mdi-sort-variant-lock::before {
  content: "\FCA9"; }

.mdi-sort-variant-lock-open::before {
  content: "\FCAA"; }

.mdi-sort-variant-remove::before {
  content: "\F0172"; }

.mdi-soundcloud::before {
  content: "\F4C0"; }

.mdi-source-branch::before {
  content: "\F62C"; }

.mdi-source-commit::before {
  content: "\F717"; }

.mdi-source-commit-end::before {
  content: "\F718"; }

.mdi-source-commit-end-local::before {
  content: "\F719"; }

.mdi-source-commit-local::before {
  content: "\F71A"; }

.mdi-source-commit-next-local::before {
  content: "\F71B"; }

.mdi-source-commit-start::before {
  content: "\F71C"; }

.mdi-source-commit-start-next-local::before {
  content: "\F71D"; }

.mdi-source-fork::before {
  content: "\F4C1"; }

.mdi-source-merge::before {
  content: "\F62D"; }

.mdi-source-pull::before {
  content: "\F4C2"; }

.mdi-source-repository::before {
  content: "\FCAB"; }

.mdi-source-repository-multiple::before {
  content: "\FCAC"; }

.mdi-soy-sauce::before {
  content: "\F7ED"; }

.mdi-spa::before {
  content: "\FCAD"; }

.mdi-spa-outline::before {
  content: "\FCAE"; }

.mdi-space-invaders::before {
  content: "\FBA5"; }

.mdi-space-station::before {
  content: "\F03AE"; }

.mdi-spade::before {
  content: "\FE48"; }

.mdi-speaker::before {
  content: "\F4C3"; }

.mdi-speaker-bluetooth::before {
  content: "\F9A1"; }

.mdi-speaker-multiple::before {
  content: "\FD14"; }

.mdi-speaker-off::before {
  content: "\F4C4"; }

.mdi-speaker-wireless::before {
  content: "\F71E"; }

.mdi-speedometer::before {
  content: "\F4C5"; }

.mdi-speedometer-medium::before {
  content: "\FFA2"; }

.mdi-speedometer-slow::before {
  content: "\FFA3"; }

.mdi-spellcheck::before {
  content: "\F4C6"; }

.mdi-spider::before {
  content: "\F0215"; }

.mdi-spider-thread::before {
  content: "\F0216"; }

.mdi-spider-web::before {
  content: "\FBA6"; }

.mdi-spotify::before {
  content: "\F4C7"; }

.mdi-spotlight::before {
  content: "\F4C8"; }

.mdi-spotlight-beam::before {
  content: "\F4C9"; }

.mdi-spray::before {
  content: "\F665"; }

.mdi-spray-bottle::before {
  content: "\FADF"; }

.mdi-sprinkler::before {
  content: "\F0081"; }

.mdi-sprinkler-variant::before {
  content: "\F0082"; }

.mdi-sprout::before {
  content: "\FE49"; }

.mdi-sprout-outline::before {
  content: "\FE4A"; }

.mdi-square::before {
  content: "\F763"; }

.mdi-square-edit-outline::before {
  content: "\F90B"; }

.mdi-square-inc::before {
  content: "\F4CA"; }

.mdi-square-inc-cash::before {
  content: "\F4CB"; }

.mdi-square-medium::before {
  content: "\FA12"; }

.mdi-square-medium-outline::before {
  content: "\FA13"; }

.mdi-square-off::before {
  content: "\F0319"; }

.mdi-square-off-outline::before {
  content: "\F031A"; }

.mdi-square-outline::before {
  content: "\F762"; }

.mdi-square-root::before {
  content: "\F783"; }

.mdi-square-root-box::before {
  content: "\F9A2"; }

.mdi-square-small::before {
  content: "\FA14"; }

.mdi-squeegee::before {
  content: "\FAE0"; }

.mdi-ssh::before {
  content: "\F8BF"; }

.mdi-stack-exchange::before {
  content: "\F60B"; }

.mdi-stack-overflow::before {
  content: "\F4CC"; }

.mdi-stackpath::before {
  content: "\F359"; }

.mdi-stadium::before {
  content: "\F001A"; }

.mdi-stadium-variant::before {
  content: "\F71F"; }

.mdi-stairs::before {
  content: "\F4CD"; }

.mdi-stairs-down::before {
  content: "\F02E9"; }

.mdi-stairs-up::before {
  content: "\F02E8"; }

.mdi-stamper::before {
  content: "\FD15"; }

.mdi-standard-definition::before {
  content: "\F7EE"; }

.mdi-star::before {
  content: "\F4CE"; }

.mdi-star-box::before {
  content: "\FA72"; }

.mdi-star-box-multiple::before {
  content: "\F02B1"; }

.mdi-star-box-multiple-outline::before {
  content: "\F02B2"; }

.mdi-star-box-outline::before {
  content: "\FA73"; }

.mdi-star-circle::before {
  content: "\F4CF"; }

.mdi-star-circle-outline::before {
  content: "\F9A3"; }

.mdi-star-face::before {
  content: "\F9A4"; }

.mdi-star-four-points::before {
  content: "\FAE1"; }

.mdi-star-four-points-outline::before {
  content: "\FAE2"; }

.mdi-star-half::before {
  content: "\F4D0"; }

.mdi-star-off::before {
  content: "\F4D1"; }

.mdi-star-outline::before {
  content: "\F4D2"; }

.mdi-star-three-points::before {
  content: "\FAE3"; }

.mdi-star-three-points-outline::before {
  content: "\FAE4"; }

.mdi-state-machine::before {
  content: "\F021A"; }

.mdi-steam::before {
  content: "\F4D3"; }

.mdi-steam-box::before {
  content: "\F90C"; }

.mdi-steering::before {
  content: "\F4D4"; }

.mdi-steering-off::before {
  content: "\F90D"; }

.mdi-step-backward::before {
  content: "\F4D5"; }

.mdi-step-backward-2::before {
  content: "\F4D6"; }

.mdi-step-forward::before {
  content: "\F4D7"; }

.mdi-step-forward-2::before {
  content: "\F4D8"; }

.mdi-stethoscope::before {
  content: "\F4D9"; }

.mdi-sticker::before {
  content: "\F038F"; }

.mdi-sticker-alert::before {
  content: "\F0390"; }

.mdi-sticker-alert-outline::before {
  content: "\F0391"; }

.mdi-sticker-check::before {
  content: "\F0392"; }

.mdi-sticker-check-outline::before {
  content: "\F0393"; }

.mdi-sticker-circle-outline::before {
  content: "\F5D0"; }

.mdi-sticker-emoji::before {
  content: "\F784"; }

.mdi-sticker-minus::before {
  content: "\F0394"; }

.mdi-sticker-minus-outline::before {
  content: "\F0395"; }

.mdi-sticker-outline::before {
  content: "\F0396"; }

.mdi-sticker-plus::before {
  content: "\F0397"; }

.mdi-sticker-plus-outline::before {
  content: "\F0398"; }

.mdi-sticker-remove::before {
  content: "\F0399"; }

.mdi-sticker-remove-outline::before {
  content: "\F039A"; }

.mdi-stocking::before {
  content: "\F4DA"; }

.mdi-stomach::before {
  content: "\F00BE"; }

.mdi-stop::before {
  content: "\F4DB"; }

.mdi-stop-circle::before {
  content: "\F666"; }

.mdi-stop-circle-outline::before {
  content: "\F667"; }

.mdi-store::before {
  content: "\F4DC"; }

.mdi-store-24-hour::before {
  content: "\F4DD"; }

.mdi-store-outline::before {
  content: "\F038C"; }

.mdi-storefront::before {
  content: "\F00EC"; }

.mdi-stove::before {
  content: "\F4DE"; }

.mdi-strategy::before {
  content: "\F0201"; }

.mdi-strava::before {
  content: "\FB25"; }

.mdi-stretch-to-page::before {
  content: "\FF48"; }

.mdi-stretch-to-page-outline::before {
  content: "\FF49"; }

.mdi-string-lights::before {
  content: "\F02E5"; }

.mdi-string-lights-off::before {
  content: "\F02E6"; }

.mdi-subdirectory-arrow-left::before {
  content: "\F60C"; }

.mdi-subdirectory-arrow-right::before {
  content: "\F60D"; }

.mdi-subtitles::before {
  content: "\FA15"; }

.mdi-subtitles-outline::before {
  content: "\FA16"; }

.mdi-subway::before {
  content: "\F6AB"; }

.mdi-subway-alert-variant::before {
  content: "\FD79"; }

.mdi-subway-variant::before {
  content: "\F4DF"; }

.mdi-summit::before {
  content: "\F785"; }

.mdi-sunglasses::before {
  content: "\F4E0"; }

.mdi-surround-sound::before {
  content: "\F5C5"; }

.mdi-surround-sound-2-0::before {
  content: "\F7EF"; }

.mdi-surround-sound-3-1::before {
  content: "\F7F0"; }

.mdi-surround-sound-5-1::before {
  content: "\F7F1"; }

.mdi-surround-sound-7-1::before {
  content: "\F7F2"; }

.mdi-svg::before {
  content: "\F720"; }

.mdi-swap-horizontal::before {
  content: "\F4E1"; }

.mdi-swap-horizontal-bold::before {
  content: "\FBA9"; }

.mdi-swap-horizontal-circle::before {
  content: "\F0002"; }

.mdi-swap-horizontal-circle-outline::before {
  content: "\F0003"; }

.mdi-swap-horizontal-variant::before {
  content: "\F8C0"; }

.mdi-swap-vertical::before {
  content: "\F4E2"; }

.mdi-swap-vertical-bold::before {
  content: "\FBAA"; }

.mdi-swap-vertical-circle::before {
  content: "\F0004"; }

.mdi-swap-vertical-circle-outline::before {
  content: "\F0005"; }

.mdi-swap-vertical-variant::before {
  content: "\F8C1"; }

.mdi-swim::before {
  content: "\F4E3"; }

.mdi-switch::before {
  content: "\F4E4"; }

.mdi-sword::before {
  content: "\F4E5"; }

.mdi-sword-cross::before {
  content: "\F786"; }

.mdi-syllabary-hangul::before {
  content: "\F035E"; }

.mdi-syllabary-hiragana::before {
  content: "\F035F"; }

.mdi-syllabary-katakana::before {
  content: "\F0360"; }

.mdi-syllabary-katakana-half-width::before {
  content: "\F0361"; }

.mdi-symfony::before {
  content: "\FAE5"; }

.mdi-sync::before {
  content: "\F4E6"; }

.mdi-sync-alert::before {
  content: "\F4E7"; }

.mdi-sync-circle::before {
  content: "\F03A3"; }

.mdi-sync-off::before {
  content: "\F4E8"; }

.mdi-tab::before {
  content: "\F4E9"; }

.mdi-tab-minus::before {
  content: "\FB26"; }

.mdi-tab-plus::before {
  content: "\F75B"; }

.mdi-tab-remove::before {
  content: "\FB27"; }

.mdi-tab-unselected::before {
  content: "\F4EA"; }

.mdi-table::before {
  content: "\F4EB"; }

.mdi-table-border::before {
  content: "\FA17"; }

.mdi-table-chair::before {
  content: "\F0083"; }

.mdi-table-column::before {
  content: "\F834"; }

.mdi-table-column-plus-after::before {
  content: "\F4EC"; }

.mdi-table-column-plus-before::before {
  content: "\F4ED"; }

.mdi-table-column-remove::before {
  content: "\F4EE"; }

.mdi-table-column-width::before {
  content: "\F4EF"; }

.mdi-table-edit::before {
  content: "\F4F0"; }

.mdi-table-eye::before {
  content: "\F00BF"; }

.mdi-table-headers-eye::before {
  content: "\F0248"; }

.mdi-table-headers-eye-off::before {
  content: "\F0249"; }

.mdi-table-large::before {
  content: "\F4F1"; }

.mdi-table-large-plus::before {
  content: "\FFA4"; }

.mdi-table-large-remove::before {
  content: "\FFA5"; }

.mdi-table-merge-cells::before {
  content: "\F9A5"; }

.mdi-table-of-contents::before {
  content: "\F835"; }

.mdi-table-plus::before {
  content: "\FA74"; }

.mdi-table-remove::before {
  content: "\FA75"; }

.mdi-table-row::before {
  content: "\F836"; }

.mdi-table-row-height::before {
  content: "\F4F2"; }

.mdi-table-row-plus-after::before {
  content: "\F4F3"; }

.mdi-table-row-plus-before::before {
  content: "\F4F4"; }

.mdi-table-row-remove::before {
  content: "\F4F5"; }

.mdi-table-search::before {
  content: "\F90E"; }

.mdi-table-settings::before {
  content: "\F837"; }

.mdi-table-tennis::before {
  content: "\FE4B"; }

.mdi-tablet::before {
  content: "\F4F6"; }

.mdi-tablet-android::before {
  content: "\F4F7"; }

.mdi-tablet-cellphone::before {
  content: "\F9A6"; }

.mdi-tablet-dashboard::before {
  content: "\FEEB"; }

.mdi-tablet-ipad::before {
  content: "\F4F8"; }

.mdi-taco::before {
  content: "\F761"; }

.mdi-tag::before {
  content: "\F4F9"; }

.mdi-tag-faces::before {
  content: "\F4FA"; }

.mdi-tag-heart::before {
  content: "\F68A"; }

.mdi-tag-heart-outline::before {
  content: "\FBAB"; }

.mdi-tag-minus::before {
  content: "\F90F"; }

.mdi-tag-minus-outline::before {
  content: "\F024A"; }

.mdi-tag-multiple::before {
  content: "\F4FB"; }

.mdi-tag-multiple-outline::before {
  content: "\F0322"; }

.mdi-tag-off::before {
  content: "\F024B"; }

.mdi-tag-off-outline::before {
  content: "\F024C"; }

.mdi-tag-outline::before {
  content: "\F4FC"; }

.mdi-tag-plus::before {
  content: "\F721"; }

.mdi-tag-plus-outline::before {
  content: "\F024D"; }

.mdi-tag-remove::before {
  content: "\F722"; }

.mdi-tag-remove-outline::before {
  content: "\F024E"; }

.mdi-tag-text::before {
  content: "\F024F"; }

.mdi-tag-text-outline::before {
  content: "\F4FD"; }

.mdi-tank::before {
  content: "\FD16"; }

.mdi-tanker-truck::before {
  content: "\F0006"; }

.mdi-tape-measure::before {
  content: "\FB28"; }

.mdi-target::before {
  content: "\F4FE"; }

.mdi-target-account::before {
  content: "\FBAC"; }

.mdi-target-variant::before {
  content: "\FA76"; }

.mdi-taxi::before {
  content: "\F4FF"; }

.mdi-tea::before {
  content: "\FD7A"; }

.mdi-tea-outline::before {
  content: "\FD7B"; }

.mdi-teach::before {
  content: "\F88F"; }

.mdi-teamviewer::before {
  content: "\F500"; }

.mdi-telegram::before {
  content: "\F501"; }

.mdi-telescope::before {
  content: "\FB29"; }

.mdi-television::before {
  content: "\F502"; }

.mdi-television-ambient-light::before {
  content: "\F0381"; }

.mdi-television-box::before {
  content: "\F838"; }

.mdi-television-classic::before {
  content: "\F7F3"; }

.mdi-television-classic-off::before {
  content: "\F839"; }

.mdi-television-clean::before {
  content: "\F013B"; }

.mdi-television-guide::before {
  content: "\F503"; }

.mdi-television-off::before {
  content: "\F83A"; }

.mdi-television-pause::before {
  content: "\FFA6"; }

.mdi-television-play::before {
  content: "\FEEC"; }

.mdi-television-stop::before {
  content: "\FFA7"; }

.mdi-temperature-celsius::before {
  content: "\F504"; }

.mdi-temperature-fahrenheit::before {
  content: "\F505"; }

.mdi-temperature-kelvin::before {
  content: "\F506"; }

.mdi-tennis::before {
  content: "\FD7C"; }

.mdi-tennis-ball::before {
  content: "\F507"; }

.mdi-tent::before {
  content: "\F508"; }

.mdi-terraform::before {
  content: "\F0084"; }

.mdi-terrain::before {
  content: "\F509"; }

.mdi-test-tube::before {
  content: "\F668"; }

.mdi-test-tube-empty::before {
  content: "\F910"; }

.mdi-test-tube-off::before {
  content: "\F911"; }

.mdi-text::before {
  content: "\F9A7"; }

.mdi-text-recognition::before {
  content: "\F0168"; }

.mdi-text-shadow::before {
  content: "\F669"; }

.mdi-text-short::before {
  content: "\F9A8"; }

.mdi-text-subject::before {
  content: "\F9A9"; }

.mdi-text-to-speech::before {
  content: "\F50A"; }

.mdi-text-to-speech-off::before {
  content: "\F50B"; }

.mdi-textarea::before {
  content: "\F00C0"; }

.mdi-textbox::before {
  content: "\F60E"; }

.mdi-textbox-lock::before {
  content: "\F0388"; }

.mdi-textbox-password::before {
  content: "\F7F4"; }

.mdi-texture::before {
  content: "\F50C"; }

.mdi-texture-box::before {
  content: "\F0007"; }

.mdi-theater::before {
  content: "\F50D"; }

.mdi-theme-light-dark::before {
  content: "\F50E"; }

.mdi-thermometer::before {
  content: "\F50F"; }

.mdi-thermometer-alert::before {
  content: "\FE61"; }

.mdi-thermometer-chevron-down::before {
  content: "\FE62"; }

.mdi-thermometer-chevron-up::before {
  content: "\FE63"; }

.mdi-thermometer-high::before {
  content: "\F00ED"; }

.mdi-thermometer-lines::before {
  content: "\F510"; }

.mdi-thermometer-low::before {
  content: "\F00EE"; }

.mdi-thermometer-minus::before {
  content: "\FE64"; }

.mdi-thermometer-plus::before {
  content: "\FE65"; }

.mdi-thermostat::before {
  content: "\F393"; }

.mdi-thermostat-box::before {
  content: "\F890"; }

.mdi-thought-bubble::before {
  content: "\F7F5"; }

.mdi-thought-bubble-outline::before {
  content: "\F7F6"; }

.mdi-thumb-down::before {
  content: "\F511"; }

.mdi-thumb-down-outline::before {
  content: "\F512"; }

.mdi-thumb-up::before {
  content: "\F513"; }

.mdi-thumb-up-outline::before {
  content: "\F514"; }

.mdi-thumbs-up-down::before {
  content: "\F515"; }

.mdi-ticket::before {
  content: "\F516"; }

.mdi-ticket-account::before {
  content: "\F517"; }

.mdi-ticket-confirmation::before {
  content: "\F518"; }

.mdi-ticket-outline::before {
  content: "\F912"; }

.mdi-ticket-percent::before {
  content: "\F723"; }

.mdi-tie::before {
  content: "\F519"; }

.mdi-tilde::before {
  content: "\F724"; }

.mdi-timelapse::before {
  content: "\F51A"; }

.mdi-timeline::before {
  content: "\FBAD"; }

.mdi-timeline-alert::before {
  content: "\FFB2"; }

.mdi-timeline-alert-outline::before {
  content: "\FFB5"; }

.mdi-timeline-clock::before {
  content: "\F0226"; }

.mdi-timeline-clock-outline::before {
  content: "\F0227"; }

.mdi-timeline-help::before {
  content: "\FFB6"; }

.mdi-timeline-help-outline::before {
  content: "\FFB7"; }

.mdi-timeline-outline::before {
  content: "\FBAE"; }

.mdi-timeline-plus::before {
  content: "\FFB3"; }

.mdi-timeline-plus-outline::before {
  content: "\FFB4"; }

.mdi-timeline-text::before {
  content: "\FBAF"; }

.mdi-timeline-text-outline::before {
  content: "\FBB0"; }

.mdi-timer::before {
  content: "\F51B"; }

.mdi-timer-10::before {
  content: "\F51C"; }

.mdi-timer-3::before {
  content: "\F51D"; }

.mdi-timer-off::before {
  content: "\F51E"; }

.mdi-timer-sand::before {
  content: "\F51F"; }

.mdi-timer-sand-empty::before {
  content: "\F6AC"; }

.mdi-timer-sand-full::before {
  content: "\F78B"; }

.mdi-timetable::before {
  content: "\F520"; }

.mdi-toaster::before {
  content: "\F0085"; }

.mdi-toaster-off::before {
  content: "\F01E2"; }

.mdi-toaster-oven::before {
  content: "\FCAF"; }

.mdi-toggle-switch::before {
  content: "\F521"; }

.mdi-toggle-switch-off::before {
  content: "\F522"; }

.mdi-toggle-switch-off-outline::before {
  content: "\FA18"; }

.mdi-toggle-switch-outline::before {
  content: "\FA19"; }

.mdi-toilet::before {
  content: "\F9AA"; }

.mdi-toolbox::before {
  content: "\F9AB"; }

.mdi-toolbox-outline::before {
  content: "\F9AC"; }

.mdi-tools::before {
  content: "\F0086"; }

.mdi-tooltip::before {
  content: "\F523"; }

.mdi-tooltip-account::before {
  content: "\F00C"; }

.mdi-tooltip-edit::before {
  content: "\F524"; }

.mdi-tooltip-edit-outline::before {
  content: "\F02F0"; }

.mdi-tooltip-image::before {
  content: "\F525"; }

.mdi-tooltip-image-outline::before {
  content: "\FBB1"; }

.mdi-tooltip-outline::before {
  content: "\F526"; }

.mdi-tooltip-plus::before {
  content: "\FBB2"; }

.mdi-tooltip-plus-outline::before {
  content: "\F527"; }

.mdi-tooltip-text::before {
  content: "\F528"; }

.mdi-tooltip-text-outline::before {
  content: "\FBB3"; }

.mdi-tooth::before {
  content: "\F8C2"; }

.mdi-tooth-outline::before {
  content: "\F529"; }

.mdi-toothbrush::before {
  content: "\F0154"; }

.mdi-toothbrush-electric::before {
  content: "\F0157"; }

.mdi-toothbrush-paste::before {
  content: "\F0155"; }

.mdi-tor::before {
  content: "\F52A"; }

.mdi-tortoise::before {
  content: "\FD17"; }

.mdi-toslink::before {
  content: "\F02E3"; }

.mdi-tournament::before {
  content: "\F9AD"; }

.mdi-tower-beach::before {
  content: "\F680"; }

.mdi-tower-fire::before {
  content: "\F681"; }

.mdi-towing::before {
  content: "\F83B"; }

.mdi-toy-brick::before {
  content: "\F02B3"; }

.mdi-toy-brick-marker::before {
  content: "\F02B4"; }

.mdi-toy-brick-marker-outline::before {
  content: "\F02B5"; }

.mdi-toy-brick-minus::before {
  content: "\F02B6"; }

.mdi-toy-brick-minus-outline::before {
  content: "\F02B7"; }

.mdi-toy-brick-outline::before {
  content: "\F02B8"; }

.mdi-toy-brick-plus::before {
  content: "\F02B9"; }

.mdi-toy-brick-plus-outline::before {
  content: "\F02BA"; }

.mdi-toy-brick-remove::before {
  content: "\F02BB"; }

.mdi-toy-brick-remove-outline::before {
  content: "\F02BC"; }

.mdi-toy-brick-search::before {
  content: "\F02BD"; }

.mdi-toy-brick-search-outline::before {
  content: "\F02BE"; }

.mdi-track-light::before {
  content: "\F913"; }

.mdi-trackpad::before {
  content: "\F7F7"; }

.mdi-trackpad-lock::before {
  content: "\F932"; }

.mdi-tractor::before {
  content: "\F891"; }

.mdi-trademark::before {
  content: "\FA77"; }

.mdi-traffic-cone::before {
  content: "\F03A7"; }

.mdi-traffic-light::before {
  content: "\F52B"; }

.mdi-train::before {
  content: "\F52C"; }

.mdi-train-car::before {
  content: "\FBB4"; }

.mdi-train-variant::before {
  content: "\F8C3"; }

.mdi-tram::before {
  content: "\F52D"; }

.mdi-tram-side::before {
  content: "\F0008"; }

.mdi-transcribe::before {
  content: "\F52E"; }

.mdi-transcribe-close::before {
  content: "\F52F"; }

.mdi-transfer::before {
  content: "\F0087"; }

.mdi-transfer-down::before {
  content: "\FD7D"; }

.mdi-transfer-left::before {
  content: "\FD7E"; }

.mdi-transfer-right::before {
  content: "\F530"; }

.mdi-transfer-up::before {
  content: "\FD7F"; }

.mdi-transit-connection::before {
  content: "\FD18"; }

.mdi-transit-connection-variant::before {
  content: "\FD19"; }

.mdi-transit-detour::before {
  content: "\FFA8"; }

.mdi-transit-transfer::before {
  content: "\F6AD"; }

.mdi-transition::before {
  content: "\F914"; }

.mdi-transition-masked::before {
  content: "\F915"; }

.mdi-translate::before {
  content: "\F5CA"; }

.mdi-translate-off::before {
  content: "\FE66"; }

.mdi-transmission-tower::before {
  content: "\FD1A"; }

.mdi-trash-can::before {
  content: "\FA78"; }

.mdi-trash-can-outline::before {
  content: "\FA79"; }

.mdi-tray::before {
  content: "\F02BF"; }

.mdi-tray-alert::before {
  content: "\F02C0"; }

.mdi-tray-full::before {
  content: "\F02C1"; }

.mdi-tray-minus::before {
  content: "\F02C2"; }

.mdi-tray-plus::before {
  content: "\F02C3"; }

.mdi-tray-remove::before {
  content: "\F02C4"; }

.mdi-treasure-chest::before {
  content: "\F725"; }

.mdi-tree::before {
  content: "\F531"; }

.mdi-tree-outline::before {
  content: "\FE4C"; }

.mdi-trello::before {
  content: "\F532"; }

.mdi-trending-down::before {
  content: "\F533"; }

.mdi-trending-neutral::before {
  content: "\F534"; }

.mdi-trending-up::before {
  content: "\F535"; }

.mdi-triangle::before {
  content: "\F536"; }

.mdi-triangle-outline::before {
  content: "\F537"; }

.mdi-triforce::before {
  content: "\FBB5"; }

.mdi-trophy::before {
  content: "\F538"; }

.mdi-trophy-award::before {
  content: "\F539"; }

.mdi-trophy-broken::before {
  content: "\FD80"; }

.mdi-trophy-outline::before {
  content: "\F53A"; }

.mdi-trophy-variant::before {
  content: "\F53B"; }

.mdi-trophy-variant-outline::before {
  content: "\F53C"; }

.mdi-truck::before {
  content: "\F53D"; }

.mdi-truck-check::before {
  content: "\FCB0"; }

.mdi-truck-check-outline::before {
  content: "\F02C5"; }

.mdi-truck-delivery::before {
  content: "\F53E"; }

.mdi-truck-delivery-outline::before {
  content: "\F02C6"; }

.mdi-truck-fast::before {
  content: "\F787"; }

.mdi-truck-fast-outline::before {
  content: "\F02C7"; }

.mdi-truck-outline::before {
  content: "\F02C8"; }

.mdi-truck-trailer::before {
  content: "\F726"; }

.mdi-trumpet::before {
  content: "\F00C1"; }

.mdi-tshirt-crew::before {
  content: "\FA7A"; }

.mdi-tshirt-crew-outline::before {
  content: "\F53F"; }

.mdi-tshirt-v::before {
  content: "\FA7B"; }

.mdi-tshirt-v-outline::before {
  content: "\F540"; }

.mdi-tumble-dryer::before {
  content: "\F916"; }

.mdi-tumble-dryer-alert::before {
  content: "\F01E5"; }

.mdi-tumble-dryer-off::before {
  content: "\F01E6"; }

.mdi-tumblr::before {
  content: "\F541"; }

.mdi-tumblr-box::before {
  content: "\F917"; }

.mdi-tumblr-reblog::before {
  content: "\F542"; }

.mdi-tune::before {
  content: "\F62E"; }

.mdi-tune-vertical::before {
  content: "\F66A"; }

.mdi-turnstile::before {
  content: "\FCB1"; }

.mdi-turnstile-outline::before {
  content: "\FCB2"; }

.mdi-turtle::before {
  content: "\FCB3"; }

.mdi-twitch::before {
  content: "\F543"; }

.mdi-twitter::before {
  content: "\F544"; }

.mdi-twitter-box::before {
  content: "\F545"; }

.mdi-twitter-circle::before {
  content: "\F546"; }

.mdi-twitter-retweet::before {
  content: "\F547"; }

.mdi-two-factor-authentication::before {
  content: "\F9AE"; }

.mdi-typewriter::before {
  content: "\FF4A"; }

.mdi-uber::before {
  content: "\F748"; }

.mdi-ubisoft::before {
  content: "\FBB6"; }

.mdi-ubuntu::before {
  content: "\F548"; }

.mdi-ufo::before {
  content: "\F00EF"; }

.mdi-ufo-outline::before {
  content: "\F00F0"; }

.mdi-ultra-high-definition::before {
  content: "\F7F8"; }

.mdi-umbraco::before {
  content: "\F549"; }

.mdi-umbrella::before {
  content: "\F54A"; }

.mdi-umbrella-closed::before {
  content: "\F9AF"; }

.mdi-umbrella-outline::before {
  content: "\F54B"; }

.mdi-undo::before {
  content: "\F54C"; }

.mdi-undo-variant::before {
  content: "\F54D"; }

.mdi-unfold-less-horizontal::before {
  content: "\F54E"; }

.mdi-unfold-less-vertical::before {
  content: "\F75F"; }

.mdi-unfold-more-horizontal::before {
  content: "\F54F"; }

.mdi-unfold-more-vertical::before {
  content: "\F760"; }

.mdi-ungroup::before {
  content: "\F550"; }

.mdi-unicode::before {
  content: "\FEED"; }

.mdi-unity::before {
  content: "\F6AE"; }

.mdi-unreal::before {
  content: "\F9B0"; }

.mdi-untappd::before {
  content: "\F551"; }

.mdi-update::before {
  content: "\F6AF"; }

.mdi-upload::before {
  content: "\F552"; }

.mdi-upload-lock::before {
  content: "\F039E"; }

.mdi-upload-lock-outline::before {
  content: "\F039F"; }

.mdi-upload-multiple::before {
  content: "\F83C"; }

.mdi-upload-network::before {
  content: "\F6F5"; }

.mdi-upload-network-outline::before {
  content: "\FCB4"; }

.mdi-upload-off::before {
  content: "\F00F1"; }

.mdi-upload-off-outline::before {
  content: "\F00F2"; }

.mdi-upload-outline::before {
  content: "\FE67"; }

.mdi-usb::before {
  content: "\F553"; }

.mdi-usb-flash-drive::before {
  content: "\F02C9"; }

.mdi-usb-flash-drive-outline::before {
  content: "\F02CA"; }

.mdi-usb-port::before {
  content: "\F021B"; }

.mdi-valve::before {
  content: "\F0088"; }

.mdi-valve-closed::before {
  content: "\F0089"; }

.mdi-valve-open::before {
  content: "\F008A"; }

.mdi-van-passenger::before {
  content: "\F7F9"; }

.mdi-van-utility::before {
  content: "\F7FA"; }

.mdi-vanish::before {
  content: "\F7FB"; }

.mdi-vanity-light::before {
  content: "\F020C"; }

.mdi-variable::before {
  content: "\FAE6"; }

.mdi-variable-box::before {
  content: "\F013C"; }

.mdi-vector-arrange-above::before {
  content: "\F554"; }

.mdi-vector-arrange-below::before {
  content: "\F555"; }

.mdi-vector-bezier::before {
  content: "\FAE7"; }

.mdi-vector-circle::before {
  content: "\F556"; }

.mdi-vector-circle-variant::before {
  content: "\F557"; }

.mdi-vector-combine::before {
  content: "\F558"; }

.mdi-vector-curve::before {
  content: "\F559"; }

.mdi-vector-difference::before {
  content: "\F55A"; }

.mdi-vector-difference-ab::before {
  content: "\F55B"; }

.mdi-vector-difference-ba::before {
  content: "\F55C"; }

.mdi-vector-ellipse::before {
  content: "\F892"; }

.mdi-vector-intersection::before {
  content: "\F55D"; }

.mdi-vector-line::before {
  content: "\F55E"; }

.mdi-vector-link::before {
  content: "\F0009"; }

.mdi-vector-point::before {
  content: "\F55F"; }

.mdi-vector-polygon::before {
  content: "\F560"; }

.mdi-vector-polyline::before {
  content: "\F561"; }

.mdi-vector-polyline-edit::before {
  content: "\F0250"; }

.mdi-vector-polyline-minus::before {
  content: "\F0251"; }

.mdi-vector-polyline-plus::before {
  content: "\F0252"; }

.mdi-vector-polyline-remove::before {
  content: "\F0253"; }

.mdi-vector-radius::before {
  content: "\F749"; }

.mdi-vector-rectangle::before {
  content: "\F5C6"; }

.mdi-vector-selection::before {
  content: "\F562"; }

.mdi-vector-square::before {
  content: "\F001"; }

.mdi-vector-triangle::before {
  content: "\F563"; }

.mdi-vector-union::before {
  content: "\F564"; }

.mdi-venmo::before {
  content: "\F578"; }

.mdi-vhs::before {
  content: "\FA1A"; }

.mdi-vibrate::before {
  content: "\F566"; }

.mdi-vibrate-off::before {
  content: "\FCB5"; }

.mdi-video::before {
  content: "\F567"; }

.mdi-video-3d::before {
  content: "\F7FC"; }

.mdi-video-3d-variant::before {
  content: "\FEEE"; }

.mdi-video-4k-box::before {
  content: "\F83D"; }

.mdi-video-account::before {
  content: "\F918"; }

.mdi-video-check::before {
  content: "\F008B"; }

.mdi-video-check-outline::before {
  content: "\F008C"; }

.mdi-video-image::before {
  content: "\F919"; }

.mdi-video-input-antenna::before {
  content: "\F83E"; }

.mdi-video-input-component::before {
  content: "\F83F"; }

.mdi-video-input-hdmi::before {
  content: "\F840"; }

.mdi-video-input-scart::before {
  content: "\FFA9"; }

.mdi-video-input-svideo::before {
  content: "\F841"; }

.mdi-video-minus::before {
  content: "\F9B1"; }

.mdi-video-off::before {
  content: "\F568"; }

.mdi-video-off-outline::before {
  content: "\FBB7"; }

.mdi-video-outline::before {
  content: "\FBB8"; }

.mdi-video-plus::before {
  content: "\F9B2"; }

.mdi-video-stabilization::before {
  content: "\F91A"; }

.mdi-video-switch::before {
  content: "\F569"; }

.mdi-video-vintage::before {
  content: "\FA1B"; }

.mdi-video-wireless::before {
  content: "\FEEF"; }

.mdi-video-wireless-outline::before {
  content: "\FEF0"; }

.mdi-view-agenda::before {
  content: "\F56A"; }

.mdi-view-agenda-outline::before {
  content: "\F0203"; }

.mdi-view-array::before {
  content: "\F56B"; }

.mdi-view-carousel::before {
  content: "\F56C"; }

.mdi-view-column::before {
  content: "\F56D"; }

.mdi-view-comfy::before {
  content: "\FE4D"; }

.mdi-view-compact::before {
  content: "\FE4E"; }

.mdi-view-compact-outline::before {
  content: "\FE4F"; }

.mdi-view-dashboard::before {
  content: "\F56E"; }

.mdi-view-dashboard-outline::before {
  content: "\FA1C"; }

.mdi-view-dashboard-variant::before {
  content: "\F842"; }

.mdi-view-day::before {
  content: "\F56F"; }

.mdi-view-grid::before {
  content: "\F570"; }

.mdi-view-grid-outline::before {
  content: "\F0204"; }

.mdi-view-grid-plus::before {
  content: "\FFAA"; }

.mdi-view-grid-plus-outline::before {
  content: "\F0205"; }

.mdi-view-headline::before {
  content: "\F571"; }

.mdi-view-list::before {
  content: "\F572"; }

.mdi-view-module::before {
  content: "\F573"; }

.mdi-view-parallel::before {
  content: "\F727"; }

.mdi-view-quilt::before {
  content: "\F574"; }

.mdi-view-sequential::before {
  content: "\F728"; }

.mdi-view-split-horizontal::before {
  content: "\FBA7"; }

.mdi-view-split-vertical::before {
  content: "\FBA8"; }

.mdi-view-stream::before {
  content: "\F575"; }

.mdi-view-week::before {
  content: "\F576"; }

.mdi-vimeo::before {
  content: "\F577"; }

.mdi-violin::before {
  content: "\F60F"; }

.mdi-virtual-reality::before {
  content: "\F893"; }

.mdi-visual-studio::before {
  content: "\F610"; }

.mdi-visual-studio-code::before {
  content: "\FA1D"; }

.mdi-vk::before {
  content: "\F579"; }

.mdi-vk-box::before {
  content: "\F57A"; }

.mdi-vk-circle::before {
  content: "\F57B"; }

.mdi-vlc::before {
  content: "\F57C"; }

.mdi-voice::before {
  content: "\F5CB"; }

.mdi-voice-off::before {
  content: "\FEF1"; }

.mdi-voicemail::before {
  content: "\F57D"; }

.mdi-volleyball::before {
  content: "\F9B3"; }

.mdi-volume-high::before {
  content: "\F57E"; }

.mdi-volume-low::before {
  content: "\F57F"; }

.mdi-volume-medium::before {
  content: "\F580"; }

.mdi-volume-minus::before {
  content: "\F75D"; }

.mdi-volume-mute::before {
  content: "\F75E"; }

.mdi-volume-off::before {
  content: "\F581"; }

.mdi-volume-plus::before {
  content: "\F75C"; }

.mdi-volume-source::before {
  content: "\F014B"; }

.mdi-volume-variant-off::before {
  content: "\FE68"; }

.mdi-volume-vibrate::before {
  content: "\F014C"; }

.mdi-vote::before {
  content: "\FA1E"; }

.mdi-vote-outline::before {
  content: "\FA1F"; }

.mdi-vpn::before {
  content: "\F582"; }

.mdi-vuejs::before {
  content: "\F843"; }

.mdi-vuetify::before {
  content: "\FE50"; }

.mdi-walk::before {
  content: "\F583"; }

.mdi-wall::before {
  content: "\F7FD"; }

.mdi-wall-sconce::before {
  content: "\F91B"; }

.mdi-wall-sconce-flat::before {
  content: "\F91C"; }

.mdi-wall-sconce-variant::before {
  content: "\F91D"; }

.mdi-wallet::before {
  content: "\F584"; }

.mdi-wallet-giftcard::before {
  content: "\F585"; }

.mdi-wallet-membership::before {
  content: "\F586"; }

.mdi-wallet-outline::before {
  content: "\FBB9"; }

.mdi-wallet-plus::before {
  content: "\FFAB"; }

.mdi-wallet-plus-outline::before {
  content: "\FFAC"; }

.mdi-wallet-travel::before {
  content: "\F587"; }

.mdi-wallpaper::before {
  content: "\FE69"; }

.mdi-wan::before {
  content: "\F588"; }

.mdi-wardrobe::before {
  content: "\FFAD"; }

.mdi-wardrobe-outline::before {
  content: "\FFAE"; }

.mdi-warehouse::before {
  content: "\FFBB"; }

.mdi-washing-machine::before {
  content: "\F729"; }

.mdi-washing-machine-alert::before {
  content: "\F01E7"; }

.mdi-washing-machine-off::before {
  content: "\F01E8"; }

.mdi-watch::before {
  content: "\F589"; }

.mdi-watch-export::before {
  content: "\F58A"; }

.mdi-watch-export-variant::before {
  content: "\F894"; }

.mdi-watch-import::before {
  content: "\F58B"; }

.mdi-watch-import-variant::before {
  content: "\F895"; }

.mdi-watch-variant::before {
  content: "\F896"; }

.mdi-watch-vibrate::before {
  content: "\F6B0"; }

.mdi-watch-vibrate-off::before {
  content: "\FCB6"; }

.mdi-water::before {
  content: "\F58C"; }

.mdi-water-boiler::before {
  content: "\FFAF"; }

.mdi-water-boiler-alert::before {
  content: "\F01DE"; }

.mdi-water-boiler-off::before {
  content: "\F01DF"; }

.mdi-water-off::before {
  content: "\F58D"; }

.mdi-water-outline::before {
  content: "\FE6A"; }

.mdi-water-percent::before {
  content: "\F58E"; }

.mdi-water-polo::before {
  content: "\F02CB"; }

.mdi-water-pump::before {
  content: "\F58F"; }

.mdi-water-pump-off::before {
  content: "\FFB0"; }

.mdi-water-well::before {
  content: "\F008D"; }

.mdi-water-well-outline::before {
  content: "\F008E"; }

.mdi-watermark::before {
  content: "\F612"; }

.mdi-wave::before {
  content: "\FF4B"; }

.mdi-waves::before {
  content: "\F78C"; }

.mdi-waze::before {
  content: "\FBBA"; }

.mdi-weather-cloudy::before {
  content: "\F590"; }

.mdi-weather-cloudy-alert::before {
  content: "\FF4C"; }

.mdi-weather-cloudy-arrow-right::before {
  content: "\FE51"; }

.mdi-weather-fog::before {
  content: "\F591"; }

.mdi-weather-hail::before {
  content: "\F592"; }

.mdi-weather-hazy::before {
  content: "\FF4D"; }

.mdi-weather-hurricane::before {
  content: "\F897"; }

.mdi-weather-lightning::before {
  content: "\F593"; }

.mdi-weather-lightning-rainy::before {
  content: "\F67D"; }

.mdi-weather-night::before {
  content: "\F594"; }

.mdi-weather-night-partly-cloudy::before {
  content: "\FF4E"; }

.mdi-weather-partly-cloudy::before {
  content: "\F595"; }

.mdi-weather-partly-lightning::before {
  content: "\FF4F"; }

.mdi-weather-partly-rainy::before {
  content: "\FF50"; }

.mdi-weather-partly-snowy::before {
  content: "\FF51"; }

.mdi-weather-partly-snowy-rainy::before {
  content: "\FF52"; }

.mdi-weather-pouring::before {
  content: "\F596"; }

.mdi-weather-rainy::before {
  content: "\F597"; }

.mdi-weather-snowy::before {
  content: "\F598"; }

.mdi-weather-snowy-heavy::before {
  content: "\FF53"; }

.mdi-weather-snowy-rainy::before {
  content: "\F67E"; }

.mdi-weather-sunny::before {
  content: "\F599"; }

.mdi-weather-sunny-alert::before {
  content: "\FF54"; }

.mdi-weather-sunset::before {
  content: "\F59A"; }

.mdi-weather-sunset-down::before {
  content: "\F59B"; }

.mdi-weather-sunset-up::before {
  content: "\F59C"; }

.mdi-weather-tornado::before {
  content: "\FF55"; }

.mdi-weather-windy::before {
  content: "\F59D"; }

.mdi-weather-windy-variant::before {
  content: "\F59E"; }

.mdi-web::before {
  content: "\F59F"; }

.mdi-web-box::before {
  content: "\FFB1"; }

.mdi-web-clock::before {
  content: "\F0275"; }

.mdi-webcam::before {
  content: "\F5A0"; }

.mdi-webhook::before {
  content: "\F62F"; }

.mdi-webpack::before {
  content: "\F72A"; }

.mdi-webrtc::before {
  content: "\F0273"; }

.mdi-wechat::before {
  content: "\F611"; }

.mdi-weight::before {
  content: "\F5A1"; }

.mdi-weight-gram::before {
  content: "\FD1B"; }

.mdi-weight-kilogram::before {
  content: "\F5A2"; }

.mdi-weight-lifter::before {
  content: "\F0188"; }

.mdi-weight-pound::before {
  content: "\F9B4"; }

.mdi-whatsapp::before {
  content: "\F5A3"; }

.mdi-wheelchair-accessibility::before {
  content: "\F5A4"; }

.mdi-whistle::before {
  content: "\F9B5"; }

.mdi-whistle-outline::before {
  content: "\F02E7"; }

.mdi-white-balance-auto::before {
  content: "\F5A5"; }

.mdi-white-balance-incandescent::before {
  content: "\F5A6"; }

.mdi-white-balance-iridescent::before {
  content: "\F5A7"; }

.mdi-white-balance-sunny::before {
  content: "\F5A8"; }

.mdi-widgets::before {
  content: "\F72B"; }

.mdi-widgets-outline::before {
  content: "\F0380"; }

.mdi-wifi::before {
  content: "\F5A9"; }

.mdi-wifi-off::before {
  content: "\F5AA"; }

.mdi-wifi-star::before {
  content: "\FE6B"; }

.mdi-wifi-strength-1::before {
  content: "\F91E"; }

.mdi-wifi-strength-1-alert::before {
  content: "\F91F"; }

.mdi-wifi-strength-1-lock::before {
  content: "\F920"; }

.mdi-wifi-strength-2::before {
  content: "\F921"; }

.mdi-wifi-strength-2-alert::before {
  content: "\F922"; }

.mdi-wifi-strength-2-lock::before {
  content: "\F923"; }

.mdi-wifi-strength-3::before {
  content: "\F924"; }

.mdi-wifi-strength-3-alert::before {
  content: "\F925"; }

.mdi-wifi-strength-3-lock::before {
  content: "\F926"; }

.mdi-wifi-strength-4::before {
  content: "\F927"; }

.mdi-wifi-strength-4-alert::before {
  content: "\F928"; }

.mdi-wifi-strength-4-lock::before {
  content: "\F929"; }

.mdi-wifi-strength-alert-outline::before {
  content: "\F92A"; }

.mdi-wifi-strength-lock-outline::before {
  content: "\F92B"; }

.mdi-wifi-strength-off::before {
  content: "\F92C"; }

.mdi-wifi-strength-off-outline::before {
  content: "\F92D"; }

.mdi-wifi-strength-outline::before {
  content: "\F92E"; }

.mdi-wii::before {
  content: "\F5AB"; }

.mdi-wiiu::before {
  content: "\F72C"; }

.mdi-wikipedia::before {
  content: "\F5AC"; }

.mdi-wind-turbine::before {
  content: "\FD81"; }

.mdi-window-close::before {
  content: "\F5AD"; }

.mdi-window-closed::before {
  content: "\F5AE"; }

.mdi-window-closed-variant::before {
  content: "\F0206"; }

.mdi-window-maximize::before {
  content: "\F5AF"; }

.mdi-window-minimize::before {
  content: "\F5B0"; }

.mdi-window-open::before {
  content: "\F5B1"; }

.mdi-window-open-variant::before {
  content: "\F0207"; }

.mdi-window-restore::before {
  content: "\F5B2"; }

.mdi-window-shutter::before {
  content: "\F0147"; }

.mdi-window-shutter-alert::before {
  content: "\F0148"; }

.mdi-window-shutter-open::before {
  content: "\F0149"; }

.mdi-windows::before {
  content: "\F5B3"; }

.mdi-windows-classic::before {
  content: "\FA20"; }

.mdi-wiper::before {
  content: "\FAE8"; }

.mdi-wiper-wash::before {
  content: "\FD82"; }

.mdi-wordpress::before {
  content: "\F5B4"; }

.mdi-worker::before {
  content: "\F5B5"; }

.mdi-wrap::before {
  content: "\F5B6"; }

.mdi-wrap-disabled::before {
  content: "\FBBB"; }

.mdi-wrench::before {
  content: "\F5B7"; }

.mdi-wrench-outline::before {
  content: "\FBBC"; }

.mdi-wunderlist::before {
  content: "\F5B8"; }

.mdi-xamarin::before {
  content: "\F844"; }

.mdi-xamarin-outline::before {
  content: "\F845"; }

.mdi-xaml::before {
  content: "\F673"; }

.mdi-xbox::before {
  content: "\F5B9"; }

.mdi-xbox-controller::before {
  content: "\F5BA"; }

.mdi-xbox-controller-battery-alert::before {
  content: "\F74A"; }

.mdi-xbox-controller-battery-charging::before {
  content: "\FA21"; }

.mdi-xbox-controller-battery-empty::before {
  content: "\F74B"; }

.mdi-xbox-controller-battery-full::before {
  content: "\F74C"; }

.mdi-xbox-controller-battery-low::before {
  content: "\F74D"; }

.mdi-xbox-controller-battery-medium::before {
  content: "\F74E"; }

.mdi-xbox-controller-battery-unknown::before {
  content: "\F74F"; }

.mdi-xbox-controller-menu::before {
  content: "\FE52"; }

.mdi-xbox-controller-off::before {
  content: "\F5BB"; }

.mdi-xbox-controller-view::before {
  content: "\FE53"; }

.mdi-xda::before {
  content: "\F5BC"; }

.mdi-xing::before {
  content: "\F5BD"; }

.mdi-xing-box::before {
  content: "\F5BE"; }

.mdi-xing-circle::before {
  content: "\F5BF"; }

.mdi-xml::before {
  content: "\F5C0"; }

.mdi-xmpp::before {
  content: "\F7FE"; }

.mdi-yahoo::before {
  content: "\FB2A"; }

.mdi-yammer::before {
  content: "\F788"; }

.mdi-yeast::before {
  content: "\F5C1"; }

.mdi-yelp::before {
  content: "\F5C2"; }

.mdi-yin-yang::before {
  content: "\F67F"; }

.mdi-yoga::before {
  content: "\F01A7"; }

.mdi-youtube::before {
  content: "\F5C3"; }

.mdi-youtube-creator-studio::before {
  content: "\F846"; }

.mdi-youtube-gaming::before {
  content: "\F847"; }

.mdi-youtube-subscription::before {
  content: "\FD1C"; }

.mdi-youtube-tv::before {
  content: "\F448"; }

.mdi-z-wave::before {
  content: "\FAE9"; }

.mdi-zend::before {
  content: "\FAEA"; }

.mdi-zigbee::before {
  content: "\FD1D"; }

.mdi-zip-box::before {
  content: "\F5C4"; }

.mdi-zip-box-outline::before {
  content: "\F001B"; }

.mdi-zip-disk::before {
  content: "\FA22"; }

.mdi-zodiac-aquarius::before {
  content: "\FA7C"; }

.mdi-zodiac-aries::before {
  content: "\FA7D"; }

.mdi-zodiac-cancer::before {
  content: "\FA7E"; }

.mdi-zodiac-capricorn::before {
  content: "\FA7F"; }

.mdi-zodiac-gemini::before {
  content: "\FA80"; }

.mdi-zodiac-leo::before {
  content: "\FA81"; }

.mdi-zodiac-libra::before {
  content: "\FA82"; }

.mdi-zodiac-pisces::before {
  content: "\FA83"; }

.mdi-zodiac-sagittarius::before {
  content: "\FA84"; }

.mdi-zodiac-scorpio::before {
  content: "\FA85"; }

.mdi-zodiac-taurus::before {
  content: "\FA86"; }

.mdi-zodiac-virgo::before {
  content: "\FA87"; }

.mdi-blank::before {
  content: "\F68C";
  visibility: hidden; }

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }

.mdi-light:before {
  color: #fff; }

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }

.mdi-rotate-45:before {
  transform: rotate(45deg); }

.mdi-rotate-90:before {
  transform: rotate(90deg); }

.mdi-rotate-135:before {
  transform: rotate(135deg); }

.mdi-rotate-180:before {
  transform: rotate(180deg); }

.mdi-rotate-225:before {
  transform: rotate(225deg); }

.mdi-rotate-270:before {
  transform: rotate(270deg); }

.mdi-rotate-315:before {
  transform: rotate(315deg); }

.mdi-flip-h:before {
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH"; }

.mdi-flip-v:before {
  transform: scaleY(-1);
  -webkit-filter: FlipV;
          filter: FlipV;
  -ms-filter: "FlipV"; }

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }

@-webkit-keyframes mdi-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes mdi-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

/*# sourceMappingURL=materialdesignicons.css.map */

